import { AbstractElement } from './abstract-element'


export default class Equipment extends AbstractElement {
    constructor(element) {
        super(element);
    }

    builtinTypes() {
        return [
            "mehiza",
            "sdera",
            "bama",
            "bar",
            "bufe",
            "hupa"
        ]
    }

    getHtml() {
        const element = this.element
        let item
        let scale = "transform:scale(" + (element.scale || 1) + ") rotate(" + (element.rotation || 0) + "deg);";
        //scale = scale + " rotate(" + (element.rotation || 0) + "deg);";
        if (this.builtinTypes().includes(element.type)) {

            let eText = "" // typeof element.text !== "undefined" && element.text != "" ? element.text : "";

            item = `<span title="${eText}" 
            data-rotation="${element.rotation || 0}" 
            class="item" 
            style="${scale};
            top:${element.position.y};
            left:${element.position.x};">
            <div ${this.dataAttributes()}  class="imgWrap" >
                <img src="/static/images/${element.type}.png" style="pointer-events:none;">
            </div>

            ${eText !== "" ? '<div class="itemLabel">' + element.text + "</div>" : ""}`

            if (this.showActionButtons)
                item += this.actionButtonsHtml()

            item += `</span>`;
        } else {

            item = `<span 
                        title="${element.text || ""}"  
                        class="item"  
                        style="${scale};top:${element.position.y};left:${element.position.x};"
                    >
                        <div 
                            ${this.dataAttributes()} 
                            data-type="equipment" 
                            class="tableWrap ${element.shape === "ellipse" ? "rectangleTable ellipse" : element.shape}Table"
                        >
                                <div class="${element.shape === "ellipse" ? "rectangle" : element.shape}">
                                </div>
                        </div>
    
                    ${typeof element.text !== "undefined" && element.text !== "" ? '<div class="itemLabel">' + element.text + "</div>" : ""}`

            if (this.showActionButtons)
                item += this.actionButtonsHtml()

            item += `</span>`;
        }


        return item
    }

}

