<template>
  <div class="">
    
    <flatPickr :value="value" @input="$emit('input', $event )" :config="config"></flatPickr>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Hebrew } from "flatpickr/dist/l10n/he";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "TimePicker",
  props: {
    
    value: {
      
      default: () => {
        return new Date();
      },
    },
  },

 
  components: { flatPickr },
  data() {
    return {
      // Initial value can be a date object as well
      //selectedDate: this.theDate,
      // Get more form https://flatpickr.js.org/options/
      config: {
         enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
        
        locale: Hebrew, // locale for this instance only
      },
    };
  },
  mounted() {},
  methods: {
      trigger($date){
            this.$emit('change', $date) 
      }
  },
};
</script>
