<template>
    <div>

        <h2 class="my-5">
            <i class="fa fa-sms mx-4"></i>
            <span>מה תרצו לשלוח לבעלי האירוע ?</span>

        </h2>
        <div class="d-flex">
            <ul class="flex-fill">
                <li class="text-left h3" v-for="_t in templateList" :key="_t.templateKey">
                    <label>
                        <input type="radio" name="smsKey" :value="_t.templateKey" v-model="selected" />
                        {{ _t.templateKey }}
                    </label>
                </li>

            </ul>
            <code class="w-50" v-if="selected">{{ templateList.find(x => x.templateKey == selected).content }}</code>
        </div>
        <button @click="send()" class="s_btn big my-3">{{ $t('send') }} {{ toPhone }}</button>

    </div>
</template>

<script>
import { apiRequest } from '../../core/http/axios_api_v2'
export default {
    name: "SMS",
    computed: {
        toPhone: function () {
            return this.event?.owner?.phone || this.eventPlanner?.ownerDetails[0]?.phone
        },
        event: function () {
            return this.$store.state.EventStore.event;
        },
        eventPlanner() {
            return this.$store.state.EventStore.eventPlanner;
        },

    },
    data() {
        return {
            templateList: [],
            selected: null
        }
    },
    mounted() {
        apiRequest('get', "templates").then((list) => {
            this.templateList = list.filter(x => x.isSimple == true)
        })
    },
    methods: {
        send() {
            apiRequest('post', "sms/send-template", {
                templateKey: this.selected,
                phone: this.toPhone,
            })
                .then(x => this.AlertSuccess())

        }
    },
}
</script>
