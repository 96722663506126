<template>
  <div class="SFormInput" :class="display">
    <label v-if="label && type!='checkbox'">{{ label }}</label>
    <component :is="element" :id="id" class="input" :class="inputClass" :name="name" :type="type" :value.prop="text" @input="update" :style="inputStyle" :placeholder="placeholder" v-bind="$attrs">
    </component>
    <label v-if="label && type=='checkbox'">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  model: {
    prop: "text",
    event: "update",
  },
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    text: {
      required: true,
    },
    placeholder: {
      type: String,
    },
    inputClass: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: String,
      default: "",
    },
    display: {
      type: String,
      default: "vertical",
    },
  },
  computed: {
    element() {
      return this.type === "textarea" ? this.type : "input";
    },
  },
  methods: {
    update(event) {
      if (this.type == 'checkbox')
        return this.$emit("update", event.currentTarget.checked);
      this.$emit("update", event.currentTarget.value);
    },
  },
};
</script>

<style lang="scss">
.SFormInput {

  &.vertical {
    display: flex;
    flex-direction: column;
  }

  &.horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  label {
    margin: 0 0 10px 0
  }

  input {
    width: 100%;
    height: 54px;
    line-height: 54px;
    border-radius: 50px;
    border: 1px solid #bfc1c5;
    /* background: rgba(0,0,0,0.35); */
    padding: 0 15px;
  }

  textarea {
    max-width: 100%;
  }

  input,
  textarea {
    font-size: inherit;
    font-family: inherit;
    border: none;
    outline: none;
    padding: 11px 16px;
    border-radius: 27px;
    border: solid 1px #cacad6;

    &:focus {
      border-color: #2ecc71;
    }
  }

  input[type="checkbox"] {
    float: right;
    //transform: scale(1.5, 1.5);
    width: 40px;
    height: 40px;
    margin: 10px;

    ~label {
      // line-height: 40px;
      vertical-align: middle;
    }
  }
}
</style>