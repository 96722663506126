<template>
  <div class="row" v-if="item">
    <div class="col-sm-12 col-md-12">
      <img
        :src="FileStoragePath(item.image)"
        alt=""
        class="img-responsive dish-image"
      />
    </div>
    <div class="col-sm-12 col-md-12 my-4">
      <h3 class="text-center">{{ item.name }}</h3>
      <h4 class="text-center">{{ item.category ? item.category.name : "" }}</h4>
      <hr />
      <div class="mb-3" v-if="item.type == 'food' && item.price>0 ">{{ $t("price") }}: {{ item.price }} ₪</div>
      <div>
        {{ $t("description") }}:
        <p>{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "../../Config.js";

export default {
  name: "ViewItem",
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          name: "",
          image: "",
          price: "",
        };
      },
    },
  },
  data() {
    return {};
  },

  methods: {
    Config() {
      return Config;
    },
  },
};
</script>

<style scoped>
.dish-image {
  /* max-height: 250px;  */
  display: block;
  margin: 0 auto;
  width: 100%;
  max-height:55vh;
  object-fit:cover
}
</style>
