<template>
    <div>
        <div class="innerMainContent">

            <PageHeader icon="i_calender" :title="$t('tableAdd')" :breadcrumbs="[
                { name: $t('menu_settings'), route: '/settings' },
                { name: $t('tables'), route: '/settings/tables' },
                { name: $t('tableEdit'), route: null },
            ]">
                <template v-slot:action-buttons>
                    <!-- <router-link tag="button" class="s_btn" :to="'/pdf/event/'+eventID">צור PDF</router-link> -->
                </template>
            </PageHeader>


            <div class="innerBox">
                <form action="" id="editBanner" class="formWrap" @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-xs-12 containerWidth">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="name">*{{ $t('table_name') }}</label>
                                        <input type="text" id="name" name="name" v-model="table.title" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="target">*{{ $t('shape') }}</label>
                                        <div class="selectWrap">
                                            <select name="target" id="target" v-model="table.shape" required>
                                                <option v-for="shape in $t('shapes')" v-bind:value="shape.id" v-bind:selected="shape.id == 'all'">{{ shape.title }}</option>
                                            </select>
                                            <svg class="i_select">
                                                <use xlink:href="#i_select"></use>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="name">*{{ $t('capacity') }}</label>
                                        <input type="number" id="capacity" name="capacity" v-model="table.capacity" required>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="table.shape !== 'ellipseTable' && table.shape !== 'circleTable'">
                                <div class="col-sm-3">
                                    <div class="inputWrap">
                                        <label for="tableSide1">{{ $t('side1') }} {{ $t('optional') }}</label>
                                        <input type="number" id="tableSide1" name="tableSide1" v-model="table.side1">
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="inputWrap">
                                        <label for="tableSide2">{{ $t('side2') }} {{ $t('optional') }}</label>
                                        <input type="number" id="tableSide2" name="tableSide2" v-model="table.side2">
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="inputWrap">
                                        <label for="tableSide3">{{ $t('side3') }} {{ $t('optional') }}</label>
                                        <input type="number" id="tableSide3" name="tableSide3" v-model="table.side3">
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="inputWrap">
                                        <label for="tableSide4">{{ $t('side4') }} {{ $t('optional') }}</label>
                                        <input type="number" id="tableSide4" name="tableSide4" v-model="table.side4">
                                    </div>
                                </div>
                            </div>
                            <FormInput v-model="table.maxReserve" label="כמות רזרבה מקסימלית"></FormInput>
                            <FormInput type="checkbox" label="שולחן עץ" display="horizontal" v-model="table.attrs.isWood" :checked="table.attrs.isWood" />
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap submitWrap">
                                        <button class="submit">{{ $t('save') }}</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditTable',
    data() {
        return {
            table: {
                id: '',
                title: '',
                capacity: '',
                shape: 'SQU',
                side1: '',
                side2: '',
                side3: '',
                side4: '',
                attrs: {}
            },
        }
    },
    components: {
    },

    beforeMount() {

        //get table
        if (this.tableId) {
            this.$data.table.id = this.tableId;
        } else {
            this.$data.table.id = this.$router.currentRoute.params.id;
        }

        this.$store.dispatch('getTable', this.$data.table.id)
            .then(response => {
                //success
                this.$data.table = response;
            })
            .catch(err => {
                //err
            })
    },

    methods: {
        submitForm() {
            this.$store.dispatch('updateTable', this.$data.table)
                .then(response => {
                    this.AlertSuccess()
                    //  this.$router.push('/settings/tables');
                })
                .catch(response => {
                    let err = this.$t('fatalError');

                    this.$swal({
                        confirmButtonText: this.$t('okButton'),
                        type: 'error',
                        title: err,
                        timer: 3000
                    }).catch(this.$swal.noop);
                })
        }
    }
}

</script>
<style scoped>
.editEventActions {
    float: left;
    margin-top: 6px;
}

.editEventActions .backBtn span {
    color: #7c97c5;
    font-weight: 700;
    font-size: 1.235rem;
    display: inline-block;
    vertical-align: middle;
}

.editEventActions .backBtn svg {
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 18px;
    fill: #293b5a;
    margin-right: 5px;
}

.editEventActions .deleteBtn {
    width: 218px;
    height: 54px;
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 27px;
    margin-left: 30px;

}

.editEventActions .deleteBtn span {
    color: #293b5a;
    font-weight: 700;
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
}

.editEventActions .deleteBtn svg {
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 23px;
    fill: #849dc8;
    margin-left: 10px;
}

.innerBox {
    padding: 30px;
}
</style>
