
const axios = require('axios');

// *****************************************
// Create Instance
// *****************************************
const axiosV1 = axios.create();


// *****************************************
// AXIOS CONFIG
// *****************************************
//add progress to axios
axios.interceptors.request.use(function (config) {
    if (typeof app.$Progress !== 'undefined') {
        app.$Progress.start()
        app.$Progress.finish()
    }

    return config;
}, function (error) {
    // Do something with request error

    return Promise.reject(error);
});




export default axiosV1