const stringToColor = (str) => {
    let hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export const calcCenterPoint = (element) => {
    const xCenter = element.offsetLeft + element.offsetWidth / 2;
    const yCenter = element.offsetTop + element.offsetHeight / 2;
    return [xCenter, yCenter]
}

export const connectTables = (gridWrap, p1, p2, lineColor, lineLabel = null) => {
    if (!p1 || !p2) {
        console.debug(p1, p2, ' one elemnt is null cannot connect line')
        return
    }
   

    const randomColor = lineColor ? lineColor : stringToColor(lineLabel || 'addfsf');

    let line = "", strokwidth = 2


    let t1CenterCoords = calcCenterPoint(p1)
    let t2CenterCoords = calcCenterPoint(p2)

    document.querySelectorAll('.element-line-connector').forEach(x => x.remove())

    line = `<svg  class="element-line-connector" style="overflow:visible;z-index:0;position:absolute;top:0;left:0;pointer-events: none;">`
    // add linear line
    line += `<line x1="${t1CenterCoords[0]}px" y1="${t1CenterCoords[1]}px" x2="${t2CenterCoords[0]}px" y2="${t2CenterCoords[1]}px" style="stroke:${randomColor};stroke-width:${strokwidth};" />`
    line += ' </svg> '
    gridWrap.insertAdjacentHTML("beforeend", line);

    // add text
    if (lineLabel) {
        line = `<svg style="overflow:visible;position:absolute;top:0;left:0;pointer-events: none;">
      <text x="${(t1CenterCoords[0] + t2CenterCoords[0]) / 2}px" y="${(t1CenterCoords[1] + t2CenterCoords[1]) / 2}px" style="fill:${randomColor};font-weight:bold;stroke:#000000">${lineLabel}</text></svg>`

        gridWrap.insertAdjacentHTML("beforeend", line);
    }



}