<template>
  <div class="innerMainContent">
    <PageHeader icon="i_event_planner" :title="$t('page_title_event_builder')" :breadcrumbs="[
      { name: $t('event'), route: '/event/wedding/' + eventID },
      { name: $t('page_title_event_builder'), route: '' },
    ]">
      <template v-slot:action-buttons>
        <button class="s_btn" @click="exportAllData">{{ $t('export') }}</button>

      </template>
    </PageHeader>

    <div class="row">
      <div class="col-sm-12 col-md-4" id="event_btn_nav">
        <div class="s-tabs">
          <button :class="'s-tab ' + (step == i + 1 ? 'selected' : '')" @click="step = i + 1" v-for="(tab, i) of tabs" :key="tab">
            {{ i + 1 }}. {{ tab }}
          </button>
        </div>
      </div>
    </div>

    <div class="s-box p-rel">
      <button class="s_btn export_button" @click="exportData">{{ $t('export') }}</button>

      <div class="d-flex items-center" v-if="$store.state.EventStore.event.stepsDone">
          <FormInput type="checkbox" display="horizontal" text="" @update="stepDone()" v-if="step==1" v-model="event.stepsDone['appointment_menu_completed']"   :checked="event.stepsDone['appointment_menu_completed'] === true" />
          <FormInput type="checkbox" display="horizontal" text="" @update="stepDone()" v-if="step==2" v-model="event.stepsDone['appointment_design_completed']" :checked="event.stepsDone['appointment_design_completed'] === true" />
          <FormInput type="checkbox" display="horizontal" text="" @update="stepDone()" v-if="step==3" v-model="event.stepsDone['appointment_manage_completed']" :checked="event.stepsDone['appointment_manage_completed'] === true" />
          <h3> שלב הושלם</h3>
      </div>
      <div v-show="step == 1">
        <MenuSelect :event-planner="eventPlanner"></MenuSelect>
      </div>
      <div v-show="step == 2">
        <DecorationSelect :event-planner="eventPlanner"></DecorationSelect>
      </div>

      <!-- 4 stages step -->
      <div v-show="step == 3">

        <div class="s-tabs">
          <button :class="'s-tab ' + (subStep == i + 1 ? 'selected' : '')" @click="subStep = i + 1" v-for="(tab, i) of subTabs" :key="tab">
            {{step}}.{{ i + 1 }}. {{ tab }}
          </button>
        </div>

        <div v-show="subStep == 1">

          <EventSuppliers :event-planner="eventPlanner"></EventSuppliers>
        </div>
        <div v-show="subStep == 2">

          <CanopyAndWelcome :event-planner="eventPlanner"></CanopyAndWelcome>
        </div>

        <div v-show="subStep == 3">
          <TimeSchedule :event-planner="eventPlanner"></TimeSchedule>
        </div>

        <div v-show="subStep == 4">
          <EventToDoList :event-planner="eventPlanner"></EventToDoList>
        </div>

      </div>


    </div>
  </div>
</template>

<script>

import EventToDoList from "../components/EventBuilder/ToDoList.vue";
import TimeSchedule from "../components/EventBuilder/TimeSchedule.vue";
import MenuSelect from "../components/EventBuilder/MenuSelect.vue";
import EventSuppliers from "../components/EventBuilder/EventSuppliers.vue";
import DecorationSelect from "../components/EventBuilder/DecorationSelect.vue";
import CanopyAndWelcome from "../components/EventBuilder/CanopyAndWelcome.vue";
import { exportCSV } from "../services/export/csv"
import PageHeader from "../components/Common/PageHeader.vue";
export default {
  name: "EventBuilderPage",
  components: {
    EventToDoList,
    TimeSchedule,
    PageHeader,
    DecorationSelect,
    MenuSelect,
    EventSuppliers,
    CanopyAndWelcome
  },
  computed: {
    event: function () {
      // return this.eventID
      return this.$store.state.EventStore.event
    },
    eventPlanner: function () {
      // return this.eventID
      return this.$store.state.EventStore.eventPlanner
    }
  },
  data() {
    return {
      step: 1,
      subStep: 1,
      // eventPlanner: {},
      eventID: null,
      tabs: [
        this.$t("ChooseMenu"),
        this.$t("ChooseDesign"),
        'נוספים'
      ],
      subTabs: [
        this.$t("SuppliersContacts"),
        this.$t("CanomyWelcome"),
        this.$t("TimeSchedule"),
        this.$t("TODO"),
      ]
    };
  },
  mounted() {
    //fetch event
    this.eventID = parseInt(this.$router.currentRoute.params.eventID);

    if (this.eventID == "" || this.eventID == 0)
      this.$router.push("/");

    this.$store.dispatch('getEvent', this.eventID);
    this.$store.dispatch('getEventPlanner', this.eventID)
    .catch(e => {
      this.AlertConfirm("תיק אירוע לא קיים, האם אתה רוצה ליצור אחד חדש ?").then((res) => {
        if (res === true) 
          this.$router.push(`/events/${this.eventID}`)
      })
    })
    this.$store.dispatch("getUserSettings")

  },
  methods: {
    stepDone(e) {
      this.$store.dispatch("updateEvent", {
        id: this.event.id,
        stepsDone: this.event.stepsDone
      });

    },
    exportData() {
      let items = []
      const eventId = this.event.id
      switch (this.step) {

        case 1:
          this.$router.push(`/pdf/food?eventId=${eventId}`)
          break
        case 2:
          this.$router.push(`/pdf/design?eventId=${eventId}`)
          break

        case 3:
          this.$router.push(`/pdf/suppliers?eventId=${eventId}`)
          break

        case 4:
          this.eventPlanner.timeSchedule.forEach(time => {
            items.push(time)
          })
          items.push({ 'notes': this.eventPlanner.timeScheduleNotes })
          exportCSV([this.$t('name'), this.$t('phone'), this.$t('proffesion')], items, 'times.csv')
          break

        case 6:
          this.$router.push(`/pdf/tasks?eventId=${eventId}`)
          break
      }
    },
    exportAllData() {
      let items = []
      const eventId = this.$store.state.EventStore.event.id
      this.$router.push(`/pdf/eventplanner?eventId=${eventId}`)
    }

  },
};
</script>
<style scoped lang="scss">
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#event_btn_nav {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: flex-end;
  overflow-x: scroll;

}

.s-tab {
  font-weight: bold;
}

.export_button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999
}
</style>