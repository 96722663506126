<template>
  <div class="innerMainContent">

    <PageHeader :title="$t('permissions')" icon="i_profile" :breadcrumbs="[
      { name: $t('users'), route: '/users' },
      { name: $t('permissions'), route: '' },
    ]">
      <template v-slot:action-buttons></template>
    </PageHeader>

    <div class="s-box mt-3 d-flex">
      <div class="mx-4">
        <div class="my-3 s_btn big p-2 d-flex items-center">
          <input type="checkbox" value="true" v-model="allow_all" class="mx-3" id="cb_select_all" @click="saveAll" />
          <label for="cb_select_all"> אפשר גישה מלאה (סימון הכל)</label>
        </div>
      </div>
    </div>

    <div class="d-flex gap-3 no-wrap">
      <div class="innerBox my-3 p-2 rounded" style="width:33%;flex:1 1" v-for="group of Object.keys(permissionGroups)">
        <h3>{{ group }}</h3>
        <div class="my-3" v-for="p of permissionGroups[group]">
          <label for="">
            <input type="checkbox" class="cb_permission" value="true" :checked="
              (Array.isArray(user_permissions) &&
                user_permissions.includes(p.permission))
              || allow_all
            " @click="updatePermssions()" :data-permission="p.permission" />
            {{ p.action }}</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { setUndefinedValue } from '@/helpers/object'
export default {
  name: "UserPermissions",
  data() {
    return {
      role_permissions: {},
      user_permissions: {},
      actionsStyle: "",
      currentID: 0,
      search: "",
      allow_all: false,
      i18n: {
        'plan': 'תיק אירוע',
        'arrival': 'מצב הגעה'

      }
    };
  },
  mounted() { },
  computed: {
    permissionGroups() {
      if (!this.role_permissions) return {}
      return this.devideToGroups(this.role_permissions)
    }
  },
  beforeMount() {
    const userId = this.$router.currentRoute.params.id;
    this.$store.dispatch("getPermissions", userId).then((r) => {
      this.role_permissions = r.role_permissions
      this.user_permissions = r.user_permissions ? r.user_permissions : {}
    });
  },
  methods: {
    saveAll() {
      setTimeout((e) => { this.updatePermssions(e) }, 1000)
    },
    splitPermission(permissionName) {
      let parts = permissionName.split(' ', 2)
      parts[1] = (parts[1] || '').toLowerCase()
      parts[0] = (parts[0] || '').toLowerCase()
      let module = this.$i18n.keyExists(parts[1]) ? this.$t(parts[1]) : this.i18n[parts[1]]
      let action = this.$i18n.keyExists(parts[0]) ? this.$t(parts[0]) : this.i18n[parts[0]]
      return { parts, module, action }
    },
    translate(permissionName) {
      let { parts, module, action } = this.splitPermission(permissionName)
      return (action ? `<b>${action}</b>  :` : '') + module
    },
    updatePermssions(e) {
      const userId = this.$router.currentRoute.params.id;
      if (userId) {
        this.$store
          .dispatch("updateUserPermissions", {
            id: userId,
            permissions: this.getSelected(),
          })
          .then((r) => { this.$notify('עודכנה הרשאה') });
      }
    },
    devideToGroups(permission) {
      let groups = {}
      Object.keys(this.role_permissions).forEach(permission => {
        let { parts, module, action } = this.splitPermission(this.role_permissions[permission])
        console.log(parts, module, action)
        groups[module] = setUndefinedValue(groups[module], [])
        groups[module].push({ permission, module, action })
      })
      return groups

    },

    getSelected() {
      let cbs = document.querySelectorAll(".cb_permission");
      let list = [];
      for (let index = 0; index < cbs.length; index++) {
        const cb = cbs[index];
        if (cb.checked) {
          list.push(cb.dataset.permission);
        }
      }
      return list;
    },

  },
};
</script>

<style scoped>

</style>
