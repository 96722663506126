<template>
  <div :class="`file-manager ${multiple ? 'multiple' : 'single'}`">

    <!-- multiple file header -->
    <div class="file-manager-header d-flex justify-center" v-if="multiple">
      <div class="flex-fill">
        <h3>{{ $t("files") }}</h3>
      </div>
      <div>
        <button type="button" @click="openFileDialog" v-bind:title="$t('add')" v-tippy>
          <svg class="box-icon">
            <use xlink:href="#i_plus"></use>
          </svg>
        </button>
      </div>
    </div>

    <!-- single file -->
    <div class="d-flex" v-else>
      <button type="button" @click="openFileDialog" v-bind:title="$t('addFile')" v-tippy>

        <svg class="box-icon">
          <use xlink:href="#i_plus"></use>
        </svg>
        <span class="mx-2">{{ $t('addFile') }}</span>
      </button>
    </div>

    <input :multiple="multiple ? 'true' : 'false'" type="file" class="InputfileUpload" name="file" v-bind:accept="extensions.join(',')" :id="'file_upload_' + id" @change="handleFileInputChange" />


    <div class="seatupTableWrapper">
      <table class="seatupTable" v-if="files.length > 0 || filesToUpload.length > 0">
        <tr v-for="file of filesToUpload" :key="file.id">
          <td>
            {{ file }}
          </td>
        </tr>
        <tr v-for="file of files" :key="file.id">
          <td>
            <a target="_blank" :href="$store.getters.getConfig.SETTINGS.MEDIA_URL + file.filePath">{{ file.fileName }} - {{ file.fileType }}
            </a>
          </td>
          <td v-if="allowDelete">
            <button type="button" @click="deleteFile(file.id)">
              {{ $t("delete") }}
            </button>
          </td>
        </tr>
      </table>
      <div v-else>אין קבצים</div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "FileManager",
  props: {
    // fileIds: {
    //   type: Array,
    //   required,
    //   default: true,
    // },
    extensions: {
      type: Array,
      default: function () {
        return [".png", ".gif", ".bmp", ".jpeg", ".jpg"];
      },
    },
    type: {
      type: String,
      default: 'file'
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    belongToEvent: {
      type: Number,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.id = "file_manager_" + Math.floor(Math.random() * 1000);
  },
  mounted() {


  },
  data() {
    return {
      id: "",
      files: [],
      filesToUpload: [],
    };
  },
  methods: {

    handleFileInputChange() {
      this.showFiles()
      this.showPreview()

      // auto upload image to server
      if (this.autoUpload) {
        this.upload()
      }
    },
    showPreview() {

      let files = document.getElementById("file_upload_" + this.id).files;

      // generate preview image
      const [file] = files
      if (file) {
        let preview = URL.createObjectURL(file)
        this.$emit('fileSelected', preview)
      }

    },
    showFiles() {
      this.filesToUpload = [];
      let files = document.getElementById("file_upload_" + this.id).files;
      for (var i = 0, l = files.length; i < l; i++) {
        this.filesToUpload.push(files[i].name);
      }

      // generate preview image
      const [file] = files
      if (file) {
        let preview = URL.createObjectURL(file)
        this.$emit('fileSelected', preview)
      }


    },
    openFileDialog() {
      document.getElementById("file_upload_" + this.id).click();
    },
    setFiles(ids) {
      if (ids == "") return;
      this.$store
        .dispatch("FileStore/getFiles", ids)
        .then((response) => {
          this.files = response;
        })
        .catch((err) => {
          console.warn("failed to get file " + err.toString());
        });
    },
    loadEventFiles(eventId) {

      this.$store
        .dispatch("FileStore/getFilesByEvent", eventId)
        .then((response) => {
          this.files = response;
        })
        .catch((err) => {
          console.warn("failed to get file " + err.toString());
        });
    },
    deleteFile(id) {
      this.$store
        .dispatch("FileStore/deleteFile", id)
        .then((response) => {
          this.files = this.files.filter(_file => _file.id != id)
          this.$emit("deleted", id);
          //this.files = response;
        })
        .catch((err) => {
          console.warn("failed to delete file " + err.toString());
        });
    },
    async upload() {
      return this.multiple ? this.uploadFiles() : this.uploadFile()
    },
    getFileInputDOM() {
      return document.getElementById("file_upload_" + this.id);
    },
    havePendingFiles() {
      let input = this.getFileInputDOM()
      return (input.files.length != 0)
    },
    async uploadFiles() {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        let file = this.getFileInputDOM()

        if (!this.havePendingFiles()) resolve({ status: 10 });

        for (let index = 0; index < file.files.length; index++) {
          formData.append(`files[]`, file.files[index]);
        }
        if(this.belongToEvent)
          formData.append(`belongToEvent`, this.belongToEvent);
        // formData.append(`rewrite`, 'test');
        this.$store
          .dispatch("FileStore/uploadFiles", formData)
          .then(r => {
            this.$emit('onUploaded', r)
            resolve(r)

          })
          .catch((err) => {
            this.AlertError(this.$t("uploadFailed"));
            file.value = file.defaultValue;
            console.warn("failed to upload file " + err.toString());
            reject(err);
          });

      })

    },
    async uploadFile() {

      let formData = new FormData();
      let file = document.getElementById("file_upload_" + this.id);

      if (file.files.length == 0) return Promise.resolve({ status: 10 });
      formData.append(`file`, file.files[0]);
      if(this.belongToEvent)
        formData.append(`belongToEvent`, this.belongToEvent);

      return this.$store
        .dispatch("FileStore/uploadFile", formData)
        .then(r => {
          this.$emit('onUploaded', r)
          return Promise.resolve(r)
        })
        .catch((err) => {
          this.AlertError(this.$t("uploadFailed"));
          file.value = file.defaultValue;
          console.warn("failed to upload file " + err.toString());
          reject(err);
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.InputfileUpload {
  width: 20px;
}

.file-manager {
  &.multiple {
    margin: 20px 0;
    border: 1px dashed var(--main-color);
    padding: 10px;
    border-radius: 10px;

    &-header {
      border-bottom: 1px solid var(--main-color)
    }
  }

  &.single {
    background: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 30px;
    display: flex;
    padding: 5px 10px;

    .seatupTableWrapper {
      float: right;
    }
  }
}


.seatupTable tr:first-child {
  border: none;
}

.box-icon {
  float: right;
}
</style>