<template>
  <a
    class="dditem"
    @click.prevent="onClick"
    :href="href"
  >
    <slot/>
  </a>
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
        default: ''
      },
      inertia: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onClick() {
        this.$emit('click')
        if (this.href) {
          if (this.inertia) {
            this.$inertia.visit(this.href)
          } else {
            window.location = this.href
          }
        }
      }
    }
  }
</script>

<style scoped>
.dditem{
    background: white;
    border-bottom: 1px solid blue;
    font-weight: bold;
    display: block;
    padding:10px;
}

.dditem svg{
    width:20px;
    height:20px;
    float:right;
    margin:0 10px; 
}
</style>