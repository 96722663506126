<template>
  <div class="eventPresetView innerMainContent">



    <div :class="isEditing ? 's-modal show' : 's-modal'" style="z-index: 999999999">
      <div class="s-modal-content small">
        <button class="close" @click="isEditing = false">
          <svg>
            <use xlink:href="#i_close"></use>
          </svg>
          <span class="sr-only">{{ $t("close") }}</span>
        </button>
        <EditPresetTable v-bind:table="selectedTable" v-on:tableChanged="handlePresetTableChange" ref="editPresetTable"></EditPresetTable>
        <div style="clear: both"></div>
      </div>
    </div>

    <PageHeader icon="i_calender" :title="event.name" :breadcrumbs="[
      { name: $t('event'), route: '/event/wedding/' + event.id },
      { name: $t('presetEdit'), route: '' },
    ]" />


    <div class="row">
      <div class="col-md-6 col-xs-12 matchHeight eventSummary">
        <div class="innerBox">
          <div class="eventInfoWrapper">
            <h2>
              <svg class="i_couple">
                <use xlink:href="#i_couple"></use>
              </svg>
              <span>
                <div>{{ event.name }}</div>
                <div class="date">{{ event.dateStr }}</div>
              </span>
            </h2>
            <ul class="mainInfo">
              <li>
                <svg class="i_location">
                  <use xlink:href="#i_location"></use>
                </svg>
                <span class="liTitle">{{ $t("hall") }}:</span>
                <span>{{ event.hall.name }}</span>
              </li>
              <li>
                <svg class="i_users">
                  <use xlink:href="#i_users"></use>
                </svg>
                <span class="liTitle">{{ $t("guestsNum") }}:</span>
                <span>{{ totalGuests }}</span>
              </li>
            </ul>
            <ul class="moreInfo" v-if="event.owner">
              <li>
                <h3>{{ $t("hostName") }}</h3>
                <div></div>
              </li>
              <li>
                <h3>{{ $t("name") }}</h3>
                <div>{{ event.owner.name }}</div>
              </li>
              <li>
                <h3>{{ $t("phone") }}</h3>
                <div>{{ event.owner.phone }}</div>
              </li>
              <li>
                <h3>{{ $t("email") }}</h3>
                <div>{{ event.owner.email }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12 matchHeight">
        <div class="innerBox pieWrapper">
          <div class="pieInnerWrapper">
            <h2>{{ $t("guests") }}</h2>
            <ul data-pie-id="pie-svg">
              <li v-bind:data-value="getGuestsPercent(guests.notResponded).toString()" class="notResponded">
                <span><span class="precent">{{ getGuestsPercent(guests.notResponded) }}%</span>
                  {{ $t("ofGuests") }}
                  <span class="status">{{ $t("notResponded") }}</span></span>
              </li>
              <li v-bind:data-value="getGuestsPercent(guests.coming).toString()" class="respondedPositive">
                <span><span class="precent">{{ getGuestsPercent(guests.coming) }}%</span>
                  {{ $t("ofGuests") }}
                  <span class="status">{{ $t("willCome") }}</span>
                  {{ $t("toEvent") }}</span>
              </li>
              <li v-bind:data-value="getGuestsPercent(guests.maybe).toString()" class="respondedMaybe">
                <span><span class="precent">{{ getGuestsPercent(guests.maybe) }}%</span>
                  {{ $t("ofGuests") }}
                  <span class="status">{{ $t("mayCome") }}</span>
                  {{ $t("willCome") }} {{ $t("toEvent") }}</span>
              </li>
              <li v-bind:data-value="getGuestsPercent(guests.notComing).toString()" class="respondedNegative">
                <span><span class="precent">{{ getGuestsPercent(guests.notComing) }}%</span>
                  {{ $t("ofGuests") }}
                  <span class="status">{{ $t("notComing") }}</span>
                  {{ $t("toEvent") }}</span>
              </li>
              <li v-bind:data-value="getGuestsPercent(guests.reserve).toString()" class="respondedNegative">
                <span><span class="precent">{{ getGuestsPercent(guests.reserve) }}%</span>
                  {{ $t("ofGuests") }}
                  <span class="status">{{ $t("reserve") }}</span>
                  {{ $t("toEvent") }}</span>
              </li>
            </ul>
          </div>
          <div id="pie-svg"></div>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
    <div class="bottomPart">
      <div class="row">
        <div class="col-md-4">
          <div class="innerBox ulamDetailes">
            <h2>
              {{ $t("sittingArrangements") }}
              <span class="placeNumbers">(<span class="ulamCapacity">{{ totalGuests }}</span>)</span><svg class="i_info">
                <use xlink:href="#i_info"></use>
              </svg>
            </h2>

            <div class="" v-if="presets && presets.length > 0">
              <select style="background: white;display: block;width: 100%;-webkit-box-sizing: border-box;box-sizing: border-box;padding: 16px;border-radius: 4px;border: solid 1px #a0a0aa;border-radius: 27px;border: solid 1px #cacad6;" v-model="selectedPreset" v-bind="$attrs" :id="name" class="s-form-select s-form-select-wrapper" :class="classList" :name="name" @change="setPreset($event)"> 
                <option :value="0" >
                  {{ placeholder ? placeholder : $t("select") }}
                </option>
                <option v-for="(option, index) in presets.map(x => { return { content: x.name, value: x.id } })" :selected="option.value == value" :value="option.value" :key="index">
                  {{ option.content }}
                </option>
              </select>
              
              <div class="" v-if="hasRegions">
                <FormSelect v-model="regionSelectedOption" @change="($event) => setRegion($event)" label="איזור:" :options="event.preset.attrs.regions.map(x => { return { content: x.name, value: x.name } })"></FormSelect>
              </div>
            </div>
            <div v-else>אין פריסות</div>

            <input  type="range" min="1" max="10" step="0.1" v-model="presetScale" @input="setScale">

            <div class="checkboxWrap">
              <span class="checkboxInnerWrap">
                <input id="lockPresetCheckBox" type="checkbox" :checked="this.event.presetLocked ? 'checked' : ''" @click="setPresetLock" />
                <span class="checkS"></span>
                <svg class="i_v">
                  <use xlink:href="#i_v"></use>
                </svg>
              </span>
              <label>{{ $t("disablePresetChanges") }}</label>
            </div>
            <div class="checkboxWrap">
              <span class="checkboxInnerWrap">
                <input type="checkbox" :checked="snapToGrid ? 'checked' : ''" @click="useGrid()" />
                <span class="checkS"></span>
                <svg class="i_v">
                  <use xlink:href="#i_v"></use>
                </svg>
              </span>
              <label>{{ $t("useGridMode") }}</label>
            </div>
            <div class="checkboxWrap">
              <span class="checkboxInnerWrap">
                <input type="checkbox" :checked="this.event.groupSeating ? 'checked' : ''" @click="useGroupSeating()" />
                <span class="checkS"></span>
                <svg class="i_v">
                  <use xlink:href="#i_v"></use>
                </svg>
              </span>
              <label>{{ $t("useGroupSeating") }}</label>
            </div>
            <div class="checkboxWrap">
              <span class="checkboxInnerWrap">
                <input id="strictTablesCheckbox" type="checkbox" :checked="this.event.strictTables ? 'checked' : ''" @click="setStrictTables" />
                <span class="checkS"></span>
                <svg class="i_v">
                  <use xlink:href="#i_v"></use>
                </svg>
              </span>
              <label>{{ $t("strictTables") }}</label>
            </div>
            <div class="checkboxWrap">
              <span class="checkboxInnerWrap">
                <input id="resevedSeatingAllowedCheckbox" type="checkbox" :checked="this.event.resevedSeatingAllowed ? 'checked' : ''" @click="setAllowReservedSeating" />
                <span class="checkS"></span>
                <svg class="i_v">
                  <use xlink:href="#i_v"></use>
                </svg>
              </span>
              <label>{{ $t("resevedSeatingAllowed") }}</label>
            </div>
            <div class="panel-group" id="moreFieldsAcc">
              <div class="panel panel-default">
                <div class="
                          panel-heading
                          accordion-toggle
                          question-toggle
                          collapsed
                        " data-toggle="collapse" data-parent="#moreFieldsAcc" data-target="#inputGroup2">
                  <h4 class="panel-title">
                    <a href="#" @click.prevent="">{{ $t("tableType") }}</a>
                    <svg class="i_plus_2">
                      <use xlink:href="#i_plus_2"></use>
                    </svg>
                    <svg class="i_minus">
                      <use xlink:href="#i_minus"></use>
                    </svg>
                  </h4>
                </div>
                <div id="inputGroup2" class="panel-collapse collapse">
                  <div class="panel-body">
                    <VisualTableList></VisualTableList>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="innerPannel">
                  <div class="
                            panel-heading
                            accordion-toggle
                            question-toggle
                            collapsed
                          " data-toggle="collapse" data-parent="#moreFieldsAcc" data-target="#inputGroup3">
                    <h4 class="panel-title">
                      <a href="#" @click.prevent="">{{ $t("tools") }}</a>
                      <svg class="i_plus_2">
                        <use xlink:href="#i_plus_2"></use>
                      </svg>
                      <svg class="i_minus">
                        <use xlink:href="#i_minus"></use>
                      </svg>
                    </h4>
                  </div>
                  <div id="inputGroup3" class="panel-collapse collapse">
                    <div class="panel-body">
                      <ul>
                        <li class="matchHeight" @click="copyItems">
                          <div class="imgWrap">
                            <svg class="svg_icon">
                              <use xlink:href="#i_copy"></use>
                            </svg>
                          </div>
                          <div>{{ $t("copy") }}</div>
                        </li>
                        <li class="matchHeight" @click="autoNum">
                          <div class="imgWrap">
                            <svg class="svg_icon">
                              <use xlink:href="#i_coffee_table"></use>
                            </svg>
                          </div>
                          <div>{{ $t("autoNum") }}</div>
                        </li>

                        <li class="matchHeight" @click="resetPreset">
                          <div class="imgWrap">
                            <svg class="svg_icon">
                              <use xlink:href="#i_trash"></use>
                            </svg>
                          </div>
                          <div>{{ $t("resetPreset") }}</div>
                        </li>

                        <li class="matchHeight" @click="saveAsTemplate">
                          <div class="imgWrap">
                            <svg class="svg_icon">
                              <use xlink:href="#i_template"></use>
                            </svg>
                          </div>
                          <div>{{ $t("saveAsTemplate") }}</div>
                        </li>

                        <li class="matchHeight" @click="setTablesReserving">
                          <div class="imgWrap">
                            <svg class="svg_icon">
                              <use xlink:href="#i_offer_tab"></use>
                            </svg>
                          </div>
                          <div>{{ $t("markAsReserved") }}</div>
                        </li>
                        <li class="matchHeight" @click="$refs.showChangePresetModal.show()">
                          <div class="imgWrap">
                            <svg class="svg_icon">
                              <use xlink:href="#i_download"></use>
                            </svg>
                          </div>
                          <div>{{ $t("change_hall") }}</div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="
                          panel-heading
                          accordion-toggle
                          question-toggle
                          collapsed
                        " data-toggle="collapse" data-parent="#moreFieldsAcc" data-target="#inputGroup1">
                  <h4 class="panel-title">
                    <a href="#" @click.prevent="">{{ $t("equipment") }}</a>
                    <svg class="i_plus_2">
                      <use xlink:href="#i_plus_2"></use>
                    </svg>
                    <svg class="i_minus">
                      <use xlink:href="#i_minus"></use>
                    </svg>
                  </h4>
                </div>

                <!-- Equipment Drag-->
                <div id="inputGroup1" class="panel-collapse collapse">
                  <div class="panel-body">
                    <div id="dragablleItems_2">

                      <span class="item matchHeight" v-for="prop in presetProps">
                        <div class="imgWrap" :data-type="prop.id">
                          <img :src="`/static/images/${prop.id}.png`" />
                        </div>
                        <div class="itemLabel">{{ $t(prop.id) }}</div>
                      </span>

                      <span class="item matchHeight" v-for="equipment of $store.state.equipments">
                        <div class="tableWrap" v-bind:class="
                          equipment.shape != 'drop'
                            ? equipment.shape == 'wood'
                              ? 'squareTable ' + equipment.shape
                              : equipment.shape == 'ellipseTable'
                                ? 'rectangleTable ' + equipment.shape
                                : equipment.shape
                            : ''
                        " data-type="equipment" v-bind:data-shape="getShape(equipment.shape)">
                          <div v-if="equipment.shape != 'drop'" v-bind:class="
                            equipment.shape != 'ellipseTable'
                              ? getShape(equipment.shape)
                              : 'rectangle'
                          "></div>
                          <img v-if="equipment.shape == 'drop'" src="/static/images/drop.png" />
                        </div>
                        <div class="itemLabel">{{ equipment.title }}</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4 matchHeight" v-if="!$store.state.isTablet">
              <div v-bind:class="!$store.state.isTablet ? 'totalSeats' : ''">
                <div class="innerTotalSeats">
                  <div>
                    <span class="ulamCapacity">{{ getSeats("all") }}</span><span>{{ $t("total") }}</span>
                  </div>
                  <div>{{ $t("hallPlaces") }}</div>
                </div>
              </div>
            </div>

            <div class="col-md-4 matchHeight">
              <div class="innerBox precentageWrap seatedPlaces">
                <div class="circlePrecWrap">
                  <svg viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-bg" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <path class="circle" stroke-dasharray="0, 100" v-bind:stroke-dasharray="
                      (isNaN(
                        isNaN(
                          Math.round(
                            (getSeats('taken') / getSeats('seats')) * 100
                          )
                        )
                          ? 0
                          : Math.round(
                            (getSeats('taken') / getSeats('seats')) * 100
                          )
                      )
                        ? 0
                        : isNaN(
                          Math.round(
                            (getSeats('taken') / getSeats('seats')) * 100
                          )
                        )
                          ? 0
                          : Math.round(
                            (getSeats('taken') / getSeats('seats')) * 100
                          )) + ', 100'
                    " d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <text x="18" y="20.35" class="percentage">
                      {{
                        isNaN(
                          Math.round(
                            (getSeats("taken") / getSeats("seats")) * 100
                          )
                        )
                        ? 0
                        : Math.round(
                          (getSeats("taken") / getSeats("seats")) * 100
                        )
                      }}%
                    </text>
                  </svg>
                </div>
                <div class="precentageTxt">
                  <div class="precentageIcon">
                    <span class="mainIcon"></span> <span></span>
                  </div>
                  <div class="placesNum">{{ getSeats("taken") }}</div>
                  <div class="seatedStatus">{{ $t("taken") }}</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 matchHeight">
              <div class="innerBox precentageWrap unSeatedPlaces">
                <div class="circlePrecWrap">
                  <svg viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-bg" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <path class="circle" stroke-dasharray="0, 100" v-bind:stroke-dasharray="
                      (isNaN(
                        isNaN(
                          Math.round(
                            (getSeats('available') / getSeats('seats')) * 100
                          )
                        )
                          ? 0
                          : Math.round(
                            (getSeats('available') / getSeats('seats')) *
                            100
                          )
                      )
                        ? 0
                        : isNaN(
                          Math.round(
                            (getSeats('available') / getSeats('seats')) *
                            100
                          )
                        )
                          ? 0
                          : Math.round(
                            (getSeats('available') / getSeats('seats')) * 100
                          )) + ', 100'
                    " d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    <text x="18" y="20.35" class="percentage">
                      {{
                        isNaN(
                          Math.round(
                            (getSeats("available") / getSeats("seats")) * 100
                          )
                        )
                        ? 0
                        : Math.round(
                          (getSeats("available") / getSeats("seats")) * 100
                        )
                      }}%
                    </text>
                  </svg>
                </div>
                <div class="precentageTxt">
                  <div class="precentageIcon">
                    <span class="mainIcon"></span> <span></span>
                  </div>
                  <div class="placesNum">{{ getSeats("available") }}</div>
                  <div class="seatedStatus">{{ $t("available") }}</div>
                </div>
              </div>
            </div>
            <div class="col-xs-12" style="height: 30px">
              <div class="controlBar">
                <span class="right">{{ $t("rollbackNote") }}</span>
                <button class="s_btn big" v-if="tablesNumbering" @click="tablesNumbering = false">
                  {{ $t("endNumbering") }}
                </button>
                <button class="s_btn big" v-if="tablesReserving" @click="
                  () => {
                    this.tablesReserving = false;
                    this.saveChanges();
                  }
                ">
                  {{ $t("endReserving") }}
                </button>
                <button class="s_btn big" v-if="!selectMode" @click="selectMode = true" style="margin-left: 5px">
                  {{ $t("startSelecting") }}
                </button>
                <button class="s_btn big" v-if="selectMode" @click="finishSelectMode" style="margin-left: 5px">
                  {{ $t("finish") }}
                </button>
                <button class="printPreset" @click="printPreset" :disabled="savingPresetScreenshot">
                  <svg>
                    <use xlink:href="#i_print"></use>
                  </svg>
                </button>
                <button class="s_btn big" @click="saveChanges()" style="background:green;margin-left: 5px">
                  {{ $t("save") }}
                </button>
              </div>
            </div>
          </div>

          <hr />


          
          <div id="for_print" class="d-flex wrap">


            <div id="ulamLayout">
              <div id="zoomContainer" >
                <img :src="presetBackground" />
                <div class="grid" id="gridWrap"></div>
              </div>
            </div>


            <table v-if="event && savingPresetScreenshot" class="printInfo px-2" :style="{ margin: savingPresetScreenshot ? '30px 0 0 0' : '' }">
              <tr>
                <td colspan="2">{{ event.name }}</td>
              </tr>
              <tr>
                <td colspan="2">{{ event.date | dateOnly }}</td>
              </tr>
              <tr>
                <td>{{ $t("taken") }}</td>
                <td>{{ getSeats("taken") }}</td>
              </tr>
              <tr>
                <td>{{ $t("available") }} </td>
                <td>{{ getSeats("available") }}</td>
              </tr>
              <tr>
                <td>{{ $t("total") }}</td>
                <td>{{ getSeats("all") }}
                  ({{ Math.floor((event.guestsSeatingLimit * event.reservePercentage) / 100) }} {{ $t("seats") }} {{ $t("reserve") }})
                </td>
              </tr>
            </table>

            <div v-show="!savingPresetScreenshot">
              <textarea placeholder="הערות להדפסה" class="printNotes" id="ta_printNotes" v-model="event.printNotes" onkeydown="document.getElementById('save_Print').style.display='block'"></textarea>
              <br>
              <button class="s_btn d-none" @click="savePrintNotes()" id="save_Print">{{ $t('save') }}</button>
            </div>
            <!-- dirty fix for htmltocanvas render error -->
            <div id="div_printNotes" v-show="savingPresetScreenshot" style="white-space: pre-wrap;">{{ event.printNotes }}</div>

          </div>
        </div>
      </div>
    </div>

    <Modal ref="showChangePresetModal">
      <ChangeEventPreset v-on:saved="handleHallChange"></ChangeEventPreset>
    </Modal>

  </div>
</template>

<script>
import Config from "../../Config";
import { toDataURL } from "../../helpers/image.js";
import EditPresetTable from "./EditPresetTable.vue";
import VisualTableList from "../Tables/VisualTableList.vue";
import Seating from "../../classes/preset-builder/Seating.js";
import PageHeader from "../Common/PageHeader.vue";
import ChangeEventPreset from "./ChangeEventPreset.vue";

import PresetBuilder from "../../classes/preset-builder/PresetBuilder";
import { PresetBuilderItemSelect, DragSystem, PresetBuilderItemTransform } from "../../classes/preset-builder/plugins/index.js"
import "../../scss/preset/preset.scss";
import "../../scss/preset/seats.scss";


import { guestsStatusStatistics, guestPresetCapacity } from "../../helpers/guest/status.js";

export default {
  name: "EventPreset",
  data() {
    return {
      PresetBackgroundDataUrl: '',
      selectMode: true,
      savingPresetScreenshot: false,
      inGridMode: false,
      controlPressed: false,
      tablesNumbering: false,
      tablesReserving: false,
      autoNumStart: 1,
      changesSaved: true,
      presetScale: 1,
      presetProps: [
        { id: 'bama', label: '' },
        { id: 'bar', label: '' },
        { id: 'hupa', label: '' },
        { id: 'sdera', label: '' },
        { id: 'bufe', label: '' },
        { id: 'mehiza', label: '' },
      ],
      event: {
        hall: {},
        owner: {},
        preset: {},
        presetData: [],
        guests: [],
        strictTables: true,
      },
      presets: [],
      selectedPreset: 0,
      selectedTable: null,
      isEditing: false,
      isSaving: false,
      snapToGrid: false,
      guestList: [],
      guests: {
        notResponded: 0,
        coming: 0,
        maybe: 0,
        notComing: 0,
        reserve: 0,
      },
      selectedRegion: null,
      regionSelectedOption: null,
      presetBuilder: null,
      itemSelectPlugin: null,
      dragSystem: null,
      draggedItem: { dbID: "", startPosition: { left: 0, top: 0 } },
    };
  },
  components: {
    VisualTableList,
    EditPresetTable,
    ChangeEventPreset,
    PageHeader,
  },
  computed: {
    presetBackground: function () {
      return this.event.preset.background &&
        this.event.preset.background.indexOf("http") >= 0
        ? this.event.preset.background
        : Config.SETTINGS.MEDIA_URL + this.event.preset.background;
    },
    totalGuests() {
      return this.getTotalGuests();
    },
    hasRegions() {
      return this.event?.preset?.attrs?.regions
    },
  },
  watch: {

  },
  mounted() {
    this.initRollbackEngine();
    this.initKeyboardMovements();
  },
  beforeMount() {
    this.$store.dispatch("getTables");
    this.$store.dispatch("getEquipments");

    const eventID = parseInt(this.$router.currentRoute.params.id);
    if (eventID == "" || eventID == 0) this.$router.push("/");

    //fetch event and preset
    window.liveIsBetter = true;
    this.$store
      .dispatch("getEvent", eventID)
      .then((response) => {
        //success
        this.event = response;
        this.inGridMode = this.event.inGridMode || false;

         // if not preset
        if (!this.event.preset) {
          this.event.preset = { background: "" };
        } 
        // if preset have regions - load  layout region
        else if(this.hasRegions) {

          let findFunction
          if(this.event.settings?.defaultRegion && this.event.settings?.defaultRegion !='') {
            findFunction = r => r?.name === this.event.settings?.defaultRegion
          } else {
            findFunction = r => r?.isDefault === true
          }
          this.selectedRegion = this.event.preset.attrs.regions.find(findFunction)
          this.regionSelectedOption = this.selectedRegion.name

        }
        
        this.getPresetDataUrl()

        this.$store
          .dispatch("getGuests", eventID)
          .then((response) => {
            this.guestList = response;
            this.initiate();
            this.calculateGuests();
          })
          .catch((err) => {
            console.error(err);
            this.$router.push("/");
            this.AlertError();
          });

        //get hall presets
        this.$store
          .dispatch("getPreset", "hall/" + this.event.hall.id)
          .then((response) => {
            //success
            this.presets = response;
            this.selectedPreset = this.event.preset
              ? this.event.preset.id
              : null;
          });
      })
      .catch((err) => {
        //err
        console.error(err);
        this.$router.go(-1);
        this.AlertError();
      });
  },
  beforeDestroy() {

    window.addEventListener("DOMContentLoaded", (event) => {
      $(document).off("keydown");
    });

  },
  beforeRouteLeave(to, from, next) {

    if (this.changesSaved == false) {
      this.AlertConfirm('שינויים לא נשמרו, להמשיך ?').then(res => {
        if (res == true) { next(); }

        this._destroy()
        return;
      })
    }
    else
      next()

  },
  methods: {

    setScale($event) {

      this.presetScale = ($event.target.value)
      this.presetBuilder.setScale(this.presetScale)
      // this.collectPresetData()
      // this.setLayout()

    },
    getPresetDataUrl() {
      toDataURL(this.presetBackground)
        .then((dataUrl) => {
          this.PresetBackgroundDataUrl = dataUrl;
        });
    },

    handleHallChange(savedData) {
      this.$refs.showChangePresetModal.close()
      this.event = { ...this.event, ...savedData }
      this.setLayout({ elements: this.event.presetData });
      this.initiate();
    },
    savePrintNotes() {

      document.getElementById('save_Print').style.display = 'none';
      this.save();
      this.AlertSuccess()

    },
    getGuestsPercent(sum) {
      let _ = Math.round((sum / this.getTotalGuests()) * 100);
      return isNaN(_) ? 0 : _;
    },
    getTotalGuests() {
      return (
        this.guests.coming +
        this.guests.maybe +
        this.guests.notComing +
        this.guests.notResponded
      );
    },
    calculateGuests() {
      if (this.guestList.length > 0) {
        this.guests = guestsStatusStatistics(this.guestList)
        this.guests.maybe = this.guests.maybeComing
      }
    },
    initiate() {
      let _this = this;
      //window.addEventListener('DOMContentLoaded', (event) => {
      $.getScript("/static/js/pie.min.js", function () {
        $.getScript("/static/js/dependencies.js", function () {
          _this.initJS();
        });
      });
     
    },
    getSeatsStatusForTable(id = "") {
      let status = {
        confirmed: 0,
        notComing: 0,
        maybeComing: 0,
        notResponded: 0,
        reserve: 0,
      };

      if (typeof id === "undefined" || id == "" || !id) return status;
      if (this.guestList.length > 0) {
        for (let guest of this.guestList) {
          if (guest.tableId == id && typeof guest.status === "object") {
            status.confirmed += +guest.status.coming;
            status.maybeComing += +guest.status.maybeComing;
            status.reserve += +guest.status.reserve;
            status.notComing += +guest.status.notComing;
            status.notResponded += +guest.status.notResponded;
          }
        }
      }

      return status;
    },

    setLayout(layout, isRestore = false) {

      const grid = "#gridWrap"
      if (!isRestore) this.saveRollBack(layout);

      this.presetBuilder = new PresetBuilder(this.event, this.guestList, grid, {
        'autofocusRegion': false
      });

      this.presetBuilder.tableClick = this.onTableClick
      this.presetBuilder.showTableActions = true;
      this.presetBuilder.buildHtml();
      this.presetBuilder.drawSeats();

      if (this.selectedRegion) {
        this.presetBuilder.setFocus(this.selectedRegion)
        this.presetScale = this.presetBuilder.scale
        this.selectedRegion = null // prevents preset from focusing on area after every item insert
      } else {
        this.presetBuilder.setScale(this.presetScale)
      }
      /** PLUGINS */
      let itemTransform = new PresetBuilderItemTransform(this.presetBuilder, {
        rotateSelector: '.rotateIcon',
        scaleSelector: '.resizeIcon'
      })
      this.itemSelectPlugin = new PresetBuilderItemSelect(this.presetBuilder)
      this.dragSystem = new DragSystem(this.presetBuilder, { snapToGrid: this.snapToGrid })


      // this part will allow "drag/move plugin" to move the selected items we pass from "item-select" plugin
      this.itemSelectPlugin.onSelectHandler = (selected) => {
        let itemsToMove = []
        Object.keys(selected).forEach(dbid => {
          itemsToMove.push(this.presetBuilder.getTableDomBydbID(dbid).parentNode)
        })
        this.dragSystem.setItemsToMove(itemsToMove)
      }

      /** * START DRAG HANDLER*/
      this.dragSystem.startDragHandler = (dragItem) => {
        let actionDOM = dragItem.querySelector('.item-actions')
        if (actionDOM)
          actionDOM.style.display = 'none'
      }


      /*** DRAG DROP/mouseupHandler HANDLER*/
      this.dragSystem.mouseupHandler = (dragItem) => {
        this.changesSaved = false

        // remove action icons from table
        if (dragItem) {
          let actionDOM = dragItem.querySelector('.item-actions')
          if (actionDOM) actionDOM.style.display = 'block'
        }

      }


      this.dragSystem.dropHandler = (dragItem) => {
        this.changesSaved = false
        this.collectPresetData()
        this.setLayout()
      }


      // table list + miscs -  drag drop
      document.querySelectorAll('#dragablleItems .item, #dragablleItems_2 .item').forEach(_item => {

        _item.setAttribute("draggable", "true")

        _item.addEventListener("dragstart", (evt) => {
          evt.dataTransfer.setData("insertObjectMode", "true");
          let target = _item.cloneNode(true)

          let label = target.querySelector('.itemLabel')
          if (label) label.remove()

          this.dragSystem.draggedElement = target

        });

      })

      this.presetBuilder.getItemsDOM().forEach(item => {

        const editIcon = item.querySelector(".editIcon")
        editIcon.addEventListener('mousedown', (e) => {
          e.stopPropagation();
          const dbID = item.firstElementChild.dataset.dbid
          const table = this.presetBuilder.getItem(dbID)
          if (table) {
            this.selectedTable = table;
            this.isEditing = true;
          }
        })

        const deleteIcon = item.querySelector(".deleteIcon")
        deleteIcon.addEventListener('mousedown', (e) => {
          e.stopPropagation();
          const dbID = item.firstElementChild.dataset.dbid
          this.AlertConfirm(this.$t("removeItemPrompt"))
            .then((result) => {
              if (result !== true) return
              if (typeof dbID !== "undefined" && dbID != "") {
                item.remove();
                this.event.presetData = this.event.presetData.filter(_ => _ != dbID)
              }
              this.collectPresetData()
              this.setLayout()
            })

        })

        // show hide actions
        item.addEventListener("mouseover", () => {
          item.classList.add('hovered')
        });
        item.addEventListener("mouseout", () => {
          item.classList.remove('hovered')
        });
        // end show hide actions

      })

      if (this.getTotalGuests() >= 1) Pizza.init();

    },

    onTableClick(table, dom) {

      if (this.tablesNumbering) {
        //  this.recursiveTableNumbering(this.autoNumStart, table.dbID);
        //if (table.tableNum && parseInt(table.tableNum) === this.autoNumStart) return;
        const otherTableContainingNumber = this.presetBuilder
          .getContainerDOM()
          .querySelector(`[data-table-num="${this.autoNumStart}"]`)

        dom.firstElementChild.dataset.tableNum = this.autoNumStart;
        dom.querySelector(".tableNum").textContent = this.autoNumStart

        if (otherTableContainingNumber && otherTableContainingNumber.dataset.dbid != table.dbID) {
          otherTableContainingNumber.dataset.tableNum = '';
          otherTableContainingNumber.querySelector(".tableNum").textContent = ''
        }
        this.autoNumStart++;
        return true;
      }

      if (this.tablesReserving) {
        table.reserved = !(table.hasOwnProperty("reserved") && table.reserved === true);
        dom.querySelector(".tableNum").textContent = table.reserved ? this.$t("reserved") + table.tableNum : table.tableNum
        dom.firstElementChild.setAttribute('data-reserved',  table.reserved);
      }

    },

    initJS() {
      this.setLayout();
      $("body").on("change", "#ulamType", function () {
        let placesNumber = $(this).find(":selected").data("places");
        $(".ulamCapacity").text(placesNumber);
      });
    },

    getPreset(presetId) {
      return this.presets.find(preset => preset.id == presetId)
    },

    setRegion(option) {
      
      if (option) {
        this.selectedRegion = this.event.preset.attrs.regions.find(x => x.name == option.content)
        console.log('setting region', this.selectedRegion)
        this.$store
        .dispatch("updateEvent", {
          id: this.event.id,
          settings: {...this.event.settings, ...{defaultRegion: this.selectedRegion.name }},
        })
      } else {
        this.presetScale = 1;
        this.selectedRegion = null
      }

      this.setLayout()
    },
    setPreset(e) {
      debugger;
      let _this = this;
      this.selectedPreset=parseInt(e.target.value);
      if (this.event.preset.id > 0) {
        this.$swal({
          title: this.$t("changePresetPrompt"),
          text: this.$t("unrevetableOperation"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
          .then((result) => {
            if (result === true) {
              if (this.selectedPreset != "") {

                let preset = this.getPreset(this.selectedPreset)
                if (preset) {
                  _this.event.preset = preset;
                  _this.event.presetData = preset.jData || [];
                  _this.updatePreset();
                }
              }

            } else {
              _this.selectedPreset = _this.event.preset.id;
            }
          })
          .catch(() => {
            _this.selectedPreset = _this.event.preset.id;
          });
      } else {
        if (this.selectedPreset != "") {
          let preset = this.getPreset(this.selectedPreset)
          if (preset) {
            this.event.preset = preset;
            this.event.presetData = preset.jData || [];
          }
        }
        this.updatePreset();
      }
    },


    updatePreset() {
      let presetCleaned = this.event.preset.jData;
      if (presetCleaned && presetCleaned.length) {
        presetCleaned.forEach(function (item) {
          if (item.type == "table") item.occupiedPlaces = "0";
        });
      }
      this.$store
        .dispatch("updateEvent", {
          id: this.event.id,
          preset: this.selectedPreset,
          presetData: presetCleaned || [],
        })
        .then((_) => {
          this.setLayout({ elements: presetCleaned });
        })
        .catch((err) => {
          console.error(err)
          this.AlertError();
        });
    },
    setPresetLock() {
      this.event.presetLocked = $("#lockPresetCheckBox").is(":checked");
      this.$store
        .dispatch("updateEvent", {
          id: this.event.id,
          presetLocked: this.event.presetLocked,
        })
        .catch((err) => {
          this.AlertError();
        });
    },

    setStrictTables() {
      this.event.strictTables = $("#strictTablesCheckbox").is(":checked");
      this.$store
        .dispatch("updateEvent", {
          id: this.event.id,
          strictTables: this.event.strictTables,
        })
        .catch((err) => {
          this.AlertError();
        });
    },

    setAllowReservedSeating() {
      this.event.resevedSeatingAllowed = $("#resevedSeatingAllowedCheckbox").is(":checked");
      this.$store
        .dispatch("updateEvent", {
          id: this.event.id,
          resevedSeatingAllowed: this.event.resevedSeatingAllowed,
        })
        .catch((err) => {
          this.AlertError();
        });
    },
    handlePresetTableChange(newTable) {

      var foundIndex = this.presetBuilder.getItems().findIndex((x) => x.dbID == newTable.dbID);
      if (foundIndex == -1) {
        console.warn("could not find table item", newTable, 'in', this.presetBuilder.getItems());
        return
      }

      const prvTable = { ...{}, ...this.event.presetData[foundIndex] }

      this.event.presetData[foundIndex] = newTable;
      this.event.presetData[foundIndex].scale = prvTable.scale;
      this.event.presetData[foundIndex].position = prvTable.position;

      this.save()
      this.selectedTable = null;
      this.isEditing = false;
    },

    collectPresetData() {
      let items = [];

      let $container = this.presetBuilder.getContainerDOM();
      const gridWidth = $container.offsetWidth;
      const gridHeight = $container.offsetHeight;

      this.presetBuilder.getItemsDOM().forEach(presetItem => {
        const $realEl = presetItem.firstElementChild

        let dbID = $realEl.dataset.dbid
        if (typeof dbID === "undefined" || !dbID || dbID == "") {
          dbID = window.guid();
        }

        let item = {
          dbID: dbID,
          id: $(this).index(),
          shape: $realEl.dataset.shape,
          type: $realEl.dataset.type,
          reserved: $realEl.dataset.reserved == "true",
          reserveQnt: Number($realEl.dataset.reserveQnt),
          scale: $realEl.dataset.scale ?? 1,
          rotation: $realEl.dataset.rotation ?? 0,
          liveGuestCount: $realEl.dataset.liveGuestCount,
          isWood: $realEl.dataset.wood == "true" ? "true" : "false",
          position: {
            x: presetItem.style.left,
            y: presetItem.style.top,
          },
        };

        try {
          let x = item.position.x.replace("px", "");
          let y = item.position.y.replace("px", "");
          const offset = 30
          if (x < offset || y < -offset || x > gridWidth - offset || y > gridHeight + offset) {
            // item.position.x = "0px";
            // item.position.y = "40px";
          }
        } catch (e) {

        }

        const isEmpty = (value) => {
          return typeof value !== "undefined" && value != ""
        }

        if (typeof $realEl.dataset.side1 !== "undefined") {
          Object.assign(item, {
            side1: isEmpty($realEl.dataset.side1) ? $realEl.dataset.side1 : "",
            side2: isEmpty($realEl.dataset.side2) ? $realEl.dataset.side2 : "",
            side3: isEmpty($realEl.dataset.side3) ? $realEl.dataset.side3 : "",
            side4: isEmpty($realEl.dataset.side4) ? $realEl.dataset.side4 : ""
          });
        } else {
          Object.assign(item, {
            side1: isEmpty(presetItem.dataset.side1) ? presetItem.dataset.side1 : "",
            side2: isEmpty(presetItem.dataset.side2) ? presetItem.dataset.side2 : "",
            side3: isEmpty(presetItem.dataset.side3) ? presetItem.dataset.side3 : "",
            side4: isEmpty(presetItem.dataset.side4) ? presetItem.dataset.side4 : ""
          });
        }

        const label = presetItem.querySelector(".itemLabel")
        if (label) {
          item.text = label.text;
        }

        if (item.type == "table") {

          Object.assign(item, {
            tableNum: $realEl.dataset.tableNum,
            tableCapcity: $realEl.dataset.capacity,
            occupiedPlaces: $realEl.dataset.occupiedplaces
          });

        } else {
          item.shape = "";
        }

        if (item.shape === "") item.shape = $realEl.dataset.shape;
        if (item.shape !== "") items.push(item);

      })

      this.event.presetData = items
      this.presetBuilder.setItems(items)
    },

    saveChanges() {
      this.collectPresetData()
      return this.save();
    },

    save(isRestore = false) {

      if (this.isSaving) return;

      this.isSaving = true;

      let updateDTO = {
        id: this.event.id,
        name: this.event.name,
        presetData: this.event.presetData,
        printNotes: this.event.printNotes,
        resetPreset:
          this.event.hasOwnProperty("resetPreset") &&
          this.event.resetPreset === true,
      }

      // protects preset data from deleting
      if (typeof updateDTO.presetData == "undefined" || (Array.isArray(updateDTO.presetData) && this.event.presetData.length == 0))
        delete (updateDTO.presetData)

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("updateEvent", updateDTO)
          .then((seatupEvent) => {
            this.event.resetPreset = false;
            this.setLayout({ elements: this.event.presetData }, isRestore);
            this.isSaving = false;
            this.$notify({
              title: 'פריסה נשמרה בהצלחה!',
              text: 'הצליח'
            });
            this.changesSaved = true
            resolve()
          })
          .catch((err) => {
            this.isSaving = false;
            this.event.resetPreset = false;
            console.error(err)
            this.AlertError()
            reject()
          });
      })

    },

    copyItems() {
      if (this.selectMode) {
        this.collectPresetData()

        let toCopy = this.itemSelectPlugin.getSelectedAsArray()
        if (!toCopy.length > 0) return;

        const items = {};
        for (let i in toCopy) {
          let item = this.presetBuilder.getItem(toCopy[i].dbID);
          if (!item.scale) {
            console.error("table missing scale parameters", item);
          }

          let newItem = {};
          Object.assign(newItem, item);

          newItem.scale = item.scale;
          newItem.dbID = window.guid();
          newItem.id = parseInt(this.event.presetData.length) + parseInt(i) + 1;
          newItem.tableNum = "";
          // debugger
          newItem.position = {
            x: parseInt(newItem.position.x.toString().replace(/[^\d|.]/g, "")) + 10 + "px",
            y: parseInt(newItem.position.y.toString().replace(/[^\d|.]/g, "")) + 10 + "px",
          };

          items[newItem.dbID] = newItem

          this.presetBuilder.addItem(newItem);
        }

        this.save().then(r => {
          this.itemSelectPlugin.setSelected(items)
        });


      } else {
        this.AlertError(this.$t("firstUseSelectMode"))
      }
    },

    autoNum() {
      this.tablesNumbering = false;
      this.tablesReserving = false;
      let _this = this;
      this.$swal({
        title: this.$t("startNumberTitle"),
        text: this.$t("startNumberText"),
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        inputValue: 1,
        showLoaderOnConfirm: true,
        confirmButtonText: this.$t("save"),
        cancelButtonText: this.$t("cancel"),
        preConfirm: function (value) {
          return new Promise(function (resolve) {
            if (typeof value === "undefined" || value == "") {
              _this.$swal.showValidationError(_this.$t("startNumberRequired"));
              _this.$swal.hideLoading();
            } else {
              resolve();
            }
          });
        },
      })
        .then((num) => {
          num = parseInt(num);
          if (isNaN(num)) return;
          this.autoNumStart = num;
          this.tablesNumbering = true;
        })
        .catch((res) => {
          this.tablesNumbering = false;
        });
    },
    setTablesReserving() {
      this.tablesNumbering = false;
      this.tablesReserving = !this.tablesReserving;
    },
    getSeats(fetch = "all") {
      let capacity
      try {
        capacity = guestPresetCapacity(this.event.presetData, this.guestList)
      } catch (e) {
        capacity = {
          seats: 0,
          taken: 0
        }
      }
      switch (fetch) {
        case "all":
        default:
          return capacity.seats;
        case "taken":
          return capacity.taken;
        case "available":
          return capacity.seats - capacity.taken > 0 ? capacity.seats - capacity.taken : 0;
      }
    },
    getShape(shape) {
      return Seating.ShapeClass(shape);
    },
    useGrid() {
      this.snapToGrid = !this.snapToGrid;
      this.collectPresetData()
      //this.setLayout([])
      this.$store.dispatch("updateEvent", {
        id: this.event.id,
        inGridMode: this.snapToGrid,
      }).then(r => {
        this.event.inGridMode = r.inGridMode
        this.setLayout([])
      });
    },
    useGroupSeating() {
      this.event.groupSeating = !this.event.groupSeating;
      this.$store.dispatch("updateEvent", {
        id: this.event.id,
        groupSeating: this.event.groupSeating,
      });
    },
    resetPreset() {
      this.$swal({
        title: this.$t("resetPresetPrompt"),
        text: this.$t("unrevetableOperation"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
      })
        .then((result) => {
          if (result === true) {
            this.confirmReset();
          }
        })
        .catch(this.$swal.noop);
    },
    confirmReset() {
      let _this = this;
      this.$swal({
        title: this.$t("beforeWeReset"),
        text: this.$t("enterYourPassword"),
        input: "password",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        showLoaderOnConfirm: true,
        confirmButtonText: this.$t("resetPreset"),
        cancelButtonText: this.$t("cancel"),
        preConfirm: function (value) {
          return new Promise(function (resolve) {
            if (typeof value === "undefined" || value == "") {
              _this.$swal.showValidationError(_this.$t("presetNameRequired"));
              _this.$swal.hideLoading();
            } else {
              //validate
              _this.$store
                .dispatch("checkPassword", value)
                .then((response) => {
                  resolve();
                })
                .catch((err) => {
                  this.AlertError(_this.$t("passwordIncorrect"))
                });
            }
          });
        },
      })
        .then((password) => {
          this.event.presetData = [];
          this.event.resetPreset = true;
          this.save();
        })
        .catch(this.$swal.noop);
    },
    saveAsTemplate() {
      var _this = this;
      this.$swal({
        title: this.$t("saveAsTemplateTitle"),
        text: this.$t("saveAsTemplateText"),
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showLoaderOnConfirm: true,
        confirmButtonText: this.$t("save"),
        cancelButtonText: this.$t("cancel"),
        preConfirm: function (value) {
          return new Promise(function (resolve) {
            if (typeof value === "undefined" || value == "") {
              _this.$swal.showValidationError(_this.$t("presetNameRequired"));
              _this.$swal.hideLoading();
            } else {
              resolve();
            }
          });
        },
      })
        .then((presetName) => {
          let presetCleaned = _this.event.presetData;

          if (presetCleaned.length) {
            presetCleaned.forEach((item) => {
              if (item.type == "table") item.occupiedPlaces = "0";
            });
          }

          _this.$store
            .dispatch("addPreset", {
              name: presetName,
              jData: presetCleaned,
              hall: _this.event.hall.id,
              background: _this.event.preset.background,
              capacity: _this.event.preset.capacity || 300,
              attrs: _this.event.preset?.attrs || {},
            })
            .then((response) => {
              //success
              _this.AlertSuccess(_this.$t("presetAdded"))
              _this.$store
                .dispatch("getPreset", "hall/" + _this.event.hall.id)
                .then((response) => {
                  //success
                  _this.presets = response;
                });
            })

        })
        .catch(this.$swal.noop);
    },
    finishSelectMode() {
      this.selectMode = false;
      this.selected = [];
      $(".item").removeClass("selected");
    },
    initRollbackEngine() {
      sessionStorage.clear();
      let _this = this;
      window.addEventListener("DOMContentLoaded", (event) => {
        $(document)
          .off("keydown")
          .on("keydown", function (e) {
            if (e.which === 90 && e.ctrlKey && e.shiftKey) {
              _this.restoreVersion("redo");
            } else if (e.which === 90 && e.ctrlKey) {
              _this.restoreVersion("undo");
            }
          });
      });
    },
    saveRollBack(layout = {}) {
      let versions = Object.keys(sessionStorage).filter((_) => {
        return _.includes("version_");
      });
      versions.sort();
      let ver = "version_" + ((versions.length ? versions.length : 0) + 1);
      sessionStorage.setItem(ver, JSON.stringify(layout));
      sessionStorage.setItem("currentVersion", versions.length + 1);
    },
    restoreVersion(opr = "undo") {
      let version = {};
      let current = parseInt(sessionStorage.getItem("currentVersion"));
      let versionToGo = 0;

      let versions = Object.keys(sessionStorage).filter((_) => {
        return _.includes("version_");
      });
      versions.sort();

      if (current == 1 && versions.length == 1) return;

      if (versions.length > 0) {
        switch (opr) {
          case "undo":
          case "undo":
            if (
              typeof sessionStorage.getItem("version_" + (current - 1)) !==
              "undefined"
            )
              versionToGo = current - 1;
            break;

          case "redo":
            if (
              typeof sessionStorage.getItem("version_" + (current + 1)) !==
              "undefined"
            )
              versionToGo = current + 1;
            break;
        }
      }

      if (versionToGo < 1) versionToGo = 1;
      if (versionToGo > versions.length) versionToGo = versions.length;

      if (typeof versionToGo === "undefined" || current == versionToGo) return;

      version = sessionStorage.getItem("version_" + versionToGo);
      sessionStorage.setItem("currentVersion", versionToGo);
      try {
        version = JSON.parse(version);
        this.event.presetData = version.elements;
        this.save(true);
      } catch (e) {
        console.warn("failed to restore with error - " + e.toString());
      }
    },
    async printPreset() {
      this.selectedTable = null;
      const controlbar = document.querySelector(".controlBar");

      $("#ulamLayout .item").removeClass("selected");
      $("#ulamLayout .item").removeClass("hovered");

      controlbar.style.display = "none";
      this.savingPresetScreenshot = true;

      const result = this.$html2canvas(
        document.getElementById("for_print"),
        Config.html2CanvasConfig
      ).then((result) => {
        this.downloadFile(result, this.event.name + ".jpg");
        this.savingPresetScreenshot = false;
        controlbar.style.display = "block";
        this.AlertSuccess();
      })
        .catch((err) => {
          console.log(err)
          this.AlertError();
          this.savingPresetScreenshot = false;
          controlbar.style.display = "block";
        });
    },
    downloadFile(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    },
    initKeyboardMovements() {
      let _this = this;

      $("body")
        .off("keydown")
        .on("keydown", function (e) {
          //   e.preventDefault();
          let map = {
            37: "left",
            38: "up",
            39: "right",
            40: "down",
            17: "control",
            46: "delete",
          };
          const keyCode = e.keyCode

          if (map.hasOwnProperty(keyCode) && map[keyCode] == "control") {
            _this.controlPressed = true;
          }

          if (_this.itemSelectPlugin.countSelected() > 0 && map.hasOwnProperty(keyCode) && Object.keys(map).includes(keyCode.toString())) {
            e.preventDefault();

            _this.$store.state.lastInteraction = Math.floor(Date.now() / 1000);

            _this.itemSelectPlugin.getSelectedAsArray().forEach((_item) => {

              let item = _this.presetBuilder.getTableDomBydbID(_item.dbID).parentNode;
              let position = {
                left: parseFloat(item.style.left),
                top: parseFloat(item.style.top)
              };
              switch (map[keyCode]) {
                case "left":
                  position.left--;
                  break;
                case "right":
                  position.left++;
                  break;
                case "up":
                  position.top--;
                  break;
                case "down":
                  position.top++;
                  break;
              }

              item.style.left = parseFloat(position.left) + "px"
              item.style.top = parseFloat(position.top) + "px"

            });
          }

          if (
            // _this.selectMode &&
            map.hasOwnProperty(keyCode) &&
            map[keyCode] === "delete"
          ) {
            _this.itemSelectPlugin.getSelectedAsArray().forEach((_item) => {
              _this.presetBuilder.getTableDomBydbID(_item.dbID).parentNode.remove();
            });
            _this.collectPresetData()
            _this.setLayout()
          }
        });
    },
    _destroy() {
      sessionStorage.clear();
      $("body")
        .off("keyup")
        .off("keydown")
    },
  },
  beforeDestroy() {
    this._destroy();
  },
  destroyed() {
    this._destroy();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#tableActionsNav {
  button {
    border: 1px solid #ccc;
    padding: 4px;
  }

  button i {
    font-size: 35px;
  }
}

.eventPresetView h2 {
  color: #7893c1;
  font-size: 1.706rem;
  font-weight: 700;
}

.eventPresetView h3 {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.059rem;
}

.eventPresetView.innerMainContent {
  padding: 55px 45px;
  float: right;
  width: 100%;
}

.eventPresetView .editEventActions {
  float: left;
  margin-top: 6px;
}

#dragablleItems_2 .imgWrap img {
  max-width: 100%;
}

.printInfo {
  border-right: 1px solid #7e7e7e;
  border-left: 1px solid #7e7e7e;
  padding: 0 20px;
  margin: 0 20px;
  height: 100%;

  td {
    padding: 5px 10px;
  }
}

.printNotes {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #ccc;
  height: 40vh;
  margin: 10px;
}

.eventPresetView .deleteEvent span {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  font-size: 0.8235rem;
  font-weight: 700;
  color: #fff;
}

.eventPresetView .noteEvent {
  width: 181px;
  height: 42px;
  border: 1px solid #bdbdbd;
  border-radius: 21px;
  margin-left: 15px;
}

.eventPresetView .noteEvent svg {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 17px;
  fill: #364764;
  margin-left: 10px;
}

.eventPresetView .noteEvent span {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  font-size: 0.8235rem;
  font-weight: 700;
  color: #7792bf;
}

.eventPresetView .pieWrapper .pieInnerWrapper {
  width: 50%;
  float: right;
}

.eventPresetView .pieWrapper #pie-svg {
  width: 50%;
  float: right;
}

[data-pie-id]>*:nth-child(7n + 1),
[data-bar-id]>*:nth-child(7n + 1),
[data-line-id]>*:nth-child(7n + 1) {
  color: #293b5a;
}

[data-pie-id]>*:nth-child(7n + 2),
[data-bar-id]>*:nth-child(7n + 2),
[data-line-id]>*:nth-child(7n + 2) {
  color: #465a7f;
}

[data-pie-id]>*:nth-child(7n + 3),
[data-bar-id]>*:nth-child(7n + 3),
[data-line-id]>*:nth-child(7n + 3) {
  color: #5e769f;
}

[data-pie-id]>*:nth-child(7n + 4),
[data-bar-id]>*:nth-child(7n + 4),
[data-line-id]>*:nth-child(7n + 4) {
  color: #7792bf;
}

#pie,
#donut,
#bar {
  max-height: 450px;
}

svg {
  width: 100%;
  height: auto;
}

ul[data-pie-id] {
  list-style: none;
}

.eventPresetView .pieWrapper ul li {
  font-size: 1.118rem;
}

.eventPresetView .pieWrapper ul li+li {
  margin-top: 10px;
}

.eventPresetView .pieWrapper ul li span {
  color: #2e2e2e;
}

.eventPresetView .pieWrapper ul li span span {
  font-weight: 700;
  font-style: italic;
}

.eventPresetView .pieWrapper ul li:before {
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 2px;
  margin-left: 15px;
}

.eventPresetView .pieWrapper .pieInnerWrapper {
  padding: 0 30px;
}

.eventPresetView .pieWrapper .pieInnerWrapper h2 {
  margin: 20px 0px;
}

.eventPresetView .pieWrapper ul li.notResponded:before {
  background: #5e769f;
}

.eventPresetView .pieWrapper ul li.respondedPositive:before {
  background: #465a7f;
}

.eventPresetView .pieWrapper ul li.respondedMaybe:before {
  background: #7792bf;
}

.eventPresetView .pieWrapper ul li.respondedNegative:before {
  background: #293b5a;
}

.eventPresetView .pieWrapper ul li.notResponded span .status {
  color: #5e769f;
}

.eventPresetView .pieWrapper ul li.respondedPositive span .status {
  color: #465a7f;
}

.eventPresetView .pieWrapper ul li.respondedMaybe span .status {
  color: #7792bf;
}

.eventPresetView .pieWrapper ul li.respondedNegative span .status {
  color: #293b5a;
}

.eventPresetView .matchHeight .innerBox {
  height: 100%;
}

.eventPresetView .eventInfoWrapper {
  padding: 20px 30px;
}

.eventPresetView .eventInfoWrapper h2 svg {
  display: inline-block;
  vertical-align: middle;
  width: 52px;
  height: 52px;
  fill: #293b5a;
  margin-left: 10px;
}

.eventPresetView .eventInfoWrapper h2 span {
  display: inline-block;
  vertical-align: middle;
}

.eventPresetView .eventInfoWrapper h2 span .date {
  color: #293b5a;
  font-size: 1.059rem;
  font-weight: 400;
}

.eventPresetView .mainInfo {
  margin: 20px 0;
}

.eventPresetView .mainInfo li {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.059rem;
}

.eventPresetView .mainInfo li+li {
  margin-right: 15px;
}

.eventPresetView .mainInfo li svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  fill: #293b5a;
}

.eventPresetView .mainInfo li svg.i_location {
  width: 28px;
  height: 28px;
}

.eventPresetView .mainInfo li svg.i_users {
  width: 40px;
  height: 30px;
}

.eventPresetView .mainInfo li span {
  display: inline-block;
  vertical-align: middle;
}

.eventPresetView .mainInfo li span.liTitle {
  font-weight: 700;
  color: #7791be;
}

.eventPresetView .moreInfo {
  background: #f0f0f0;
  padding: 15px;
}

.eventPresetView .moreInfo li {
  display: inline-block;
  vertical-align: top;
}

.eventPresetView .moreInfo li h3 {
  margin-bottom: 10px;
}

.eventPresetView .moreInfo li+li {
  margin-right: 20px;
}

.eventPresetView .bottomPart {
  margin-top: 35px;
}

.eventPresetView .ulamDetailes {
  padding: 20px 15px;
}

.eventPresetView .placeNumbers {
  font-weight: 400;
}

.eventPresetView .ulamDetailes h2 {
  margin-bottom: 10px;
}

.eventPresetView .ulamDetailes h2 svg {
  display: inline-block;
  vertical-align: middle;
  fill: #ff0000;
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.eventPresetView .ulamDetailes .selectWrap {
  width: 250px;
}

.eventPresetView .ulamDetailes .selectWrap select {
  background: #fff;
  color: #293b5a;
  font-size: 0.8824rem;
}

.eventPresetView .ulamDetailes label {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.059rem;
}

.eventPresetView .ulamDetailes .checkboxWrap {
  margin: 20px 0;
}

.eventPresetView .ulamDetailes .checkboxWrap label {
  color: #4a4a4a;
  font-size: 0.8235rem;
  font-weight: 400;
  margin-right: 7px;
}

.eventPresetView input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.eventPresetView input[type="checkbox"]+.checkS {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  /* ff3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0%, rgba(41, 59, 90, 1)),
      color-stop(100%, rgba(123, 150, 196, 1)));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  /* ie10+ */
  background: linear-gradient(90deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);

}

.eventPresetView .checkboxInnerWrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.eventPresetView .checkboxInnerWrap svg {
  width: 14px;
  height: 10px;
  position: absolute;
  top: calc(50% - 5px);
  right: calc(50% - 7px);
  fill: #fff;
  opacity: 0;
}

input[type="checkbox"]:checked+.checkS+svg {
  opacity: 1;
}

.eventPresetView .panel-title {
  position: relative;
}

.eventPresetView .panel-title svg {
  width: 19px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: 0px;
  fill: #3f5376;
}

.eventPresetView .panel-heading:not(.collapsed) svg.i_plus_2,
.panel-heading.collapsed svg.i_minus {
  display: none;
}

.eventPresetView .panel-heading:not(.collapsed) svg.i_minus {
  display: block;
}

.eventPresetView .panel-group {
  margin-bottom: 0px;
}

.eventPresetView .panel-group .panel {
  border-radius: 0;
}

.eventPresetView .panel-default {
  border-bottom-color: #55e4d4 !important;
  box-shadow: none;
}

.eventPresetView .panel-group .panel+.panel {
  margin-top: 15px;
}

.eventPresetView .panel-heading {
  background: #f0f0f0;
}

.eventPresetView .panel-default {
  border-color: #f0f0f0;
}

.eventPresetView .panel-default>.panel-heading {
  background: #fff;
  border-color: #55e4d4;
}

.eventPresetView .panel-default>.panel-heading.collapsed {
  background: #f0f0f0;
}

.eventPresetView .panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 0;
  padding: 0;
}

.eventPresetView .panel-title>a {
  color: #7a95c2;
  font-weight: 700;
}

.eventPresetView .collapsed .panel-title>a {
  color: #293b5a;
  font-weight: 700;
}

.eventPresetView .panel-body ul li {
  float: right;
  width: 33.3%;
  padding: 15px;
}

.eventPresetView .panel-body ul li img {
  max-width: 100%;
}

.eventPresetView .panel-body ul li div.imgWrap {
  height: calc(100% - 30px);
  text-align: center;
}

.eventPresetView .panel-body ul li div:not(.imgWrap) {
  color: #242424;
  font-size: 0.8824rem;
  text-align: center;
  margin-top: 10px;
}

.eventPresetView .totalSeats {
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0%, rgba(41, 59, 90, 1)),
      color-stop(100%, rgba(123, 150, 196, 1)));
  background: -webkit-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  background: -o-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  background: -ms-linear-gradient(0deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  background: linear-gradient(90deg,
      rgba(41, 59, 90, 1) 0%,
      rgba(123, 150, 196, 1) 100%);
  text-align: center;
  color: #cdcdcd;
  font-size: 1.118rem;
  padding: 15px 0;
}

.eventPresetView .innerTotalSeats {
  display: inline-block;
  text-align: right;
}

.eventPresetView .totalSeats .ulamCapacity {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  font-style: italic;
  margin-left: 5px;
}

.eventPresetView .totalSeats .ulamCapacity+span {
  font-style: italic;
  font-size: 0.9412rem;
}


#list-1 .item {
  padding: 10px;
}




.eventPresetView .circlePrecWrap {
  width: 76px;
  height: 76px;
  display: inline-block;
}

.eventPresetView .precentageTxt {
  display: inline-block;
  padding-right: 15px;
  text-align: right;
}

.eventPresetView .circlePrecWrap .circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 1.8;
}

.eventPresetView .circlePrecWrap .circle {
  fill: none;

  stroke-width: 1.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.eventPresetView .seatedPlaces .circle {
  stroke: #ff0000;
}

.eventPresetView .unSeatedPlaces .circle {
  stroke: #293b5a;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.eventPresetView .circlePrecWrap .percentage {
  fill: #626262;
  font-style: italic;
  font-size: 0.5rem;
  text-anchor: middle;
}

.eventPresetView .precentageWrap {
  padding: 15px;
  text-align: center;
}

.eventPresetView .precentageIcon {
  position: relative;
  margin-right: 5px;
}

.eventPresetView .precentageIcon .mainIcon {
  width: 18px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid #fff;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.eventPresetView .precentageIcon span:not(.mainIcon) {
  width: 24px;
  height: 14px;
  position: absolute;
  top: -1px;
  right: -3px;
  border-radius: 5px 5px 0px 0px;
}

.eventPresetView .seatedPlaces .precentageIcon span:not(.mainIcon),
.eventPresetView .seatedPlaces .precentageIcon .mainIcon {
  background: #ff0000;
}

.eventPresetView .unSeatedPlaces .precentageIcon span:not(.mainIcon),
.eventPresetView .unSeatedPlaces .precentageIcon .mainIcon {
  background: #293b5a;
}

.eventPresetView .placesNum {
  color: #4c4c4c;
  font-weight: bold;
  font-size: 1.412rem;
  line-height: 20px;
}

.eventPresetView .seatedStatus {
  color: #9b9b9b;
  font-size: 1.059rem;
}

.eventPresetView .itemLabel {
  margin-top: 15px;
}

.eventPresetView .circleTable .circle {
  margin: 0 auto;
  border: 2px solid #c1bfbf;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
}



.eventPresetView .circleTable .seat:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  position: absolute;
  bottom: -2px;
  right: 1px;
}

.eventPresetView .item .resizeIcon {
  display: none;
}

.eventPresetView .item .rotateIcon,
.eventPresetView .item .editIcon,
.eventPresetView .item .deleteIcon {
  display: none;
}

.eventPresetView .item.selected {
  z-index: 500;
}

.svg_icon {
  height: 100px;
  fill: #c1bfbf;
}

.squareTable {
  z-index: 9999999;
}

@media only screen and (max-width: 769px) and (max-height: 1025px) {
  .eventSummary {
    margin-bottom: 35px;
  }
}

.printPreset svg {
  height: 40px;
  width: 50px;
  fill: #000;
  -webkit-box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.printPreset {
  float: left;
  position: absolute;
  left: 0;
  background: #fff;
  border-radius: 50px;
}

.eventPresetView .item.hovered,
.eventPresetView .item.selected {
  $d: 25px;
  $b: -5px;
  $side_d: 5px;

  i {
    font-size: 21px;
    position: absolute;
    color: black;
    font-weight: bold;
  }

  .deleteIcon {
    display: block;
    z-index: 1;
    top: -$d;
    position: absolute;
    font-size: 26px;
    right: -24px;
  }

  .rotateIcon {
    display: block;
    z-index: 1;
    position: absolute;
    top: -$d;
    left: -5px;
    font-size: 23px;
  }

  .editIcon {
    display: block;
    z-index: 1;
    bottom: -$b;
    left: -5px;
    position: absolute;
    font-size: 23px;
  }

  .resizeIcon {
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 5px;
    right: -26px;
  }
}

.ulamLayoutWrapper {
  width: auto;
}
</style>
