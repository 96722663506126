import Config from "../Config";

export default {
    methods: {
        FileStoragePath(relativeFilePath) {
            return Config.SETTINGS.MEDIA_URL + relativeFilePath
        },
     
        
        
    },
};
