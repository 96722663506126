
import axiosV2, { apiRequest } from "../core/http/axios_api_v2";

const TasksStore = {
    namespaced: false,
    state: {
        inbox: [],
        eventInbox: [],
        peer_list: [],
    },
    actions: {
        createTask: ({ commit, state, rootState }, data) =>
            new Promise((resolve, reject) => {
                const url = data.id > 0 ? 'user-messages/edit' : 'user-messages/create'
                return apiRequest('post', url, data)
            }),
        inbox: ({ state }, data) =>
            new Promise((resolve, reject) => {

                const params = new URLSearchParams(data);

                axiosV2.get('user-messages/inbox', { params })
                    .then((response) => {
                        console.log(data);
                        if (data.belongToEvent > 0)
                            state.eventInbox = response.data.data
                        else
                            state.inbox = response.data.data
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),

        setMsgStatus: ({ commit, state }, msg) =>
            new Promise((resolve, reject) => {

                apiRequest('post', 'user-messages/set-status', msg)
                    .then((response) => {
                        commit('updateInboxMutation', response)
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
    },
    mutations: {
        updateInboxMutation(state, payload) {
            const index = state.inbox.findIndex(item => item.id === payload.id);
            if (index !== -1) state.inbox.splice(index, 1, payload);
        },
        addMessage(state, payload) {
            state.inbox.push(payload)
        }
    },
    getters: {

    },


}
export default TasksStore;
