<template>
  <div>
    <div id="modal-addGuest2">
      
      <h2  v-if="!editGuest">{{ $t("addGuest_2") }}</h2>
      <h2  v-if="editGuest">{{ $t("editGuest") }}</h2>

      <input
        type="hidden"
        name="quantity"
        id="gQuantity"
        data-id="quantity"
        v-model="guest.quantity"
      />
      <input
        type="hidden"
        name="event"
        data-id="eventID"
        v-model="guest.eventID"
      />
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="inputWrap">
            <label for="guestNameEdit">{{ $t("guestName") }}</label>
            <input
              type="text"
              id="guestNameEdit"
              name="guestNameEdit"
              data-id="fullName"
              required
              v-model="guest.fullName"
            />
          </div>
        </div>
        
        <div class="col-md-6 col-xs-12">
          <div class="row secondLevel">
            <div class="col-md-6 col-xs-12">
              <label>{{ $t("quantity") }}</label>
              <div class="guestCounter">
                <span class="plus" v-on:click="guestQuantityUp"
                  ><svg><use xlink:href="#i_plus"></use></svg
                ></span>
                <span class="value vQyt">{{ guest.quantity }}</span>
                <span class="minus" v-on:click="guestQuantityDown"
                  ><svg><use xlink:href="#i_minus"></use></svg
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xs-12" v-if="isBussinesEvent">
          <div class="inputWrap">
            <label for="companyEdit"
              >{{ $t("company") }} {{ $t("conferenceOnly") }}</label
            >
            <input
              type="text"
              id="companyEdit"
              name="companyEdit"
              data-id="company"
              v-model="guest.company"
            />
          </div>
        </div>
        <div class="col-md-6 col-xs-12" v-if="isBussinesEvent">
          <div class="inputWrap">
            <label for="roleEdit"
              >{{ $t("role") }} {{ $t("conferenceOnly") }}</label
            >
            <input
              type="text"
              id="roleEdit"
              name="roleEdit"
              data-id="role"
              v-model="guest.role"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <label for="groupName">{{ $t("groupName") }}</label>
          <v-select
            dir="rtl"
            :placeholder="this.$t('noGuestGroup')"
            :options="$store.getters.eventGuestGroups"
            ref="guestGroupDD"
            :clearable="false"
            v-model="guest.group"
            
          >
          <li slot="list-header" class="text-center">
            <h5>{{ $t('Type To enter new group')}} </h5>
            <button class="s_btn" @click="handleAddGuestGroup()">{{ $t('Add Group') }}</button>
          </li>
           <span slot="no-options">{{ $t('noResults') }}</span>
          </v-select>
          
          <!-- </div> -->
        </div>
        <div class="col-md-6 col-xs-12">
       
          <label for="guestTable">{{ $t("tableNum") }}</label>
          <v-select
            dir="rtl"
            :placeholder="this.$t('notSeated')"
            :options="tables"
            :taggable="true"
            :clearable="false"
            v-model="guest.tableId"
            :reduce="(table) => table.value"
            v-if="!presetLocked || $store.state.user.type === 2"
          ></v-select>

      
        </div>
      </div>

      <hr />
      <div class="row"  >
        <div class="col-md-6 col-xs-12" v-if="!$store.state.isTablet">
          <div class="inputWrap">
            <label for="guestEmail">{{ $t("email") }}</label>
            <input
              v-model="guest.email"
              type="email"
              id="guestEmail"
              name="guestEmail"
              data-id="email"
            />
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="inputWrap">
            <label for="guestPhone">{{ $t("phone") }}</label>
            <input
              v-model="guest.phone"
              type="tel"
              id="guestPhone"
              name="guestPhone"
              data-id="phone"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="inputWrap">
            <label for="printNote">{{ $t("coupleNotes") }}</label>
            <input
              v-model="guest.printNote"
              id="printNote"
              name="printNote"
              data-id="printNote"
            />
          </div>
        </div>
      </div>
<!-- <small style="font-size:9px;" v-if="guest.pandaId">{{ guest.pandaId }}</small> -->
      <div
        class="row"
        v-bind:style="$store.state.isTablet ? 'margin-bottom:10px' : ''"
      >
        <hr />

        <!-- <div class="col-sm-4">
          <GuestQntStatus :guest="guest"></GuestQntStatus>
        </div> -->

        <div class="col-sm-3" v-if="isGuestArrivalScreen">
          <div class="inputWrap text-center">
            <label for="isArrived">{{ $t("isArrived") }}</label>
            <input
              v-model="guest.arrived"
              type="checkbox"
              id="isArrived"
              name="isArrived"
              data-id="arrived"
              v-bind:style="
                $store.state.isTablet ? '-webkit-appearance:checkbox' : ''
              "
            />
          </div>
        </div>
        <div class="col-sm-3" v-if="!isGuestArrivalScreen && guest.isDeletable">
          <div class="inputWrap text-center d-flex justify-center  items-center">
            
            <input
              v-model="guest.willArive"
              type="checkbox"
              id="willArrived"
              name="willArrived"
              data-id="willArrived"
            />
            <label for="willArrived" class="d-block mx-3">{{ $t("willArrived") }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 mt-4 text-center">
          <div class="inputWrap submitWrap">
            <button
              class="submit d-block mx-auto"
              v-on:click.prevent="handleEditGuest"
              v-if="editGuest"
            >
              {{ $t("editGuest") }}
            </button>

            <button
              class="submit"
              v-on:click.prevent="handleCreateGuest"
              v-if="!editGuest"
            >
              {{ $t("addGuest_3") }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="showLoader" class="modalLoader">
        <rotate-loader style="opacity: 0.6" :color="'#132C43'"></rotate-loader>
      </div>
    </div>
    <pre class="ltr" v-if="user.type==2 || user.type==5"> {{guest}} </pre>
  </div>
</template>

<script>
import RotateLoader from "vue-spinner/src/RotateLoader.vue";
import { existInList } from "../../helpers/guest/validation";
import GuestQntStatus from "./GuestQntStatus";
export default {
  name: "AddGuestModal",
  props: ["editGuest", "isGuestArrivalScreen", "tableList"],
  data() {
    return {
      guest: this.newGuest(),
      showLoader: false,
    };
  },

  components: {
    GuestQntStatus,
    RotateLoader,
  },
  computed: {
    isBussinesEvent: function () {
      let classs = this.$store.state.EventStore.event.type.class;
      return classs == "bussines" || classs == "conference";
    },
    presetLocked: function () {
      this.$store.state.EventStore.event.presetLocked;
    },
    tables: function () {
      if (!Array.isArray(this.$store.state.EventStore.event.presetData))
        return [];

      return this.$store.state.EventStore.event.presetData
        .filter((item) => item.type === "table" && item.tableNum != "")
        .sort((tblA, tblB) => tblA.tableNum - tblB.tableNum)
        .map((x) => {
          return { label: x.tableNum, value: x.dbID };
        });
    },
  },
  watch: {
    editGuest: function () {
      if (this.editGuest != null) {
        this.guest = this.editGuest;
      } else {
        this.guest = this.newGuest();
      }
      // this.guest = this.editGuest;
    },
  },
  mounted() {
    //this.demoData();
  },
  methods: {
    newGuest() {
      return {
        id: null,
        fullName: "",
        email: null,
        company: "",
        role: "",
        phone: "",
        eventID: 0,
        note: "",
        printNote: "",
        group: "",
        tableId: null,
        group: "",
        quantity: 1,
        arrived: false,
        willArrive: false,
        isReserve: false,
        isDeletable: true
      };
    },
    demoData() {
      this.guest = {

        fullName: "דין סופר",
        email: "deanwebdev@gmail.com",
        company: "DS",
        role: "",
        phone: "0525233232",
        note: "",
        printNote: "",
        groupID: "",
        tableNumber: 2,
        group: "deans",
        quantity: 5,
        arrived: 3,
        isReserve: false,
        showLoader: false,
      };
    },

    handleAddGuestGroup(){
      this.guest.group = this.$refs.guestGroupDD.$refs.search.value;
      this.$refs.guestGroupDD.$refs.search.blur();
    },

    guestQuantityDown(e) {
      if (isNaN(this.guest.quantity)) this.guest.quantity = 1;

      if (this.guest.quantity > 1) this.guest.quantity--;
    },
    guestQuantityUp(e) {
      if (isNaN(this.guest.quantity)) this.guest.quantity = 1;

      this.guest.quantity++;
    },
    handleCreateGuest(e) {
      e.preventDefault();
      this.save();
    },
    handleEditGuest(e) {
      e.preventDefault();
      if( !this.guest.id ||  this.guest.id <= 0){
        this.AlertError('אורח לא תיקני')
        retrun
      }
      this.showLoader = true;
      this.$store
        .dispatch("updateGuest", this.guest)
        .then((gData) => {
          //success
          this.$swal({
            confirmButtonText: this.$t("okButton"),
            type: "success",
            title: this.$t("guestSaved"),
            timer: 2000,
          }).catch(this.$swal.noop);

          this.$emit("guestEdited", this.guest);
          this.showLoader = false;
          this.guest = this.newGuest();
        })
        .catch((err) => {
          console.warn(err);
          //err
          this.showLoader = false;
          this.$swal({
            confirmButtonText: this.$t("okButton"),
            type: "error",
            title: this.$t("saveFailed"),
            timer: 3000,
          }).catch(this.$swal.noop);
        });
    },
    save() {
      let _this = this;
      if (this.$store.state.isTablet) {
        this.guest.status = {
          coming: parseInt(this.guest.quantity),
          notResponded: 0,
        };
      } else {
        this.$data.status = {
          notResponded: parseInt(this.guest.quantity),
          coming: 0,
        };
      }

      // set event details

      const guestEvent = this.$store.state.EventStore.event;
      this.guest.event = guestEvent.id;
      this.guest.pandaEventId = guestEvent.pandaId | null;

      // validate not exist

      if (existInList(this.guest, this.$store.getters.getGuests)) {
        this.$swal({
          confirmButtonText: this.$t("okButton"),
          type: "error",
          title: "אורח כבר קיים ברשימה",
          timer: 2000,
        }).catch(this.$swal.noop);
        return;
      }

      // dispatch add guest
      this.$store
        .dispatch("addGuest", this.guest)
        .then((gData) => {
          //success
          this.$swal({
            confirmButtonText: this.$t("okButton"),
            type: "success",
            title: this.$t("guestSaved"),
            timer: 2000,
          }).catch(this.$swal.noop);

          this.$emit("addGuest", gData);
          this.showLoader = false;
          this.guest = this.newGuest();
        })
        .catch((err) => {
          console.warn(err);
          //err
          this.showLoader = false;
          this.$swal({
            confirmButtonText: this.$t("okButton"),
            type: "error",
            title: this.$t("saveFailed"),
            timer: 3000,
          }).catch(this.$swal.noop);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  color: #7893c1;
  font-size: 1.706rem;
  font-weight: 700;
}
h3 {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.059rem;
}
#willArrived{
  width: 40px;
}

.addGuest {
  border: 1px solid #52dbcd;
  background: -moz-linear-gradient(
    0deg,
    rgba(41, 59, 90, 1) 0%,
    rgba(123, 150, 196, 1) 100%
  );
  /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(41, 59, 90, 1)),
    color-stop(100%, rgba(123, 150, 196, 1))
  );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(
    0deg,
    rgba(41, 59, 90, 1) 0%,
    rgba(123, 150, 196, 1) 100%
  );
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    0deg,
    rgba(41, 59, 90, 1) 0%,
    rgba(123, 150, 196, 1) 100%
  );
  /* opera 11.10+ */
  background: -ms-linear-gradient(
    0deg,
    rgba(41, 59, 90, 1) 0%,
    rgba(123, 150, 196, 1) 100%
  );
  /* ie10+ */
  background: linear-gradient(
    90deg,
    rgba(41, 59, 90, 1) 0%,
    rgba(123, 150, 196, 1) 100%
  );

}
.addGuest svg {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  fill: #fff;
  margin-left: 5px;
}
.addGuest span {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  font-size: 0.8235rem;
  font-weight: 700;
  //color:#fff;
}

#all span {
  color: #293b5a;
  font-weight: 700;
}
#all {
  border-radius: 27px 0 0 27px;
}
.statusBar .active {
  background: #f3f3f3 !important;
}
.statusBtn svg {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.guestCounter {
  width: 110px;
  max-width: 100%;
  height: 44px;
  line-height: 40px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
  //float: right;
}
.guestCounter span,
.guestCounter input {
  width: 33.3%;
  float: right;
  text-align: center;
  height: 100%;
  cursor: pointer;
}
.guestCounter span.plus {
  background: #f2f2f2;
  border-radius: 0 27px 27px 0;
}
.guestCounter span.minus {
  background: #f2f2f2;
  border-radius: 27px 0 0 27px;
}
.guestCounter span svg {
  width: 17px;
  height: 100%;
  fill: #293b5a;
  display: block;
  margin: 0 auto;
}
.guestCounter input.value {
  font-size: 1rem;
  color: #434343;
  background: #fff;
}

#modal-addGuest2 {
  //max-width: 600px!important;
  background: #efefef;
}
.row {
  margin: 10px 0;
}
#modal-addGuest2 .inputWrap input,
#modal-addGuest2 .inputWrap select,
#modal-addGuest2 {
  background: #fff;
  color: #555555;
  font-size: 0.8235rem;
}
#modal-addGuest2 h2 {
  color: #293b5a;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

#modal-addGuest2 label {
  color: #434343;
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
}

#modal-addGuest2 .statusDropDown li {
  padding: 7px 0;
  cursor: pointer;
}
#modal-addGuest2 .selectedValue span svg,
#modal-addGuest2 .statusDropDown li svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  display: inline-block;
  margin-left: 7px;
}
#modal-addGuest2 .selectedValue span span,
#modal-addGuest2 .statusDropDown li span {
  vertical-align: middle;
  display: inline-block;
  color: #555555;
  font-size: 0.8235rem;
}
#modal-addGuest2 .selectedValue {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
  background: #fff;
  padding: 0 15px;
}
#modal-addGuest2 .statusDropDown {
  z-index: 1;
  background: #fff;
  width: 125px;
  padding: 0 10px;
  border: 1px solid #dddddd;
  position: absolute;
  top: calc(100% + 15px);
  right: 50%;
  transform: translateX(50%);
  display: none;
}
#modal-addGuest2 .statusDropDown.open {
  display: block;
}
#modal-addGuest2 .statusDropDown:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #dddddd;
  position: absolute;
  bottom: 100%;
  right: calc(50% - 6px);
}
#modal-addGuest2 .statusDropDown:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  bottom: 100%;
  right: calc(50% - 4px);
}
#modal-addGuest2 .secondLevel {
  margin: 0 -7px;
}
#modal-addGuest2 .secondLevel div[class^="col-"] {
  padding: 0 7px;
}
#modal-addGuest2 .selectedValue {
  position: relative;
}
#modal-addGuest2 .selectedValue .i_select {
  width: 16px;
  height: 9px;
  position: absolute;
  top: calc(50% - 4px);
  left: 10px;
  fill: #8798b5;
}
#modal-addGuest2 .guestCounter {
  width: 100%;
  height: 54px;
  line-height: 52px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
  float: right;
}
#modal-addGuest2 .guestCounter span {
  width: 33.3%;
  float: right;
  text-align: center;
  height: 100%;
}
#modal-addGuest2 .guestCounter span.plus {
  background: #f2f2f2;
  border-radius: 0 27px 27px 0;
}
#modal-addGuest2 .guestCounter span.minus {
  background: #f2f2f2;
  border-radius: 27px 0 0 27px;
}
#modal-addGuest2 .guestCounter span svg {
  width: 17px;
  height: 100%;
  fill: #293b5a;
  display: block;
  margin: 0 auto;
}
#modal-addGuest2 .guestCounter span.value {
  font-size: 1rem;
  color: #434343;
}
.resetGuestList {
  border-color: #c30303;
}
.resetGuestList svg {
  fill: #c30303;
}
.resetGuestList span {
  color: #c30303;
}

@media only screen and (max-width: 1450px) {
}
</style>
