export async function toDataURL(url, callback) {
    let blob = await toBlob(url)
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export async function toBlob(url) {

    return new Promise(resolve => {
        fetch(url, {
            mode: 'cors'
        }).then(r => {
            resolve(r.blob())
        });
    })


}

