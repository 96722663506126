import Vue from 'vue';

Number.prototype.padZeros = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
}
Vue.filter('invoicePad', function (value) {
    return Number(value).padZeros(6)
});

Vue.filter('padZeros', function (value, n) {
    return Number(value).padZeros(n)
});


Number.prototype.priceFormat = function (number) {
    var s = Number(this);
    return s.toLocaleString();
}
Vue.filter('priceFormat', function (value) {
    try {
        if (!value) return ''
        return value.priceFormat()
    } catch {
        return 'error'
    }
});

