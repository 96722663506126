<template>
  <div class="row">

    <Modal ref="previewItem">
      <ViewItem :item="selecteditem"></ViewItem>
    </Modal>


    <Modal ref="addNotesModal">
      <div class="card card-body" v-if="selecteditem">
        <h3>{{ $t('notes') }}</h3>
        <textarea class="form-control my-3" @input="setNotes($event)" :value="getNotesValue(selecteditem)" placeholder="  (לא חובה) הוסף כאן הערות בנוגע לפריט ..."></textarea>
        <button class="s_btn flex-fill" @click="emitSaved(selecteditem)">
          <i class="fa fa-plus mx-1"></i>{{ $t('add') }}
        </button>
      </div>
    </Modal>

    <!-- Select item -->
    <div class="col-sm-12 col-md-12">

      <div class=" d-flex gap-4 items-wrapper" v-if="items.length > 0">
        <div  class="flex-fill" v-for="item in items" v-bind:key="item.id" :set="itemFound = selected_items.find((el) => el.id == item.id)">
          <div :class="itemFound ? 'item selected' : 'item'">
            
            <!-- Item -->
            <img :src="FileStoragePath(item.image)" alt="" class="img-responsive item-image" v-if=item.image />
            <div class="item-image" v-if="!item.image && missingIconClass">
              <i :class="missingIconClass" style="font-size: 120px; margin: 30px 0 0 0;" />
            </div>
            <div class="item-name mt-5 mb-3">{{ item.name }}</div>
           
            <p class=" mb-3" v-if="itemFound && itemFound.extra">{{ itemFound.extra.notes }}</p>


            <!-- Buttons -->
            <div class="d-flex gap-3 px-1 mb-3" style="position:absolute;bottom:5px;left:0;width:100%">
              <button class=" flex-fill" @click="showItem(item)"> <i class="fa fa-eye mx-1"></i> {{ $t('view') }}</button>

              <button class=" flex-fill" @click="removeItem(item)" v-if="itemFound">
                <i class="fa fa-minus mx-1"></i>{{ $t('הסרה') }}
              </button>
              <button class=" flex-fill" @click="addItem(item)" v-else>
                <i class="fa fa-plus mx-1"></i>{{ $t('add') }}
              </button>

            </div>
       
          </div>
        </div>
      </div>

      <div v-else>
        <h4>אין תוצאות</h4>
      </div>

    </div>

  </div>
</template>

<script>
import ViewItem from "./ViewItem.vue";

export default {
  name: "ItemTiles",
  components: { ViewItem },
  computed: {
  },
  props: {
    missingIconClass: String,
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    selected_items: {
      type: Array,
      default: () => {
        return []
      },
    },
    showPrice: {
      type: Boolean,
      default: false
    },

  },// [ 'items', 'selected_items' ],
  data() {
    return {
      selecteditem: null,
    };
  },
  beforeMount() { },
  methods: {
    getNotesValue(selecteditem) {
      if(selecteditem?.extra?.notes ) return selecteditem?.extra?.notes
      return ''
    },
    setNotes(e){
     
      this.selecteditem.extra = this.selecteditem.extra || {}
      this.selecteditem.extra.notes= e.target.value
    },
    showItem(item) {
      this.selecteditem = item;
      this.$refs.previewItem.show()
    },

    addItem(item) {
      this.selecteditem = item;
      this.$refs.addNotesModal.show()
    },
    removeItem(item) {
      this.selecteditem = null;
      this.emitSaved(item)
    },

    emitSaved(item) {
      this.$refs.addNotesModal.hide()
      this.$emit('itemAdded', item)
    }
  },
};
</script>
<style scoped lang="scss">
#event_btn_nav {
  width: 100%;

  button {
    background: #f8f8f8;

    border: #ccc;
    padding: 3px;
    margin: 3px;
    display: inline-block;
  }
}

.items-wrapper {
  flex-wrap: wrap;
  width: 100%;
}

.item {
  background: white;
  box-shadow: 2px 2px 10px 8px #ccc;
  border: 3px solid var(--main-color);
  color: var(--main-color);
  border-radius: 20px;
  padding: 10px 0 40px 0;
  margin: 15px;
  min-width: 220px;
  max-width: 420px;
  flex: 1 1;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &-name {
    text-align: center;
  }

  &.selected {
    background: var(--main-color);
    border: none;
    border-radius: 20px;
    outline: none;
    box-shadow: none;
    border: 3px solid rgb(172, 251, 253);
    color: white;

  }

  img {
    display: block;
    margin: 0 auto;
  }

  .item-name {
    font-weight: bold;
    
  }

  .item-image {
    height: 30vh;
    object-fit: cover;
    width: 100%;
  }
}

.selected_item {
  background: #f8f8f8;
  border: #ccc;
  padding: 3px;
  margin: 3px;
}
</style>