import { connectTables } from "../helpers/LineConnect"

export class DragSystem {
    presetBuilder = null
    items = []
    shadowElement = null
    draggedElement = null
    closeEl = null
    distanceThreshold = 120

    dropHandler = null
    mouseupHandler = null
    startDragHandler = null
    containerRect = {}

    moveDiff = { x: 0, y: 0 }
    startPoint = { x: 0, y: 0 }
    mouseIsDown = false

    mouseDownTime = null
    debounceTime = 200

    constructor(presetBuilder, options) {
        this.options = options
        this.presetBuilder = presetBuilder
        this.container = document.querySelector(presetBuilder.elementId)
        this.items = this.presetBuilder.getItemsDOM()
        this.itemsToMove = []
        this.containerRect = this.container.getBoundingClientRect()
        this.initDragSystem()

        setInterval(() => {
            if (this.closeEl && this.itemsToMove[0])
                connectTables(this.container, this.closeEl, this.itemsToMove[0], 'green')
        }, 500)
    }

    destroy() {
        this.container.addEventListener("mousedown", this.container.fn1, false);
        this.container.addEventListener("mouseup", this.container.fn2, false);
        this.container.addEventListener("mousemove", this.container.fn3, false);
        this.container.classList.remove('drag-system-on')
    }


    setItemsToMove(items) {
        this.itemsToMove = items
    }


    initDragSystem() {
        const items = this.items
        if (!this.container.classList.contains('drag-system-on')) {

            this.container.addEventListener("drop", (evt) => { evt.preventDefault(); this.onDragDrop(evt) }, false);
            this.container.addEventListener("dragover", (evt) => { evt.preventDefault(); }, false);
            this.container.addEventListener("dragstart", (evt) => { evt.preventDefault(); }, false);

            items.forEach((item) => {
                item.addEventListener("mousedown", this.container.fn1 = (evt) => { this.onMouseDown(evt) }, false);
                item.style.userSelect = "none";
            });
            this.container.addEventListener("mouseup", this.container.fn2 = (evt) => { this.onMouseUp(evt) }, false);
            this.container.addEventListener("mousemove", this.container.fn3 = (evt) => { this.onMouseMove(evt) }, false);

            this.container.classList.add('drag-system-on')

        }

    }
    applyScale(value) {
        return value / this.presetBuilder.scale
    }
    onMouseDown(evt) {
        evt.stopPropagation();
        this.draggedElement = evt.currentTarget
        this.mouseIsDown = true
        this.mouseDownTime = new Date()

        let bounds = this.draggedElement.getBoundingClientRect();
        this.startPoint.x = bounds.left*this.presetBuilder.scale
        this.startPoint.y = bounds.top*this.presetBuilder.scale


        if (this.startDragHandler)
            this.startDragHandler(this.draggedElement)


        if (this.draggedElement) {
            this.draggedElement.style.opacity = '0.7'
        }
        
    }
    // getMouseLocRelativeToParent(event, parentDom) {
    //     let bounds = parentDom.getBoundingClientRect();
    //     //*(this.presetBuilder.scale)
    //     let x = event.clientX - bounds.left;
    //     let y = event.clientY - bounds.top;
       
    //     return { x, y }
    // }
    onMouseMove(evt) {
        evt.stopPropagation();
        if (!this.mouseIsDown) return
        //minimum movement
        if (this.mouseDownTime && (new Date()).getTime() - this.mouseDownTime.getTime() < 100) {
            return
        }
        // const mouseLocation = { x: evt.x, y: evt.y }
        const mouseRect = evt.currentTarget.getBoundingClientRect()
        const mouseLocation =  [ mouseRect.left, mouseRect.top ]
       
        this.moveDiff = {
            x: mouseLocation[0] - parseFloat(this.startPoint.x),
            y: mouseLocation[1] - parseFloat(this.startPoint.y)
        }

        // if (this.itemsToMove.length <= 1) {
        //     let mousePosRelativeToParent = this.getMouseLocRelativeToParent(evt, this.container)
        //     let singleItem = this.itemsToMove[0]
        //     let br = singleItem.getBoundingClientRect()
            
        //     console.log('====================================')
        //     console.log('mousePosRelativeToParent', mousePosRelativeToParent)
        //     console.log('mouseLocation', mouseLocation)
        //     console.log('startPoint', this.startPoint)
        //     console.log('====================================')
            
        //     singleItem.style.top = parseFloat(mousePosRelativeToParent.y - this.applyScale(br.height) / 2) + 'px'
        //     singleItem.style.left = parseFloat(mousePosRelativeToParent.x - this.applyScale(br.width) / 2) + 'px'
        // } else {
          
        // }
        for (let index = 0; index < this.itemsToMove.length; index++) {
            const element = this.itemsToMove[index];
            element.style.top = parseFloat(element.style.top) + parseFloat(this.applyScale(evt.movementY)) + 'px'
            element.style.left = parseFloat(element.style.left) + parseFloat(this.applyScale(evt.movementX)) + 'px'
        }


        if (this.options.snapToGrid) {
           
            this.closeEl = this.findClosestElement(mouseLocation)
            if (this.closeEl) {

                this.containerRect = this.container.getBoundingClientRect()
                let _closestCenter = this.findCenterPoint(this.closeEl)
              
                let result = this.shorterAxis(mouseLocation, this.closeEl.getBoundingClientRect())
                let cords = {}

                if (result.shorter_axis == 'y') {
                    cords = {
                        y: _closestCenter.y - this.itemsToMove[0].offsetHeight / 2,
                        x: evt.x - this.containerRect.x
                    }
                } else {
                    cords = {
                        y: evt.y - this.containerRect.y,
                        x: _closestCenter.x - this.itemsToMove[0].offsetWidth / 2
                    }
                }
                //console.log(result,this.containerRect,evt.y,evt.clientX,evt,cords)
                // for debugging
                //
                // let line = `<svg  class="element-line-connector" style="overflow:visible;z-index:0;position:absolute;top:0;left:0;pointer-events: none;">`
                // line += ` <circle cx="${_closestCenter.x}" cy="${_closestCenter.y}" r="10" style="stroke:red;stroke-width:2;" /> `
                // line += ` <circle cx="${cords.x}" cy="${cords.y}" r="10" style="stroke:blue;stroke-width:2;" /> `
                // line += ' </svg> '
                // this.container.insertAdjacentHTML("beforeend", line);


                this.itemsToMove[0].style.top = parseFloat(cords.y).toFixed(2) + 'px'
                this.itemsToMove[0].style.left = parseFloat(cords.x).toFixed(2) + 'px'
            }
        }


    }

    onDragDrop(evt) {
        this.containerRect = this.container.getBoundingClientRect()
        let copy = this.draggedElement

        copy.style.left = this.applyScale(evt.x - this.containerRect.x) + `px`
        copy.style.top = this.applyScale(evt.y - this.containerRect.y) + `px`

        this.container.prepend(copy)

        if (this.dropHandler)
            this.dropHandler(this.draggedElement)
    }


    onMouseUp(evt) {
        evt.stopPropagation();

        if (this.mouseIsDown == false)
            return

        this.mouseIsDown = false

        if (this.mouseupHandler)
            this.mouseupHandler(this.draggedElement)

        if (this.draggedElement) {
            this.draggedElement.style.opacity = '1'
        }

        // debounce - wait x ms while mouse is down in order to apply move
        if (this.mouseDownTime && (new Date()).getTime() - this.mouseDownTime.getTime() < this.debounceTime) {
            return
        }




    }


    // setShadowPosition(evt) {
    //     if (!this.shadowElement) return

    //     let dragX = evt.x,
    //         dragY = evt.y,
    //         xVal,
    //         yVal,
    //         rect = this.getDomPosition(this.container)

    //     xVal = (parseFloat(dragX - rect.left).toFixed(2) - this.shadowElement.offsetHeight / 2)
    //     yVal = ((parseFloat(dragY - rect.top).toFixed(2) - this.shadowElement.offsetWidth / 2))

    //     this.shadowElement.style.top = (yVal) + 'px'
    //     this.shadowElement.style.left = (xVal) + 'px'
    // }


    getDomPosition(dom) { return dom.getBoundingClientRect(); }


    calcDistance(r1, r2) { return Math.sqrt((r2.y - r1.y) ** 2 + (r2.x - r1.x) ** 2) }


    shorterAxis(cord1, cord2) {
        let shorter_axis, axis_value
        if (Math.abs(cord2.y - cord1[1]) > Math.abs(cord2.x - cord1[0])) {
            shorter_axis = 'x'
            axis_value = cord2.x - cord1[0]
        } else {
            shorter_axis = 'y'
            axis_value = cord2.y - cord1[1]
        }

        return {
            shorter_axis,
            axis_value
        }
    }


    findCenterPoint(dom) {
        let centerX = dom.offsetLeft + dom.offsetWidth / 2;
        let centerY = dom.offsetTop + dom.offsetHeight / 2;
        return { x: centerX, y: centerY }
    }


    findClosestElement(point) {

        let min = 1000, distance = 1000, closestElement = null

        this.items.forEach((item) => {
            distance = this.calcDistance(point, item.getBoundingClientRect())
            //console.log(distance)
            if (item != this.draggedElement && distance < min && distance < this.distanceThreshold) {
                min = distance
                closestElement = item
            }

        });

        return closestElement;
    }



}