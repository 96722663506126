<template>
  <div>
    <div class="innerMainContent">
        <PageHeader :title="$t('userEdit')" icon="i_profile" :breadcrumbs="[{
                route: '/users', name: $t('menu_users')
            },{
                route: null, name: $t('userEdit')
            },]">

            </PageHeader>
     
      <div class="innerBox">
        <form
          action=""
          id="editProfile"
          class="formWrap"
          @submit.prevent="submitForm"
        >
          <div class="row">
            <div class="col-xs-12 containerWidth">
              <div class="row">
                <div class="col-xs-12">
                  <h2>{{ $t("generalDetails") }}</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="fullName">*{{ $t("fullName") }}</label>
                    <input
                      type="text"
                      maxlength="50"
                      id="fullName"
                      name="fullName"
                      v-model="user.name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="phone"
                      >{{ $t("phone") }}
                      <span>({{ $t("mobile") }})</span></label
                    >
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      v-model="user.phone"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="email">*{{ $t("email") }}</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      v-model="user.email"
                      required
                    />
                  </div>
                </div>
              </div>

              <FormInput
                type="checkbox"
                label="סוכן מכירות"
                display="horizontal"
                v-model="user.attrs.isSalesman"
                v-if="this.$store.state.user.type === 5"
                :text="1"
              />

              <div class="row" v-if="this.$store.state.user.type === 1">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="domain">*{{ $t("app_domain") }}</label>
                    <input
                      type="text"
                      maxlength="50"
                      id="domain"
                      name="domain"
                      v-model="app.domain"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="this.$store.state.user.type === 1">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="appName">*{{ $t("app_name") }}</label>
                    <input
                      type="text"
                      maxlength="50"
                      id="appName"
                      name="appName"
                      v-model="app.name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="this.$store.state.user.type === 1">
                <div class="col-md-12">
                  <div class="inputWrap">
                    <label for="snippets">*{{ $t("app_snippets") }}</label>
                    <textarea
                      id="snippets"
                      name="snippets"
                      v-model="snippets"
                      dir="ltr"
                      style="text-align: left; direction: ltr; width: 100%"
                    ></textarea>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div class="row">
                <div class="col-xs-12">
                  <h2>{{ $t("passwordUpdate") }}</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap">
                    <label for="newPass">{{ $t("newPassword") }}</label>
                    <password
                      :toggle="true"
                      dir="ltr"
                      :badge="false"
                      :secureLength="6"
                      :required="false"
                      :placeholder="null"
                      id="newPass"
                      name="newPass"
                      v-model="user.newPass"
                      style="direction: ltr; text-align: left"
                    ></password>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="inputWrap submitWrap">
                    <button class="submit">{{ $t("save") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import axiosV2 from "../../core/http/axios_api_v2";
export default {
  name: "UserEdit",
  data() {
    return {
      user: { attrs: {}, app: {} },
      app: {},
      snippets: "",
    };
  },
  components: {
    Password,
  },
  mounted() {},

  beforeMount() {
    const userId = this.$router.currentRoute.params.id;
    //get user
    if (
      this.$store.state.user.id == this.$router.currentRoute.params.id
      // || this.$store.state.user.type > 2
    ) {
      this.$router.push("/profile");
    } else {
      this.$store
        .dispatch("restGet", {
          route: "settings/user/" + userId,
        })
        .then((app) => {
          this.app = app;
        });

      this.$store
        .dispatch("getUser", userId)
        .then((response) => {
          if (response.parent.id === this.$store.state.user.id) {
            this.user = response;
            if (!this.user.attrs) this.user.attrs = {};
            document.title += " :: " + this.user.name;

            if (this.$store.state.user.type === 1) {
              try {
                this.snippets =
                  response.data.hasOwnProperty("app") &&
                  response.data.app.hasOwnProperty("extra") &&
                  response.data.app.extra !== null &&
                  response.data.app.extra.hasOwnProperty("snippets")
                    ? JSON.parse(response.data.app.extra.snippets)
                    : "";
              } catch (e) {
                this.snippets = "";
              }
            }
          } else {
            console.warn("this user does not belong to you, going back");
            this.$router.push("/users");
          }
        })
        .catch((response) => {
          console.warn(response);
          this.AlertError();
        });
    }
  },
  methods: {
    submitForm() {
      const userId = this.$router.currentRoute.params.id;
      if (
        typeof this.$data.user.newPass !== "undefined" &&
        this.$data.user.newPass != "" &&
        this.$data.user.newPass.length < 6
      ) {
        this.$swal({
          confirmButtonText: this.$t("okButton"),
          type: "error",
          title: this.$t("passwordLength"),
          timer: 3000,
        }).catch(this.$swal.noop);
        return false;
      }

      //const obj = Object.assign({}, this.$data.user);
      if (this.snippets !== "")
        this.app.snippets = JSON.stringify(this.snippets);

      if(this.$store.state.user.type === 1)
        axiosV2.put("settings/" + userId, this.app);

      this.$store
        .dispatch("updateUser", this.user)
        .then((user) => {
          this.AlertSuccess(this.$t("detailsSaved"))
        })
        .catch((response) => {
          let err = this.$t("fatalError");
          switch (response.code) {
            case -13:
              err = this.$t("emailExist");
              break;

            case -12:
              err = this.$t("fieldRequired_" + response.data.field);
              break;

            case -16:
              err = this.$t("passwordsNotMatching");
              break;

            case -17:
              err = this.$t("incorrectPassword");
              break;
          }
          this.AlertError();
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.editEventActions {
  float: left;
  margin-top: 6px;
}
.editEventActions .backBtn span {
  color: #7c97c5;
  font-weight: 700;
  font-size: 1.235rem;
  display: inline-block;
  vertical-align: middle;
}
.editEventActions .backBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 18px;
  fill: #293b5a;
  margin-right: 5px;
}
.editEventActions .deleteBtn {
  width: 218px;
  height: 54px;
  background: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 27px;
  margin-left: 30px;
}
.editEventActions .deleteBtn span {
  color: #293b5a;
  font-weight: 700;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}
.editEventActions .deleteBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 23px;
  fill: #849dc8;
  margin-left: 10px;
}
.innerBox {
  padding: 30px;
}
.h1Wrap svg.i_profile {
  width: 26px;
  height: 30px;
}
</style>
