export function exportCSV(header, data , name = "export.csv") {
    let csv = header.join(",") + '\n'  //name,email,phone\n";

    data.forEach((_d) => {
        Object.keys(_d).forEach((_key) => {
            csv += _d[_key] + ',';   
        });
        csv += "\n";
    });
    csv = String.fromCharCode(0xFEFF) + csv
    const anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    anchor.target = "_blank";
    anchor.download = name;
    anchor.click();
}