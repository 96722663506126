<template>
  <div>
    <div class="actionBtns">
     
    </div>

    <div v-if="items">
      <table class="seatupTable">
        <tr>
          <th>{{ $t("image") }}</th>
          <th>{{ $t("name") }}</th>
          <th>{{ $t("price") }}</th>
          <th></th>
        </tr>

        <tr v-bind:key="item.id" v-for="item in items" :class="(item.outofstock==1) ? 'outofstock' : ''">
          <td width="70">
            <img
              v-if="item.image"
              v-bind:src="FileStoragePath(item.image)"
              style="height: 70px"
            />
            {{ !item.image ? $t("notExist") : "" }}
          </td>
          <td>{{ item.name }}</td>

          <td>{{ item.price ? item.price : $t("notExist") }}</td>

          <td class="eventsActions">

            <button @click="$emit('onEdit', item);" v-tippy :title="$t('edit')">
              <svg><use xlink:href="#i_edit"></use></svg>
              <span class="sr-only">{{ $t("") }}</span>
            </button>

            <button @click="deleteItem(item.id)" v-tippy :title="$t('delete')">
              <svg><use xlink:href="#i_close"></use></svg>
              <span class="sr-only">{{ $t("") }}</span>
            </button>

          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Config from "../../Config";
export default {
  name: "ItemList",
  components: {},
  props: {
    category: {
      type: Number,
    },
    itemType: { type: String, default: "" },
  },
  data() {
    return {
      search: "",
      actionsStyle: "",
      currentID: 0,
    };
  },
  computed: {
    items: function () {
      let items;
      const itemType = this.itemType

      switch (itemType) {
        case 'napkin':
           items = this.$store.getters.getNapkins
          break;
          case 'food':
           items = this.$store.getters.getDishes
          break;
          case 'tabletop':
           items = this.$store.getters.getTableTop
          break;
           case 'design':
           items = this.$store.getters.getEventDesigns
          break;
        default:
          break;
      }
      
      if (this.category)
        return items.filter(
          (x) => x.categoryId == this.category
        );

      return items;
    },
  },
  mounted() {
    this.$store.dispatch("getAllEventItems", this.itemType);
  },

  methods: {
    getConfig() {
      return Config;
    },
    showActions(e, id) {},

    toggleSearch() {
      // $("body").toggleClass("search_open");
    },
   
    deleteItem(dishId) {
      var $this = this;
      this.$swal({
        title: this.$t("confirmDelete"),
        text: this.$t("unrevetableOperation"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
      })
        .then((result) => {
          if (result === true) {
            this.$store
              .dispatch("restDelete", {
                route: "event-item",
                deleteId: dishId,
                callback: function () {
                  $this.dishes.splice(
                    $this.dishes
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(dishId),
                    1
                  );
                  $this.AlertSuccess()
                },
              })
              .catch((response) => {
                this.AlertError()
              });
          }
        })
        .catch(this.$swal.noop);
    },
  },
};
</script>

<style scoped>

tr.outofstock{
  opacity:0.5;
}
.eventsActions button{
  margin:0 10px;
  float:left;
}
svg {
  width: 30px;
  height: 30px;
}

.searchWrapper {
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
}
.search_open .searchWrapper {
  border-color: #adadad;
}
.searchWrapper input[type="search"] {
  width: 0;
  height: 32px;
  transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.search_open input[type="search"] {
  width: 275px;
  transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.searchWrapper input[type="search"]::-webkit-input-placeholder {
  font-style: italic;
  color: #adadad;
  font-size: 0.9412rem;
}
.search_open .searchEvent svg {
  fill: #293b5a;
}
</style>
