import Vue from 'vue'
import Router from 'vue-router'
import RootAdminRoutes from './routes/root-admin'
import EventRoutes from './routes/event'
import AuthRoutes from './routes/auth'
import GuestRoutes from './routes/guest'
import UserRoutes from './routes/user'
import CalendarRoutes from './routes/calendar'
import {
    guardInRole,
    guardHavePermission,
    guardRoleLevel
} from './guards/index'

import Settings from '@/components/Settings/Settings'

import AddBanner from '@/components/Banners/AddBanner'
import AddTable from '@/components/Tables/AddTable'
import AddEquipment from '@/components/Equipment/AddEquipment'
import AddHall from '@/components/Halls/AddHall'
import EditEquipment from '@/components/Equipment/EditEquipment'
import EditBanner from '@/components/Banners/EditBanner'

import EditTable from '@/components/Tables/EditTable'

import NotFound from '@/components/NotFound'

//import Calendar from '@/components/Calendar/Calendar'

import AddPreset from '@/components/Presets/AddPreset'

import EventPreset from '@/components/Presets/EventPreset'
import TablesStatus from '@/components/Tables/TablesStatus'


import Seating from '@/components/Guests/Seating'
import SMS from '@/components/SMS/SMS'


import EventBuilderPage from '@/pages/EventBuilderPage'


const QuotationForm = () => import('@/components/Quote/QuoteForm')
const DrinksAssign = () => import('@/components/Drinks/DrinksAssign')


// meeting
const MeetingForm = () => import('@/components/Meeting/MeetingForm')
const MeetingView = () => import('@/components/Meeting/ViewMeeting')

//Kiosk

import KioskTablesPreset from '@/components/Kiosk/KioskTablesPreset'

//pages

const BrowsePage = () => import('@/pages/BrowsePage')

const SignContractPage = () => import('@/pages/SignContractPage')

const AnalyticsPage = () => import('@/pages/AnalyticsPage')

import store from '../store'
import vuexI18n from 'vuex-i18n';

//import languages
import TPL_HE from '../translations/he'
import TPL_EN from '../translations/en'
import TPL_RU from '../translations/ru'
import { ROLES_NUM as Role } from '../core/auth/roles.enum'
import { isPlanup } from '../Config'

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('he', TPL_HE);
Vue.i18n.add('en', TPL_EN);
Vue.i18n.add('ru', TPL_RU);
Vue.i18n.set(store.state.preferences.lang);

Vue.use(Router)
//const Role = Roles;

const router = new Router({

    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import('@/pages/DashboardPage'),
            meta: {
                requiresAuth: true,
                // userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },
        {
            path: '/event/tables/preset',
            name: 'KioskTablesPreset',
            component: KioskTablesPreset,
            meta: {
                requiresAuth: true,
                minimumUserLevel: 3,
                matchHeight: true
            }
        },

        ...RootAdminRoutes,
        ...EventRoutes,
        ...AuthRoutes,
        ...GuestRoutes,
        ...UserRoutes,
        ...CalendarRoutes,


        {
            path: '/notFound',
            name: 'notFound',
            component: NotFound,
            meta: {
                requiresAuth: false,
                matchHeight: true
            }
        },
        // {
        //     path: '/settings/templates',
        //     name: 'settings.templates',
        //     component: () => import('@/components/Settings/Tabs/TemplatesTab'),
        //     meta: {
        //         requiresAuth: true,
        //         userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
        //         matchHeight: true
        //     }
        // },
        {
            path: '/settings/banners/add',
            name: 'addBanner',
            component: AddBanner,
            meta: {
                requiresAuth: true,
                minimumUserLevel: 1,
                matchHeight: true
            }
        },

        {
            path: '/settings/presets/add',
            name: 'addPreset',
            component: AddPreset,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/settings/presets/:id',
            name: 'editPreset',
            component: () => import('@/components/Presets/EditPreset'),
            meta: {
                requiresAuth: true,
                userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/preset/:id',
            name: 'eventPreset',
            component: EventPreset,
            meta: {
                requiresAuth: true,
                //userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                havePermission: 'event.update',
                matchHeight: true
            }
        },

        {
            path: '/settings/halls/add',
            name: 'addHall',
            component: AddHall,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/settings/tables/add',
            name: 'addTable',
            component: AddTable,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/settings/equipment/add',
            name: 'addEquipment',
            component: AddEquipment,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/settings/banners/:id',
            name: 'editBanner',
            component: EditBanner,
            meta: {
                requiresAuth: true,
                minimumUserLevel: 1,
                matchHeight: true
            }
        },

        {
            path: '/settings/equipment/:id',
            name: 'editEquipment',
            component: EditEquipment,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/settings/tables/:id',
            name: 'editTable',
            component: EditTable,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },

        {
            path: '/settings/halls/:id',
            name: 'editHall',
            component: () => import('@/components/Halls/EditHall'),
            meta: {
                requiresAuth: true,
                userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },





        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Admin, Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },


        {
            path: '/settings/:tab',
            name: 'settingsTabSelected',
            component: Settings,
            meta: {
                requiresAuth: true,
                userInRole: [Role.Resseler, Role.HallManager],
                matchHeight: true
            }
        },





        {
            path: '/browse/:entity',
            name: 'BrowsePage',
            component: BrowsePage,
            meta: {
                requiresAuth: true,
                //userInRole: [1, 2, 5],
                matchHeight: true
            }
        },


        {
            path: '/events/:eventID/build',
            name: 'event-builder-page',
            component: EventBuilderPage,
            meta: {
                requiresAuth: true,
                havePermission: 'event_planner.create'
            }
        },

        {
            path: '/tables/:eventID',
            name: 'tables',
            component: TablesStatus,
            meta: {
                requiresAuth: true,
                minimumUserLevel: 3,
                matchHeight: true
            }
        },

        {
            path: '/arrival/:eventID',
            name: 'arrival',
            component: () => import('@/components/Guests/GuestsArrival'),
            meta: {
                requiresAuth: true,
                havePermission: 'guests.arrival',
                matchHeight: true
            }
        },
        {
            path: '/messages',
            name: 'messages',
            component: () => import('@/pages/MessagesPage'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/sms',
            name: 'sms',
            component: SMS,
            meta: {
                requiresAuth: true,
                minimumUserLevel: 3,
                matchHeight: true
            }
        },

        {
            path: '/seating/:eventID',
            name: 'seating',
            component: Seating,
            meta: {
                requiresAuth: true,
                //userInRole: [Role.Admin, Role.Resseler, Role.HallManager, Role.HallManagerChild],
                matchHeight: true
            }
        },

        {
            path: '/screen',
            name: 'screen',
            component: () => import('@/components/Screen/Screen'),
            meta: {
                requiresAuth: true,
                userInRole: [Role.Admin, Role.Resseler],
                matchHeight: true
            }
        },


        {
            path: '/screen/:eventID',
            name: 'screenEvent',
            component: () => import('@/components/Screen/Screen'),
            meta: {
                requiresAuth: true,
                minimumUserLevel: 3,
                matchHeight: true
            }
        },
        {
            path: '/pdf/obligation',
            name: 'pdfObligation',
            props: true,
            component: () => import('@/components/Pdf/pdfObligation'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/pdf/contract',
            name: 'pdfContract',
            props: true,
            component: () => import('@/components/Pdf/pdfContract'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/pdf/quote',
            name: 'pdfQuotation',
            props: true,
            component: () => import('@/components/Pdf/pdfQuotation'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/pdf/:type',
            name: 'pdfPage',
            props: true,
            component: () => import('@/pages/pdfExportPage'),
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/contract-sign/:quoteId',
            name: 'SignContractPage',
            props: true,
            component: SignContractPage,
            meta: {
                requiresAuth: false,
            }
        },
        {
            path: '/quotation/:id',
            name: 'QuotationForm',
            component: QuotationForm,
            meta: {
                requiresAuth: true,

            }
        },

        {
            path: '/meeting/:id',
            name: 'MeetingForm',
            component: MeetingForm,
            meta: { requiresAuth: true, havePermission: 'meeting.create' }
        },
        {
            path: '/meeting/:id/view',
            name: 'MeetingView',
            component: MeetingView,
            meta: { requiresAuth: true }
        },
        {
            path: '/events/:event/drinks',
            name: 'DrinksAssign',
            component: DrinksAssign,
            meta: { requiresAuth: true, }
        },

        {
            path: '/waiters',
            name: 'waiters',
            component: () => import('@/pages/WaitersPage'),
            meta: { requiresAuth: true }
        },
        {
            path: '/events/:event/waiters',
            name: 'WaiterTableAssign',
            component: () => import('@/components/Waiters/WaiterTableAssign'),
            meta: { requiresAuth: true }
        },

        {
            path: '/waiters/shifts',
            name: 'WaiterShiftAssign',
            component: () => import('@/components/Waiters/WaiterShiftAssign'),
            meta: { requiresAuth: true }
        },

        {
            path: '/events/:event/drinks',
            name: 'DrinksAssign',
            component: DrinksAssign,
            meta: { requiresAuth: true, }
        },

        {
            path: '/billing',
            name: 'billing',
            component: () => import('@/pages/BillingPage'),
            meta: { requiresAuth: true, havePermission: 'billing', }
        },
        {
            path: '/billing/:event',
            name: 'billing',
            component: () => import('@/components/Billing/EventBilling'),
            meta: { requiresAuth: true, havePermission: 'billing', }
        },
        {
            path: '/analytics',
            name: 'AnalyticsPage',
            component: AnalyticsPage,
            meta: { requiresAuth: true, minimumUserLevel: 5, }
        },
    ]
})

export default router

//apply middleware between routes
router.beforeEach((to, from, next) => {

    $("html, body").animate({ scrollTop: 0 }, "fast");

    //save from to var (history backup)
    window.routerLastFrom = from.name;

    //set page title

    if (isPlanup()) {
        document.title = 'PlanUp'
    } else {
        try {
            document.title = store.state.app.name;
        } catch (e) {
            document.title = 'SeatUP';
        }
    }



    if (store.state.isLogged && to.name != 'login') {
        setTimeout(function () { $(".wrapper").fadeIn() }, 500); //prevent login to app "view jump"
    }

    store.state.usedBanners = [];

    if (to.meta.requiresAuth && !store.getters.isLogged) {
        next({
            path: '/login',
            query: {
                redirect: to.fullPath,
            },
        });
    }

    if (!store.state.dashboardInitiated && to.meta.requiresAuth && store.getters.isLogged) {

        store.dispatch('getDashboardData')
            .then(response => {

                document.body.className = (store.state.preferences.menuState == 'open' ? 'menu_open' : '');
                if (to.name == 'login' && store.getters.dashboardInitiated && store.getters.isLogged) {
                    if (to.query.redirect != '') {
                        next(to.query.redirect);
                    } else {
                        next('/');
                    }
                }

                if (!to.matched.length) {
                    next('/notFound');
                } else {

                    if (to.matched.some(record => record.meta.requiresAuth)) {
                        if (!store.getters.isLogged) {
                            next({
                                path: '/login',
                                query: {
                                    redirect: to.fullPath,
                                },
                            });
                        } else {
                            next();
                        }
                    } else {
                        next();
                    }
                }
            })

    } else {
        if (to.name == 'login' && store.getters.dashboardInitiated && store.getters.isLogged) {

            if (to.query.redirect != '') {
                next(to.query.redirect);
            } else {
                next('/');
            }
        } else {
            if (!to.matched.length) {
                next('/notFound');
            } else {

                if (to.matched.some(record => record.meta.requiresAuth)) {
                    if (!store.getters.isLogged) {
                        next({
                            path: '/login',
                            query: {
                                redirect: to.fullPath,
                            },
                        });
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        }
        next();

    }

    if ((!store.state.isLogged || to.name == 'login' || to.name == 'passwordRecovery') && to.name != 'notFound') {
        // document.body.className = 'loginWrap';

    } else {
        document.body.className = (store.state.preferences.menuState == 'open' ? 'menu_open' : '');
    }

    // if (to.meta.matchHeight) {
    //     setTimeout(function () {
    //         window.addEventListener('DOMContentLoaded', (event) => {
    //             $(".matchHeight").matchHeight();
    //         });

    //     }, 300)
    // }

    setTimeout(function () {
        const user = store.state.user

        // remove this line !!!! only for demo
        //if(user.type == Role.HallManager) return

        if (!guardInRole(to, store.state.user)) {
            console.log('role guard: access denied, redirecting to homepage', to.path, to.meta.userInRole, store.state.user.type)
            next('/');
        }

        if (typeof to.meta.havePermission !== 'undefined' && to.meta.havePermission != '') {

            if (user.permissions == null) {
                store.dispatch('getLoggedUserInfo').then(user => {
                    store.state.user = user
                    if (!guardHavePermission(to, user)) {
                        console.log('havePermission guard: access denied, redirecting to homepage', to.meta.havePermission, store.state.user.type)
                        next('/');
                    }
                })
            } else {
                if (!guardHavePermission(to, user)) {
                    console.log('havePermission guard: access denied, redirecting to homepage', to.meta.havePermission, user.permissions)
                    next('/');
                }
            }

        }

        if (!guardRoleLevel(to, user)) {
            console.log('role-level guard: access denied, redirecting to homepage, route:', to, user)
            next('/');
        }

    }, 2500);

    if (store.state.isKiosk) document.body.className = ''

})
