import Config from "../Config";
import axiosV2, { apiRequest } from "../core/http/axios_api_v2";
import { isObject, mergeDeep } from "../helpers/object";

const UserStore = {
    namespaced: false,
    state: {
        userSettings: {
            id: null,
            defaultEventTimes: [],
            defaultEventContacts: "",
            attrs: {
                defaultExtras: []
            }
        }
    },
    actions: {
        getUser: ({ commit }, id) =>
            new Promise((resolve, reject) => {

                axiosV2.get('users/' + id)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),

        updateUser: ({ commit }, o) =>
            new Promise((resolve, reject) => {

                axiosV2.put('users/' + o.id, o)
                    .then(function (response) {

                        var data = response.data;
                        resolve(data)


                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),

        getUserSettings: ({ commit, state, rootState }) =>

            new Promise((resolve, reject) => {
               
                if (state.userSettings.id && state.userSettings.id > 0)
                    return resolve(state.userSettings)


                let uri = (rootState.user.type != 3) ? '/user-settings' : '/user-settings/client';
                axiosV2.get(uri)
                    .then((response) => {

                        const defaultSettings = {
                            attrs: {
                                defaultExtras: [],
                                eventSteps: {},
                                logo: ''
                            },
                            defaultEventTimes: []
                        }

                       

                        state.userSettings = response.data
                        if(!isObject(state.userSettings.attrs)) {
                            state.userSettings.attrs = defaultSettings.attrs
                        }
                        if(!Array.isArray(state.userSettings.defaultEventTimes)) {
                            state.userSettings.defaultEventTimes = defaultSettings.defaultEventTimes
                        }
                        console.log(state.userSettings, defaultSettings.attrs)
                        resolve(state.userSettings)
                    })
                    .catch((error) => {
                        console.warn(error);
                        reject(error)
                    });

            }),
        saveUserSettings: ({ commit, state }, data) => {
            return apiRequest('post', 'user-settings', data).then(_s => {
                state.userSettings = _s
            })

        },

        getPermissions: ({ commit, rootState }, id) => apiRequest('get', `users/permissions/` + id),


        updateUserPermissions: ({ commit }, data) => new Promise((resolve, reject) => {
            axiosV2.post(`users/permissions/${data.id}/permissions`,
                { 'permissions': data.permissions })
                .then(function (response) {
                    if (response.status == 201) { // http 201 = created
                        resolve(response.data)
                    } else {
                        reject(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });
        }),
    },
    mutations: {

    },
    getters: {
        userSettings(state) {
            return state.userSettings
        }
    }
}
export default UserStore;
