import ROLES_NUM from '../../core/auth/roles.enum';

export default [
    {
        path: '/guests/myPlace',
        name: 'guestsMyPlace',
        component: () => import('@/components/Kiosk/KioskMyPlace'),
        meta: {
            requiresAuth: true,
            minimumUserLevel: 3,
            matchHeight: true
        }
    },

    {
        path: '/guests/myPlace/:id',
        name: 'kioskGuest',
        component: () => import('@/components/Kiosk/KioskGuest'),
        meta: {
            requiresAuth: true,
            minimumUserLevel: 3,
            matchHeight: true
        }
    },
    {
        path: '/guests/myPlace/:id/table',
        name: 'KioskTablePrint',
        component: () => import('@/components/Kiosk/KioskTablePrint'),
        meta: {
            requiresAuth: true,
            minimumUserLevel: 3,
            matchHeight: true
        }
    },
    {
        path: '/guests/:eventID',
        name: 'guests',
        component: () => import('@/components/Guests/Guests'),
        meta: {
            requiresAuth: true,
            havePermission: 'guests.create',
            matchHeight: true
        }
    },
]