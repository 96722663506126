import Config from "../Config";
import axiosV2, { apiRequest } from "../core/http/axios_api_v2";
import socket from '../core/http/socket'
import Vue from 'vue'
import { setUndefinedValue } from "../helpers/object";

const axios = require('axios').default;

const EventStore = {
    namespaced: false,
    state: {
        event: { type: {}, gallery: {} },
        eventPlanner: {},
        events: [],
        eventTypes: [],
        selectedEventID: 0,
        closestEvents: [],
        guestGroups: []
    },
    actions: {
        sendEventLoginDetails: ({ commit, state, rootState }, owner) =>
            new Promise((resolve, reject) => {
                axiosV2.post('event/send-event-login', owner)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),

        kalaEventDisconnect: ({ commit, state, rootState }, eventId) =>
            new Promise((resolve, reject) => {

                axiosV2.post('kala-crm/kala-event-disconnect/' + eventId, {})
                    .then((response) => {

                        if (response.status == 201)
                            resolve(response.data)
                        else
                            reject(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
            kalaEventConnect: ({ commit, state, rootState }, data) =>
            new Promise((resolve, reject) => {

                axiosV2.post('kala-crm/kala-event-connect', data)
                    .then((response) => {
                        if (response.status == 201)
                            resolve(response.data)
                        else
                            reject(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
            itayEventConnect: ({ commit, state, rootState }, data) =>
            new Promise((resolve, reject) => {

                axiosV2.post('kala-crm/itay-event-connect', data)
                    .then((response) => {
                        if (response.status == 201)
                            resolve(response.data)
                        else
                            reject(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
            ConnectToItay: ({ commit, state, rootState }, data) =>
            new Promise((resolve, reject) => {

                axiosV2.post('kala-crm/itay-event-connect', data)
                    .then((response) => {
                        if (response.status == 201)
                            resolve(response.data)
                        else
                            reject(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),


        getEvents: ({ commit, state, rootState }, homepage = false) =>
            new Promise((resolve, reject) => {

                axiosV2.get('event/?homepage=true')
                    //axios.get(`${Config.SETTINGS.API_URL}${Config.API_ROUTES.events}${homepage ? '?homepage=true' : ''}`, Config.DEFAULT_API_SETTINGS)
                    .then((response) => {
                        const data = response.data
                        commit('setClosestEvents', data)
                        //state.closestEvents = data

                        resolve()

                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
        // changeCalendarEventDate: ({ commit }, event) =>
        //     new Promise((resolve, reject) => {
        //         var $this = this;

        //         axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.calendar + '/' + event.id, event, Config.DEFAULT_API_SETTINGS)
        //             .then(function (response) {
        //                 if (response.data.status) {
        //                     resolve(response.data.data)
        //                 } else {
        //                     reject(response.data)
        //                 }
        //             })
        //             .catch(function (error) {
        //                 console.warn(error);
        //                 reject()
        //             });

        //     }),

        getEventPlanner: ({ commit, state }, belongToEvent) =>
            new Promise((resolve, reject) => {
                apiRequest('get', `event-planner/${belongToEvent}`)
                    .then((eventPlanner) => {
                        eventPlanner.categoryDishLimit = setUndefinedValue(eventPlanner.categoryDishLimit, {})
                        commit('setEventPlanner', eventPlanner)
                        resolve(eventPlanner)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });
            }),

        saveEventPlanner: ({ commit, state }, data) => {
            apiRequest('patch', '/event-planner/' + data.id, data)
        },

        getEvent: ({ commit, state, rootState, dispatch }, id) =>

            new Promise((resolve, reject) => {
                const _socket = socket
                axiosV2.get('event/' + id)
                    .then((response) => {
                        if (response.status != 200) {
                            reject(response.data.message)
                            return
                        }

                        // disconnect from old event
                        let roomName
                        let currentEventId = response.data.id
                        if (state.event.id != currentEventId) {
                            roomName = `${rootState.tenantId || ''}_seatup_event_${state.event.id}`
                            _socket.off(roomName)
                            console.log(`disconnected from ${roomName}`)
                            // connect  to current event
                            roomName = `${rootState.tenantId || ''}_seatup_event_${currentEventId}`
                            _socket.on(roomName, (data) => {
                                const eventGuests = data.data
                                if (data.type == 'guests-updated-by-kala-crm') {

                                    eventGuests.forEach(_g => {
                                        console.log('updating guest from sms:', _g)
                                        commit('updateGuest', _g, { root: true })
                                    })

                                    Vue.notify({
                                        title: 'אורחים עודכנו ממערכת SMS',
                                        text: ''
                                    });
                                }
                            });
                            console.log(`connected to ${roomName}`)
                        }
                        // stage commit
                        commit('setEvent', response.data)

                        resolve(response.data)

                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),

        getEventWithGuests: ({ commit, state }, id) =>
            new Promise((resolve, reject) => {

                axios.get(Config.SETTINGS.API_URL + Config.API_ROUTES.events + '/' + id + '?getGuestsForTables=true', Config.DEFAULT_API_SETTINGS)
                    .then(function (response) {
                        if (response.data.status) {
                            state.guestGroups = response.data.data.event.guestGroups;
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
        saveCurrentEvent: ({ commit, state, dispatch },) =>

            new Promise((resolve, reject) => {

                if (typeof state.event != typeof {}) {
                    console.error('evet is not set');
                    reject('evet is not set')
                }
                dispatch('updateEvent', (state.event));


            }),
        updateEvent: ({ commit, dispatch }, o) =>
            new Promise((resolve, reject) => {

                axiosV2.put('event/' + o.id, o)
                    .then(function (response) {
                        const data = response.data;
                        resolve(data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),

        updateEventPreset: ({ commit, dispatch }, o) => apiRequest('put', `event/${o.id}/preset/`, o),

        addEvent: ({ commit, dispatch }, o) =>
            new Promise((resolve, reject) => {
                apiRequest('post', 'event', o)
                    .then( (response) => {
                        if (response.success) {
                            dispatch('getDashboardData').then(dashboard => {
                                resolve(response)
                            });
                        } else {
                            reject(response)
                        }
                    })
            }),
        getGuestGroupList: ({ commit, state }, eventId) =>
            new Promise((resolve, reject) => {
                axiosV2.get(`guest/groups/${eventId}`)
                    .then(function (response) {
                        let d = response.data.map(x => x._group);

                        commit("setGuestGroups", d)
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),



    },
    mutations: {
        setEvent(state, ev) {
            // mutate state
            state.event = ev;
        },
        setClosestEvents(state, evs) {
            // mutate state
            state.closestEvents = evs;
        },
        setEventPlanner(state, ep) {
            // mutate state
            state.eventPlanner = ep;
        },
        setEventTypes(state, eventTypes) {
            // mutate state
            state.eventTypes = eventTypes;
        },
        setGuestGroups(state, payload) {

            // add default groups to user groups
            state.guestGroups = payload.filter(x => { return x != null });
        }
    },
    getters: {
        eventPlanner(state) {
            return state.eventPlanner
        },
        currentEvent(state) {
            return state.event
        },

        isConferenceEvent(state) {
            return state.event.type.id == 6
        },
        defaultGroups() {
            return [
                "משפחה אמא חתן",
                "משפחה אבא חתן",
                "משפחה אמא כלה",
                "משפחה אבא כלה",
                "משפחה גרעינית חתן",
                "משפחה גרעינית כלה",
                "עבודה חתן",
                "עבודה כלה",
                "עבודה אמא חתן",
                "עבודה אמא כלה",
                "עבודה אבא חתן",
                "עבודה אבא כלה",
                "חברים חתן",
                "חברים כלה",
                "חברים משותפים",
                "חברים אבא כלה",
                "חברים אבא חתן",
                "חברים אמא כלה",
                "חברים אמא חתן",
            ]
        },

        // differant than guestGroups because this getter return only event relavant guest groups (without default groups)
        eventRelevantGuestGroups(state, getters) {

            if (!state.event.settings)
                return state.guestGroups

            return [...new Set(state.guestGroups
                .concat(state.event.settings.defaultGroups || []))];
        },
        eventGuestGroups(state, getters) {

            if (!state.event.settings)
                return state.guestGroups;

            return [...new Set(state.guestGroups
                .concat(state.event.settings.defaultGroups)
                .concat(getters.defaultGroups)
            )];
        }


    }
}
export default EventStore;
