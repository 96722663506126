<template>
  <div>
    <div class="innerMainContent">
      <PageHeader icon="i_user" :title="$t('myProfile')" :breadcrumbs="[{ name: $t('myProfile'), route: null }]">
      </PageHeader>


      <div class="d-flex">

        <div class="s-box profile-menu">
          <button @click="$router.push('browse/quotation')" v-can:quotation="'view'"><i class="fa fa-file"></i> {{ $t('my quotes') }} </button>
          <button @click="$router.push('browse/contract')" v-can:quotation="'view'"><i class="fa fa-signature"></i> {{ $t('חוזים') }} </button>
          <button @click="$router.push('browse/meeting')" v-can:meeting="'view'"><i class="fa fa-users"></i> {{ $t('my meetings') }} </button>
         
        </div>

        <div class="s-box flex-fill p-5">
          <form action="" id="editProfile" class="formWrap" @submit.prevent="submitForm">
            <div class="row">
              <div class="col-xs-12 containerWidth">
                <div class="row">
                  <div class="col-xs-12">
                    <h2>{{ $t("generalDetails") }}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <FormInput type="text" maxlength="50" :label="$t('fullName')" name="fullName" v-model="user.name" required></FormInput>
                    
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="phone">{{ $t("phone") }}
                        <span>({{ $t("mobile") }})</span></label>
                      <input type="tel" id="phone" name="phone" v-model="user.phone" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="email">*{{ $t("email") }}</label>
                      <input type="email" id="email" name="email" v-model="user.email" required />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="email">*{{ $t("sendToPanda") }}</label>
                      <select v-model="user.calluptype" >
                        <option value="1">all</option>
                        <option value="2">callup</option>
                        <option value="3">panda</option>
                        <option value="4">none</option>
                        </select>                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="row">
                  <div class="col-xs-12">
                    <h2>{{ $t("passwordUpdate") }}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="currentPass">{{ $t("currentPassword") }}</label>
                      <input type="password" minlength="6" id="currentPass" name="currentPass" v-model="user.currentPass" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="newPass">{{ $t("newPassword") }}</label>
                      <password :badge="false" :secureLength="6" :required="false" :placeholder="null" id="newPass" name="newPass" v-model="user.newPass"></password>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap">
                      <label for="verifyNewPass">{{ $t("verifyNewPass") }}</label>
                      <password :badge="false" :secureLength="6" :required="false" :placeholder="null" id="verifyNewPass" name="verifyNewPass" v-model="user.verifyNewPass"></password>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="inputWrap submitWrap">
                      <button class="submit">{{ $t("save") }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
export default {
  name: "Profile",
  data() {
    return {
      user: {},
    };
  },
  components: {
    Password,
  },
  mounted() { },

  beforeMount() {
    this.$data.user = this.$store.state.user;
  },
  methods: {
    submitForm() {
      //check if updating password
      if (this.$data.user.currentPass != "" && this.$data.user.newPass != "") {
        //check if passwords matching
        if (this.$data.user.newPass != this.$data.user.verifyNewPass) {
          this.AlertError(this.$t("passwordsNotMatching"))
          return false;
        }

        if (
          typeof this.$data.newPass !== "undefined" &&
          this.$data.newPass.length < 6
        ) {
          this.AlertError(this.$t("passwordLength"))
          return false;
        }
      }
      this.$store
        .dispatch("updateUser", this.$data.user)
        .then((response) => {
          this.AlertSuccess(this.$t("detailsSaved"))
        })
        .catch((response) => {
          this.AlertError() 
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profile-menu {
  button {
    margin: 10px;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 10px;
    font-size: 20px;
    display: block;
    width: 100%;
  }
}

.editEventActions {
  float: left;
  margin-top: 6px;
}

.editEventActions .deleteBtn {
  width: 218px;
  height: 54px;
  background: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 27px;
  margin-left: 30px;
}

.editEventActions .deleteBtn span {
  color: #293b5a;
  font-weight: 700;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.editEventActions .deleteBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 23px;
  fill: #849dc8;
  margin-left: 10px;
}


</style>
