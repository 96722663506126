<template>
  <div>

    <table class="seatupTable">
      <tr>
        <th width="100%">{{ $t("name") }}</th>

        <th>{{ $t("actions") }}</th>
      </tr>

      <tr v-for="eventItemCat in $store.state.EventItemStore.itemCategories" v-bind:key="eventItemCat.id" @click="$emit('selected', eventItemCat)" :class="(selected == eventItemCat.id) ? 'selected' : ''">
        <td @click="selected = eventItemCat.id">{{ eventItemCat.name }}</td>

        <td class="eventsActions">
          <button @click="$emit('onEdit', eventItemCat);" v-tippy :title="$t('edit')">
            <svg>
              <use xlink:href="#i_edit"></use>
            </svg>
            <span class="sr-only">{{ $t("") }}</span>
          </button>
          <button @click="deleteEventItemCat(eventItemCat.id)" v-tippy :title="$t('delete')">
            <svg>
              <use xlink:href="#i_close"></use>
            </svg>
            <span class="sr-only">{{ $t("guestDelete") }}</span>
          </button>
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
export default {
  name: "eventItemCatList",
  components: {},
  data() {
    return {
      search: "",
      actionsStyle: "",
      currentID: 0,
      selected: null
    };
  },
  beforeMount() {
    this.$store.dispatch("EventItemCategories");
  },
  methods: {
    showActions(e, id) { },

    toggleSearch() {
      // $('body').toggleClass('search_open');
    },

    deleteEventItemCat(catId) {
      var $this = this;
      this.$swal({
        title: $this.$t("confirmDelete"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: $this.$t("yes"),
        cancelButtonText: $this.$t("no"),
      })
        .then((result) => {
          if (result === true) {
            $this.$store
              .dispatch("restDelete", {
                route: "event-item-category",
                deleteId: catId,
                callback: function () {
                  const categories = $this.$store.state.EventItemStore.dishCategories;
                  categories = $this.$store.state.EventItemStore.dishCategories.splice(
                    categories
                      .map(function (x) { return x.id; })
                      .indexOf(catId),
                    1
                  );

                  this.AlertSuccess()
                },
              })
              .catch((response) => {
                this.AlertError()
              });
          }
        })
        .catch(this.$swal.noop);
    },
  },

};
</script>

<style scoped lang="scss">
.seatupTable table {
  width: 100%;
}

tr.selected,
tr.selected td {
  background: #293b5a !important;
  color: white !important;
  font-weight: bold !important;
  ;
}

svg {
  width: 30px;
  height: 30px;
}

.searchWrapper {
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
}

.search_open .searchWrapper {
  border-color: #adadad;
}

.searchWrapper input[type="search"] {
  width: 0;
  height: 32px;
  transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}

.search_open input[type="search"] {
  width: 275px;
  transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}

.searchWrapper input[type="search"]::-webkit-input-placeholder {
  font-style: italic;
  color: #adadad;
  font-size: 0.9412rem;
}

.search_open .searchEvent svg {
  fill: #293b5a;
}

.eventsActions {
  display: flex;

  button {
    margin: 0 5px;
  }
}
</style>
