<template>
  <div>

    <Modal ref="addNewModal">
      <form @submit.prevent="create">
        <FormInput v-model="templateNew.templateKey" :label="$t('key')" required max="150"></FormInput>
        <FormInput type="textarea" rows="10" v-model="templateNew.content" :label="$t('content')" required id="taTemplateContent"></FormInput>
        <button class="s_btn big"> {{ $t('create') }}</button>
      </form>
    </Modal>

    <button class="s_btn big" @click="$refs.addNewModal.show()"> {{ $t('create') }}</button>
    
    <div class="row">

      <section class="p-4 col-md-6">
        <FormSelect required @change="handleEdit($event)" :options="templateOptions" v-model="selectedTemplateKey" :label="$t('templates')"></FormSelect>
        <form @submit.prevent="save">
          <FormInput v-model="template.templateKey" disabled :label="$t('key')" required></FormInput>
          <FormInput type="textarea" rows="10" v-model="template.content" :label="$t('content')" required id="taTemplateContent"></FormInput>
          <button class="s_btn big my-5">{{ $t('save') }}</button>
        </form>
      </section>

      <aside class="p-4 col-md-4">
        <h3>{{ $t('parameters') }}</h3>
        <div v-if="selectedTemplateKey && systemTemplates[selectedTemplateKey]" class="mt-3">
          <span v-for="param of systemTemplates[selectedTemplateKey].params" class="s-badge" :key="param" @click="typeInTextarea(`[${param}]`)">
            [{{ param }}]
          </span>

        </div>
      </aside>

    </div>

  </div>
</template>

<script>
import { apiRequest } from "../../../core/http/axios_api_v2";

export default {
  name: "TemplateTab",
  components: {},
  data() {
    return {
      selectedTemplateKey: null,
      template: {},
      templateNew: {},
      templateList: [],
      systemTemplates: [],
      i18n: {
        'smsContract': 'SMS שנשלח עבור חוזה',
        'smsQuote': 'SMS שנשלח עבור הצעת מחיר',
        'smsSeatingApproved': 'SMS הושבה',
        'smsAkumComplete': 'SMS תשלום אקום',
        'smsMeetingCreated': 'SMS פגישה',
      },
    };
  },
  watch: {},
  computed: {
    templateOptions() {
      const systemTemplates = Object.keys(this.systemTemplates).map(x => ({ value: x, content: this.i18n[x] }))
      const customTemplates = this.templateList
        .filter(x => !Object.keys(this.systemTemplates).includes(x.templateKey))
        .map(x => ({ value: x.templateKey, content: x.templateKey }))
      return [...systemTemplates, ...customTemplates]
    }
  },
  beforeMount() { },
  created() { },
  mounted() {
    apiRequest('get', "templates/system-templates").then((keys) => {
      this.systemTemplates = keys
    });
    this.loadList()
  },

  methods: {
    typeInTextarea(newText) {
      let el = document.getElementById('taTemplateContent')
      const [start, end] = [el.selectionStart, el.selectionEnd];
      el.setRangeText(newText, start, end, 'select');
    },
    handleEdit(option) {
      const templateExist = this.templateList.find(_template => _template.templateKey == option.value)

      if (templateExist) {
        this.template = templateExist
      } else {
        this.template = {
          templateKey: option.value,
          content: this.systemTemplates[option.value].defaultContent
        }
      }
    },
    save() {
      ///${this.selectedTemplateKey}
      this.template.isSimple = false
      apiRequest('post', `templates`, this.template).then((template) => {
        this.selectedTemplateKey = null
        this.template = {}
        this.AlertSuccess()
        this.loadList()
      });
    },
    create() {
      ///${this.selectedTemplateKey}
      this.templateNew.isSimple = true
      apiRequest('post', `templates`, this.templateNew).then((template) => {
        this.selectedTemplateKey = null
        this.templateNew = {}
        this.loadList()
      });
    },
    loadList() {
      apiRequest('get', "templates").then((list) => {
        this.templateList = list
      })
    }
  },
};
</script>

<style scoped>
input[type="radio"],
select {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;

}

label {
  margin: 0 10px;
}
</style>
