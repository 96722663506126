<template>
  <div class="container-fluid">
    <h2 class="text-info my-4">{{ title }}</h2>
    <p>{{ msg }}</p>

    <div class="col-sm-8">


      <!-- default contacts -->
      <!-- <div class="d-flex" v-for="p, inx of contacts" :key="inx">
        <h3 class="ml-5">
          הורה
          {{ inx + 1 }}
        </h3>
        
        <input type="text" class="" :placeholder="$t('name')" v-model="p.name" />
        <input type="number" class="" :placeholder="$t('phone')" v-model="p.phone" />
      </div>

      <hr /> -->

      <!-- contact list  -->
      <!-- <h2>אנשי קשר נוספים \ ספקים</h2> -->

      <!-- add new contact -->
      <div v-if="error" class="alert alert-danger" @click="error = !error">
        <strong>שגיאה:</strong> הוסף פרטי ספק
      </div>
      <form @submit.prevent="addTask" class="s-box p-3">
        <h3 class="mb-3">הוספה</h3>
        <div class="sup-add d-flex mb-3 justify-center align-center ">

          <FormInput v-model="itemProfession" :placeholder="$t('profession')"></FormInput>
          <FormInput v-model="itemName" :placeholder="$t('name')" required></FormInput>
          <FormInput type="number" v-model="itemPhone" :placeholder="$t('phone')" required></FormInput>

          <div class="">

            <button class="s_btn" type="submit">
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{ $t("add") }}
            </button>
          </div>
        </div>
      </form>

      <table class="my-4 seatupTable">
        <tr>
          <th>{{ $t('profession') }}</th>
          <th>{{ $t('name') }}</th>
          <th>{{ $t('phone') }}</th>
          <th></th>
        </tr>
        <tr v-for="(supplier, id) in items" :key="id" class="">

          <td class="col-sm-3">
            <input type="text" class="editable" v-model="supplier['profession']" :placeholder="$t('profession')" />
          </td>

          <td class="col-sm-3" @click="loadData(id)">
            <input type="text" class="editable" v-model="supplier['name']" :placeholder="$t('name')" />
          </td>

          <td class="col-sm-3">
            <input class="editable" type="text" v-model="supplier['phone']" :placeholder="$t('phone')" />
          </td>
          <td class="col-sm-2">
            <button class="s_btn danger mb-3" @click="remove(supplier)">
              {{ $t("delete") }}
            </button>
          </td>

        </tr>
      </table>


    </div>
    <div class="col-sm-4">
      <div class="inputWrap textareaWrap">
        <label for="">{{ $t("notes") }}</label>
        <textarea v-model="eventPlanner.supplierNotes" maxlength="200" class="noteWrapper"></textarea>
        <span class="charsLength"><span class="charNum">200</span>/200 </span>
      </div>
      <button class="s_btn btn-warning" type="button" @click="save">
        <i class="fa fa-download" aria-hidden="true"></i>
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { apiRequest } from '../../core/http/axios_api_v2';
export default {
  name: "EventSuppliers",

  data() {
    return {
      title: this.$t("אנשי קשר"),
      msg: "",
      itemName: "",
      itemPhone: "",
      itemProfession: "",
      contacts: [{}, {}],
      error: false,
      update: false,
      updateid: null,
      ids: [],
      deleteMultiple: false,
    };
  },
  props: {
    eventPlanner: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {

    setTimeout(() => {

      if (Array.IsNotArrayOrEmpty(this.eventPlanner.eventSuppliers) ) {
        const defaults = this.$store.getters.userSettings.defaultEventContacts || ''
        let items = []
        defaults.split(",").forEach(i => {

          items.push({
            profession: i,
            name: '',
            phone: ''
          });
        })
        this.items = items;

      }
    }, 1500)
  },
  computed: {
    items: {
      get() {
        return this.eventPlanner.eventSuppliers || [];
      },
      set(val) {
        return this.eventPlanner.eventSuppliers = val
      }
    },
  },
  watch: {
    'eventPlanner.eventContacts': function () {
      this.contacts = this.eventPlanner.eventContacts || [{}, {}]
    }
  },

  methods: {
    deleteMulti: function (e) {
      this.items.forEach((item) => {
        console.log(item.task, item.check);
      });
      console.log("----");
      var ids = [];
      this.items.forEach((item) => {
        if (item.check == true) {
          ids.push(item.id);
        }
      });
      ids.forEach((id) => {
        this.items = this.items.filter(function (task) {
          return task["id"] != id;
        });
      });
      this.deleteMultiple = false;
      this.items.forEach((task) => {
        console.log("DELETE:", task.task, task.check);
      });
    },
    changeCheck: function (e) {
      // this.items[e.target.id]['check'] = e.target.checked
      // var count = 0
      // this.items.forEach((task) => {
      //   if(task.check == true){
      //     count = count + 1
      //   }
      // })
      //this.deleteMultiple = (count > 0) ? true : false
      console.log("changeCheck");
      this.items.forEach((task) => {
        // console.log(task.task, task.check);
      });
    },
    remove(supplier) {
      const index = this.items.findIndex((item) => item.phone === supplier.phone && item.proffession === supplier.proffession);
      if (index !== -1) this.items.splice(index, 1);
      this.save();
    },
    loadData: function (id) {
    
      this.itemName = this.items[id]["task"];
      this.updateid = id;
      this.update = true;
    },
    save() {
      this.$store.dispatch('saveEventPlanner',{
          id: this.eventPlanner.id,
          eventSuppliers: this.items,
          eventContacts: this.contacts,
          supplierNotes: this.eventPlanner.supplierNotes
        })
      .then(r => {
        this.$notify('בוצע בהצלחה')
      })
    },
    addTask: function () {

      if (this.itemName != "") {
        this.items.push({
          name: this.itemName,
          phone: this.itemPhone,
          profession: this.itemProfession,
          check: false,
        });
        this.save();
      } else {
        this.error = true;
      }

      this.itemName = "";
      this.itemPhone = "";
      this.itemProfession = "";
    },
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-weight: bold;
}

.sup-add>div {
  margin: 0 10px;
  flex: 1 1;
}

.inputWrap input,
.inputWrap select {
  width: 100%;
  height: 42px;
  line-height: 32px;
}

.editable {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 30px;
  ;

  &:focus {
    background-color: white;
    border: 1px solid #ccc;
  }
}
</style>