const tableExistInEvent = (seatupEvent, tableId) => {
   return seatupEvent.presetData.find(x => x.dbID == tableId)
}


const presetDataToDictionary = (seatupEvent) => {
   const dictionary = {}
   seatupEvent.presetData.forEach(x => {
      dictionary[x.dbID] = x
   })
   return dictionary
}


const tableUUIDByNumber = (seatupEvent, tableNumber) => {
   let t = seatupEvent.presetData.find(x => x.tableNum == tableNumber)
   return t ? t.dbID : -1

}

const tableList = (seatupEvent) => {
   let tables = {};
   seatupEvent.presetData
      .sort((a, b) => a.tableNum - b.tableNum)
      .filter((table) => table.tableNum !== "")
      .forEach((_) => {
         tables[_.dbID] = _.tableNum;
      });
      return tables

}

export {
   tableExistInEvent, tableUUIDByNumber, tableList , presetDataToDictionary
}