<template>

  <header>
    <button class="openMenu flex-fill" @click="changeMenuState">
      <svg>
        <use xlink:href="#i_menu"></use>
      </svg>
      <span class="sr-only">{{ $t("menu") }}</span>
    </button>
    <ul class="headernav-ul">
      <li class="l_user">
        <div class="dropdown" style="float: right">
          <button class="selectBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!--<span class="profileImg"><img src="/static/images/user.png" alt=""></span>-->
            <span class="badge" style="color: white; background: #293b5a; margin: 0 3px" v-can:messages="'view'">
              {{ messageCount }}
            </span>
            <span>{{ $t("hello") }} {{ getUserName }}</span>
            <svg class="i_select">
              <use xlink:href="#i_select"></use>
            </svg>
          </button>
          <div class="dropdown-menu">
            <ul>
              <li v-can:messages="'view'">
                <router-link to="/messages">
                  <svg class="i_user">
                    <use xlink:href="#i_message"></use>
                  </svg>
                  <span>{{ messageCount }} {{ $t("messages") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/profile">
                  <svg class="i_user">
                    <use xlink:href="#i_user"></use>
                  </svg>
                  <span>{{ $t("myProfile") }}</span>
                </router-link>
              </li>
              <li>
                <a href="#" @click.prevent="logOff">
                  <svg class="i_logout">
                    <use xlink:href="#i_logout"></use>
                  </svg>
                  <span>{{ $t("logoff") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>


      <li class="l_lang">
        <LanguageSelector></LanguageSelector>
      </li>


      <li class="l_info">
        <div class="dropdown">
          <button class="selectBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg class="i_info">
              <use xlink:href="#i_info"></use>
            </svg>
            <span>{{ $t("support") }}</span>
            <svg class="i_select">
              <use xlink:href="#i_select"></use>
            </svg>
          </button>
          <div class="dropdown-menu">
            <ul>
              <li>
                <a href="#" class="trigger" id="contactUs" @click="openContactModal">
                  <svg class="i_contact">
                    <use xlink:href="#i_contact"></use>
                  </svg>
                  <span>{{ $t("contact") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li class="l_info">
        <div class="dropdown">
          <button @click="openVideo" class="selectBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg class="i_help">
              <use xlink:href="#i_help"></use>
            </svg>
            <span>{{ $t("getStarted") }}</span>
          </button>
        </div>
      </li>
     
    </ul>
    
  </header>
</template>

<script>
import LanguageSelector from "./LanguageSelector";

export default {
  name: "HeaderNav",
  data() {
    return {
      // pandaNotifications: []
    };
  },
  sockets: {

  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    getUserName() {
      return this.user ? this.user.name : "";
    },

    messageCount() {

      let m = this.$store.state.TasksStore.inbox.filter(
        (x) => !x.msg_to_user || x.msg_to_user.status != "completed"
      );
      if (m) return m.length;
      return 0;
    },
  },
  mounted() {
    this.$socket.on(this.$socket.id, (data) => {
      const socketData = data.data
      const socketType = data.type
      
      switch (socketType) {
        case 'quote_reminder':
        this.$store.commit('addMessage', socketData.message)
          this.$notify({
            title: 'תזכורת לסגור עיסקה',
            text: socketData.clientName,
            route: '/browse/quotation'
          });
          break;
        case 'message_received':
          this.$store.commit('addMessage', socketData)
          this.$notify({
            title: 'קיבלתם הודעה!',
            text: socketData.title,
            route: '/browse/quotation'
          });
          break;
      }
    })
  },
  methods: {
    openVideo() {
      window.open("https://vimeo.com/303576733");
    },
    logOff() {
      this.$store
        .dispatch("logoff")
        .then((response) => {
          this.$router.push("/login");
        })
        .catch((err) => {
          console.warn("Failed to logoff - ", err);
          this.$router.replace("/login", function () {
            setTimeout(function () {
              location.reload();
            }, 1000);
          });
        });
    },

    openContactModal() {
      $("#modal-custom").iziModal({
        autoOpen: 1,
      });


    },

    changeMenuState() {
      if (this.$store.state.isKiosk && !this.$store.state.isTablet) return;
      if (this.$store.state.preferences.menuState == "closed") {
        this.$store.dispatch("saveMenuState", "open").then((response) => {
          //on success
          document.body.className = "menu_open";
        });
      } else {
        this.$store.dispatch("saveMenuState", "closed").then((response) => {
          //on success
          document.body.className = "";
        });
      }
    },
  },

  components: {
    LanguageSelector,
  },
};
</script>
<style scoped lang="scss">
header {
  display: flex;
  flex-wrap: nowrap;
}

.selectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.openMenu {
  max-width: 70px;
}

.i_help {
  fill: #7b96c4;
  width: 30px;
  height: 28px;
  margin-left: 7px;
}

.headernav-ul {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .svg {
    margin: 0;
  }

  .headernav-ul {
    li {
      padding: 10px !important;
    }
  }
}
</style>
