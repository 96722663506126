<template>
    <div>
        <div class="innerMainContent">
            <div class="innerHeader">
                <div class="h1Wrap">
                    <svg class="i_profile">
                        <use xlink:href="#i_profile"></use>
                    </svg>
                    <div class="innerH1Wrap">
                        <h1>{{ $t('presetAdd') }}</h1>
                        <ul class="breadcrumb">
                            <li>
                                <router-link to="/">{{ $t('menu_hp') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/settings">{{ $t('menu_settings') }}</router-link>
                            </li>
                            <li>
                                <router-link to="/settings/presets">{{ $t('presets') }}</router-link>
                            </li>
                            <li class="active">{{ $t('presetAdd') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="editEventActions">
                    <router-link tag="button" class="backBtn" to="/settings/presets">
                        <span>{{ $t('back') }}</span>
                        <svg class="i_back">
                            <use xlink:href="#i_longArr"></use>
                        </svg>
                    </router-link>
                </div>
            </div>
            <div class="innerBox">
                <form action="" id="editPreset" class="formWrap" @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-xs-12 containerWidth">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="name">*{{ $t('preset_name') }}</label>
                                        <input type="text" id="name" name="name" v-model="preset.name" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="target">*{{ $t('hall') }}</label>
                                        <div class="selectWrap">
                                            <select name="target" id="target" v-model="preset.hall" required>
                                                <option value="" selected="selected">{{ $t('select') }}</option>
                                                <option v-for="hall in this.$store.state.halls" v-bind:value="hall.id">{{ hall.name }}</option>
                                            </select>
                                            <svg class="i_select">
                                                <use xlink:href="#i_select"></use>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="capacity">*{{ $t('capacity') }}</label>
                                        <input type="number" id="capacity" name="capacity" v-model="preset.capacity" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <hr>
                                    <FileManager ref="fileManager" :label="'preset_background'"></FileManager>
                                    <hr>
                                  
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap submitWrap">
                                        <button class="submit">{{ $t('save') }}</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import FileManager from "../Common/FileManager.vue";
export default {
    name: 'AddPreset',
    data() {
        return {
            preset: {
                name: '',
                hall: '',
                capacity: 0,
                background: '',
            },
        }
    },
    components: {
        FileManager
    },

    beforeMount() {
    
    },
    methods: {
        setFile(_) {
            this.$data.preset.background = _;
        },
        submitForm() {
            this.preset.capacity = parseInt(this.preset.capacity)
            this.preset.hall = parseInt(this.preset.hall)

            this.$refs.fileManager.upload().then(r => {
                this.preset.background = r?.file?.filePath
                this.$store.dispatch('addPreset', this.preset)
                    .then(response => {
                        this.$swal({
                            confirmButtonText: this.$t('okButton'),
                            type: 'success',
                            title: this.$t('presetAdded'),
                            timer: 2000
                        })
                            .then(preset => {
                                this.$router.push('/settings/presets/' + response.id);
                            })
                            .catch(this.$swal.noop);
                    })
                    .catch(response => {

                        this.AlertError()
                    })
            })

        }
    }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.editEventActions {
    float: left;
    margin-top: 6px;
}

.editEventActions .backBtn span {
    color: #7c97c5;
    font-weight: 700;
    font-size: 1.235rem;
    display: inline-block;
    vertical-align: middle;
}

.editEventActions .backBtn svg {
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 18px;
    fill: #293b5a;
    margin-right: 5px;
}

.editEventActions .deleteBtn {
    width: 218px;
    height: 54px;
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 27px;
    margin-left: 30px;

}

.editEventActions .deleteBtn span {
    color: #293b5a;
    font-weight: 700;
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
}

.editEventActions .deleteBtn svg {
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 23px;
    fill: #849dc8;
    margin-left: 10px;
}

.innerBox {
    padding: 30px;
}
</style>
