<template>
  <div>
      <h2>
         עריכת ציוד
      </h2>
      <hr>
    <div class="innerBox" v-if="table == null">אנא בחר שולחן</div>

    <div class="" v-if="table != null">

      <div class="alert alert-danger" role="alert" v-if="errorMsg">
            {{ errorMsg }}
      </div>

      <form
        action=""
        id="editPresetTable"
        class="formWrap"
        @submit.prevent="submitForm"
      >
        <div class="row">
          <div class="col-xs-12">
          
            <h4 class="panel-title">
                <a href="#" @click.prevent="">{{ $t('tableType') }}</a>
            </h4>
           
            <VisualTableList v-on:tableSelected="handleTableSelected" highlight="true"></VisualTableList>
            
            <div class="row">
              <div class="col-sm-12 mt-5">
                <div class="inputWrap submitWrap" style="margin:30px 0 0 0;text-align:center;float:none;">
                  <button class="submit">{{ $t("save") }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VisualTableList from '../Tables/VisualTableList.vue'; 
export default {
  name: "EditPresetTable",
  props: {
    
    table: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return {
          dbID: null,
          id: null,
          shape: "",
          type: null,
          reserved: false,
          scale: 1,
          rotation: 0,
          position: {
            x: 0,
            y: 0,
          },
          side1: null,
          side2: null,
          side3: null,
          side4: null,
          tableNum: "",
          tableCapcity: null,
          occupiedPlaces: null,
        };
      },
    },
  },
    components:{
        VisualTableList
    },
  data() {
    return {
      newTable: {},
      errorMsg:''
    };
  },

  watch: {
    table: function () {
      if (this.table) {
        // delete this.table.scale;
        this.newTable = this.table;
      }
    },
  },
  methods: {
    handleTableSelected(e){
        this.newTable = Object.assign({}, this.table, e);
    },
    submitForm(e) {
       this.$emit("tableChanged", this.newTable);
    },
  },
};
</script>
<style scoped>


.editEventActions {
  float: left;
  margin-top: 6px;
}
.editEventActions .backBtn span {
  color: #7c97c5;
  font-weight: 700;
  font-size: 1.235rem;
  display: inline-block;
  vertical-align: middle;
}
.editEventActions .backBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 18px;
  fill: #293b5a;
  margin-right: 5px;
}
.editEventActions .deleteBtn {
  width: 218px;
  height: 54px;
  background: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 27px;
  margin-left: 30px;
}
.editEventActions .deleteBtn span {
  color: #293b5a;
  font-weight: 700;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}
.editEventActions .deleteBtn svg {
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 23px;
  fill: #849dc8;
  margin-left: 10px;
}
.innerBox {
  padding: 30px;
}

.inputWrap.submitWrap button{
  float: none;
}
</style>
