
import { sumGuestStatus } from "../../helpers/guest/status";
import Seating from './Seating'
import { tableExistInEvent, presetDataToDictionary } from "../../helpers/event/tables";
import Equipment from './elements/equipment'


export default class PresetBuilder {

  #scale;
  #itemsMap;

  constructor(event, guests, elementId = null, config = {}) {

    this.config = {
      ...{
        'showLiveGuestCount': false,
        'showTableActions': true,
        'arriveOnlyMode': false,
        'highlightExcessive': false,

        'autofocusRegion': true,
        // style classes
        'excessiveClass': null
      }, ...config
    }
    this.backgroundImage = ''

    this._event = event;
    this._guests = guests;
    this.elementId = elementId

    // this.showTableActions = true;
    this.tablesSeatStatus = {}

    // calc seat status for all tables
    this.seatsStatus()

    // seating class
    this.Seating = new Seating(elementId, this.tablesSeatStatus)
    this.Seating.useStatusColor = !this.config.arriveOnlyMode
    this.tableClick = null
    this.itemsDOM = null

    this.itemsMap = presetDataToDictionary(this._event)
    this.scale = 1
    this.size = [720, 720]

    this.wrapperDOM = document.querySelector('#ulamLayout')
    this.zoomContainer = document.querySelector('#zoomContainer')
    this.backgroundDOM = document.querySelector('#zoomContainer > img')
  }

  actionButtonsHtml() {
    return `<div class="item-actions"><div class="resizeIcon"><i class="la la-arrows"></i></div>
    <div class="rotateIcon"><i class="la la-rotate-left"></i></div>
    <div class="deleteIcon"><i class="la la-trash-o"></i></div>
    <div class="editIcon"><i class="la la-pencil"></i></div></div>`
  }

  getItems() {
    return this._event.presetData
  }

  getItemsMap() {
    return this.itemsMap
  }

  addItem(item) {
    this._event.presetData.push(item)
  }

  setItems(items) {
    this._event.presetData = items
    this.itemsMap = presetDataToDictionary(this._event)
  }

  getItems() {
    return this._event.presetData
  }

  getData() {
    return this.getItems()
  }

  getItem(dbid) {
    return this.getItemsMap()[dbid] //this._event.presetData.find(x => x.dbID == dbid)
  }

  getItemsDOM() {
    if (this.itemsDOM)
      return this.itemsDOM
    return document.querySelectorAll(`${this.elementId} .item`)
  }

  getContainerDOM() {
    return document.querySelector(this.elementId)
  }

  initGrid() {

    // remove previous dom if exist
    const gridDOM = this.getContainerDOM()
    if (gridDOM)
      gridDOM.remove()

    // create new dom
    const containerDOM = document.createElement('div')
    containerDOM.id = gridDOM.id

    // set styles
    containerDOM.style.width = `${this.size[0]}px`
    containerDOM.style.height =  this.backgroundDOM.offsetHeight + 'px'
    containerDOM.style.position = 'absolute'
    containerDOM.style.top = '0'
    containerDOM.style.left = '0'
    containerDOM.style.zIndex = '99'

    // containerDOM.style.background = 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 44%, rgba(0,212,255,1) 98%)' 

    this.zoomContainer.style.transformOrigin = '0 0'
    this.zoomContainer.style.transform = `scale(${this.scale})`
    this.zoomContainer.append(containerDOM)

    return containerDOM
  }
  setScale(scale) {
    this.scale = scale
    this.zoomContainer.style.transformOrigin = 'right top'
    this.zoomContainer.style.transform = `scale(${this.scale})`
  }
  setFocus(region = { x: 0, y: 0, width: 0, height: 0, name: '' }) {
    let focusSize = region.height > region.width ? region.height : region.width
  
    this.setScale(this.size[0] / focusSize)

    let point = document.createElement('div')
    point.style.pointerEvents = 'none'
    point.style.position = 'absolute'
    point.style.left = region.x + 'px'
    point.style.top = region.y + 'px'
    point.style.width = region.width + 'px'
    point.style.height = region.height + 'px'
    point.style.border = '1px dashed #008000a6'
    point.id = "regionFocusPoint"
    this.getContainerDOM().append(point)
    setTimeout(() => {
      point.scrollIntoView({ behavior: 'instant', block: 'center' })
    }, 200)

  }
  buildHtml(_selector = null) {

    if (_selector)
      this.elementId = _selector

    const gridWrap = this.initGrid()

    const presetData = this.getItems();
    let html = "";

    if (!this._event.preset == null) {
      html = '<div class="preset-not-selected"><div>'
      html += "יש צורך לבחור פריסה במסך פריסת אולם"
      html += "</div></div>"
    }

    if (!presetData || !presetData.length) {
      console.debug('preset is empty')
      gridWrap.innerHTML = "";
      return
    }

    for (let index = 0; index < presetData.length; index++) {

      const element = presetData[index];
      let tablehead = 0,
        tableClass = "",
        shapeClass = "",
        item = "";

      const tableNumRotation = element.rotation ? (element.rotation > 0 ? 0 - element.rotation : Math.abs(element.rotation)) : 0;
      let isReserved = element.hasOwnProperty("reserved") && element.reserved === true

      if (!element.dbID) element.dbID = window.guid();


      if (element.type === "table") {

        let numb = !isNaN(parseFloat(element.tableNum)) && isFinite(element.tableNum) ? element.tableNum : '' // is numeric

        switch (element.shape) {
          case "circle":
            tablehead = 0;
            tableClass = "circleTable";
            shapeClass = "circle";
            break;
          case "square":
            tablehead = 0;
            tableClass = "squareTable";
            shapeClass = "square";
            break;
          case "rectangle":
            tablehead = Math.round(element.tableCapcity / 4 / 2);
            tableClass = "rectangleTable";
            shapeClass = "rectangle";
            break;
          case "ellipse":
            tablehead = 1;
            tableClass = "rectangleTable ellipseTable";
            shapeClass = "rectangle";
            break;
        }

        if (this.config.highlightExcessive) {
          tableClass += this.config.excessiveClass ? " " + this.config.excessiveClass : " show-excessive"
        }
        let scale = `transform:scale(${element.scale || 1}) rotate(${element.rotation || 0}deg);`;
        //scale = `${scale} rotate(${element.rotation || 0}deg);`;


        let _seated = this.tablesSeatStatus[element.dbID];
        if (typeof _seated === "object") {
          if (this.config.arriveOnlyMode) {
            _seated = _seated.arrived
          } else {
            _seated = _seated.total
          }
        }
        const tableUsage = `${_seated || 0}/${element.tableCapcity}`;
        const reserved = isReserved ? ` data-reserved="true"` : ''
        const excessive = _seated > element.tableCapcity ? _seated - element.tableCapcity : 0

        let dataAttrs = `
              data-table-usage="${tableUsage}" 
              data-shape="${element.shape}" 
              data-dbid="${element.dbID}" 
              class="tableWrap ${tableClass}" 
              data-tablehead="${tablehead}" 
              data-capacity="${element.tableCapcity}" 
              data-occupiedplaces="${_seated}" 
              data-rotation="${element.rotation || 0}" 
              data-scale="${element.scale || 1}"
              data-reserve-qnt="${element.reserveQnt || 0}"
              data-table-num="${element.tableNum || ''}"
              data-wood=${element.isWood == 'true' ? "true" : 'false'}
              ${reserved}
             `
        if (element.liveGuestCount)
          dataAttrs += `data-live-guest-count="${element.liveGuestCount}" `

        if (excessive)
          dataAttrs += `data-excessive="${excessive}" `

        let liveGuestCountDiv = element.liveGuestCount ? `<div class="live-guest-count">${element.liveGuestCount}/${element.tableCapcity}</div>` : ''

        let tableUsageDiv = `<div class="table-usage">${tableUsage}</div>`
        let tableNum = (isReserved) ? `R${numb}` : element.tableNum = numb
        let reserveText = (element.reserveQnt && element.reserveQnt > 0) ? `<div class="table-reserve-text">${element.reserveQnt}</div>` : null

        item =
          `<span data-reserved="${isReserved ? "true" : "false"}" 
          data-side1="${element.side1 || ""}" 
          data-side2="${element.side2 || ""}" 
          data-side3="${element.side3 || ""}" 
          data-side4="${element.side4 || ""}" 
         
          class="item" style="${scale};top:${element.position.y};left:${element.position.x};">
          <div class="tableWrap ${tableClass}" ${dataAttrs} data-type="table">
            <div class="${shapeClass}">
                <span class="tableNum" ${tableNumRotation !== 0 ? 'style="transform:rotate(' + tableNumRotation + 'deg);display:block"' : ""}>${tableNum}</span>
            </div>
           
            ${this.config.showLiveGuestCount && liveGuestCountDiv != '' ? liveGuestCountDiv : tableUsageDiv}
           
          </div>`

        if (this.config.showTableActions)
          item += this.actionButtonsHtml()

        if (reserveText)
          item += reserveText

        item += `</span>`;

      } else {
        ///////////////////////////////////////////////////////////////////////////////////
        // if equipment
        let equipment = new Equipment(element)
        equipment.showActionButtons = this.config.showTableActions
        item = equipment.getHtml()
      }

      html += item;
    }

    gridWrap.innerHTML = "";
    gridWrap.insertAdjacentHTML("beforeend", html);

    this.addEvents()
    this.focusRegion()
    return html;
  }

  focusRegion() {
    if (this.config.autofocusRegion === true) {
      if(!this._event.preset?.attrs?.regions) return
      const region = this._event.preset.attrs.regions.find(r => r.name == this._event.settings.defaultRegion)
      this.setFocus(region)     
    }
  }

  addEvents() {
    const items = this.getItemsDOM();

    items.forEach((item) => {

      // click event
      if (this.tableClick && typeof this.tableClick == 'function') {

        item.addEventListener("click", (evt) => {
          evt.preventDefault();
          const dom = evt.currentTarget
          const dbId = dom.firstElementChild.dataset.dbid;

          const table = tableExistInEvent(this._event, dbId);
          if (!table) {
            console.error('table not found', dbId, evt.currentTarget)
          }
          this.tableClick(table, dom)

        });
      }

    })

  }


  isItIn(parent, child, offset = 0) {
    var box1coords = parent.getBoundingClientRect();
    var box2coords = child.getBoundingClientRect();

    if (
      box2coords.top < box1coords.top + offset ||
      box2coords.right > box1coords.right - offset ||
      box2coords.bottom > box1coords.bottom - offset ||
      box2coords.left < box1coords.left + offset) {

      return true;
    }

    return false;

  }

  /**
   * Draw seats for preset
   */
  drawSeats() {
    this.Seating.drawSeats()
  }

  /**
 * var id = table UUID
 * Return seating status object {} for table id
 */
  seatsStatus() {
    let _s = {}

    if (!this.getItems() || !Array.isArray(this.getItems())) {
      return {}
    }

    for (let table of this.getItems()) {

      _s[table.dbID] = {
        confirmed: 0,
        notComing: 0,
        maybeComing: 0,
        reserve: Number(table.reserveQnt || 0),
        notResponded: 0,
        arrived: 0,
        notArrived: 0,
        total: 0,
      };
    }
    for (let guest of this._guests) {

      if (guest.tableId == null || _s[guest.tableId] == null) continue;

      let statusSum = sumGuestStatus(guest);

      if (this.config.arriveOnlyMode) {
        if (guest.arrived) {
          _s[guest.tableId].arrived += parseInt(statusSum || 0) > 0 ? statusSum : 1;
        } else {
          _s[guest.tableId].notArrived += parseInt(statusSum || 0) > 0 ? statusSum : 1;
        }

        _s[guest.tableId].total +=
          parseInt(guest.status.coming || 0) +
          parseInt(guest.status.maybeComing || 0) +
          parseInt(guest.status.notComing || 0) +
          parseInt(guest.status.notResponded || 0)
      } else {
        try {
          _s[guest.tableId].confirmed += parseInt(guest.status.coming || 0);
          _s[guest.tableId].maybeComing += parseInt(guest.status.maybeComing || 0);
          _s[guest.tableId].notComing += parseInt(guest.status.notComing || 0);
          _s[guest.tableId].notResponded += parseInt(guest.status.notResponded || 0);
          _s[guest.tableId].total = _s[guest.tableId].confirmed +
            _s[guest.tableId].maybeComing +
            _s[guest.tableId].notComing +
            _s[guest.tableId].notResponded
        } catch (e) {
          console.error('[seatsStatus ERRR]', e)
        }
      }
    }

    // update property
    this.tablesSeatStatus = _s

    return this.tablesSeatStatus;
  }

  highlightTables(tables) {
    tables.forEach(table => {
      this.getTableDomBydbID(table).classList.add('heightlight')
    })
  }

  clearHighlightTables(tables = null) {
    if (tables) {
      tables.forEach(table => {
        this.getTableDomBydbID(table).classList.add('heightlight')
      })
    }
    else {
      this.getItems().forEach(table => {
        this.getTableDomBydbID(table.dbID).classList.remove('heightlight')
      })
    }
  }



  getTableDomBydbID(dbid) {
    return document.querySelector(`[data-dbid='${dbid}']`)
  }

  connectTables(tablesIdsArray, lineColor, lineLabel) {

    const stringToColor = (str) => {
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }
    const calcCenterPoint = (element) => {
      const xCenter = element.offsetLeft + element.offsetWidth / 2;
      const yCenter = element.offsetTop + element.offsetHeight / 2;
      return [xCenter, yCenter]
    }

    const gridWrap = document.querySelector(this.elementId);
    const randomColor = lineColor ? lineColor : stringToColor(lineLabel);
    const presetDataDictionary = presetDataToDictionary(this._event) // used for O(1) instead of N^2

    let t1, t2, line = "", strokwidth = 2

    if (tablesIdsArray.length == 1) {
      t1 = presetDataDictionary[tablesIdsArray[0]]
      if (!t1) return
      // add text
      line = `<svg style="overflow:visible;position:absolute;top:0;left:0;pointer-events: none;">
       <text x="${t1.position.x}" y="${t1.position.y}" style="fill:#000000;font-weight:bold">${lineLabel}</text></svg>`

      gridWrap.insertAdjacentHTML("beforeend", line);
      return
    }

    for (let index = 0; index < tablesIdsArray.length - 1; index++) {

      t1 = presetDataDictionary[tablesIdsArray[index]]
      t2 = presetDataDictionary[tablesIdsArray[index + 1]]

      if (!t1 || !t2)
        continue

      let t1CenterCoords = calcCenterPoint(document.querySelector(`[data-dbid='${t1.dbID}']`).parentElement)
      let t2CenterCoords = calcCenterPoint(document.querySelector(`[data-dbid='${t2.dbID}']`).parentElement)

      line = `<svg style="overflow:visible;z-index:0;position:absolute;top:0;left:0;pointer-events: none;">`
      // add linear line
      line += `<line x1="${t1CenterCoords[0]}px" y1="${t1CenterCoords[1]}px" x2="${t2CenterCoords[0]}px" y2="${t2CenterCoords[1]}px" style="stroke:${randomColor};stroke-width:${strokwidth};" />`
      line += ' </svg> '
      gridWrap.insertAdjacentHTML("afterbegin", line);

      // add text
      line = `<svg style="overflow:visible;position:absolute;top:0;left:0;pointer-events: none;">
      <text x="${(t1CenterCoords[0] + t2CenterCoords[0]) / 2}px" y="${(t1CenterCoords[1] + t2CenterCoords[1]) / 2}px" style="fill:${randomColor};font-weight:bold;stroke:#000000">${lineLabel}</text></svg>`

      gridWrap.insertAdjacentHTML("beforeend", line);
    }


  }

}

