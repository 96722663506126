<template>
  <div>
   

    <Modal ref="addModal">
      <CreateItem :item-type="itemType" :itemToEdit="itemToEdit"></CreateItem>
    </Modal>

    <Modal ref="addCategory">
      <CreateEventItemCategory :itemToEdit="categoryToEdit"></CreateEventItemCategory>
    </Modal>


    <div class="d-flex items-center justify-center">
      <button type="button" :class="'tab-btn flex-fill ' + (selectedTab == indx + 1 ? 'selected' : '')" v-for="(tab, indx) of tabs" @click="selectedTab = indx + 1" :key="tab.title">
        {{ tab.title }}
      </button>
    </div>

    <div class="p-3">
      <div class="row" v-if="selectedTab == 1">
        <div class="col-sm-12 col-md-4">
          <button tag="button" class="s_btn" @click="$refs.addCategory.show()">
            <svg>
              <use xlink:href="#i_plus"></use>
            </svg>
            <span>{{ $t("add dish category") }}</span>
          </button>
        
          <ItemCategoryList v-on:selected="dishCategorySelected" v-on:onEdit="handleEditCategory"></ItemCategoryList>
        </div>
        <div class="col-sm-12 col-md-8">
          <button tag="button" class="s_btn" @click="addItem('food')">
            <svg>
              <use xlink:href="#i_plus"></use>
            </svg>
            <span>{{ $t("add") }} {{ $t("Dishes") }}</span>

          </button>
          <ItemList item-type="food" :category="selectedCategory" v-on:onEdit="handleEditItem"></ItemList>
        </div>
      </div>

      <div class="" v-if="selectedTab == 2">
       <button tag="button" class="s_btn" @click="addItem('napkin')">
        <svg>
          <use xlink:href="#i_plus"></use>
        </svg>
        <span>{{ $t("add") }} {{ $t("napkins") }}</span>
      </button>
        <ItemList item-type="napkin"></ItemList>
      </div>

      <div v-if="selectedTab == 3">
       <button tag="button" class="s_btn" @click="addItem('tabletop')">
        <svg>
          <use xlink:href="#i_plus"></use>
        </svg>
        <span>{{ $t("add") }} {{ $t("tabletop") }}</span>
      </button>
        <ItemList item-type="tabletop"></ItemList>
      </div>

      <div v-if="selectedTab == 4">
       <button tag="button" class="s_btn" @click="addItem('design')">
        <svg>
          <use xlink:href="#i_plus"></use>
        </svg>
        <span>{{ $t("add") }} {{ $t("design") }}</span>
      </button>
        <ItemList item-type="design"></ItemList>
      </div>
    </div>
  </div>
</template>

<script>
import CreateEventItemCategory from "../../EventItem/CreateEventItemCategory.vue";
import CreateItem from "../../EventItem/CreateItem.vue";
import ItemList from "../../EventItem/ItemList.vue";
import ItemCategoryList from "../../EventItem/ItemCategoryList.vue";
export default {
  name: "EventItemTab",
  components: {
    CreateEventItemCategory,
    CreateItem,
    ItemList,
    ItemCategoryList,
  },
  data() {
    return {
      dishes: [],
      showModal: false,
      showAddModal: false,
      search: "",
      itemToEdit: null,
      actionsStyle: "",
      currentID: 0,
      selectedCategory: null,
      categoryToEdit: null,
      itemType: null,
      selectedTab: 1,
      tabs: [
        { title: this.$t("Dishes"), icon: "" },
        { title: this.$t("napkins"), icon: "" },
        { title: this.$t("tabletop"), icon: "" },
        { title: this.$t("design"), icon: "" },
      ],
    };
  },
  methods: {
    handleEditCategory(category) {
     
      this.categoryToEdit = category
      this.$refs.addCategory.show()
    },
    handleEditItem(item) {
      this.itemToEdit = item
      this.$refs.addModal.show()
    },
    dishCategorySelected(e) {
      this.selectedCategory = e.id;
      //this.dishes = e.dishes;
    },
    addItem(type) {
      this.itemType = type;
      this.itemToEdit = null
      this.$refs.addModal.show()
      //this.dishes = e.dishes;
    },
  },
};
</script> 
<style scoped lang="scss">
.add-item-button-group .s_btn {
  margin: 0 10px;
}

.tab-btn {
  margin: 10px 10px;
  border: 3px solid var(--main-color);
  padding: 10px 30px;
  background: #f8f8f8;
  font-weight: bold;
  font-size: 1.5rem;
  transition: 1s all;

  &.selected {
    background: var(--main-color);
    color: #f8f8f8;
  }
}
</style>