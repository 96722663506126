const statusesNeedAttension = () => {
    return [
        'בלי מספר',
        'טעות במספר',
        'מספר לא מחובר',
        'בעיית תקשורת עם המוזמן',
        'לא קיבל הזמנה\\לא הוזמן',
        'לא זמין',
        'לא ליצור קשר',
        'מספר ושם נמחקו על ידי בעל האירוע - לאחר התקשרות',
        'מספר ושם נמחקו על ידי בעל האירוע - ללא חיוב',
        'מספר מנותק זמנית',
        'מספר לא תקין עם יותר ספרות',
        'מספר לא תקין עם חוסר בספרות',
        'מנוי לא זמין או תפוס',
        'מנוי לא מחובר או מנותק',
        'אורח מנתק\מסנן את השיחה',
        'אורח לא מעוניין להשיב לאחר מענה',
        'לא מכיר את בעלי האירוע',
        'לא קיבל\ה הזמנה',
        'בעיית שפה עם האורח - הועבר לטיפול',
        'בעיית תקשורת עם המוזמן',
        'עדיין טעות במספר',
        'טעות במספר - בשיחה',
        'טעות במספר - בהודעה',
        'כפילות במספר או בשם',
    ]
}

const sumGuestStatus = (guest) => {
    try{
        let amount = parseInt(guest.status.coming || 0)
        + parseInt(guest.status.notResponded || 0)
        + parseInt(guest.status.reserve || 0)
        + parseInt(guest.status.notComing || 0)
        + parseInt(guest.status.maybeComing || 0)
        return amount > 0 ? amount : 1
    }catch(e){
        console.error('could not clculate amount for guest, returning amount 1', e, guest)
        return 1
    }
}


const guestsStatusStatistics = (guestsList) => {

    if (guestsList.length <= 0) {
        throw 'guest list is empty'
    }
    let statusStatistics = {
        coming: 0,
        notComing: 0,
        maybeComing: 0,
        notResponded: 0,
        totalGuests: 0
        //arrived:0,
        //notArrived:0,
        //amount:0
    };

    for (let guest of guestsList) {
        if (guest.status && typeof guest.status === 'object') {

            statusStatistics.notResponded += parseInt(guest.status.notResponded || 0);
            statusStatistics.maybeComing += parseInt(guest.status.maybeComing || 0);
            statusStatistics.coming += parseInt(guest.status.coming || 0);
            statusStatistics.notComing += parseInt(guest.status.notComing || 0);

            statusStatistics.totalGuests += statusStatistics.notResponded
                + statusStatistics.maybeComing
                + statusStatistics.coming
                + statusStatistics.notComing

            //statusStatistics.arrived +=          parseInt(guest.status.reserve)||0;
        }
    }
    return statusStatistics;
}

const guestPresetCapacity = (presetData, guests, resevedSeatingAllowed = false ) => {

    let seats=0, taken=0, reserved =0

    if (!guests)
        throw 'please set guests parameter'
    
    if(Array.isArray(presetData)){
    
        presetData.forEach((item) => {

            if (item.type != "table") 
                return;

            if (!item.hasOwnProperty("reserved") || item.reserved !== true  || resevedSeatingAllowed) {
                if (!isNaN(item.tableCapcity))
                    seats += Number(item.tableCapcity);
                
                reserved += parseInt(item.reserveQnt) || 0
                for (let guest of guests) {
                    if (guest.tableId == item.dbID) {
                        taken += Number(sumGuestStatus(guest));
                    }
                }
            }else{
                
            }
        })
    }
    return {taken, seats, reserved}
}

export { statusesNeedAttension, sumGuestStatus, guestsStatusStatistics, guestPresetCapacity }