<template>
    <div id="keyboard"></div>
</template>

<script>
    export default {
        name: "Keyboard",
        beforeMount(){
            this.initKeyboardJS()
        },
        methods: {
            initKeyboardJS() {
                if(typeof $("#keyboard").jkeyboard !=='function') {
                    $.getScript("/static/js/jKeyboard.js", function () {
                        if (!window.keyboardInitiated) {
                            window.keyboardInitiated = true;
                            $('body').on('focus', 'input', function () {
                                $('#keyboard').unbind().removeData();
                                $('#keyboard').jkeyboard({
                                    layout: "hebrew",
                                    input: $('#' + $(this).attr('id'))
                                });
                                $('#keyboard').addClass("open");
                            });
                            $('body').click(function (evt) {
                                if ($(evt.target).hasClass("jkey") || evt.target.id == "keyboard" || evt.target.id == "loginEmail" || evt.target.id == "loginPassword" || evt.target.id == "searchInput")
                                    return;

                                $('#keyboard').removeClass("open");

                            });
                        }
                    })
                }else{

                }
            }
        }
    }
</script>

<style>
    .jkeyboard {
        display: inline-block;
    }
    .jkeyboard, .jkeyboard .jline, .jkeyboard .jline ul {
        display: block;
        margin: 0;
        padding: 0;
    }
    .jkeyboard .jline {
        text-align: center;
        margin-left: -14px;
    }
    .jkeyboard .jline ul li {
        font-family: arial, sans-serif;
        font-size: 42px;
        display: inline-block;
        border: 1px solid #ffffff;
        margin: 20px 0 1px 14px;
        color: #ffffff;
        background-color: rgba(0,0,0,0.41);
        border-radius: 10px;
        width: 50px;
        height: 70px;
        box-sizing: border-box;
        text-align: center;
        line-height: 67px;
        overflow: hidden;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -ms-user-select: none;
        user-select: none;
    }
    .jkeyboard .jline ul li.uppercase {
        text-transform: uppercase;
    }
    .jkeyboard .jline ul li:hover, .jkeyboard .jline ul li:active {
        background-color: rgba(0,0,0,0.8);
    }
    .jkeyboard .jline ul li.symbol_switch{
        width: 100px;
    }
    .jkeyboard .jline .return {
        width: 120px;
    }
    .jkeyboard .jline .space {
        width: 456px;
        border-color: #44898d;
    }
    .jkeyboard .jline .character_switch,
    .jkeyboard .jline .numeric_switch {
        width: 84px;
        font-weight: 700;
        font-size: 25px;
        border-color: #44898d;
    }
    .jkeyboard .jline .layout_switch {
        width: 84px;
        background: url("~/public/static/images/locale.png") no-repeat center center;
        background-size: 43px;
        border-color: #44898d;
        background-color: rgba(0,0,0,0.41);
    }
    .jkeyboard .jline ul li.backspace {
        width: 84px;
        background: url("~/public/static/images/shift.png") no-repeat center center;
        background-size: 45px;
        border-color:#485675;
        background-color: rgba(0,0,0,0.41);
    }
    .jkeyboard .jline .backspace {
        width: 69px;
        background: url("~/public/static/images/backspace.png") no-repeat center center;
    }
    @media only screen and (max-width:769px) and (max-height: 1025px) {
        .jkeyboard .jline ul li {
            width: 55px;
            height: 42px;
            line-height: 31px;
        }
    }
</style>
