import axiosV2, { apiRequest } from '../core/http/axios_api_v2'

const FileStore = {
    namespaced: true,
    state: {

    },
    actions: {
        uploadFiles: ({ commit }, data) =>
            new Promise((resolve, reject) => {

                //data.append('fileName','11111')
                apiRequest('post', 'files/multi', data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });
            })
        ,
        uploadFile: ({ commit }, data) =>
            new Promise((resolve, reject) => {
                apiRequest('post', 'files/single', data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });
            })
        ,
        /**
         * @param {*} ids  , seperated ids
         */
        getFiles: ({ commit }, ids) =>
            new Promise((resolve, reject) => {
                axiosV2.get('files?ids=' + ids)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });
            }),
        getFilesByEvent: ({ commit }, eventId) =>
            new Promise((resolve, reject) => {
                axiosV2.get('files?eventId=' + eventId)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });
            }),
        /**
    * @param {*} ids  , seperated ids
    */
        deleteFile: ({ commit }, id) =>
            new Promise((resolve, reject) => {
                axiosV2.delete('files/' + id)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });
            })
    },
    mutations: {

    },
    getters: {

    }

}
export default FileStore
