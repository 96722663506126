import Vue from 'vue'
import Vuex from 'vuex'
import Config, { isDev, isPlanup, useMultiSchemaTenancy } from './Config'
import socket from './core/http/socket'
import { loadProgressBar } from 'axios-progress-bar'
import MobileDetect from 'mobile-detect'
import PandaCrmModule from './store/panda-crm.store';
import GuestStore from './store/guest.store';
import EventStore from './store/event.store';
import UserStore from './store/user.store';
import TasksStore from './store/tasks.store';

import EventItemStore from './store/event-item.store';
import MeetingStore from './store/meeting.store';
import AuthStore from './store/auth.store';
import FileStore from './store/file.store';
import axiosV2, { apiRequest } from './core/http/axios_api_v2'
import axios from './core/http/axios_api_v1'

loadProgressBar({ showSpinner: false })

Vue.use(Vuex);


// set store sockets.io
Vuex.Store.prototype.$socket = socket

// ---------------------------------------------------------------------------------
const mb = new MobileDetect(window.navigator.userAgent);

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
};

//for old ios devices (ios <= 9.3.*) with session&localStorage bug
const isKiosk = () => {
    try {
        return sessionStorage.getItem('isKiosk') == 'true' ? true : false;
    } catch (e) {
        console.log('failed to access sessionStorage');
        return false;
    }
};

const getLang = () => {
    try {
        return localStorage.getItem('lang');
    } catch (e) {
        console.log('failed to access sessionStorage');
        return 'he';
    }
};



const getMenuState = () => {
    try {

        return localStorage.getItem('menuState')
    } catch (e) {
        console.log('failed to access sessionStorage');
        return 'closed';
    }
};

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    lastInteraction: (Math.floor(Date.now() / 1000)),
    calendarMonth: null,
    access_token: '',
    isLogged: false,
    dashboardInitiated: false,
    userName: '',
    user: {},
    users: [],
    kioskEvent: { guests: [], preset: {} },
    halls: [],
    meetingTypes: {},
    presets: [],
    printAssets: {
        logo: '',
        divider: '',
    },

    isKiosk: (location.href.includes('?kiosk') || isKiosk()),

    equipments: [],
    usedBanners: [],
    tables: [],
    dashboard: null,
    snippetsInitialized: false,

    app: { name: 'SeatUP', banners: [], domain: 'app.seatup.co.il' },
    tenantId: null,
    preferences: {
        lang: (getLang() ? getLang() : 'he'),
        menuState: (getMenuState() == 'closed' ? 'closed' : 'open'),
    },
    pandaNotifications: [],
    axiosQueue: [],
    isTablet: ((mb.phone() !== null && mb.phone() !== "") || (mb.tablet() !== null && mb.tablet() !== "") ? true : false),
    isLoading: false,
    //guestGroups: [],
    //isKiosk:(window.screen.height > window.screen.width),
}





const mutations = {

    setApp(state, payload) {
        state.app = payload
    },

    setTenant(state, payload) {
        if (payload && typeof payload != "undefined" && payload != "undefined") {
            axiosV2.defaults.headers.common['x-tenant-id'] = payload
            localStorage.setItem('tenantId', payload)
            state.tenantId = payload
            console.debug('Tenant set successfully', payload)
        } else {
            localStorage.removeItem('tenantId')
            console.debug('Tenant deleted from storage successfully')
        }
    },

    setAccessToken(state, payload) {

        if (payload == null)
            payload = getCookie('token')


        state.access_token = payload

        axiosV2.defaults.headers.common['Authorization'] = 'bearer ' + payload;
        axios.defaults.headers.common['Authorization'] = 'bearer ' + payload;

        document.cookie = `token=${payload}; path=/;SameSite=strict; Secure;`;
    },
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    restPatch: ({ commit }, config) =>
        new Promise((resolve, reject) => {
            console.log(config);
            axiosV2.patch(config.route, config.data)
                .then(function (response) {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),
    // REST API GENERAL ACTIONS
    restPost: ({ commit }, config) =>
        new Promise((resolve, reject) => {

            axiosV2.post(config.route, config.data)
                .then(function (response) {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                })
                .catch(function (error) {
                    console.warn(error.data);
                    reject(error.data)
                });

        }),
    restGet: ({ commit }, config) =>
        new Promise((resolve, reject) => {

            axiosV2.get(config.route)
                .then(function (response) {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    restDelete: ({ commit, state }, config) =>
        new Promise((resolve, reject) => {
            axiosV2.delete(config.route + "/" + config.deleteId)
                .then((response) => {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        console.log(response.data);
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                }).catch(function (error) {
                    console.error(error);
                    reject(error)
                });

        }),
    // REST API GENERAL ACTIONS

    getUsers: ({ commit, state }, data) =>
        new Promise((resolve, reject) => {

            const params = new URLSearchParams(data);
            axiosV2.get("users", { params })
                .then(function (response) {
                    state.users = response.data;
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    addSnippets: () => {
        try {

            if (!state.snippetsInitialized && state.hasOwnProperty('app') && state.app.hasOwnProperty('extra') && state.app.extra
                !== null && state.app.extra.hasOwnProperty('snippets')) {
                const snippets = JSON.parse(state.app.extra.snippets);
                if (typeof snippets === "string" && snippets !== "") {
                    $('head').append(snippets)
                    state.snippetsInitialized = true;
                }
            }
        } catch (e) {
            console.log(`failed to parse snippets - ${e.message}`);
        }
    },

    getLoggedUserInfo: ({ commit }, o) =>
        new Promise((resolve, reject) => {
            var $this = this;
            axiosV2.get('users/get-logged-in-user-info')
                .then(function (response) {
                    state.user = response.data
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),
    logoff: ({ commit, state }) =>
        new Promise((resolve, reject) => {

            state.isLogged = false;
            state.dashboardInitiated = false

            document.cookie = `token=; path=/;SameSite=strict; Secure;`;
            axiosV2.post('auth/logout', {}).then((response) => {

                if (state.isKiosk)
                    setTimeout(() => { window.location = '/?kiosk=1'; }, 500)
                else
                    setTimeout(() => { window.location = '/'; }, 500)

                resolve()
            });
            axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.login, { method: 'logoff' }, Config.DEFAULT_API_SETTINGS)
                .then((response) => {

                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });

        }),


    makeLogin: ({ commit, state }, o) =>
        new Promise((resolve, reject) => {
            var $state = state
            // o.method = 'login';
            let url, headers = {}
            if (useMultiSchemaTenancy()) {
                url = 'tenancy/auth/login'
                headers = { 'x-tenant-id': 'default' }
            } else {
                url = 'auth/login'
            }

            axiosV2.post(url, {
                username: o.email,
                password: o.password
            }, { headers }).then(res => {

                if (res.data.access_token) {
                    const token = res.data.access_token.toString()
                    const user = res.data.user
                    commit('setAccessToken', token)

                    $state.user = user;
                    $state.isLogged = true

                    // get tenant id from response
                    
                    commit('setTenant', user.tenant)
                    

                    resolve(user);
                } else {
                    commit('setTenant', null)
                    $state.isLogged = false
                    reject('APIV2: invalid login')
                }

            })
                .catch((e) => {
                    state.isLogged = false
                    console.warn('APIV2: login failed - ' + e.toString());
                    reject(e)
                })

        }),

    checkPassword: ({ commit }, password) =>
        new Promise((resolve, reject) => {
            axiosV2.post('auth/check-password', { password })
                .then((response) => {
                    try {
                        if (!!response.data.confirmed == true) {
                            resolve();
                        } else {
                            reject()
                        }
                    } catch (e) {
                        reject()
                        console.warn('failed to process password check - ' + e.toString())
                    }
                })
                .catch(function (e) {
                    reject()
                    console.warn('password check failed - ' + e.toString());
                });

        }),

    getDashboardData: ({ dispatch, state, commit }) =>
        new Promise((resolve, reject) => {

            axiosV2.get('dashboard-data')
                .then((response) => {
                    let res = response.data

                    try {

                        state.isLogged = true;
                        state.dashboardInitiated = true;
                        state.dashboard = res.app || {};

                        if (res.user) {
                            state.user = res.user;
                            state.userName = state.user.name;
                        }
                        state.users = (res.users ? res.users : state.users);

                        state.halls = (res.halls ? res.halls : []);
                        state.meetingTypes = (res.meetingTypes ? res.meetingTypes : {});
                        // state.presets = (res.presets ? res.presets : []);

                        // inbox
                        state.TasksStore.inbox = res.inbox
                        // event types
                        state.EventStore.eventTypes = res.eventTypes ? res.eventTypes : []

                        if (state.user.type == 3 && Array.isArray(res.events)) {

                            commit('setClosestEvents', res.events)
                            commit('setEvent', res.events.at(-1))

                        }

                        if (typeof res.app !== 'undefined') {
                            state.app = res.app
                            state.app.banners = []
                            document.title = state.app.name;

                            if (!state.snippetsInitialized) store.dispatch('addSnippets')

                        } else {
                            state.app = {
                                name: 'SeatUP'
                            }
                        }
                        resolve();
                    } catch (e) {
                        //login
                        state.isLogged = false;
                        state.dashboardInitiated = false;
                        reject(e);
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    state.dashboardInitiated = false;
                    reject()
                });

        }),


    checkLogin: ({ commit, state }) =>
        new Promise((resolve, reject) => {
            commit('setAccessToken', null)

            if (!state.access_token || typeof state.access_token == 'undefined')
                return reject()

            axiosV2.get('/auth/is-logged')
                .then(function (response) {

                    if (response?.data?.logged == true) {
                        state.isLogged = true;
                        state.user = response.data.user
                       
                        commit('setTenant', response.data.user.tenant)
                        
                        resolve(response.data)
                    } else {
                        state.isLogged = false;
                        commit('setTenant', null)
                        reject('not logged in')
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    getBanners: () =>
        new Promise((resolve, reject) => {

            axios.get(Config.SETTINGS.API_URL + Config.API_ROUTES.banners, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        state.app.banners = (response.data.data ? response.data.data : []);
                        resolve()
                    } else {
                        state.app.banners = [];
                        reject()
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    getPresets: ({ state }) =>
        new Promise((resolve, reject) => {
            axiosV2.get('event-preset')
                .then((response) => {
                    state.presets = (response.data ? response.data : []);
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });

        }),

    getHalls: () =>
        new Promise((resolve, reject) => {
            var $this = this;
            axiosV2.get('event-hall')
                .then((response) => {

                    state.halls = (response.data ? response.data : []);
                    resolve(state.halls)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    getTables: () =>
        new Promise((resolve, reject) => {
            axiosV2.get('event-table')
                .then(function (response) {
                    state.tables = response.data;
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    getEquipments: () =>
        new Promise((resolve, reject) => {
            var $this = this;
            axiosV2.get('event-equipment')
                .then(function (response) {
                    state.equipments = response.data;
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),




    getBanner: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            var $this = this;

            axios.get(Config.SETTINGS.API_URL + Config.API_ROUTES.banners + '/' + id, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        resolve(response.data.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    getPreset: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            axiosV2.get('event-preset/' + id)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    getEquipment: ({ commit }, id) => apiRequest('get', 'event-equipment/' + id),

    getHall: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            for (var hall of state.halls) {
                if (hall.id == id) resolve(hall);
            }
            return apiRequest('get', 'event-hall/' + id)
        }),

    getTable: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            for (var table of state.tables) {
                if (table.id == id) {
                    resolve(table);
                    return;
                }
            }
            return apiRequest('get', '/event-table/' + id).then(r => {
                resolve(r);
            }).catch(e => {
                reject('table not found')
            })
        }),



    addBanner: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axios.put(Config.SETTINGS.API_URL + Config.API_ROUTES.banners, data, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        store.dispatch('getBanners');
                        resolve(response.data.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    addPreset: ({ commit }, data) => apiRequest('post', 'event-preset', data).then(_res => {
        store.dispatch('getPresets');
        store.dispatch('getHalls');
    }),

    addTable: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.post('/event-table', data)
                .then(function (response) {
                    store.dispatch('getTables');
                    resolve(response.data.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    addEquipment: ({ commit }, data) =>
        new Promise((resolve, reject) => {
            var $this = this;

            axiosV2.post('event-equipment', data)
                .then(function (response) {
                    store.dispatch('getEquipments');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });

        }),

    addHall: ({ commit }, data) =>
        new Promise((resolve, reject) => {
            axiosV2.post('event-hall', data)
                .then((response) => {

                    store.dispatch('getHalls');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });
        }),

    updateBanner: ({ commit }, data) =>
        new Promise((resolve, reject) => {
            var $this = this;

            axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.banners + '/' + data.id, data, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        store.dispatch('getBanners');
                        resolve(response.data.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    updatePreset: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('event-preset/' + data.id, data)
                .then(function (response) {

                    store.dispatch('getPresets');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),




    updateHall: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('event-hall/' + data.id, data)
                .then((response) => {

                    store.dispatch('getHalls');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    updateTable: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('/event-table/' + data.id, data)
                .then(function (response) {

                    store.dispatch('getTables');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    updateEquipment: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('event-equipment/' + data.id, data)
                .then(function (response) {
                    store.dispatch('getEquipments');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    updateAppSettings: ({ commit }, data) => apiRequest('post', 'settings', data),

    checkResetToken: ({ commit }, token) =>
        new Promise((resolve, reject) => {

            var o = { method: 'checkToken', token: token };
            axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.login, o, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    try {
                        if (response.data.status) {
                            resolve(true);
                        } else {
                            resolve()
                        }
                    } catch (e) {
                        reject()
                        console.warn('failed to process token check - ' + e.toString())
                    }
                })
                .catch(function (e) {
                    reject()
                    console.warn('token check failed - ' + e.toString());
                });

        }),


    addUser: ({ commit }, o) => apiRequest('post', 'users', o),
    deleteUser: ({ commit }, id) => apiRequest('delete', 'users/' + id),


    deleteBanner: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            var $this = this;
            axios.delete(Config.SETTINGS.API_URL + Config.API_ROUTES.banners + '/' + id, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        store.dispatch('getBanners')
                        resolve(response.data);
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    deletePreset: ({ commit }, id) => apiRequest('delete', '/event-preset/' + id).then(_r => {
        store.dispatch('getPresets')
    }),

    deleteHall: ({ commit }, id) => apiRequest('delete', 'event-hall/' + id).then(_r => {
        store.dispatch('getHalls')
    }),

    deleteTable: ({ commit }, id) => apiRequest('delete', 'event-table/' + id).then(_r => {
        store.dispatch('getTables')
    }),

    deleteEquipment: ({ commit }, id) => apiRequest('delete', 'event-equipment/' + id).then(_r => {
        store.dispatch('getEquipments')
    }),

    saveLang: ({ commit }, lang) => {
        try {
            localStorage.setItem('lang', lang);
        } catch (e) {
            console.error('failed to access localStorage')
        }
        state.preferences.lang = lang;
    },

    saveMenuState: ({ commit }, _) => {
        try {
            localStorage.setItem('menuState', _);
        } catch (e) {
            console.log('failed to access localStorage')
        }
        state.preferences.menuState = _;
    },
    showLoading({ state }) {
        state.isLoading = true
    },
    hideLoading({ state }) {
        state.isLoading = false
    }
}

// getters are functions
const getters = {

    isLoading() {
        return state.isLoading;
    },
    isLogged() {
        return state.isLogged;
    },
    getUser() {
        return state.user;
    },
    dashboardInitiated() {
        return state.dashboardInitiated;
    },
    getPresets() {
        return state.presets;
    },
    getConfig() {
        return Config;
    },
    appLogo(state) {
        if (isPlanup()) {
            return `${Config.SETTINGS.APP_URL}static/images/planup-logo.png`
        } else {
            return state.app.logo
                ? Config.SETTINGS.MEDIA_URL + 'logos/' + state.app.logo
                : `${Config.SETTINGS.APP_URL}static/images/logo.png`
        }
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
const store = new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    modules: {
        PandaCrmModule,
        GuestStore,
        EventStore,
        UserStore,
        TasksStore,

        EventItemStore,
        MeetingStore,
        AuthStore,
        FileStore
    }
});

export default store;
