/** 
EVENTS:
- tableSelected: return table properties
***************************************/
<template>
  <div id="dragablleItems" :class="domID" style="height:400px;overflow-y: scroll">
    <span class="item matchHeight">
      <div
        class="tableWrap squareTable"
        data-capacity="12"
        data-type="table"
        data-shape="square"
      >
        <div class="square"></div>
      </div>
      <div class="itemLabel">{{ $t("square") }}</div>
    </span>
    <span class="item matchHeight">
      <div
        class="tableWrap circleTable"
        data-capacity="12"
        data-type="table"
        data-shape="circle"
      >
        <div class="circle"></div>
      </div>
      <div class="itemLabel">{{ $t("circle") }}</div>
    </span>
    <span class="item matchHeight">
      <div
        class="tableWrap rectangleTable"
        data-tablehead="2"
        data-capacity="12"
        data-type="table"
        data-shape="rectangle"
      >
        <div class="rectangle"></div>
      </div>
      <div class="itemLabel">{{ $t("rectangle") }}</div>
    </span>
    <span class="item matchHeight">
      <div
        class="tableWrap rectangleTable ellipseTable"
        data-tablehead="1"
        data-capacity="12"
        data-type="table"
        data-shape="ellipse"
      >
        <div class="rectangle"></div>
      </div>
      <div class="itemLabel">{{ $t("ellipse") }}</div>
    </span>
   
    <span class="item">
      <div
        class="tableWrap squareTable wood"
        data-capacity="12"
        data-type="table"
        data-shape="square"
        
      >
        <div class="square"></div>
      </div>
      <div class="itemLabel">{{ $t("wood") }}</div>
    </span>

    <span class="item" v-for="table of tableList" >
      
      <div
        class="tableWrap"
        data-type="table"
        :data-side1="table.side1 ? table.side1 : ''"
        :data-side2="table.side2 ? table.side2 : ''"
        :data-side3="table.side3 ? table.side3 : ''"
        :data-side4="table.side4 ? table.side4 : ''"
        
        :class="
          table.shape != 'drop'
            ? table.shape == 'wood'
              ? 'squareTable ' + table.shape
              : table.shape == 'ellipseTable'
              ? 'rectangleTable ' + table.shape
              : table.shape
            : ''
        "
        :data-wood="(table.attrs && table.attrs.isWood) ? 'true' : 'false'"
        :data-capacity="table.capacity"
        :data-shape="ShapeClass(table.shape)"
        :data-tablehead="
          table.shape == 'rectangleTable'
            ? Math.round(table.capacity / 4 / 2): (table.shape == 'ellipseTable' ? 1 : '')
        "
      >
        <div
          v-if="table.shape != 'drop'"
          v-bind:class="table.shape != 'ellipseTable' ? ShapeClass(table.shape) : 'rectangle'"
        ></div>
        <img v-if="table.shape == 'drop'" src="/static/images/drop.png" />
      </div>
      <div class="itemLabel">{{ table.title }}</div>
    </span>
  </div>
</template>

<script>

 import Seating from '../../classes/preset-builder/Seating' // /Utils/Seating.js';
 //import {drawRectengularTable, drawSquareTable, drawCircleTable} from '../../clasess/preset-builder/PresetBuilder';
export default {
  name: "EditPresetTable",
  props: ["selectedTable", "minCapacity","highlight"],
  data() {
    return {
        domID:null
    };
  },
  

  beforeMount(){
      this.domID = 'vt_'+ Math.floor(Math.random()*100000);
      this.$store.dispatch('getTables')
  },
  
  mounted(){
      const selectorId = this.domID;
      const seating = new Seating(`.${selectorId}`, [])
      
      setTimeout(() =>  seating.drawSeats() ,500)
    
      document.querySelectorAll('#dragablleItems .tableWrap').forEach(el=>{
        el.addEventListener('click', (ev) =>{
            const table = ev.currentTarget;

            // unselect all
            [].forEach.call(document.querySelectorAll('#dragablleItems .item'), function (el) {
               el.classList.remove('selected');
            });
            // select
            table.parentElement.classList.add('selected');

            let data = {
                shape:          table.dataset.shape,
                type:           table.dataset.type,

                // remove scale because we want to keep selected/edited table scale
                //  scale:          parseFloat(table.dataset.scale) | 1,
                tableCapcity:   parseInt(table.dataset.capacity),
                tablehead:      parseInt(table.dataset.tablehead),
                side1:          parseInt(table.dataset.side1),
                side2:          parseInt(table.dataset.side2),
                side3:          parseInt(table.dataset.side3),
                side4:          parseInt(table.dataset.side4),
                isWood:         (table.dataset.wood),
                
            };
            //console.log(data.position)
            this.$emit('tableSelected', data);
        });
      });
  },
computed: {
    tableList: function () {
        return this.$store.state.tables;
    },
},
  methods: {
   
    ShapeClass(shape){
        return Seating.ShapeClass(shape);
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item{
  cursor: pointer;
}
.item.selected{
  border:4px solid lightblue;
  box-shadow: none;
  outline: none;
}
.circle {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
		}
</style>
