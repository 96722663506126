<template>
  <div>
    <div class="d-grid three-cols gap-sm">




      <div class=" s-box p-4 ">
        <h3><u>מעקב אירוע</u></h3>
        <div class="d-flex">
          <small class="flex-fill"> מעקב עבור האורחים</small>

        </div>
        <br>
        <ul id="sortlist" v-if="userSettings.attrs.eventSteps">

          <li class="d-flex" v-for="(value, key, index) of EventSteps">
            <label><input type="checkbox"  v-model="userSettings.attrs.eventSteps[key]" class="mr-2 ml-4"  /> {{ value }}</label>
          </li>

        </ul>
        <button class="s_btn big" @click="generateEventSteps()" v-else>צור מעקב אירוע</button>
      </div>


      <div class=" s-box p-4 ">
        <h3><u>זמני אירוע ברירת מחדל</u></h3>
        <div class="d-flex">
          <small class="flex-fill"> לוחות זמנים</small>
          <button type="button" @click="addTime" class="">+ {{ $t('add') }}</button>
        </div><br>
        <div class="d-flex" v-for="t of userSettings.defaultEventTimes">
          <button type="button" @click="userSettings.defaultEventTimes.splice(userSettings.defaultEventTimes.map(item => item.name).indexOf(t.name), 1)" class="">x</button>
          <input type="text" v-model="t.name" class="s-badge flex-fill" placeholder="שם אירוע זמן">
          <input type="time" v-model="t.time" required>

        </div>
      </div>

      <div class=" s-box p-4 ">
        <h3><u>מלצרים</u></h3>
        <FormInput type="number" label="יחס מלצרים לשולחן" v-model="userSettings.attrs.waitersPerTable"></FormInput>
      </div>

      <form @submit.prevent="addExtra" class=" s-box p-4 ">
        <h3><u>תוספות לאירוע</u></h3>
        <div class="d-flex">
          <small class="flex-fill"> מחירון</small>
          <button>+ {{ $t('add') }}</button>
        </div>
        <div class="d-flex items-center" v-for="t of userSettings.attrs.defaultExtras">
          <button type="button" @click="userSettings.attrs.defaultExtras.splice(userSettings.attrs.defaultExtras.map(item => item.name).indexOf(t.name), 1)" class="">x</button>
          <input type="text" v-model="t.name" class="s-badge flex-fill" placeholder="שם תוספת" required> :
          
          <input type="number" v-model="t.price" class="s-badge " placeholder="₪" required>
          <FormSelect style="width:90px;" class="flex-fill" :options="[{content: 'גלובלי' , value: 1}, {content: 'פר אורח' , value: 0}]" v-model="t.isGlobal"></FormSelect>
       
        </div>
      </form>


      <div class=" s-box p-4">
        <h3><u>אנשי קשר ברירת מחדל</u></h3>
        <small>הפרד בפסיק (,) אנשי קשר</small>
        <hr>
        <div class="inputWrap textareaWrap">
          <textarea v-model="userSettings.defaultEventContacts" :placeholder="$t('notes')" class="s-textarea"></textarea>
        </div>
      </div>

    </div>
    <hr>

    <div class="inputWrap submitWrap">
      <button class="button" @click.prevent="submitForm">{{ $t("save") }}</button>
    </div>

  </div>
</template>

<script>
import FormInput from '../../UI/FormInput.vue';
import { EventSteps } from '../../../helpers/constants';
export default {
  name: "EventSettingsTab",
  components: { FormInput },
  data() {
    return {
      EventSteps
    };
  },
  computed: {
    userSettings() {
      return this.$store.getters.userSettings
    }
  },
  mounted() {
    if(!this.userSettings){
      this.$store.dispatch('getUserSettings').catch(e=>console.error(e))
    }

  },
  methods: {
    generateEventSteps(){
      this.$store.getters.userSettings.attrs.eventSteps = {}
      this.$forceUpdate()
    },
    addTime() {
      if (typeof this.userSettings.defaultEventTimes == 'undefined' || !this.userSettings.defaultEventTimes)
        this.userSettings.defaultEventTimes = []
      this.userSettings.defaultEventTimes.push({});
    },
    addExtra() {
      if (typeof this.userSettings.attrs.defaultExtras == 'undefined' || !this.userSettings.attrs.defaultExtras || !Array.isArray(this.userSettings.attrs.defaultExtras))
        this.userSettings.attrs.defaultExtras = []
      this.userSettings.attrs.defaultExtras.push({});
      this.$forceUpdate()
    },
    submitForm() {
      this.$store
        .dispatch("saveUserSettings", this.userSettings)
        .then((response) => {
          this.AlertSuccess();
          // this.userSettings = response
        })
        .catch((err) => {
          this.AlertError();
        });
    },
  },
};
</script>
<style scoped>
h3 {
  margin: 0 0 10px 0;
}

.col-sm-6 {
  margin: 5px;
}
</style>