<template>
    <span class="kioskMyPlace kioskTablesPreset" @click="closeOpenTable($event)">
        <div class="topFlo" v-if="!$store.state.kioskEvent.isConference"></div>
        <h1>{{ $store.state.kioskEvent.name }}</h1>

        <div class="mainImgWrap ulamLayoutWrapper">
                <div id="ulamLayout" v-bind:style="'background-image:url(\''+ FileStoragePath(presetBackground) +'\')'">

                    <div class="grid" id="gridWrap"></div>
                </div>
            </div>

		<KioskFooter active="tables"></KioskFooter>
        <div class="bottomMenuWrap">
            <KioskLanguageSelector></KioskLanguageSelector>
            <AdminMenu v-on:openMenu="adminMenuOpened = true"></AdminMenu>
            <button class="adminEnt" @click.prevent="showArrivalStatus()">
                <Clock  />
            </button>
            <KioskArrivalStatus v-if="arrivalStatusOpened" v-on:close="arrivalStatusOpened = false"></KioskArrivalStatus>
        </div>
        <EventSelectModal @setEvent="setEvent" v-if="!$store.state.kioskEvent.id"></EventSelectModal>
        <AdminMenu :modal="true" v-if="adminMenuOpened" v-on:closeMenu="closeMenu"></AdminMenu>
        <Keyboard></Keyboard>

        <div class="tableGuestList" v-bind:class="tableGuestListOpen ? 'open' : ''">
            <div class="tableGuestListH">
                <div>
                    <button class="closeBtn" @click="tableGuestListOpen = false">
                        <svg><use xlink:href="#i_close"></use></svg>
                    </button>
                    <h3> {{ $t('tableNumber') }} <span id="tableNumber">{{ table.tableNum }}</span> </h3>
                </div>
                <div v-if="table.name">
                   {{ table.name }}
                </div>
                <div>
			<span class="tableCapacity">
                        <span>{{ $t('total') }}:</span>
                        <span id="tableCapacity">{{ table.tableCapcity }}</span>
                    </span>
                    <span class="tableAvailability">
                        <span>{{ $t('available') }}:</span>
                        <span id="availablePlaces">{{ table.tableCapcity-getSeatsStatusForTable(table.dbID).amount }}</span>
                    </span>
                    <span class="tableAvailability">
                        <span>{{ $t('seated2') }}:</span>
                        <span id="seated2">{{ getSeatsStatusForTable(table.dbID).amount }}</span>
                    </span>

                    <span class="tableAvailability">
                        <span>{{ $t('arrived') }}:</span>
                        <span id="arrived">{{ getSeatsStatusForTable(table.dbID).arrived }}</span>
                    </span>
                </div>
            </div>
            <div class="scrollerWrap">
                <ul id="addTable">
                    <li v-for='guest of table.guests' v-bind:data-guest-id="guest.id" class="guestItem">
                        <span data-status="yes" class="guestIcon">
                            <svg class="i_arraiving"><use xlink:href="#i_arraiving"></use></svg>
                        </span>
                        <span class="guestInfo">
                            <div class="guestName">{{ guest.fullName }}</div>
                            <div class="guestGroup">{{ guest.group }}</div>
                            <div class="guestTable">
                                <svg class="i_table"><use xlink:href="#i_filter"></use></svg>
                                <span class="guestAmount"><svg class="i_user"><use xlink:href="#i_user"></use></svg> <span>{{ Object.values(guest.status).filter(a => !isNaN(a)).reduce((total,a)=>{return total+a;},0) }}</span></span>
                            </div></span>
                        </li>
                </ul>
            </div>
        </div>

    </span>
</template>

<script>
    import KioskFooter from '../Kiosk/KioskFooter'
    import KioskLanguageSelector from '../Kiosk/KioskLanguageSelector'
    import EventSelectModal from '../Modals/EventSelectModal'
    import AdminMenu from '../Modals/AdminMenu'
    import Clock from 'vue-digital-clock'
    import Keyboard from './Keyboard'
    import KioskArrivalStatus from './KioskArrivalStatus'
    export default {
        name: "KioskTablesPreset",
        components:{
            KioskLanguageSelector,
            EventSelectModal,
            AdminMenu,
            Clock,
            KioskFooter,
            Keyboard,
            KioskArrivalStatus
        },
        computed: {
            presetBackground(){
                try{
                    return this.$store.state.EventStore.event.preset.background
                }catch{
                    return '';
                }
                return '';
            }
        },
        data(){
            return {
                tableGuestListOpen:false,
                adminMenuOpened:false,
                arrivalStatusOpened:false,
                search:'',
                table:{guests:[]}
            }
        },
        destroyed(){
            try{
                $(".scrollerWrap").getNiceScroll().remove();
            }
            catch(e){
                console.warn('failed to destroy nicescroll')
            }
        },
        mounted(){
            let _this = this;
            if(!this.$store.state.kioskEvent.id) {
                this.$router.push('/?kiosk=1')
            }else{
                this.processKiosk();
            }

            $("#searchInput").off('change').on('change',function(){
                setTimeout(function(){
                    _this.search = $("#searchInput").val()
                },50);
            });
        },
        methods:{
            closeMenu(){
                this.adminMenuOpened = false;
                this.clicksCounter = 0;
            },
            showArrivalStatus(){
                this.arrivalStatusOpened = !this.arrivalStatusOpened;
                const _this = this;
                setTimeout(function(){ _this.arrivalStatusOpened = false },5000);
            },
            setEvent(eventID){
                this.$store.state.kioskEvent = this.$store.state.EventStore.closestEvents.filter(event => {
                    if(event.id == eventID) return true;
                    return false;
                }).pop();
                if(this.$store.state.kioskEvent.id){
                    this.processKiosk();
                }else{
                    alert('logging off')
                    this.$store.dispatch('logoff')
                }
            },
            getGuests(){
                return (!this.$store.state.kioskEvent.guests ? [] : this.$store.state.kioskEvent.guests.filter(guest=>{
                    if(this.search != '' && !guest.fullName.toLowerCase().includes(this.search)) return false;
                   return true;
                }));
            },
            destroyed(){
                try{
                    $(".scrollerWrap").getNiceScroll().remove();
                }
                catch(e){
                    console.warn('failed to destroy nicescroll')
                }
            },
            processKiosk(){
                this.setLayout({elements:this.$store.state.kioskEvent.presetData})
                let outer = $('.ulamLayoutWrapper');
                let inner = $('.ulamLayoutWrapper > #ulamLayout');
                outer.scrollLeft( (inner.width() - outer.width()) / 2);

                let _this = this;

                $('span.item').off('click');
                $('span.item').on('click',function(event){

                    //destroy prev nicescroll
                    try{
                        $(".scrollerWrap").getNiceScroll().remove();
                    }
                    catch(e){
                        // i dont care!
                    }

                    //set table data to vueJS
                    let tableId = $(this).find('[data-dbid]').data('dbid');
                    _this.table = _this.$store.state.kioskEvent.presetData.filter(table=>{
                        return tableId == table.dbID
                    }).pop();
                    //set guests
                    _this.table.guests = _this.$store.state.kioskEvent.guests.filter(guest=>{
                        return guest.tableId == tableId
                    })

                    _this.tableGuestListOpen = true

                    if(typeof $(".scrollerWrap").niceScroll !=='function') {
                        $.getScript("/static/js/niceScroll.min.js", function () {
                            $(".scrollerWrap").niceScroll({smoothscroll:true,hwacceleration:true,emulatetouch:true,grabcursorenabled:true,  cursordragontouch: true,cursordragontouch:true})
                            setTimeout(function(){
                                $('.tableGuestList').fadeIn();
                            },100)
                        });
                    }else{
                        $(".scrollerWrap").niceScroll({smoothscroll:true,hwacceleration:true,emulatetouch:true,grabcursorenabled:true,  cursordragontouch: true,cursordragontouch:true})
                        setTimeout(function(){
                            $('.tableGuestList').fadeIn();
                        },100)
                    }

                });

            },

            setLayout(layout){
                const _this = this;
                if(layout.elements.length > 0){
                    for(let item of layout.elements){
                        item.occupiedPlaces = 0;
                        if(this.$store.state.kioskEvent.guests.length > 0){
                            if(item.type == 'table') {
                                for (let guest of this.$store.state.kioskEvent.guests) {
                                    if (guest.tableId == item.dbID && typeof guest.status === 'object') {
                                        item.occupiedPlaces += guest.status.coming;
                                    }
                                }
                            }
                        }
                    }
                }

                $('#gridWrap').html('');
                let message = typeof layout === 'object' ? layout : JSON.parse(layout);
                $.each(message.elements, function (index, element) {
                    let item = "";

                    let elmBackup = {};
                    let isReserved = element.hasOwnProperty("reserved") && element.reserved === true;
                    elmBackup = Object.assign({},element);

                    if(isReserved) element.tableNum = `${_this.$t('reserved')}${element.tableNum}`;

                    const tableNumRotation = (element.rotation ? (element.rotation > 0 ? 0-element.rotation : Math.abs(element.rotation)) : 0);

                    let scale = 'transform:scale('+(element.scale ? element.scale : 1)+')';
                    scale = scale + ' rotate('+(element.rotation ? element.rotation : 0)+'deg);';

                    if (element.type == "table") {
                        let tablehead = 0,
                            tableClass = "",
                            shapeClass = "";
                        switch (element.shape) {
                            case "circle" :
                                tablehead = 0;
                                tableClass = "circleTable";
                                shapeClass = "circle";
                                break;
                            case "square" :
                                tablehead = 0;
                                tableClass = "squareTable";
                                shapeClass = "square";
                                break;
                            case "rectangle" :
                                tablehead = (Math.round((element.tableCapcity/4)/2));
                                tableClass = "rectangleTable";
                                shapeClass = "rectangle";
                                break;
                            case "ellipse" :
                                tablehead = 1;
                                tableClass = "rectangleTable ellipseTable";
                                shapeClass = "rectangle";
                                break;
                        }

                        if(element.shape =='drop'){
                            item = '<span data-rotation="'+(element.rotation ? element.rotation : 0)+'" data-scale="'+(element.scale ? element.scale : 1)+'" class="item" style="'+scale+';top:'+element.position.y+';left:'+element.position.x+';"><div data-shape="'+element.shape+'" data-dbid="'+element.dbID+'" id="item_' + element.id + '" class="tableWrap '+tableClass+'" data-tablehead="'+tablehead+'" data-capacity="'+element.tableCapcity+'" data-occupiedplaces="'+element.occupiedPlaces+'" data-type="table"><div class="'+shapeClass+'"><img src="/static/images/drop.png"><span class="tableNum" '+ (tableNumRotation !== 0 ? 'style="transform:rotate('+tableNumRotation+'deg);display:block"' : '') +'>'+element.tableNum+'</span></span></div></div></span>';
                        }else{
                            item = '<span data-rotation="'+(element.rotation ? element.rotation : 0)+'" data-scale="'+(element.scale ? element.scale : 1)+'" class="item" style="'+scale+';top:'+element.position.y+';left:'+element.position.x+';"><div data-shape="'+element.shape+'" data-dbid="'+element.dbID+'" id="item_' + element.id + '" class="tableWrap '+tableClass+'" data-tablehead="'+tablehead+'" data-capacity="'+element.tableCapcity+'" data-occupiedplaces="'+element.occupiedPlaces+'" data-type="table"><div class="'+shapeClass+'"><span class="tableNum" '+ (tableNumRotation !== 0 ? 'style="transform:rotate('+tableNumRotation+'deg);display:block"' : '') +'>'+element.tableNum+'</span></span></div></div></span>';
                        }


                    } else {
                        item ='<span data-rotation="'+(element.rotation ? element.rotation : 0)+'" data-scale="'+(element.scale ? element.scale : 1)+'" class="item" style="'+scale+';top:'+element.position.y+';left:'+element.position.x+';"><div data-shape="'+element.shape+'" data-dbid="'+element.dbID+'" data-type="'+element.type+'" class="imgWrap"><img style="height:auto" src="/static/images/'+element.type+'.png"></div></span>';

                    }
                    $('#gridWrap').append(item);


                    if(isReserved) element.tableNum = elmBackup.tableNum || "";

                });

                $(".squareTable").each(function() {
                    let capacity  = $(this).data("capacity"),
                        capacityPerSide = capacity/4,
                        size = (Math.round(capacityPerSide)) * 14,
                        text = "";

                    let seats = _this.getSeatsHtml(_this.getSeatsStatusForTable($(this).data('dbid'),false,true), capacity);

                    $(this).find(".square").css({"width":size,"height":size,"line-height":(size-2)+"px"});
                    for (let i = 0; i < 4; i++) {
                        text += "<div class='tableSide side"+i+"'>";
                        for (let j = 0; j < capacityPerSide; j++) {
                            let seat = seats.shift();
                            if(typeof seat !=='undefined') text += seat;
                        }

                        //if left any un splitable seats
                        if(i === 4 && seats.length > 0){
                            text += seats.join("");
                        }
                        text += "</div>";
                    }
                    $(this).find(".square").append(text);
                });

                $(".circleTable").each(function() {
                    let capacity  = $(this).data("capacity"),
                        size = capacity * 3,
                        text = "",
                        rot = 0,
                        angle = 360/capacity,
                        angleV = 85,
                        occupiedPlaces  = $(this).data("occupiedplaces");

                    let seats = _this.getSeatsHtml(_this.getSeatsStatusForTable($(this).data('dbid'),false,true), capacity);

                    $(this).find(".circle").css({"width":size,"height":size,"line-height":(size-2)+"px"});
                    for (let i = 0; i < capacity; i++) {
                        let translateV=capacity*2,
                            rotateV = rot * 1;

                        let seat = seats.shift();
                        if(typeof seat !=='undefined'){
                            seat = $(seat).attr('style',"transform: rotate("+rotateV+"deg) translate("+translateV+"px) rotate("+angleV+"deg)")[0].outerHTML;
                            text += seat;

                            rot= rot + angle;
                            angleV = angleV + 1;
                        }

                    }
                    $(this).find(".circle").append(text);

                });
                $(".rectangleTable").each(function() {

                    let capacity  = $(this).data("capacity"),
                        tablehead  = $(this).data("tablehead"),
                        capacityPerSideHeight = (capacity - (tablehead*2))/2,
                        capacityPerSideWidth = tablehead,
                        width = Math.round(capacityPerSideWidth) * 14,
                        height = Math.round(capacityPerSideHeight) * 14,
                        text = "";

                    let seats = _this.getSeatsHtml(_this.getSeatsStatusForTable($(this).data('dbid'),false,true), capacity);

                    $(this).find(".rectangle").css({"width":width,"height":height,"line-height":(height-2)+"px"});
                    for (let i = 0; i < 4; i++) {
                        text += "<div class='tableSide side"+i+"'>";

                        if(i == 1 || i==3){

                            for (let j = 0; j < capacityPerSideWidth; j++) {
                                let seat = seats.shift();
                                if(typeof seat !=='undefined') text += seat;
                            }
                        }else{
                            for (let j = 0; j < capacityPerSideHeight; j++) {
                                let seat = seats.shift();
                                if(typeof seat !=='undefined') text += seat;
                            }
                        }

                        text += "</div>";
                    }
                    $(this).find(".rectangle").append(text);
                });
            },
            getSeatsStatusForTable(id = '',dontAddArrival = false, dontAddAmount = false){
                let status = {
                    confirmed:0,
                    notComing:0,
                    maybeComing:0,
                    reserve:0,
                    notResponded:0,
                    arrived:0,
                    notArrived:0,
                    amount:0
                };

                if(typeof id ==='undefined' || id == '' || !id) return status;
                if(this.$store.state.kioskEvent.guests.length > 0) {
                    for (let guest of this.$store.state.kioskEvent.guests) {
                        if(guest.tableId == id && typeof guest.status ==='object'){
                            status.confirmed += guest.status.coming;
                            status.maybeComing += guest.status.maybeComing;
                            status.reserve += guest.status.reserve;
                            status.notComing += guest.status.notComing;
                            status.notResponded += guest.status.notResponded;
                            if(!dontAddAmount) status.amount += !isNaN(guest.quantity) ? parseInt(guest.quantity) : 0;

                            if(!dontAddArrival) {
                                if (guest.arrived) {
                                    status.arrived += parseInt(guest.quantity) > 0 ? parseInt(guest.quantity) : 1;
                                } else {
                                    status.notArrived += parseInt(guest.quantity) > 0 ? parseInt(guest.quantity) : 1;
                                }
                            }

                        }
                    }
                }

                return status;

            },
            getSeatClass(status = ''){
                switch(status){
                    case 'arrived':
                    case 'confirmed':
                        return 'confirmed';
                        break;
                    case 'notComing':
                        return 'notComing';
                        break;
                    case 'maybeComing':
                        return 'maybe';
                        break;
                    case 'notArrived':
                    case 'notResponded':
                        return 'notResponded';
                        break;
                    case 'reserve':
                        return 'reserve';
                        break;
                    default:
                        return '';
                        break;
                }
            },
            getSeatsHtml(seatsStatus = {}, capacity = 0){
                let seats = [];
                for(let status in seatsStatus) {
                    if(status !== 'arrived' && status !== 'notArrived') continue;
                    let status_places = seatsStatus[status]
                    if (status_places > 0) {
                        for(let s = 1;s<=status_places;s++){
                            let seat = "<span class='seat " + this.getSeatClass(status) + "'></span>";
                            seats.push( seat );
                        }
                    }
                }
                for(let s = seats.length;s<=capacity-1;s++){
                    let seat = "<span class='seat " + this.getSeatClass('') + "'></span>";
                    seats.push( seat );
                }
                return seats;
            },
            closeOpenTable(e){
                let fromTableOrPopup = false

                e.path.forEach(_=>{
                    if($(_).hasClass('item')) fromTableOrPopup = true;
                    if($(_).hasClass('tableGuestList')) fromTableOrPopup = true;
                })
                if(!fromTableOrPopup) this.tableGuestListOpen = false
            }
        }
    }
</script>

<style scoped>
    .kioskTablesPreset h2{
        color:#7893c1;
        font-size: 1.706rem;
        font-weight: 700;
    }
    .kioskTablesPreset h3{
        color: #293b5a;
        font-weight: 700;
        font-size:1.059rem;
    }

    .kioskTablesPreset .totalSeats .ulamCapacity + span{
        font-style: italic;
        font-size: 0.9412rem;
    }

    #ulamLayout{
        width: 720px;
        height:720px;
        background: url("~/public/static/images/layoutBg.jpg") no-repeat right top;
        background-size: contain;
    }
    .kioskTablesPreset .grid{
        width:100%;
        height: 100%;
    }

    .kioskTablesPreset .item{
        display: inline-block;
        padding: 20px;
        position: relative;
        width:32%;
        text-align: center;
    }
    #list-1 .item{
        padding:10px;
    }
    .kioskTablesPreset .item .imgWrap{
        margin-bottom: 20px;
    }
    .kioskTablesPreset .item .imgWrap img{
        max-width: 100%;
    }
    #gridWrap{
        position: relative;
    }
    #gridWrap .item{
        width:auto;
        position: absolute;
        padding: 0;
    }
    #gridWrap .item .rectangle,
    #gridWrap .item .square:not(.wood),
    #gridWrap .item .circle{
        background: #fff;
    }
    .kioskTablesPreset .tableWrap{
        position: relative;
        padding: 3px 3px;
        display: inline-block;
    }
    #gridWrap .item .tableNum{
        color:#314364;
        font-size: 0.9412rem;
    }
    .kioskTablesPreset .rectangle,
    .kioskTablesPreset .square{
        margin:0 auto;
        border: 2px solid #c1bfbf;
        min-width: 30px;
        min-height: 30px;

    }
    .kioskTablesPreset .ellipseTable .rectangle{
        min-width: 25px;
        border-radius: 50%;
    }
    .kioskTablesPreset .seat{
        width:10px;
        height:8px;
        border: 1px solid #000;
        border-radius: 2px;
        display: block;
        background: #fff;
    }
    .kioskTablesPreset .ulamLayoutWrapper{
        margin-top:30px;
    }
    .kioskTablesPreset .circlePrecWrap{
        width:76px;
        height:76px;
        display: inline-block;
    }
    .kioskTablesPreset .precentageTxt{
        display: inline-block;
        padding-right: 15px;
        text-align: right;
    }
    .kioskTablesPreset .circlePrecWrap .circle-bg {
        fill: none;
        stroke: #eee;
        stroke-width: 1.8;
    }

    .kioskTablesPreset .circlePrecWrap .circle {
        fill: none;

        stroke-width: 1.8;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }
    .kioskTablesPreset .seatedPlaces .circle{
        stroke: #ff0000;
    }
    .kioskTablesPreset .unSeatedPlaces .circle{
        stroke: #293b5a;
    }
    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }
    .kioskTablesPreset .circlePrecWrap .percentage {
        fill: #626262;
        font-style: italic;
        font-size: 0.5rem;
        text-anchor: middle;
    }
    .kioskTablesPreset .precentageWrap{
        padding:15px;
        text-align: center;
    }
    .kioskTablesPreset .precentageIcon{
        position: relative;
        margin-right: 5px;
    }
    .kioskTablesPreset .precentageIcon .mainIcon{
        width: 18px;
        height: 16px;
        border-radius: 5px;
        border: 1px solid #fff;
        display: inline-block;
        z-index: 1;
        position: relative;
    }
    .kioskTablesPreset .precentageIcon span:not(.mainIcon){
        width: 24px;
        height: 14px;
        position: absolute;
        top: -1px;
        right: -3px;
        border-radius: 5px 5px 0px 0px;
    }
    .kioskTablesPreset .seatedPlaces .precentageIcon span:not(.mainIcon),
    .kioskTablesPreset .seatedPlaces .precentageIcon .mainIcon{
        background:#ff0000;
    }
    .kioskTablesPreset .unSeatedPlaces .precentageIcon span:not(.mainIcon),
    .kioskTablesPreset .unSeatedPlaces .precentageIcon .mainIcon{
        background:#293b5a;
    }
    .kioskTablesPreset .placesNum{
        color: #4c4c4c;
        font-weight: bold;
        font-size: 1.412rem;
        line-height: 20px;
    }
    .kioskTablesPreset .seatedStatus{
        color:#9b9b9b;
        font-size: 1.059rem;
    }
    .kioskTablesPreset .rectangleTable .seat,
    .kioskTablesPreset .squareTable .seat{
        display: inline-block;
        position: relative;
    }
    .kioskTablesPreset .rectangleTable .seat:before,
    .kioskTablesPreset .squareTable .seat:before{
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        background: #fff;
        border: 1px solid #000;
        border-radius: 2px;
    }
    .kioskTablesPreset .rectangleTable .side1 .seat,
    .kioskTablesPreset .squareTable .side1 .seat{
        vertical-align: top;
    }
    .kioskTablesPreset .rectangleTable .side3 .seat,
    .kioskTablesPreset .squareTable .side3 .seat{
        vertical-align: top;
    }
    .kioskTablesPreset .rectangleTable .side2 .seat,
    .kioskTablesPreset .rectangleTable .side0 .seat,
    .kioskTablesPreset .squareTable .side2 .seat,
    .kioskTablesPreset .squareTable .side0 .seat{
        width: 8px;
        height: 10px;
        display: block;

    }
    .kioskTablesPreset .rectangleTable .side0 .seat:before,
    .kioskTablesPreset .squareTable .side0 .seat:before{
        left: -2px;
        top: 1px;
    }
    .kioskTablesPreset .rectangleTable .side1 .seat:before,
    .kioskTablesPreset .squareTable .side1 .seat:before{
        left: 1px;
        top: -2px;
    }
    .kioskTablesPreset .rectangleTable .side2 .seat:before,
    .kioskTablesPreset .squareTable .side2 .seat:before{
        top: 1px;
    }
    .kioskTablesPreset .rectangleTable .side3 .seat:before,
    .kioskTablesPreset .squareTable .side3 .seat:before{
        bottom: -2px;
        right: 1px;
    }

    .kioskTablesPreset .rectangleTable .side0 .seat + .seat,
    .kioskTablesPreset .squareTable .side0 .seat + .seat{
        margin-top:3px;
    }
    .kioskTablesPreset .rectangleTable .side1 .seat + .seat,
    .kioskTablesPreset .squareTable .side1 .seat + .seat{
        margin-right:3px;
    }
    .kioskTablesPreset .rectangleTable .side2 .seat + .seat,
    .kioskTablesPreset .squareTable .side2 .seat + .seat{
        margin-top:3px;
    }
    .kioskTablesPreset .rectangleTable .side3 .seat + .seat,
    .kioskTablesPreset .squareTable .side3 .seat + .seat{
        margin-right:3px;
    }

    .kioskTablesPreset .rectangleTable .tableSide,
    .kioskTablesPreset .squareTable .tableSide{
        position: absolute;
        text-align: center;
    }
    .kioskTablesPreset .rectangleTable .side0,
    .kioskTablesPreset .squareTable .side0{
        left:100%;
        top: 50%;
        transform: translate(0,-50%);
    }
    .kioskTablesPreset .rectangleTable .side1,
    .kioskTablesPreset .squareTable .side1{
        top:100%;
        right: 50%;
        transform: translate(50%,0);
        width:calc(100% - 6px);
        height: 8px;
    }
    .kioskTablesPreset .rectangleTable .side2,
    .kioskTablesPreset .squareTable .side2{
        right:100%;
        top: 50%;
        transform: translate(0,-50%) rotate(180deg);
    }
    .kioskTablesPreset .rectangleTable .side3,
    .kioskTablesPreset .squareTable .side3{
        bottom: 100%;
        right: 50%;
        transform: translate(50%,0);
        width:calc(100% - 6px);
        height: 8px;
    }
    .kioskTablesPreset .itemLabel{
        margin-top: 15px;
    }
    .kioskTablesPreset .circleTable .circle{
        margin: 0 auto;
        border: 2px solid #c1bfbf;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
    }
    .kioskTablesPreset .circleTable .circle .seat{
        position: absolute;
        top: 40%;
        left: 40%;

    }
    #gridWrap .seat,
    #gridWrap .seat:before{
        background:#fff!important;
    }
    #gridWrap .seat.notComing:before,
    #gridWrap .seat.notComing{
        background: #ff1010!important;
    }
    #gridWrap .seat.reserve:before,
    #gridWrap .seat.reserve{
        background: #0034ff !important;
    }
    #gridWrap .seat.confirmed:before,
    #gridWrap .seat.confirmed{
        background: #47ce41!important;
    }
    #gridWrap .seat.maybe:before,
    #gridWrap .seat.maybe{
        background: #FAFF04!important;
    }
    #gridWrap .seat.notResponded:before,
    #gridWrap .seat.notResponded{
        background: #A2A2A2!important;
    }
    .kioskTablesPreset .circleTable .seat:before{
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        background: #fff;
        border: 1px solid #000;
        border-radius: 2px;
        position: absolute;
        bottom:-2px;
        right:1px;
    }
    .kioskTablesPreset .ellipseTable .side0 .seat:first-child{    transform: rotate(-21deg) translate(-2px);}
    .kioskTablesPreset .ellipseTable .side0 .seat:last-child{    transform: rotate(21deg) translate(-2px);}
    .kioskTablesPreset .ellipseTable .side2 .seat:first-child{transform: rotate(-21deg) translate(-2px);}
    .kioskTablesPreset .ellipseTable .side2 .seat:last-child{    transform: rotate(21deg) translate(-2px);}
    .kioskTablesPreset .squareTable.wood .square{
        background:#e1e1e1;
    }
    #gridWrap .itemLabel{
        display: none;
    }
    .kioskTablesPreset .guestFilter{
        margin-bottom:23px;
        padding:15px;

    }
    .kioskTablesPreset .guestFilter input,
    .kioskTablesPreset .guestFilter select{
        background: #fff;
        color: #555555;
        height:43px;
        line-height: 43px;
        font-size: 0.8235rem;
    }
    .kioskTablesPreset .guestFilter .inputWrap.searchWrapper input {
        padding-left: 40px;
    }
    .kioskTablesPreset .guestFilter .inputWrap.searchWrapper .searchEvent {
        position: absolute;
        top: calc(50% - 10px);
        left: 20px;
    }
    .kioskTablesPreset .guestFilter .inputWrap.searchWrapper .searchEvent svg {
        fill: #293b5a;
        width: 18px;
        height: 20px;
        display: block;
    }
    .kioskTablesPreset .guestFilter .inputWrap.searchWrapper input[type="search"]::-webkit-input-placeholder{color:#adadad;font-style: italic;font-size: 0.8824rem;}
    .kioskTablesPreset .guestFilter .inputWrap.searchWrapper  input[type="search"]:-moz-placeholder{color:#adadad;font-style: italic;font-size: 0.8824rem;}
    .kioskTablesPreset .statusBar{
        margin-top:15px;
        width: 100%;
        height:43px;
        line-height:40px;
        border-radius: 27px;
        border: 1px solid #bfc1c5;
    }
    .kioskTablesPreset .statusBtn{
        float: right;
        width:20%;
        text-align: center;
        padding: 0 5px;
    }
    #all span{
        color:#293b5a;
        font-weight: 700;
    }
    #all{
        border-radius: 27px 0 0 27px;
    }
    .kioskTablesPreset .statusBtn svg{
        width:24px;
        height:24px;
        display: inline-block;
        vertical-align: middle;
        margin-left:5px;
    }
    .kioskTablesPreset .statusBtn span{
        color:#555555;
        font-size: 0.8235rem;
        display: inline-block;
        vertical-align: middle;
    }
    svg.i_arraiving{
        fill:#41b53e;
    }
    svg.i_notArraiving{
        fill:#bb1b1b;
    }
    svg.i_maybeArraiving{
        fill:#e49e9e;
    }
    svg.i_didentAnswer{
        fill:#656565;
    }
    .kioskTablesPreset .guestFilter .col-lg-6{margin-top:15px;}
    .kioskTablesPreset .guestFilter .inputWrap>label{
        color:#293b5a;
        font-size: 0.8235rem;
        margin-bottom: 7px;
        font-weight: 700;
    }
    .kioskTablesPreset .guestItem .guestIcon,
    .kioskTablesPreset .guestItem .guestInfo{
        display: inline-block;
        vertical-align: top;
    }
    .kioskTablesPreset .guestItem .guestIcon svg{
        width:19px;
        height:19px;
        vertical-align: middle;
        margin-left: 5px;
    }
    .kioskTablesPreset .guestName{
        color:#293b5a;
        font-weight: 700;
        font-size: 1.059rem;
    }
    .kioskTablesPreset .guestGroup{
        color:#7994c2;
        font-size: 0.7647rem;
    }
    .kioskTablesPreset .guestTable{
        margin-top: 5px;
    }
    .kioskTablesPreset .guestTable .i_table{
        width:16px;
        height:15px;
        fill:#293b5a;
        vertical-align: middle;
        display: inline-block;
    }
    .kioskTablesPreset .guestTable .tableNum:after{
        content: "|";
        margin-right: 5px;
    }
    .kioskTablesPreset .guestTable .tableNum,
    .kioskTablesPreset .guestTable .tableName{
        vertical-align: middle;
        display: inline-block;
        color:#6f6f6f;
        font-size: 0.7647rem;
    }
    .kioskTablesPreset .guestAmount{
        vertical-align: middle;
        display: inline-block;
        margin-right:20px;
    }
    .kioskTablesPreset .guestAmount .i_user{
        width:13px;
        height:15px;
        fill:#293b5a;
        vertical-align: middle;
        display: inline-block;
    }
    .kioskTablesPreset .guestAmount span{
        vertical-align: middle;
        display: inline-block;
        color:#6f6f6f;
        font-size: 0.7647rem;
    }
    .kioskTablesPreset .guestEdit{
        float: left;
    }
    .kioskTablesPreset .guestEdit svg.editGuestBtn{
        width:16px;
        height:16px;
        fill:#293b5a;
    }
    .kioskTablesPreset .guestEdit svg.deleteGuestBtn{
        width:11px;
        height:11px;
        fill:#7792bf;
    }
    .kioskTablesPreset .guestList{
        max-height: 500px;
        height: auto;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .kioskTablesPreset .guestList.musk .guestItem:before{
        content: "";
        width: 100%;
        height:100%;
        position: absolute;
        top:0;
        right:0;
        background: #50668c;
    }
    .kioskTablesPreset .guestItem{
        border-bottom: 1px solid #293b5a;
        padding:10px;
        background: #f2f2f2;
        z-index: 0;
    }
    .kioskTablesPreset .guestList li:nth-child(odd){
        background: #e7e7e7;
    }
    #dragablleItems .guestItem{
        cursor: move;
    }
    .kioskTablesPreset .gu-mirror,
    #dragablleItems .guestItem:hover{
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
        z-index: 1;
        position: relative;
    }
    .kioskTablesPreset .gu-mirror{
        opacity: 1;
        list-style: none;
    }
    .kioskTablesPreset .gu-mirror .guestEdit svg.deleteGuestBtn,
    .kioskTablesPreset .guestList .guestEdit svg.deleteGuestBtn{
        display: none;
    }
    #addTable .guestEdit svg.editGuestBtn{
        display: none;
    }
    .kioskTablesPreset .scrollerWrap{
        height: 150px;
    }
    .kioskTablesPreset .mCS-dir-rtl>.mCSB_inside>.mCSB_scrollTools, .mCS-dir-rtl>.mCSB_outside+.mCSB_scrollTools{
        right:0;
        left:auto;
    }
    .kioskTablesPreset .mCS-dir-rtl:not(.mCS_no_scrollbar)>.mCSB_inside>.mCSB_container{
        margin-right: 20px;
        margin-left: 0px;
    }
    .kioskTablesPreset .mCSB_scrollTools .mCSB_draggerRail{
        width: 6px;
        background-color: #f0f0f0;
    }
    .kioskTablesPreset .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        width: 6px;
        background-color: #7a94c2;
    }
    .kioskTablesPreset .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .kioskTablesPreset .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
        background-color: #7a94c2;
    }
    .kioskTablesPreset .scrollerWrap {
        height: calc(100% - 59px);
    }
    .kioskTablesPreset .sideHeight{
        min-height:795px;
    }
    .kioskTablesPreset .tableGuestList{
        width:225px;
        height:235px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.53);
        background: #ffffff;
        position: absolute;
        transform: translateX(-50%);
        z-index: 1;
        display: none;
        top: 0px;
        left: 0px;
    }
    .kioskTablesPreset .tableGuestList:before{
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        top: 100%;
        right: calc(50% - 4px);
        z-index: 1;
    }
    .kioskTablesPreset .tableGuestList:after{
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #dddddd;
        position: absolute;
        top: 100%;
        right: calc(50% - 6px);
    }
    .kioskTablesPreset .tableGuestListH{
        padding: 10px 10px 5px;
        float: right;
        width:100%;
        border-bottom:1px solid #293b5a;
        text-align: right;
    }
    .kioskTablesPreset .tableGuestList .closeBtn{
        float: left;
    }
    .kioskTablesPreset .tableGuestList .closeBtn svg{
        width:11px;
        height:11px;
        fill:#293b5a;
        display: block;
    }
    .kioskTablesPreset .tableGuestList h3{
        float: right;
        font-size: 0.8824rem;
        font-weight: 700;
    }
    .kioskTablesPreset .tableGuestListH>div{
        float: right;
        width:100%;
        line-height: 16px;
    }
    .kioskTablesPreset .tableGuestListH label{
        margin-left: 7px;
        color: #7a94c2;
        font-size: 0.7059rem;
    }
    .kioskTablesPreset .editWrap{
        position: absolute;
        top: 32px;
        right: 10px;
    }
    .kioskTablesPreset .saveNewName{
        position: absolute;
        left:10px;
        top:calc(50% - 4px);
        z-index: -1;
        opacity: 0;
    }
    .kioskTablesPreset .saveNewName svg{
        width:11px;
        height:8px;
        fill:#293b5a;
        display: block;
    }
    #editbleTableName{
        color: #7a94c2;
        font-size: 0.7059rem;
        z-index: -1;
        opacity: 0;
        border: 1px solid #bfc1c5;
        border-radius: 10px;
        display: block;
        background: #fff;
        min-width: 130px;
        padding-right: 5px;
        transition: all .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
    }
    #editbleTableName:focus{
        z-index: 1;
        opacity: 1;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;

    }
    #editbleTableName:focus + .saveNewName{
        z-index: 1;
        opacity: 1;
    }
    .kioskTablesPreset .editName{
        display: inline-block;
        vertical-align: middle;
    }
    .kioskTablesPreset .editName svg{
        width:11px;
        height:11px;
        display: block;
    }
    .kioskTablesPreset .tableCapacity, .tableAvailability{
        color:#484848;
        font-size: 0.7059rem;
        line-height: 0.7059rem;
    }
    .kioskTablesPreset .tableCapacity{
        margin-left:10px;
    }
    .kioskTablesPreset .tableGuestList .scrollerWrap{
        float: right;
        width: 100%;
        height:90%;
    }

    .kioskTablesPreset .tableGuestList .scrollerWrap ul{
        width:100%;
        min-height: 100%;
    }
    #addTable{
        min-height: 165px;
    }
    #addTable .guestName{
        font-size: 0.8235rem;
    }
    #addTable .guestGroup{
        font-size: 0.6471rem;
    }
    #addTable .guestTable{
        margin:0;

        line-height: 13px;
    }
    #addTable .guestItem{
        padding:5px;
    }
    #addTable .guestTable .tableNum,
    #addTable .guestTable .tableName,
    #addTable .guestTable .i_table{
        display: none;
    }
    #addTable .guestTable .guestAmount{
        margin:0;
    }
    #addTable .guestAmount .i_user{
        width: 8px;
        height: 10px;
    }
    #addTable .guestEdit{
        margin-left: 5px;
        margin-top: 12px;
    }
    .kioskTablesPreset .tableGuestList .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
        width: 4px;
    }
    .kioskTablesPreset .tableGuestList .mCSB_scrollTools .mCSB_draggerRail{
        width: 4px;
    }
    .kioskTablesPreset .tableGuestList .mCS-dir-rtl:not(.mCS_no_scrollbar)>.mCSB_inside>.mCSB_container{
        margin-right: 16px;
    }
    #modal-editGuest{
        background: #efefef;
    }
    #modal-editGuest .inputWrap input, #modal-editGuest .inputWrap select, #modal-editGuest{
        background: #fff;
        color:#555555;
        font-size: 0.8235rem;
    }
    #modal-editGuest h2{
        color:#293b5a;
        font-size: 2rem;
        font-weight: 700;
    }
    #modal-editGuest h3{
        margin:10px 0;
        margin-bottom: 20px;
    }
    #modal-editGuest label{
        color:#434343;
        font-size: 1rem;
        margin-bottom: 10px;
        display: block;
    }
    #modal-editGuest h3 svg{
        width: 22px;
        height:26px;
        vertical-align: middle;
        display: inline-block;
        margin-left: 7px;
        fill: #7690bd;
    }
    #modal-editGuest h3 span{
        color:#7690bd;
        font-size: 1.176rem;
        vertical-align: middle;
        display: inline-block;
    }
    .kioskTablesPreset .statusDropDown li{
        padding:7px 0;
        cursor: pointer;
    }
    .kioskTablesPreset .selectedValue span svg,
    .kioskTablesPreset .statusDropDown li svg{
        width:24px;
        height:24px;
        vertical-align: middle;
        display: inline-block;
        margin-left:7px;
    }
    .kioskTablesPreset .selectedValue span span,
    .kioskTablesPreset .statusDropDown li span{
        vertical-align: middle;
        display: inline-block;
        color:#555555;
        font-size: 0.8235rem;
    }
    .kioskTablesPreset .selectedValue{
        width: 100%;
        height: 54px;
        line-height: 54px;
        border-radius: 27px;
        border: 1px solid #bfc1c5;
        background: #fff;
        padding: 0 15px;
        color: #fff;
    }
    .kioskTablesPreset .statusDropDown{
        z-index: 1;
        background: #fff;
        width: 125px;
        padding: 0 10px;
        border: 1px solid #dddddd;
        position: absolute;
        top: calc(100% + 15px);
        right: 50%;
        transform: translateX(50%);
        display: none;
    }
    .kioskTablesPreset .statusDropDown.open{
        display: block;
    }
    .kioskTablesPreset .statusDropDown:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #dddddd;
        position: absolute;
        bottom: 100%;
        right: calc(50% - 6px);
    }
    .kioskTablesPreset .statusDropDown:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        bottom: 100%;
        right: calc(50% - 4px);
    }
    .kioskTablesPreset .secondLevel{
        margin:0 -7px;
    }
    .kioskTablesPreset .secondLevel div[class^="col-"]{
        padding:0 7px;
    }
    .kioskTablesPreset .selectedValue{
        position: relative;
    }
    .kioskTablesPreset .selectedValue .i_select{
        width:16px;
        height:9px;
        position:absolute;
        top:calc(50% - 4px);
        left:10px;
        fill:#8798b5;
    }
    #modal-editGuest{
        max-width: 700px!important;
    }
    .kioskTablesPreset .guestCounter{
        width: 100%;
        height: 54px;
        line-height: 52px;
        border-radius: 27px;
        border: 1px solid #bfc1c5;
        float: right;
    }
    .kioskTablesPreset .guestCounter span{
        width:33.3%;
        float: right;
        text-align: center;
        height: 100%;
    }
    .kioskTablesPreset .guestCounter span.plus{
        background: #f2f2f2;
        border-radius: 0 27px 27px 0;
    }
    .kioskTablesPreset .guestCounter span.minus{
        background: #f2f2f2;
        border-radius: 27px 0 0 27px;
    }
    .kioskTablesPreset .guestCounter span svg{
        width: 17px;
        height: 100%;
        fill: #293b5a;
        display: block;
        margin:0 auto;
    }
    .kioskTablesPreset .guestCounter span.value{
        font-size: 1rem;
        color: #434343;
    }
    #modal-editGuest .row + .row{
        margin-top:20px;
    }
    .kioskTablesPreset .inputWrap.submitWrap.deleteBtn button{
        background: #fff;
    }
    .kioskTablesPreset .inputWrap.submitWrap.deleteBtn button svg{
        width:18px;
        height:19px;
        fill:#7893c1;
        display: inline-block;
        vertical-align: middle;
        margin-left: 7px;
    }
    .kioskTablesPreset .inputWrap.submitWrap.deleteBtn button span{
        color:#7893c1;
        display: inline-block;
        vertical-align: middle;
    }
    .kioskTablesPreset .statusBar .active{
        background: #f3f3f3 !important;
    }
    #arrive.active{
        border-radius: 0 27px 27px 0;
    }
    @media only screen and (max-width:1450px){
        .kioskTablesPreset .statusBtn:not(#all) span{display: none;}
        .kioskTablesPreset .statusBtn svg{margin:0;}
        .kioskTablesPreset .guestFilter .col-lg-6{width: 100%;}
        .kioskTablesPreset .guestWrap{height: calc(100% - 327px);}
    }
    .kioskTablesPreset .tableGuestList{
        width:225px;
        height:235px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.53);
        background: #ffffff;
        position: absolute;
        transform: translateX(-50%);
        z-index: 1;
        display: none;
        top: 0px;
        left: 0px;
    }
    .kioskTablesPreset .tableGuestList.open{
        display: block;
    }
    .kioskTablesPreset .tableGuestList:before{
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        top: 100%;
        right: calc(50% - 4px);
        z-index: 1;
    }
    .kioskTablesPreset .tableGuestList:after{
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #dddddd;
        position: absolute;
        top: 100%;
        right: calc(50% - 6px);
    }
    .kioskTablesPreset .tableGuestListH{
        padding: 10px 10px 5px;
        float: right;
        width:100%;
        border-bottom:1px solid #293b5a;
        text-align: right;
    }
    .kioskTablesPreset .tableGuestList .closeBtn{
        float: left;
    }
    .kioskTablesPreset .tableGuestList .closeBtn svg{
        width:11px;
        height:11px;
        fill:#293b5a;
        display: block;
    }
    .kioskTablesPreset .tableGuestList h3{
        float: right;
        font-size: 0.8824rem;
        font-weight: 700;
    }
    .kioskTablesPreset .tableGuestListH>div{
        float: right;
        width:100%;
        line-height: 16px;
    }
    .kioskTablesPreset .tableGuestListH label{
        margin-left: 7px;
        color: #7a94c2;
        font-size: 0.7059rem;
    }
    .kioskTablesPreset .tableGuestList .scrollerWrap {
        width: 100% !important;
        height: 90%;
        position: unset!important;
    }
    .kioskTablesPreset .guestTable{
        margin-top: 5px;
    }
    .kioskTablesPreset .guestTable .i_table{
        width:16px;
        height:15px;
        fill:#293b5a;
        vertical-align: middle;
        display: inline-block;
    }
    .kioskTablesPreset .guestTable .tableNum:after{
        content: "|";
        margin-right: 5px;
    }
    .kioskTablesPreset .guestTable .tableNum,
    .kioskTablesPreset .guestTable .tableName{
        vertical-align: middle;
        display: inline-block;
        color:#6f6f6f;
        font-size: 0.7647rem;
    }
    .kioskTablesPreset .guestAmount{
        vertical-align: middle;
        display: inline-block;
        margin-right:20px;
    }
    .kioskTablesPreset .guestAmount .i_user{
        width:13px;
        height:15px;
        fill:#293b5a;
        vertical-align: middle;
        display: inline-block;
    }
    .kioskTablesPreset .guestAmount span{
        vertical-align: middle;
        display: inline-block;
        color:#6f6f6f;
        font-size: 0.7647rem;
    }
    .kioskTablesPreset .guestItem{
        text-align: right;
    }
    .kioskTablesPreset .tableGuestList{
        top: 17%;
        left: 50%;
        width: 66%;
        height: 40%;
        position: fixed;
    }
</style>
