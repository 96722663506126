<template>
    <!--SELECT EVENT START-->
    <span>

    </span>
    <!--SELECT EVENT END-->
</template>

<script>
    export default {
        name: 'EventSelectModal',
        data() {
            return{
                client:null,
                selected:''
            }
        },

        watch:{
            selected:(val)=>{
                console.log(val);
            }
        },

        mounted(){
            this.selectEvent();
        },

        methods:{
            async selectEvent() {
                try {
                    const selected = await this.$swal({
                                                          title: this.$t('chooseEvent'),
                                                          input: 'select',
                                                          inputOptions: this.$store.state.EventStore.closestEvents.reduce(
                                                              (events, event) => {
                                                                  events[event.id] = event.name;
                                                                  return events;
                                                              }, {}),
                                                          inputPlaceholder: this.$t(
                                                              'selectEventRequired'),
                                                          showCancelButton: true,
                                                          inputValidator: function (value) {
                                                              return new Promise(
                                                                  function (resolve, reject) {
                                                                      if (value !== '') {
                                                                          resolve();
                                                                      } else {
                                                                          reject(this.$t(
                                                                              'selectEventRequired'));
                                                                      }
                                                                  });
                                                          }
                                                      });
                    this.$emit('setEvent',parseInt(selected));
                } catch (e) {
                    this.selectEvent();
                }
            }
        }

    }

</script>
