<template>
    <button v-if="!modal" class="bottomLogo" @click.prevent="menuController">
        <img v-bind:src="conf.SETTINGS.APP_URL !== 'https://app.seatup.co.il/' ? `${conf.SETTINGS.APP_URL}static/images/logo.png` : '/static/images/logo_kiosk_grey.png'" alt="">
    </button>
    <div v-else-if="modal" class="adminMenu">
        <button @click="$emit('closeMenu')">X</button>
        <h2>{{ $t('adminMenu') }}</h2>
        <span v-if="$store.state.user.type == 2" @click="showEventSelect">{{ $t('chooseEvent') }}</span>
        <span @click="logoff">{{ $t('logoffUser') }}</span>
        <a v-if="$store.state.user.type == 2" href="javascript:window.close();">{{ $t('closeApp') }}</a>
    </div>
</template>

<script>
    import Config from '../../Config'
    export default {
        name: "AdminMenu",
        data(){
            return{
                clicksCounter:0,
                conf:Config
            }
        },
        props:{
            modal:{
                type:Boolean,
                default:false
            }
        },
        methods:{
            logoff(){
                this.$store.dispatch('logoff')
            },
            showEventSelect(){
                this.$emit('closeMenu');
                this.$store.state.kioskEvent = {reset:true};
            },
            menuController(){
                if(this.clicksCounter === 10){
                    this.clicksCounter = 0;
                    let _this = this;
                    this.$swal({
                        text: this.$t('enterYourPassword'),
                        input: 'password',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        showLoaderOnConfirm:true,
                        confirmButtonText: this.$t('continue'),
                        cancelButtonText: this.$t('cancel'),
                        preConfirm: function(value) {
                            return new Promise(function(resolve) {
                                if (typeof value ==='undefined' || value == '') {
                                    _this.$swal.showValidationError(
                                        _this.$t('passwordRequired')
                                    )
                                    _this.$swal.hideLoading();
                                }else {

                                    //validate
                                    _this.$store.dispatch('checkPassword',value)
                                        .then(response =>{
                                            //success
                                            resolve()
                                        })
                                        .catch(err => {
                                            //err
                                            _this.$swal({
                                                confirmButtonText: _this.$t('okButton'),
                                                type: 'error',
                                                title: _this.$t('passwordIncorrect'),
                                                timer: 3000
                                            })
                                        })

                                }
                            });
                        },
                    }).then((password) => {

                        this.$emit('openMenu')

                    }).catch(this.$swal.noop);

                }else{
                    this.clicksCounter++;
                }
            }
        }
    }
</script>
<style>
    .kioskComp .adminMenu{
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0px;
        left:0px;
        text-align: center;
        background: rgba(255, 255, 255, 0.87);
        padding-top: 30%;
        font-size: 40pt;
    }
    .kioskComp .adminMenu h2{
        font-size: 50pt;
        font-weight: bold;
        margin-bottom: 50px;
    }
    .kioskComp .adminMenu span{
        display: block;
    }
    .kioskComp .adminMenu a{
        color:red
    }
    .kioskComp .adminMenu button{
        float: left;
        top: -2px;
        position: absolute;
        left: 26px;
        font-size: 80pt;
        font-weight: bold;
    }
</style>
