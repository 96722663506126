<template>
  <div class="s-form-select-wrapper form-group">
    <label :for="name">{{ label }}</label>

    <div class="select">
      <i class="fa fa-chevron-down" />
      <select :style="selectStyle" v-bind="$attrs" :id="name" class="s-form-select" :class="classList" :name="name" @change="handleChange">
        <option :value="null" v-if="showEmptyOption">
          {{ placeholder ? placeholder : $t("select") }}
        </option>
        <option v-for="(option, index) in selectOptions" :selected="option.value == value" :value="option.value" :key="index">
          {{ option.content }}
        </option>
      </select>
    </div>
    <small v-if="helper" class="form-text text-muted">{{ helper }}</small>
  </div>
</template>

<script>
function removeInvalidOptions(option) {
  return (
    Object.keys(option).includes("value", "content") && option.content != null
  );
}
export default {
  inheritAttrs: false,
  components: {
    //  DisplayErrors,
  },
  //mixins: [uniqIdsMixin, ValidationMixin, DataFormatMixin, hasDefaultOptionKey],
  props: {
    "label" : {type: String},
    "error": {type: String},
    "value": {type: [String,Number]},
    "options": {type: Array},
    "helper": {type: String},
    "name": {type: String},
    "controlClass": {type: String},
    "validationData": {type: Object},
    "placeholder": {type: String},
    "selectStyle": {type: String},
    "showEmptyOption": {
      default: true,
      type: Boolean
    }

  },
  beforeMount() {
   
  },
  computed: {

    classList() {
      return {
        "is-invalid":
          (this.validator && this.validator.errorCount) || this.error,
        [this.controlClass]: !!this.controlClass,
      };
    },
    selectOptions() {
      //if(!this.option) return [];
      if (Array.isArray(this.options)) {
        // if object array
        if (typeof this.options[0] == "object") return this.options;

        // if simple array
        return this.options.map((x, i) => ({
          value: x,
          content: x,
        }));
      }
      return this.optionsFromDataSource;
    },
    optionsFromDataSource() {
      const { jsonData, key, value, dataName } = this.options;
      let options = [];
      const convertToSelectOptions = (option) => ({
        value: option[key || "value"],
        content: option[value || "content"],
      });
      if (jsonData) {
        try {
          options = JSON.parse(jsonData)
            .map(convertToSelectOptions)
            .filter(removeInvalidOptions);
        } catch (error) {
          /* Ignore error */
        }
      }
      if (dataName) {
        try {
          options = Object.values(this.validationData[dataName])
            .map(convertToSelectOptions)
            .filter(removeInvalidOptions);
        } catch (error) {
          /* Ignore error */
        }
      }
      return options;
    },
  },
  methods: {
    handleChange($event) {

      this.$emit('change', this.selectOptions.find(x => x.value == $event.target.value))
      this.$emit('input', $event.target.value)
    },
  }
};
</script>

<style lang="scss">
.s-form-select-wrapper {

  .select {
    position: relative;

    select {
      -webkit-appearance: none;
      padding-left: 50px;
    }

    .fa-chevron-down {
      position: absolute;
      left: 20px;
      top: 35%;
    }
  }


  label {
    margin-bottom: 8px;
  }

  .s-form-select {
    background: white;
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 4px;
    border: solid 1px #a0a0aa;



    border-radius: 27px;
    border: solid 1px #cacad6;

    &:focus {
      border-color: #2ecc71;
    }

  }
}
</style>