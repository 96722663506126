<template>
   
        <div class="dropdown">
            <button class="selectBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg class="i_lang"><use xlink:href="#i_lang"></use></svg>
                <span>{{ $t('langName') }}</span>
                <svg class="i_select"><use xlink:href="#i_select"></use></svg>
            </button>
            <div class="dropdown-menu">
                <ul>
                    <li v-for="(lang) in $t('langs')">
                        <a href="#" @click.prevent="setLang(lang.code)">
                            <span>{{lang.name}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    
</template>

<script>
    import Vue from 'vue'
    export default {
        name: 'LanguageSelector',
        data() {
            return{}
        },
        computed:{
            getCurrentLang(){

            }
        },
        methods:{
            setLang(lang){
                Vue.i18n.set(lang);
                this.$store.dispatch('saveLang',lang);
            }
        }
    }

</script>

<style scoped>
.selectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>