<template>
    <form action="" id="editBanner" class="formWrap" @submit.prevent="submitForm">
        <div class="row">
            <div class="col-xs-12 containerWidth">
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <div class="inputWrap">
                            <label for="app_name">*{{ $t('app_name') }}</label>
                            <input type="text" id="app_name" name="app_name" v-model="name" required>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <div class="inputWrap">
                            <label for="app_domain">*{{ $t('app_domain') }}</label>
                            <input type="text" id="app_domain" name="app_domain"  v-model="domain" required>
                        </div>
                    </div>
                    
                    <div class="col-sm-6 col-xs-12">
                        <FileUpload v-on:uploaded="setFile" :label="'app_logo'" :placeHolder="'updateLogo'" :autoUpload="false"></FileUpload>
                        <img :src="FileStoragePath('/logos/' + $store.state.app.logo)" width="30%">
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <FileUpload v-on:uploaded="setFile" :label="'app_kiosk_logo'" :placeHolder="'updateKioskLogo'" :autoUpload="false"></FileUpload>
                         <img :src="FileStoragePath('/logos/' + $store.state.app.kioskLogo)" width="30%">
                    </div>
                </div>
                
                <div class="row mt-4">
                    <div class="col-sm-6">
                        <div class="inputWrap submitWrap">
                            <button class="submit">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </form>
</template>

<script>
    import FileUpload from '../../Common/FileUpload'
    import FileManager from '../../Common/FileManager'
    export default {
        name: "AppSettingsTab",
        components:{
            FileUpload,
            FileManager
        },
        data(){
            return {
                name:this.$store.state.app.name,
                domain:this.$store.state.app.domain,
                logo:'',
                kioskLogo:'',
            }
        },
        methods:{
            submitForm(){
              debugger;
                let formData = new FormData();
                let app_kiosk_logo = document.getElementById('app_kiosk_logo');
                let app_logo = document.getElementById('app_logo');

                if(typeof app_logo.files[0] !=='undefined');
                    formData.append("logo", app_logo.files[0]);

                if(typeof app_kiosk_logo.files[0] !== 'undefined');
                    formData.append("kioskLogo", app_kiosk_logo.files[0]);

                formData.append("domain",this.domain);
                formData.append("name", this.name);
              
                this.$store.dispatch('updateAppSettings', formData)
                    .then(response =>{
                        this.AlertSuccess(this.$t('appSettingsUpdated'))
                    })
                    .catch(err => {
                        this.AlertError()
                    })
            },
            setFile(_,type){
                if(type === "app_logo"){
                    this.logo = _;
                } else {
                    this.kioskLogo = _;
                }
            },
        }
    }
</script>
