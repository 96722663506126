<template>
  <div class="kioskArrivalStatus" @click="closeMe()">
    <ul>
      <li>
        <strong>{{ arrived }}</strong> {{ $t("arrived") }}
      </li>
      <li>
        <strong>{{ seated }}</strong> {{ $t("seated") }}
      </li>
      <li>
        <strong>{{ percentage }}%</strong> {{ $t("attendance") }}
      </li>
    </ul>
  </div>
</template>

<script>
import {tableExistInEvent} from '../../helpers/event/tables.js'
export default {
  name: "kiosk-arrival-status",
  computed: {
    arrived() {
      let arrived = 0;
      if (
        this.$store.state.kioskEvent.hasOwnProperty("id") &&
        this.$store.state.kioskEvent.hasOwnProperty("guests")
      ) {
        this.$store.state.kioskEvent.guests.forEach((guest) => {
          if (guest.arrived)
            arrived += parseInt(guest.quantity) > 0 ? parseInt(guest.quantity) : 1;
        });
      }

      return arrived;
    },

    seated() {
      let seated = 0;
      if (
        this.$store.state.kioskEvent.hasOwnProperty("id") &&
        this.$store.state.kioskEvent.hasOwnProperty("guests")
      ) {
        this.$store.state.kioskEvent.guests.forEach((guest) => {
          if (
            guest.tableId !== "" &&
            tableExistInEvent(
              this.$store.state.kioskEvent,
              guest.tableId
            ) 
          )
            seated += parseInt(guest.quantity);
        });
      }

      return seated;
    },

    percentage() {
      let percentage = parseInt((this.arrived / this.seated) * 100);
      return isNaN(percentage) ? 0 : percentage;
    },
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>
