<template>
  <div class="sideMenu">
    <div class="logoWrap">
      <img
        v-if="$store.state.app.domain"
        class="logo"
        v-bind:src="
          $store.state.app.logo !== ''
            ? `https://${$store.state.app.domain}/logos/${$store.state.app.logo}`
            : '/static/images/logo.png'
        "
        alt=""
      />
    </div>
    <ul class="menuWrap">
      <li
        v-for="menu in SeatupMenu"
        :class="menuClass(menu)"
        v-bind:key="menu.name"
      >
        <router-link v-bind:to="menuRoute(menu.route)">
          <svg v-if="menu.svg" class="menuIcon">
            <use :xlink:href="'#'+menu.svg"></use>
          </svg>
          <img :src="'/static' + menu.image" v-if="menu.image">
          
          <span>
            {{
              menu[0] == "users"
                ? $t("menu_usersFor_" + $store.state.user.type)
                : $t("menu_" + menu.name)
            }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import MenuByPermission from "@/core/menu/menu_by_permission";
export default {
  name: "SideMenu",
  data() {
    return {
      router: this.$router,
    };
  },
  computed: {
    SeatupMenu() {
      let menu = MenuByPermission();
      let visibleMenu = [];


      
      console.table(menu);


      if (
        typeof this.$store.getters.getUser.permissions == "undefined" ||
        !this.$store.getters.getUser
      ) {
        console.log("user not set", this.$store.getters.getUser);
        return;
      }

      var userPermissions = Object.keys(
        this.$store.getters.getUser.permissions
      );

      for (let i = 0; i < menu.length; i++) {
        const menuItem = menu[i];
        if (menuItem.permission == "*") {
          visibleMenu.push(menuItem);
          continue;
        }

        for (let j = 0; j < userPermissions.length; j++) {
          const userPermission = userPermissions[j];
          if (menuItem.permission == userPermission) visibleMenu.push(menuItem);
        }
      }
      return visibleMenu;
    },
  },

  beforeMount() {},

  methods: {
    MenuByPermission() {},
    MenuByRole(role) {
      return MenuByRole2[role];
    },
    menuRoute(route) {
      if (!route) return "";
      try {
        if (route.search("{eventId}") != -1 && typeof this.$store.state.EventStore.event.id == "undefined")
          return "";
        route = route.replace("{eventId}",this.$store.state.EventStore.event.id);
      } catch {}

      return route;
    },
    menuClass: function (menu) {
      let router = this.$router;
      //console.log(router.currentRoute.name,menu)
      let iclass =
        router.currentRoute.name != null &&
        router.currentRoute.name.includes(menu.name)
          ? "active"
          : "";

      return iclass;
    },
    getMenuIcon(menu, hashtag) {
      let icon = "";
      switch (menu) {
        case "hp":
          icon = "home";
          break;
        case "settings":
        case "sms":
          icon = "settings";
          break;
        case "users":
        case "guests":
          icon = "users";
          break;
        case "calendar":
          icon = "calender";
          break;
        case "seating":
          icon = "seating";
          break;
        case "waiters":
          icon = "waiter";
          break;
        case "drinks":
          icon = "drinks";
          break;
        case "messages":
          icon = "message";
          break;
        case "screen":
          icon = "template";
          break;
        case "reports":
          icon = "i_download";
          break;
        default:
          icon = "home";
          break;
      }
      return (hashtag ? "#" : "") + "i_" + icon;
    },

    usersTitle() {
      return this.$t("menu_usersFor_" + this.$store.state.user.type);
    },
  },
};
</script>
<style scoped>
.menuIcon, .menuIcon img  {
  height: 28px;
  fill: #e1e1e1
}

</style>
