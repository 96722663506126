

function guardInRole(to, user) {
    if(!user) return false;
    if (typeof to.meta.userInRole !== 'undefined'
        && to.meta.userInRole != ''
        && !to.meta.userInRole.includes(user.type)
    ) {
        return false
    }
    return true;
}

function guardHavePermission(to, user) {
    if(!user) return false;

    // if admin
    if(user.type==1)
        return true
        
    if (user.permissions == null || !Object.keys(user.permissions).includes(to.meta.havePermission)) {
        return false
    }
    return true;
}
function guardRoleLevel(to, user) {
    if(!user) return false;
    // role level guard
    if ((typeof to.meta.minimumUserLevel !== 'undefined' || to.meta.minimumUserLevel != '')
        && to.meta.minimumUserLevel < user.type) {
        return false
    }
    return true;
}


export {
    guardInRole,
    guardHavePermission,
    guardRoleLevel
}