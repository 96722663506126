import Config from "../Config";
import axiosV2, { apiRequest } from "../core/http/axios_api_v2";
// const axios = require('axios').default;
const GuestStore = {
    namespaced: false,
    state: {
        guests: []
    },
    actions: {
        
        updateGuest: ({ commit }, data) =>

            new Promise((resolve, reject) => {

                if(data.quantity)
                    data.quantity = parseInt(data.quantity);

                apiRequest('put', 'guest/' + data.id, data)
                    .then((response) => {
                        if (response) {
                            commit('updateGuest', response)
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),

        getGuests: ({ commit,state }, id) =>
            new Promise((resolve, reject) => {
               
                apiRequest('get', 'guest/by-event/' + id)
                    .then( (response) => {
                        commit('setGuests', response)
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
     
        addGuest: ({ commit }, data) =>
            new Promise((resolve, reject) => {
               
                if (!data.event) {
                    console.log(data)
                    reject('please set data.event');
                    return;
                }

                data.quantity = parseInt(data.quantity);
                axiosV2.post('guest', data)
                    .then(function (response) {
                        const data = response.data
                        if (data) {

                            if(data.statusCode === 400)
                                reject(data.message)

                            resolve(data)
                        } else {
                            reject(data)
                        }
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
        deleteGuest: ({ commit }, data) =>
            new Promise((resolve, reject) => {

                axiosV2.delete('guest/' + data.id)
                    .then(function (response) {
                        if (response.data) {
                            commit('deleteGuest', data.id)
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });
               
            }),
     
        importGuests: ({ commit }, data) =>
            new Promise((resolve, reject) => {
                
                axiosV2.post('guest/import', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
        resetGuests: ({ commit }, eventID) =>
            new Promise((resolve, reject) => {
                axiosV2.delete(`guest/${eventID}/removeAll`)
                    .then( (response) => {

                        resolve(response.data)

                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),
    },
    mutations: {
        addGuest(state, payload){
            state.guests.push(payload)
        },
        setGuests(state, payload){
            state.guests = payload;
        },
        deleteGuest(state, payload) {
            state.guests = state.guests.filter(x => x.id != payload)
        },
        updateGuest(state, payload) {
            const idx = state.guests.findIndex(x => x.id === payload.id)
            if(idx>-1){
                let _g = [...state.guests].filter(x => x.id != payload.id)
                _g.splice(idx,0,payload)
                state.guests = _g
           }else{
               console.error('guest not found')
           }
        },
    },
    getters: {
        getGuests(state){
            return state.guests || []
        }
    }
}
export default GuestStore;
