<template>
  <div class="row">


    <div class="col-sm-12 col-md-6">


      <div class="s-box p-4">
        <div class="d-flex mb-4">
          <div class="flex-fill">
            <h3>סידור זמנים</h3>
          </div>
        </div>

        <div class="vtl">
          <div v-for="(task_name) in tasks" :key="task_name['name']" class="event eventa">
            <div class="edate"></div>
            <div class="etxt">
              <input type="text" v-model="task_name['name']" placeholder="נקודת זמן" />
              <TimePicker v-model="task_name['time']"></TimePicker>
              <button class="del" @click="deleteItem(task_name)"> x </button>
            </div>
          </div>

          <div class="event eventa">
            <div class="edate"></div>
            <div class="etxt">
              <h3>הוספה</h3>
              <div v-if="error" class="alert alert-danger" @click="error = !error">
                <strong>שגיאה:</strong> הוסף שם
              </div>

              <form @submit.prevent="addTask">
                <input type="text" placeholder="נקודת זמן" v-model="taskName" />
                <div class="time-picker">
                  <TimePicker v-model="taskTime"></TimePicker>
                </div>
                <button class="s_btn mt-3 mx-auto" type="submit">

                  <i class="fa fa-plus" aria-hidden="true"></i>
                  {{ $t('create') }}

                </button>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <div class="inputWrap textareaWrap">
        <label for="">{{ $t("notes") }}</label>
        <textarea v-model="eventPlanner.timeScheduleNotes" maxlength="200" class="noteWrapper"></textarea>
        <span class="charsLength"><span class="charNum">200</span>/200 </span>
      </div>
      <button class="s_btn btn-warning" type="button" @click="save">
        <i class="fa fa-download" aria-hidden="true"></i>
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import TimePicker from "../Common/TimePicker.vue";
export default {
  name: "TimeSchedule",
  components: { TimePicker },
  props: {
    eventPlanner: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    tasks: function () {
      let res;
      if (this.TS && this.TS.length > 0) {

        this.TS = this.TS
          .sort((a, b) => {
            if (!a.time || !b.time) return 0
            a = parseInt(a.time.replace(':', ''))
            b = parseInt(b.time.replace(':', ''))
            return a - b;
          });
      } else {
        this.TS = this.$store.getters['userSettings'].defaultEventTimes || [];
      }

      if (!Array.IsNotArrayOrEmpty(this.TS))
        this.taskTime = this.TS.at(-1).time
      return this.TS ? this.TS.sort((a, b) => {
        if (!a.time || !b.time) return 0
        a = parseInt(a.time.replace(':', ''))
        b = parseInt(b.time.replace(':', ''))
        return a - b;
      }) : []
    },
  },
  watch: {
    "eventPlanner.timeSchedule": function () {
      if (!this.eventPlanner.timeSchedule)
        this.TS = []
      else
        this.TS = [...this.eventPlanner.timeSchedule]
    },
  },
  data() {
    return {
      title: "לוח זמנים",
      msg: "",
      taskName: "",
      taskTime: null,
      defaults: {

      },
      error: false,
      update: false,
      TS: []
    };
  },
  updated: function () { },
  methods: {
    save() {
      this.$store.dispatch('saveEventPlanner', {
        id: this.eventPlanner.id,
        timeSchedule: this.TS,
        timeScheduleNotes: this.eventPlanner.timeScheduleNotes
      }).then(r=> this.$notify('בוצע בהצלחה'));
    },

    removeAll() {
      this.eventPlanner.timeSchedule = [];
      this.$forceUpdate();
    },

    deleteItem(item) {
      this.eventPlanner.timeSchedule = this.tasks.filter((task) => {
        return task["name"] != item.name;
      });
    },

    changeCheck: function (e) {
      this.tasks[e.target.id]["check"] = e.target.checked;
    },

    addTask: function () {
      if (this.update == true) {
        this.update = false;
        this.TS[this.updateIndex]["name"] = this.taskName;
        this.TS[this.updateIndex]["time"] = this.taskTime;
      } else {

        if (this.taskName != "" && this.taskTime != "") {
          this.TS.push({
            name: this.taskName,
            time: this.taskTime,
          });
          this.save();
        } else {
          this.error = true;
        }
      }
      this.taskName = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/UI/timeline.scss";

.t_box {
  padding: 5px;
  margin: 0 10px 10px;
}

.time-picker {}

.s_btn.danger {
  width: auto;
  padding: 4px 20px;
  height: auto;
}

.del {
  position: absolute;
  top: 10px;
  left: 10px;
}

.etxt {
  input {
    width: 100%;
    background: transparent;
    height: 30px;
  }
}
</style>