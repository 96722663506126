<template>
  <div class="d-flex">
    <section class="s-box p-4">
      <h3><u>אלפון</u></h3>
      <small class="flex-fill"> ייצוא פרטי לקוחות</small>
      <hr>
      <label for="alphone_days_30">חודש
        <input type="radio" id="alphone_days_30" name="alphone_days" v-model="alphone_days" value="30" />
      </label>
      <label for="alphone_days_90">3 חודשים
        <input type="radio" id="alphone_days_90" name="alphone_days" v-model="alphone_days" value="90" />
      </label>
      <label for="alphone_days_360">שנה
        <input type="radio" id="alphone_days_360" name="alphone_days" v-model="alphone_days" value="360" />

      </label>

      <hr>
      <div class="inputWrap submitWrap">
        <button type="button" class="submit" @click="getAlphone">
          {{ $t("אלפון") }}
        </button>
      </div>
    </section>


    

  </div>
</template>

<script>
import axiosV2 from "../../../core/http/axios_api_v2";
import XLSX from "xlsx";
export default {
  name: "ExportTab",
  components: {},
  data() {
    return {
      userSettings: {
        contract: "",
        vatNumber: "",
        businessName: "",
        quoteTerms: "",
      },
      alphone_days: 90,
      tab: 1,
    };
  },
  watch: {},
  beforeMount() { },
  created() { },
  mounted() { },
  methods: {
    getAlphone() {
      axiosV2.get("export/al-phone?days=" + 30).then((csvdata) => {
        let csv = "name,email,phone\n";
        csvdata.data.forEach((row) => {
          csv += `${row.name},${row.email},${row.phone}`;
          csv += "\n";
        });

        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        anchor.target = "_blank";
        anchor.download = "אלפון.csv";
        anchor.click();
      });
    },
   
  },
};
</script>

<style scoped>
input[type="radio"],
select {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;

}

label {
  margin: 0 10px;
}
</style>
