<template>
  <main id="app" v-bind:class="$store.state.isKiosk ? ($store.state.isLogged ? 'kioskComp' : 'kioskCompLogin') : ''" @click="setInteraction()">
    <SideMenu v-if="this.$store.state.isLogged"></SideMenu>
    <div v-bind:class="this.$store.state.isLogged ? 'mainContent' : ''">
      <HeaderNav v-if="this.$store.state.isLogged"></HeaderNav>
      <transition name="slide-fade" mode="out-in" appear>
        <router-view />
      </transition>
    </div>
    <ContactModal />

    <notifications position="bottom right">

    </notifications>

    <Preloader v-show="$store.getters.isLoading" />
  </main>
</template>

<script>

import ContactModal from './components/Modals/ContactModal';
import SideMenu from './components/Common/SideMenu.vue'
import HeaderNav from './components/Common/HeaderNav.vue'
import { useMultiSchemaTenancy } from './Config';
// import imageToBase64 from 'image-to-base64'; // causes ipad bug!!

export default {
  name: 'App',
  data() {
    return {
    }
  },

  beforeMount() {
    window.seatupApp = this;
    this.$store.commit('setAccessToken', null);

    if (useMultiSchemaTenancy())
      console.log(' - Multi Tenant APP loaded')
    else
      console.log(' - Single Tenant APP loaded')

  },
  methods: {
    goBack() {
      if (window.routerLastFrom == 'login') {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    },
    setInteraction() {
      this.$store.state.lastInteraction = Math.floor(Date.now() / 1000);
    },
    loadPrintAssets() {

    }
  },

  mounted() {

    // apiRequest error alerts
    document.addEventListener('s_apiError', (customEvent) => {
      const errorData = customEvent.detail
      
      this.$notify({
        type:'error',
        title: errorData?.statusCode,
        text: errorData?.message || this.$t('error'),
        duration: 8000
      })
    }, false);

    // apiRequest success alerts
    document.addEventListener('s_apiSuccess', (customEvent) => {
      const code = customEvent.detail
      if(code == 'resource_created')
        this.$notify({
          type:'success',
          title: this.$t('created_successfully'),
          //text: errorData?.message || this.$t('error')
        })
    }, false);


    if (this.$store.state.isKiosk) {
      let _this = this;

      $('body').off('mousemove').on('mousemove', function () {
        _this.setInteraction()
      });
      $('body').off('mouseover').on('mouseover', function () {
        _this.setInteraction()
      });
      $('body').off('scroll').on('scroll', function () {
        _this.setInteraction()
      });
      $('body').off('touchstart').on('touchstart', function () {
        _this.setInteraction()
      });

    }



  },
  components: {
    ContactModal,

    SideMenu,
    HeaderNav,
  },
}

</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.kioskView {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  position: fixed !important;
  width: 100% !important;
}
</style>
