<template>
  <div class="row">
    <div :class="selectedItem ? 's-modal modal show' : 's-modal'">
      <div class="s-modal-content medium">
        <button class="icon-close" v-on:click="selectedItem = null">
          <svg>
            <use xlink:href="#i_close"></use>
          </svg>
        </button>
        <ViewItem :item="selectedItem"></ViewItem>
      </div>
    </div>

    <!-- Select Menu -->
    <div class="s-box col-sm-12 col-md-4 p-4">
      <h2 class="mb-3">
        {{ $t("בחר פריטים לאירוע") }}
      </h2>

      <div v-for="category in categories" v-bind:key="category.id" @click="selectedCategory = category.id">
        <div :class="
          'dishCat ' + (selectedCategory == category.id ? 'selected' : '')
        ">
          <h3>{{ category.name }}</h3>
          <div class="d-flex my-2">
            <span>{{
            filterByType(selected_decor, category.id).length
            }}
              &nbsp;</span>
            <span> {{ $t("selected") }}</span>
          </div>
          <span v-for="s in filterByType(selected_decor, category.id)" v-bind:key="s.id" class="selected_dish" @click="removeItem(s)">
            X {{ s.name }}
          </span>
        </div>
        <hr />
      </div>
      <div class="inputWrap textareaWrap">
        <textarea :placeholder="$t('notes')" v-model="eventPlanner.designNotes"></textarea>
        <button @click="saveNotes" class="s_btn">שמור הערות</button>
      </div>
    </div>

    <!-- Select Dish -->
    <div class="col-sm-12 col-md-8">
      <div class=" p-4">
        <div class="d-flex items-center">
        
          <h3>{{ $t("choose") }}</h3>
        </div>

        <hr />
       
        <ItemTiles  missingIconClass="fas fa-layer-group" :items="items" v-on:itemAdded="addItem" :selected_items="selected_decor"></ItemTiles>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "../../Config";
import ViewItem from "../EventItem/ViewItem.vue";
import ItemTiles from "../EventItem/ItemTiles.vue";
export default {
  name: "DecorationSelect",

  components: { ViewItem, ItemTiles },
  props: {
    eventPlanner: {
      type: Object,
      default: () => {
        return {
          categoryDishLimit: [],
        };
      },
    },
  },
  computed: {
    items: function () {
      if (this.selectedCategory == "napkin")
        return this.$store.getters.getNapkins || [];
      if (this.selectedCategory == "tabletop")
        return this.$store.getters.getTableTop || [];
      if (this.selectedCategory == "design")
        return this.$store.getters.getEventDesigns || [];
      return [];
    },
    selected_decor: function () {
      return this.eventPlanner.eventItems || [];
    },
  },

  data() {
    return {
      selectedItem: null,
      selectedCategory: "",

      categories: [
        { id: "napkin", name: "מפיות" },
        { id: "tabletop", name: "מפות" },
        { id: "design", name: "עיצוב" },
      ],
    };
  },

  beforeMount() {
    this.$store.dispatch("getAllEventItems", "napkin").then((r) => { this.selectedCategory = 'napkin'  });
    this.$store.dispatch("getAllEventItems", "tabletop").then((r) => { });
    this.$store.dispatch("getAllEventItems", "design").then((r) => { });
  },

  methods: {
    saveNotes() {
      this.$store.dispatch('saveEventPlanner', {
        id: this.eventPlanner.id,
        designNotes: this.eventPlanner.designNotes
      })
    },
    stepDone(e) {
      this.$store.dispatch("updateEvent", {
        id: this.$store.state.EventStore.event.id,
        settings: this.$store.state.EventStore.event.settings
      });

    },
    showItem(item) {
      this.selectedItem = item;
    },
    // loadTypeItems(type) {
    //   this.$store.dispatch("EventItemCategories").then((r) => {
    //     this.$store.dispatch("getAllEventItems", type).then((r) => {});
    //   });
    // },
    filterByType(items, type) {
      if (!Array.isArray(items)) return [];
      return items.filter((x) => x.type == type);
    },
    removeItem(item) {
      let i = this.selected_decor.findIndex((x) => {
        return x.id == item.id;
      });

      this.selected_decor.splice(i, 1);
      this.$forceUpdate();
    },

    addItem(item) {

      let alreadyExist = this.selected_decor.find((x) => {
        return x.id == item.id;
      });

      if (!alreadyExist) {
        this.selected_decor.push(item);
        this.$store.dispatch("restPost", {
          route: `event-planner/${this.eventPlanner.id}/add-item`,
          data: item,
        });
        this.$forceUpdate();
      } else {
        this.$store.dispatch("restPost", {
          route: `event-planner/${this.eventPlanner.id}/remove-item`,
          data: item,
        });
        this.removeItem(item);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dishCat {
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: 1s background;

  &.selected {
    background: #daf5ff;
  }
}

#event_btn_nav {
  width: 100%;

  button {
    background: #c8e9ff;
    border: #ccc;
    padding: 3px;
    margin: 3px;
    display: inline-block;
  }
}

.selected_dish {
  background: white;
  border-bottom: 2px solid #293B5A;
  padding: 3px;
  margin: 3px;
  border-radius: 2px;
  cursor: pointer;

}
</style>