export class AbstractElement{
    constructor(element) { 
        this.element = element
        this.showActionButtons = true
    }
    dataAttributes(){
        const element = this.element
        return `
        data-shape="${element.shape}" 
        data-type="${element.type}" 
        data-dbid="${element.dbID}"  
        data-rotation="${element.rotation || 0}" 
        data-scale="${element.scale || 1}"
       `
    }

    actionButtonsHtml() {
        if(!this.showActionButtons) return ''

        return `<div class="resizeIcon"><i class="la la-arrows"></i></div>
        <div class="rotateIcon"><i class="la la-rotate-left"></i></div>
        <div class="deleteIcon"><i class="la la-trash-o"></i></div>
        <div class="editIcon"><i class="la la-pencil"></i></div>`
      }
}