var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":_vm.globalClick}},[_c('div',{staticClass:"actionBtns"},[_c('ul',[_c('li',{staticStyle:{"display":"inline"}},[_c('router-link',{staticClass:"addEvent",attrs:{"tag":"button","to":"/settings/presets/add"}},[_c('svg',[_c('use',{attrs:{"xlink:href":"#i_plus"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("addPreset")))])])],1)])]),_c('hr'),_c('div',{staticClass:"display-relative",attrs:{"id":"table-wrpper"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":this.$store.getters.getPresets,"rtl":true,"search-options":{
      enabled: true,
      placeholder: _vm.$t('search'),
    },"pagination-options":{
      enabled: true,
      nextLabel: _vm.$t('next'),
      prevLabel: _vm.$t('prev'),
      rowsPerPageLabel: _vm.$t('Rows per page'),
      ofLabel: _vm.$t('of'),
      pageLabel: _vm.$t('page'), // for 'pages' mode
      allLabel: _vm.$t('all'),
    },"styleClass":"vgt-table condensed"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'background')?_c('span',[_c('img',{staticStyle:{"max-height":"80px"},attrs:{"src":_vm.FileStoragePath(props.row.background)}})]):(props.column.field == 'actions')?_c('span',[_c('router-link',{staticClass:"s_btn p-2",attrs:{"to":'/settings/presets/' + props.row.id}},[_c('svg',{staticClass:"i_editEvent"},[_c('use',{attrs:{"xlink:href":"#i_edit"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("editPreset")))])]),_c('a',{staticClass:"s_btn danger p-2",on:{"click":function($event){return _vm.deletePreset(props.row.id)}}},[_c('svg',{staticClass:"i_deleteEvent"},[_c('use',{attrs:{"xlink:href":"#i_trash"}})]),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }