<template>
  <div>
    <form class="innerBox text-center" @submit.prevent="submitForm">
      <h3>הוספת קטגוריה</h3>

      <div class="inputWrap my-4">
        <input type="text" id="name" name="name" class="w-100" v-model="category.name" :placeholder="$t('name')" required />
      </div>

      <div class="inputWrap my-4">
        <input type="number" id="defaultLimit" name="defaultLimit" class="w-100" v-model="category.defaultLimit" :placeholder="$t('limit')" required />
      </div>

      <div class="inputWrap my-4">
        <input type="number" id="position" name="position" class="w-100" v-model="category.position" :placeholder="0"  />
        <small>מספר גבוה יופיע קודם</small>
      </div>

      <div class="inputWrap submitWrap text-center">
        <button class="s_btn mx-auto d-block">{{  $t("save")  }}</button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "CreateEventItemCategory",
  props: {
    itemToEdit: {
      type: Object,
    },
  },
  watch: {
    itemToEdit(newval, oldval) {
      if (this.itemToEdit) {
        this.category = newval;
        this.$forceUpdate()
      } else {
        this.category = this.newItem()
      }
    }
  },
  data() {
    return {
      category: {
        name: "",
        type: 'food',
        defaultLimit: 0,
        position: 0
      },
    };
  },
  components: {

  },

  beforeMount() { },
  methods: {
    setFile(_) {
      this.$data.dish.background = _;
    },
    newItem() {
      return {
        name: "",
        type: 'food',
        defaultLimit: 0
      }
    },
    submitForm() {
      const action = this.itemToEdit ? "updateEventItemCategory" : "createEventItemCategory"

      this.category.defaultLimit = Number(this.category.defaultLimit)
      this.$store
        .dispatch(action, this.$data.category)
        .then((dishCategory) => {

          this.AlertSuccess(this.$t("dishAdded"))
            .then((t) => {
              if(!this.itemToEdit)
                this.$store.commit("addItemCategory", dishCategory);
            })
            .catch(this.$swal.noop);
        })
        .catch((e) => {
          console.error(e)
          this.AlertError()
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.innerBox {
  padding: 30px;
}
</style>
