<template>
    <div>
        <div class="actionBtns">
            <ul>
                <li class="searchWrapper" style="display: inline">
                    <input type="search" v-model="search" v-bind:placeholder="$t('searchTable') + '...'">
                    <button class="searchEvent" @click="toggleSearch">
                        <svg>
                            <use xlink:href="#i_search"></use>
                        </svg>
                        <span class="sr-only">{{ $t('searchTable') }}</span>
                    </button>
                </li>
                <li style="display: inline" class="pull-left">
                    <router-link tag="button" class="addEvent" to="/settings/tables/add">
                        <svg>
                            <use xlink:href="#i_plus"></use>
                        </svg>
                        <span>{{ $t('addTable') }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div>
            <table class="seatupTable">
                <tr>
                    <th>{{ $t('table_name') }}</th>
                    <th>{{ $t('shape') }}</th>
                    <th>{{ $t('capacity') }}</th>
                    <th></th>
                </tr>

                <tr  v-bind:key="table.id" v-for="table in getTables()">
                    <td>{{ (table.title ? table.title : $t('notExist')) }}</td>
                    <td>{{ (table.shape ? showShape(table.shape) : $t('notExist')) }}</td>
                    <td>{{ (table.capacity ? table.capacity : 0) }} {{ $t('people') }}</td>
                    <td>
                        <dropdown :text="$t('options')">
                            <dropdown-item @click="$router.push('/settings/tables/' + table.id)"  >
                                <svg>  <use xlink:href="#i_edit"></use></svg>
                                <span>{{ $t("edit") }}</span>
                            </dropdown-item>
                            <dropdown-item @click="deleteTable(table)">
                                <svg><use xlink:href="#i_trash"></use></svg>
                                <span>{{ $t("delete") }}</span>
                            </dropdown-item>
                        </dropdown>

                    </td>
                </tr>
            </table>

        </div>
    </div>
</template>

<script>
export default {
    name: 'TableTypesTab',
    data() {
        return {
            search: '',
            actionsStyle: '',
            currentID: 0
        }
    },
    methods: {


        toggleSearch() {
            $('body').toggleClass('search_open');
        },
        getTables() {
            let _this = this;
            if (!this.$store.state.tables) return [];
            return this.$store.state.tables.filter((item) => {
                if (_this.search != '') {
                    if (item.title.includes(_this.search)) return true;
                    if (item.shape.includes(_this.search)) return true;
                    if (item.capacity.toString().includes(_this.search)) return true;
                } else {
                    return true;
                }
            });
        },
        deleteTable(table) {
            this.$swal({
                title: this.$t('deleteTablePrompt'),
                text: this.$t('unrevetableOperation'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then((result) => {

                if (result === true) {
                    this.$store.dispatch('deleteTable', table.id)
                        .catch(response => {
                            //on err
                            this.$swal({
                                confirmButtonText: this.$t('okButton'),
                                type: 'error',
                                title: this.$t('fatalError'),
                                timer: 3000
                            }).catch(this.$swal.noop);
                        })
                }
            })
                .catch(this.$swal.noop);
        },
        showShape(shape) {
            for (let _ of this.$t('shapes')) {
                if (_.id == shape) return _.title;
            }
        }
    },
    beforeMount() {

        //get tables from this.$store
        if (typeof this.$store.state.tables === 'object' && this.$store.state.tables.length > 0) {
            this.$data.tables = this.$store.state.tables;
        } else {
            this.$store.dispatch('getTables')
                .then(response => {
                    this.$data.tables = this.$store.state.tables;
                })
                .catch(response => {
                    this.$swal({
                        confirmButtonText: this.$t('okButton'),
                        type: 'error',
                        title: this.$t('fatalError'),
                        timer: 3000
                    }).catch(this.$swal.noop);
                })
        }
    }
}

</script>

<style scoped>
.innerBox h2 {
    color: #293b5a;
    font-weight: 700;
    font-size: 1.824rem;

}

.closeEvents {
    padding: 25px;
    padding-bottom: 10px;
}

.closeEvents h2 {
    width: 250px;
    float: right;
}

.closeEvents .actionBtns {
    width: calc(100% - 250px);
    float: right;
}

.closeEvents .actionBtns ul {
    float: left;
}

.closeEvents .actionBtns ul li {
    display: inline-block;
    vertical-align: middle;
}

.closeEvents .actionBtns ul li+li {
    margin-right: 20px;
}

.closeEvents .actionBtns ul li svg {
    display: inline-block;
    vertical-align: middle;
}

.closeEvents .actionBtns ul li span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    font-size: 0.8235rem;
    font-weight: 700;
}

.searchEvent svg {
    width: 29px;
    height: 32px;
    display: block;
    fill: #7b96c4;
}

.addEvent {
    width: 181px;
    height: 42px;
    border: 1px solid #52dbcd;
    border-radius: 21px;
    background: -moz-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(41, 59, 90, 1)), color-stop(100%, rgba(123, 150, 196, 1)));
    /* safari4+,chrome */
    background: -webkit-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* opera 11.10+ */
    background: -ms-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* ie10+ */
    background: linear-gradient(90deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#293b5a', endColorstr='#7b96c4', GradientType=1);
    /* ie6-9 */
}

.addEvent svg {
    width: 15px;
    height: 15px;
    fill: #fff;
    margin-left: 10px;
}

.addEvent span {
    color: #fff;
}

.allEventsBtn {
    border: 1px solid #e4e4e4;
    backround: #fff;
    border-radius: 21px;
    width: 181px;
    height: 42px;
}

.allEventsBtn svg {
    width: 19px;
    height: 14px;
    fill: #7b96c4;
    margin-right: 10px;
}

.allEventsBtn span {
    color: #293b5a;
}

.searchWrapper {
    padding-bottom: 7px;
    border-bottom: 1px solid transparent;
}

.search_open .searchWrapper {
    border-color: #adadad;

}

.searchWrapper input[type="search"] {
    width: 0;
    height: 32px;
    transition: width .25s ease-in-out;
    -moz-transition: width .25s ease-in-out;
    -webkit-transition: width .25s ease-in-out;
}

.search_open input[type="search"] {
    width: 275px;
    transition: width .25s ease-in-out;
    -moz-transition: width .25s ease-in-out;
    -webkit-transition: width .25s ease-in-out;
}

.searchWrapper input[type="search"]::-webkit-input-placeholder {
    font-style: italic;
    color: #adadad;
    font-size: 0.9412rem;

}

.search_open .searchEvent svg {
    fill: #293b5a;
}

.eventsActions {
    position: relative;
}

.eventActionDropDown {
    width: 165px;
    position: absolute;
    border: 1px solid #dddddd;
    background: #fff;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    display: none;
}

.eventActionDropDown ul li a {
    padding: 10px 20px;
    display: block;
}

.eventActionDropDown ul li a:hover,
.eventActionDropDown ul li a:focus {
    background: #f4f4f4;
    text-decoration: none;
}

.eventActionDropDown ul li a svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    fill: #878787;
}

.eventActionDropDown ul li a span {
    display: inline-block;
    vertical-align: middle;
    color: #969696;
    font-size: 0.7647rem;
}

.eventActionDropDown ul li a:hover span,
.eventActionDropDown ul li a:focus span {
    color: #2f4262;
}

.eventActionDropDown ul li a:hover svg,
.eventActionDropDown ul li a:focus svg {
    fill: #7590bd;
}

.eventActionDropDown ul li a svg.i_deleteEvent {
    width: 13px;
    height: 16px;
}

.eventActionDropDown ul li a svg.i_editEvent {
    width: 14px;
    height: 15px;
}

.eventActionDropDown ul li a svg.i_contact {
    width: 16px;
    height: 12px;
}

.eventActionDropDown:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #dddddd;
    position: absolute;
    bottom: 100%;
    right: calc(50% - 6px);
}

.eventActionDropDown:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    bottom: 100%;
    right: calc(50% - 4px);
}

.eventActionDropDown ul {
    width: 100%;
}

.eventsActions button {
    height: 38px;
    width: 68px;
    float: left;
}

.eventsActions button span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #a3a3a3;
    display: block;
    margin: 2px auto;
}

.progressNumber {
    display: inline-block;
    vertical-align: middle;
    width: 45px
}

.eventProgress span:not(.progressBar):not(.progressNumber) {
    width: 170px;
    height: 5px;
    border-radius: 2.5px;
    background: #e2e2e2;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
}

.eventProgress span span {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    border-radius: 2.5px;
    background: #000;
}

.eventsCharts {
    width: 70%;
    height: 415px;
    float: right;
    margin-top: 40px;
}

.actionBoxes {
    width: calc(30% - 45px);
    margin-right: 45px;
    float: right;
    margin-top: 40px;
}

.actionBoxes .innerBox {
    width: 100%;
    height: 185px;
    display: block;
    padding: 35px;
    background: #fff;
    transition: background .25s ease-in-out;
    -moz-transition: background .25s ease-in-out;
    -webkit-transition: background .25s ease-in-out;

}

.actionBoxes .innerBox:hover,
.actionBoxes .innerBox:focus {
    text-decoration: none;
    transition: background .25s ease-in-out;
    -moz-transition: background .25s ease-in-out;
    -webkit-transition: background .25s ease-in-out;
    background: -moz-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(41, 59, 90, 1)), color-stop(100%, rgba(123, 150, 196, 1)));
    /* safari4+,chrome */
    background: -webkit-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* opera 11.10+ */
    background: -ms-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* ie10+ */
    background: linear-gradient(90deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#293b5a', endColorstr='#7b96c4', GradientType=1);
    /* ie6-9 */
}

.actionBoxes .innerBox+.innerBox {
    margin-top: 45px;
}

.actionBoxes .innerBox .svgWrap {
    display: inline-block;
    vertical-align: middle;
    width: 83px;
    height: 83px;
    border-radius: 50%;
    margin-left: 30px;
    border: 1px solid #80ebdf;
    background: #fff;
    text-align: center;
}

.actionBoxes .innerBox .svgWrap .i_users {
    width: 39px;
    height: 29px;
    fill: #293b5a;
    margin-top: 24px;
}

.actionBoxes .innerBox .svgWrap .i_template {
    width: 19px;
    height: 30px;
    fill: #293b5a;
    margin-top: 25px;
}

.actionBoxes .innerBox .linkText {
    display: inline-block;
    vertical-align: middle;
    margin-left: 50px;
}

.actionBoxes .innerBox .linkText .linkTxtNum {
    color: #99aed1;
    font-size: 2.412rem;
    font-weight: 400;
}

.actionBoxes .innerBox .linkText .linkTxtHeader {
    color: #293b5a;
    font-size: 1.588rem;
    font-weight: 700;
}

.actionBoxes .innerBox:hover .linkTxtNum,
.actionBoxes .innerBox:focus .linkTxtNum,
.actionBoxes .innerBox:hover .linkTxtHeader,
.actionBoxes .innerBox:focus .linkTxtHeader {
    color: #fff;
}

.actionBoxes .innerBox .i_linkArr {
    display: inline-block;
    vertical-align: middle;
    fill: #7b96c4;
    width: 38px;
    height: 38px;
    float: left;
    margin-top: 35px;
}

.actionBoxes .innerBox:hover .i_linkArr,
.actionBoxes .innerBox:focus .i_linkArr {
    fill: #fff;
}
</style>
