
export const isDev = () => {
    return ["localhost","planup-dev.co.il", "test.seatup-dev.co.il"].includes(window.location.hostname)
}

export const useMultiSchemaTenancy = () => {


    //yoni edit
    return false;


    return ["localhost","planup-dev.co.il","planup.co.il"].includes(window.location.hostname)
}

export const isPlanup = () => {
    
    return ["localhost","planup-dev.co.il","planup.co.il","www.planup.co.il"].includes(window.location.hostname)
}



class Config {
    
    static hostname = isPlanup() ? 'planup.co.il' : 'seatup.co.il';

    static SETTINGS = {
        //API_URL:'http://localhost/api/',
        APP_URL: isDev() ? "http://localhost:8080/" : `https://${window.location.hostname}/`,
        API_URL: isDev() ? "http://localhost/api/" : `https://${Config.hostname}/api/`,
        API_V2_URL: isDev() ? "http://localhost:3000/apiv2/" : `https://apiv2.${Config.hostname}/apiv2/`,
        SOCKET_URL: isDev() ? "http://localhost:3000/" : `https://apiv2.${Config.hostname}/`,
        MEDIA_URL: isDev() ? "http://localhost/public/" : `https://${Config.hostname}/public/`,
    };
    static API_ROUTES = {
        'login': 'login',
        'isLogged': 'isLogged',
        'dashboard': 'dashboard',
        'users': 'users',
        'appointments': 'appointments',
        'quotations': 'quotations',
        'contact': 'contact',
        'events': 'events',
        'guests': 'guests',
        'upload': 'fileUpload',
        'halls': 'halls',
        'tables': 'tables',
        'reports': 'reports',
        'presets': 'presets',
        'settings': 'appSettings',
        'calendar': 'calendar',
        'equipments': 'equipment',
        'banners': 'banners'
    }
    static DEFAULT_API_SETTINGS = { headers: { 'Content-Type': 'text/json' }, withCredentials: true };

    static DEFAULT_API_V2_SETTINGS() {
        return {
            baseURL: Config.SETTINGS.API_V2_URL,
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    static GUEST_STATUS = {
        'notResponded': 0,
        'coming': 1,
        'maybe': 2,
        'notComing': 3,
    }

    static html2CanvasConfig = {
        "type": "dataURL",
        // "allowTaint": true,
        // "foreignObjectRendering": false,
        // "logging": false,
        "useCORS": true,
        // "crossOrigin": 'anonymous'
    }

};

export default Config
