<template>
    <div class="col-md-4 col-sm-6 col-xs-12 tableItem matchHeight">
        <div class="col-xs-12 tableHeader" @click.prevent="setTable()">
            <div class="col-xs-3"><svg class="i_table"><use xlink:href="#i_table"></use></svg> <span class="tableNum">{{ table.tableNum }}</span></div>
            <div class="col-xs-4"><span class="tableNum"  style="font-size: 12px">{{ table.seated }} {{ $t('seated') }}</span></div>
            <div class="col-xs-5 text-right">
                <svg class="i_user"><use xlink:href="#i_user"></use></svg>
                <span class="arrivalStatus">
                                <span>{{ table.arrived }}</span>/{{ table.tableCapcity }} <span>{{ $t('arrived') }}</span>
                            </span>
            </div>
            <div class="col-xs-12 border">&nbsp;</div>
        </div>
        <div class="col-xs-12 tableData" @click.prevent="setTable()">
            <ul>
                <li><span>{{ $t('tableName') }}:</span> {{ table.name && table.name !== ''? table.name : $t('not_exist') }}</li>
                <li><span>{{ $t('groups') }}:</span> {{ table.groups.length > 0 ? table.groups.join(', ') : $t('not_exist') }}</li>
            </ul>
        </div>
        <div class="col-xs-12 tableFooter" @click.prevent="setTable()">
            <div class="pull-right eventProgress"><span class="progressNumber">{{ table.arrivalPercentage }}%</span> <span><span class="progressBar" v-bind:style="percentStyle()"></span></span></div>
            <div class="pull-left">
                <svg class="i_longArr"><use xlink:href="#i_longArr"></use></svg>
            </div>
        </div>
        <div class="col-xs-12 tableGuests" v-if="show || fromSearch" @click.prevent="setShow($event,false)">
            <table style="width: 100%;">
                <thead @click.prevent="setShow($event,false)">
                <tr>
                    <th style="width: 10%;"></th>
                    <th style="width: 90%;">{{ $t('guestName') }}</th>
                    <th style="width: 10%;">{{ $t('seated') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="guest in table.guests" :key="guest.id" v-bind:style=" { opacity: (guest.hasOwnProperty('arrived') && guest.arrived ? 1 : 0.8), background: (guest.hasOwnProperty('marked') && guest.marked === true) ? '#efefef' : '#fff' } " @click.prevent="markGuest(guest)" v-bind:title="$t('guestMarkTooltip')" v-tippy>
                    <td style="width: 10%;"><i style="font-size: 25px;" class="guestArrived la" v-bind:class="(guest.arrived ? 'la-check-circle' : 'la-circle')"></i></td>
                    <td style="width: 80%;">{{ guest.fullName }}</td>
                    <td style="width: 10%;" ><svg class="i_user"><use xlink:href="#i_user"></use></svg>{{ guest.status.coming }}</td>
                </tr>
                </tbody>
                <tfoot @click.prevent="show=false">
                <tr>
                <td colspan="2" style="text-align: center"><strong>X</strong></td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TableItem",
        props:{
            table:{
                type:Object,
                default:{}
            },
            guests:{
                type:Array,
                default:function(){return []}
            },
            searchInput:{
                type:String,
                default:''
            }
        },
        watch:{
            searchInput:function(){
                this.fromSearch = false;
                if(this.searchInput !== ''){
                    if(typeof this.table.guests === 'object' && this.table.guests.length > 0){
                        this.table.guests.forEach(_guest=>{
                            if(_guest.fullName !== '' && _guest.fullName.toLowerCase().includes(this.searchInput.toLowerCase())){
                                this.fromSearch = true;
                                _guest.marked = true;
                            } else {
                                  _guest.marked = false;
                            }
                        })
                    }
                }
            }
        },
        data(){
            return {
                show : false,
                fromSearch : false
            }
        },
        methods:{
            percentStyle(){
                return {
                    background:this.getProgressColor(),
                        width:`${this.table.arrivalPercentage >= 100 ? 100 : this.table.arrivalPercentage}%`
                }
            },
            getProgressColor(){
                if(this.table.arrivalPercentage <= 50){
                    return '#7b96c4';
                }

                if(this.table.arrivalPercentage > 100){
                    return '#ff0e00';
                }

                if(this.table.arrivalPercentage > 90){
                    return '#48ce41';
                }

                return '#e3e703';
            },
            setTable(){
                this.$emit('hideAll');
                this.show = this.show ? false : true;
            },
            markGuest(guest){
                this.$emit('guestMark',guest.id);
            },
            setShow($event,show = false){
                if($event.target.className.toString().includes("guestArrived")) return;
                this.show = show;
            }
        }
    }
</script>