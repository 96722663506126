<template>
  <div :class="visible ? `s-modal ${skin} show` : 's-modal'">
    <div class="s-modal-content">
      <button class="icon-close" v-on:click="visible = null">
        <svg>
          <use xlink:href="#i_close"></use>
        </svg>
      </button>
      <slot></slot>
    </div>
  </div>

</template>

<script>

export default {
  name: "Modal",

  props: {
    skin: {
      default: 'normal',
    },
    // tableData: {},
    // actions: {
    //   required: true,
    // },
    // showPagination: {
    //   default: true,
    // },

    // isAjax: {
    //   type: Boolean,
    //   default: false,
    // },
    // endpoint: {
    //   default: "",
    // },
    // cellFormat: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   },
    // },
  },
  data() {
    return {
      visible: false
    };
  },
  mounted() {

  },

  beforeMount() { },
  methods: {

    close(params) {
      this.visible = false
      this.$emit('onClose', params)
    },
    hide() {
      this.visible = false
      this.$emit('onClose', {})
    },
    show(newProps) {
      this.visible = true
    },

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.large > .s-modal-content {
    width: 90%;
}
@media screen and (min-width:720px) {
  .normal>.s-modal-content {
    width: 50%;
  }

  .big>.s-modal-content {
    width: 80%;

  }
}
</style>
