import Config from "../Config";
import axiosV2 from "../core/http/axios_api_v2";
const axios = require('axios').default;
const PandaStore = {
    namespaced: false, 
    state: {

    },
    actions: {
        createPandaGuest: ({ commit }, data) =>
        
        new Promise((resolve, reject) => {

            if(!data.pandaEventId || data.pandaEventId==0){
                reject('invalid pandaEventId - cannot add guest to panda');
                return;
            }
    
            let guestDTO =  {
                full_name: data.fullName,
                email: data.email,
                attributes:{
                  people: data.quantity,
                  arrive: 0,
                  undecided: 0,
                  category: (data.group) ? data.group : ''
                }
            };
            if(data.pandaEventId && data.pandaEventId != 0){
                guestDTO.member_id =  data.pandaEventId;
            }
            axiosV2.post('notify-panda-crm/guest', guestDTO)
                .then(function (response) {
                    if (response.status == 201) { // http 201 = created
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    //console.warn(error);
                    reject(error)
                });
        }),

        updatePandaGuest: ({ commit }, data) =>
            new Promise((resolve, reject) => {
               
                if(!data.pandaId || data.pandaId==0 || !data.pandaEventId || data.pandaEventId==0){
                    reject('pandaId and pandaEventId cannot be empty');
                    return;
                }
                //console.log(data);
                let guestDTO = {
                    full_name:      data.fullName,
                    email:          data.email,
                    attributes:     { }
                }
                if(data.quantity)             guestDTO.attributes.people = data.quantity;
                if(data.status.coming)        guestDTO.attributes.arrive = data.status.coming;
                if(data.status.maybeComing)   guestDTO.attributes.undecided = data.status.maybeComing;
                if(data.group)               guestDTO.attributes.category = data.group;

                axiosV2.put('notify-panda-crm/guest/' + data.pandaId, guestDTO)
                    .then(function (response) {
                        if (response.data.status) {
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch(function (error) {
                        //console.warn(error);
                        reject(error)
                    });
            }),
    },
    mutations: {
        setPandaLogs(state, payload) {
            state.pandaNotifications = payload;
        }
    },
    getters: {

    }
}
export default PandaStore;
