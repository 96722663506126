<template>
  <div>
    <div v-if="guest.status">
      <div class="g_status_drop">
        <ul>
          <li>
            <div class="yes">
              <svg><use xlink:href="#i_arriving"></use></svg>
              <span>{{ $t("willCome") }}</span>
            </div>
            <div class="guestCounter innerCounter">
              <span
                class="plus"
                @click.prevent="guestStatusControl('coming', '+')"
                ><svg><use xlink:href="#i_plus"></use></svg
              ></span>
              <span class="value">{{ guest.status.coming }}</span>
              <span
                class="minus"
                @click.prevent="guestStatusControl('coming', '-')"
                ><svg><use xlink:href="#i_minus"></use></svg
              ></span>
            </div>
          </li>
          <li>
            <div class="no">
              <svg><use xlink:href="#i_notArriving"></use></svg>
              <span>{{ $t("notComing") }}</span>
            </div>
            <div class="guestCounter innerCounter">
              <span
                class="plus"
                @click.prevent="guestStatusControl('notComing', '+')"
                ><svg><use xlink:href="#i_plus"></use></svg
              ></span>
              <span class="value">{{ guest.status.notComing }}</span>
              <span
                class="minus"
                @click.prevent="guestStatusControl('notComing', '-')"
                ><svg><use xlink:href="#i_minus"></use></svg
              ></span>
            </div>
          </li>
          <li>
            <div class="maybe">
              <svg><use xlink:href="#i_maybeArriving"></use></svg>
              <span>{{ $t("mayCome") }}</span>
            </div>
            <div class="guestCounter innerCounter">
              <span
                class="plus"
                @click.prevent="guestStatusControl('maybeComing', '+')"
                ><svg><use xlink:href="#i_plus"></use></svg
              ></span>
              <span class="value">{{ guest.status.maybeComing }}</span>
              <span
                class="minus"
                @click.prevent="guestStatusControl('maybeComing', '-')"
                ><svg><use xlink:href="#i_minus"></use></svg
              ></span>
            </div>
          </li>
          <li>
            <div class="noAnswer">
              <svg><use xlink:href="#i_didentAnswer"></use></svg>
              <span>{{ $t("notResponded") }}</span>
            </div>
            <div class="guestCounter innerCounter">
              <span
                class="plus"
                @click.prevent="guestStatusControl('notResponded', '+')"
                ><svg><use xlink:href="#i_plus"></use></svg
              ></span>
              <span class="value">{{ guest.status.notResponded }}</span>
              <span
                class="minus"
                @click.prevent="guestStatusControl('notResponded', '-')"
                ><svg><use xlink:href="#i_minus"></use></svg
              ></span>
            </div>
          </li>
          <li>
            <div class="isReserve">
              <span>{{ $t("reserve") }}</span>
            </div>
            <div class="guestCounter innerCounter">
              <span
                class="plus"
                @click.prevent="guestStatusControl('reserve', '+')"
                ><svg><use xlink:href="#i_plus"></use></svg
              ></span>
              <span class="value">{{ guest.status.reserve }}</span>
              <span
                class="minus"
                @click.prevent="guestStatusControl('reserve', '-')"
              >
                <svg><use xlink:href="#i_minus"></use></svg>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>Please Select Guest</div>
  </div>
</template>

<script>
export default {
  name: "GuestQntStatus",
  props: {
    guest: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch:{
      "guest.quantity": function(){
          this.updateStatuses();
      }
  },
  methods: {
    // fix bad database data ( if status column is null or empty object)
    initDefaultStatusIfNull() {
        
      let obj = this.guest.status;

      if (obj && Object.keys(obj).length === 0) {
        this.guest.status = {
          notResponded: this.guest.quantity,
          coming: 0,
          notComing: 0,
          maybeComing: 0,
          reserve: 0,
        };
      }
    },

    guestStatusControl(type, action = "+") {
      let amount = 0;
      if (action == "+") {
        let notResponded = this.guest.status.notResponded
          ? this.guest.status.notResponded
          : 0;
        let total = this.guest.quantity - notResponded;

        if (total + 1 <= this.guest.quantity) {
          amount = 1;
        }
      } else {
        if (this.guest.status[type] > 0) {
          amount = -1;
        }
      }

      this.guest.status[type] += amount;
      
      this.updateStatuses();
       this.$emit("statusChanged", {
        quantity: this.guest.quantity,
      });
    },

    updateStatuses() {
      this.initDefaultStatusIfNull(); // fix bad database data ( if status column is null or empty object)
      // console.log(this.guest.status);
      let responded =
        (this.guest.status.coming ? this.guest.status.coming : 0) +
        (this.guest.status.notComing ? this.guest.status.notComing : 0) +
        (this.guest.status.maybeComing ? this.guest.status.maybeComing : 0) +
        (this.guest.status.reserve ? this.guest.status.reserve : 0);

      this.guest.status.notResponded = this.guest.quantity - responded;
      this.guest.status.notResponded =
        this.guest.status.notResponded < 0 ? 0 : this.guest.status.notResponded;

      while (this.guest.quantity < responded) {
        if (this.guest.status.notResponded > 0) {
          this.guest.status.notResponded--;
        } else if (this.guest.status.maybeComing > 0) {
          this.guest.status.maybeComing--;
        } else if (this.guest.status.notComing > 0) {
          this.guest.status.notComing--;
        } else if (this.guest.status.coming > 0) {
          this.guest.status.coming--;
        }

        break;
      }
     
      //this.saveChanges();
      //this.$forceUpdate();
    },
  },
};
</script>


<style scoped lang="scss">


.g_status_drop {
  //position: absolute;
  //top: calc(100% - 10px);
  background: #fff;
 
  //display: none;
  z-index: 100;
}

.active .g_status_drop {
  display: block;
}

.g_status_drop {
  svg {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    margin:3px 5px;
  }

  & > span {
    display: inline-block;
    vertical-align: middle;
    color: #1c1c1c;
    font-size: 0.9412rem;
    margin:3px 5px;
  }

  .yes,
  .no,
  .maybe,
  .noAnswer,
  .isReserve {
    float: right;
  }
}

.yes svg {
  fill: #44b642;
}

.no svg {
  fill: #ba1818;
}

.maybe svg {
  fill: #e5a2a2;
}

.noAnswer svg {
  fill: #797979;
}

.guestCounter.innerCounter {
  float: left;
  width: 71px;
  height: 30px;
  line-height: 28px;

  span svg {
    width: 9px;
  }
}

.g_status_drop ul {
  width: 180px;

  li {
    float: right;
    width: 100%;
    padding: 5px;

    &:nth-child(even) {
      background: #f0f0f0;
    }
  }
}

.guestCounter.innerCounter span {
  &.plus,
  &.minus {
    background: #fff;
  }

  &.value {
    background: #fff;
    border-right: 1px solid #bfc1c5;
    border-left: 1px solid #bfc1c5;
  }
}

.g_status {
  border-radius: 27px;
}

.active .g_status {
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #bfc1c5;
}


.guestCounter {
  width: 110px;
  max-width: 100%;
  height: 44px;
  line-height: 40px;
  border-radius: 27px;
  border: 1px solid #bfc1c5;
  float: right;

  span, input {
    width: 33.3%;
    float: right;
    text-align: center;
    height: 100%;
  }

  span {
    &.plus {
      background: #f2f2f2;
      border-radius: 0 27px 27px 0;
    }

    &.minus {
      background: #f2f2f2;
      border-radius: 27px 0 0 27px;
    }

    svg {
      width: 17px;
      height: 100%;
      fill: #293b5a;
      display: block;
      margin: 0 auto;
    }
  }

  input.value {
    font-size: 1rem;
    color: #434343;
    background: #fff;
  }
}

.counterPop {
  min-width: 85px;
}

.innerMainContent .guestCounter:not(.innerCounter) {
  &.active {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    opacity: 0;
    z-index: -1;
    border: 1px solid #bfc1c5;
    border-radius: 27px;
    display: block;
    background: #fff;
    width: 90%;
    height: 44px;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  opacity: 0;
  z-index: -1;
  border: 1px solid #bfc1c5;
  border-radius: 27px;
  display: block;
  background: #fff;
  width: 90%;
  height: 44px;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
</style>