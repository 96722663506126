<template>
  <div id="app" class="container-fluid text-center pt-4">
    <div>
      <div v-if="error" class="alert alert-danger" @click="error = !error">
        <strong>שגיאה:</strong> הוסף את שם המשימה
      </div>
      <h3>רשימת דגשים והערות</h3>
      <form @submit.prevent="addItem" v-if="!compactMode" class="m-5">
        <div class="mb-3 d-flex justify-center items-center">
          <div class="flex-fill">
            <input
              type="text"
              class="w-100 mx-3"
              placeholder="שם המשימה"
              v-model="itemName"
              style="
                width: 100%;
                border-bottom: 2px solid black;
                font-size: 20px;
                padding: 10px;
              "
            />
          </div>
          <div class="mx-3">
            <button class="s_btn big" type="submit">
              {{ $t("add") }}
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </form>

      <div v-if="eventPlanner">
        <table class="seatupTable w-100">
          <tr></tr>
          <tr v-for="(item, id) in items" :key="id" class="">
            <td width="100">
              <label style="display: block; opacity: 1">
                <i
                  class="la la-check-circle"
                  style="font-size: 37px; color: green; font-weight: bold"
                  v-if="item['status']"
                ></i>

                <i
                  class="la la-check-circle"
                  style="font-size: 37px; color: grey"
                  v-else
                ></i>

                <input
                  type="checkbox"
                  style="display: none"
                  :checked="item['status']"
                  @change="changeStatus(item)"
                  :id="item['id']"
                />
              </label>
              <!-- <input type="statusbox"  style="opacity:1"> -->
            </td>

            <td @click="loadData(id)">
              <input
                type="text"
                class="editable w-100"
                placeholder="שם המשימה"
                v-model="item['title']"
              />
            </td>

            <td>
              <button class="s_btn danger mb-3" @click="remove(item)">
                {{ $t("delete") }}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <button class="s_btn px-5 m-4" @click.prevent="save()">
        {{ $t("save") }}
      </button>
    </div>
  </div>
</template>

<script>
import { subDays, dateDiffInDays } from "../../helpers/date-helper";
export default {
  name: "EventToDoList",
  computed: {
    items: function () {
      if (!this.eventPlanner.toDoList) return [];

      return this.eventPlanner.toDoList;
    },
  },
  data() {
    return {
      title: "Event ToDo List",
      msg: "",
      itemName: "",
      //items: [],
      error: false,
      update: false,
      updateid: null,
      ids: [],
      deleteMultiple: false,
    };
  },
  props: {
    eventPlanner: {
      type: Object,
      default: () => {
        return {};
      },
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
  },
  updated: function () {},
  methods: {
    setDefault() {
      return [
        { title: "Download VueJS", status: false },
        { title: "Install Node Modules", status: false },
        { title: "Run NodeJS Server", status: false },
        { title: "Open App", status: false },
        { title: "Add title", status: false },
        { title: "Update title", status: false },
        { title: "Delete title", status: false },
      ];
    },

    save() {
      // let eventDate = new Date(this.$store.state.EventStore.event.date);
      let data = {
        id: this.eventPlanner.id,
        toDoList: this.items,
      };

      // data.forEach((x) => {
      //   if (x.daysBefore) {
      //     x.reminderDate = subDays(eventDate, x.daysBefore);
      //   }
      // });

      this.$store.dispatch("restPatch", {
        route: `event-planner/${this.eventPlanner.id}`,
        data: data,
      }).then(r=>{
        this.AlertSuccess()
      });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        const el = this.items[i];
        if ((el.id == item.id)) this.items.splice(i, 1);
      }
      this.save();
    },

    changeStatus: function (item) {
      // console.log(e);
      item["status"] = !item["status"];
      var count = 0;
      this.save();
    },
    loadData: function (id) {
      this.item = this.items[id]["title"];
      this.updateid = id;
      this.update = true;
    },

    addItem: function () {
      if (this.update == true) {
        this.update = false;
        this.items[this.updateid]["title"] = this.itemName;
      } else {
        if (this.itemName != "") {
          let theid = this.items.length;
          this.items.push({
            id: theid,
            title: this.itemName,
            status: false,
          });
          this.save();
        } else {
          this.error = true;
        }
      }

      this.itemName = "";
    },
  },
};
</script>

<style scoped lang="scss">
.daysBefore {
  max-width: 120px;
  text-align: center;
}
.editable {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 30px;
  &:focus {
    background-color: white;
    border: 1px solid #ccc;
  }
}
</style>