// const paymentTypes = { check: "צ'ק", credit_card: "אשראי", cash: "מזומן", wire_transfer: "העברה בנקאית" }


export const billingTypeList = [
    { code: "inv_proforma", type_code: 300, name: 'חשבונית קבלה' },
    { code: "inv_tax", type_code: 305, name: "חשבונית מס" },
    { code: "inv_receipt", type_code: 400, name: "קבלה" },
    { code: "inv_tax_receipt", type_code: 320, name: "חשבונית מס קבלה" },
    // { code: "inv_refund", type_code: 330, name: "החזר" },
    // { code: "crt_delivery", type_code: 200, name: "משלוח" },
    // { code: "crt_return", type_code: 210, name:'' },
    // { code: "order_purchase", type_code: 500, name:'' },
    // { code: "purchase", type_code: 100, name:'' },
    // { code: "dc_quote", type_code: null, name: "הצעת מחיר" },
    // { code: "inv_don_receipt", type_code: 405, name:'' },
]

export const paymentTypes = {
    'credit-card': 'כרטיס אשראי',
    'cash': 'מזומן',
    'check': "צ'ק",
    // 'paypal' : 'פייפאל',
    'bank-transfer': 'העברה בנקאית',
    'other': 'אחר',

}

const weekDaysList = [
    'ראשון',
    'שני',
    'שלישי',
    'רביעי',
    'חמישי',
    'שישי',
    'שבת',
]

const monthList = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
]

const menuTypes = ['בופה', 'סרוויס מסעדה', 'הגשה']


/**
 * 
יצירת חוזה - ממולא אוטומטית בתהליך
שליחת חוזה- ממולא אוטומטית בתהליך
חתימת חוזה- ממולא אוטומטית בתהליך
פתיחת יוזר- ממולא אוטומטית בתהליך
קביעת פגישת ניהול
פגישת ניהול
קביעת פגישת תפריט
פגישת תפריט
קביעת פגישת עיצוב
פגישת עיצוב
תשלום אקו"ם
פתיחת סקיצת הושבה-- ממולא אוטומטית בתהליך
הזנת אורחים למערכת-- ממולא אוטומטית בתהליך
סיום הושבה סופית-- ממולא אוטומטית בתהליך
 */
const EventSteps = {
    'contract_create': 'יצירת חוזה',
    'contract_send': 'שליחת חוזה',
    'contract_sign': 'חתימת חוזה',
    'appointment_manage': 'קביעת פגישת ניהול',
    'appointment_manage_completed': 'פגישת ניהול',
    'appointment_design': 'קביעת פגישת עיצוב',
    'appointment_design_completed': 'פגישת עיצוב',
    'appointment_menu': 'קביעת פגישת תפריט',
    'appointment_menu_completed': 'פגישת עיצוב',
    'akum_Payment': 'תשלום אקו"מ',
    'assign_event_preset': 'פתיחת סקיצת הושבה',
    'assign_guests': 'הזנת אורחים למערכת',
    'seating_completed': 'סיום הושבה',
}

export {
    monthList,
    weekDaysList,
    menuTypes,
    EventSteps
}