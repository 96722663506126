<template>
    <button class="changeLang" @click="dropDownControl()">
        <span>{{ $store.state.preferences.lang.toUpperCase() }}</span>

        <div class="kioskLangSelectorDrop" v-bind:class="dropDownOpen ? 'open' : ''">
            <span v-for="(lang) of $t('langs')" @click.prevent="setLang(lang.code)" v-bind:class="lang.code == $store.state.preferences.lang ? 'active' : ''">{{ lang.code.toUpperCase() }}</span>
        </div>

        <svg class="i_lang"><use xlink:href="#i_lang"></use></svg>
    </button>
</template>

<script>
    import Vue from 'vue'
    export default {
        name: "KioskLanguageSelector",
        data(){
            return {
                dropDownOpen:false
            }
        },
        methods:{
            setLang(lang){
                Vue.i18n.set(lang);
                this.$store.dispatch('saveLang',lang);
                let _this = this;
                setTimeout(function(){
                    _this.dropDownOpen = false
                },50);
            },
            dropDownControl(){
                if(this.dropDownOpen){
                    this.dropDownOpen = false
                }else{
                    this.dropDownOpen = true
                    let _this = this;
                    setTimeout(function(){
                        _this.dropDownOpen = false
                    },3000);
                }
            }
        }
    }
</script>
<style>
    .kioskComp .kioskLangSelectorDrop{
        display: none;
        position: absolute;
        top: -101px;
        width: 93px;
        right: 0px;
        float: right;
        background: white;
        text-align: center;
        height: 102px;
        line-height: 32px;
        border-left: 1px solid #88a5a0;
        border-top: 1px solid #88a5a0;
    }
    .kioskComp .kioskLangSelectorDrop.open{
        display: block;
    }
    .kioskComp .kioskLangSelectorDrop span{
        display: block !important;
    }
    .kioskComp .kioskLangSelectorDrop span:hover,.kioskComp .kioskLangSelectorDrop span.active{
        font-weight: bold;
    }
</style>
