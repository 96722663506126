<template>
    <li class="guestItem" v-bind:data-guest-id="draggableGuest.id">
											<span class="guestIcon" data-status="yes">
												<svg class="draggableGuestIcon" v-bind:class="getIcon()"><use v-bind:xlink:href="`#${getIcon()}`" ></use></svg>
											</span>
        <span class="guestInfo">
            <div class="guestName">{{ draggableGuest.fullName }}</div>
            <div class="guestGroup">{{ draggableGuest.group ? draggableGuest.group : $t('noGuestGroup') }}</div>
            <div class="guestTable">
                <svg class="i_table"><use xlink:href="#i_filter"></use></svg>
                <span class="tableNum">{{ draggableGuest.tableNumber > 0 ? draggableGuest.tableNumber : '#' }}</span>
                <span v-bind:title="$t('editableLabel')" v-tippy class="tableName" v-if="!editMode" @click.prevent="(!presetLocked || $store.state.user.type === 2) ? editMode = true : null">
                   {{getTableLabel(table()[0] )}}
                </span>
                <span class="tableName" v-if="editMode" >
                    <input type="number" 
                    v-model="tableNum" 
                    style="border: 1px solid #cfcfcf;" 
                    v-on="{blur: setTable, focusout: setTable, change: setTable}" 
                    v-if="!isLocked"/> 
                </span>
                |
                <span style="margin-right:10px" class="guestAmount" v-if="draggableGuest.status.coming > 0"><svg class="i_user"><use xlink:href="#i_user" style="fill:#41b53e"></use></svg> <span style="color:#41b53e;font-weight:bold">{{ draggableGuest.status.coming }}</span></span>
                <span style="margin-right:2px" class="guestAmount" v-if="draggableGuest.status.notComing > 0"><svg class="i_user" style="fill:#bb1b1b"><use xlink:href="#i_user"></use></svg> <span style="color:#bb1b1b;font-weight:bold">{{ draggableGuest.status.notComing }}</span></span>
                <span style="margin-right:2px" class="guestAmount" v-if="draggableGuest.status.maybeComing > 0"><svg class="i_user" style="fill:#FAFF04"><use xlink:href="#i_user"></use></svg> <span style="color:#FAFF04;font-weight:bold">{{ draggableGuest.status.maybeComing }}</span></span>
                <span style="margin-right:2px" class="guestAmount" v-if="draggableGuest.status.notResponded > 0"><svg class="i_user"><use xlink:href="#i_user"></use></svg> <span style="font-weight:bold">{{ draggableGuest.status.notResponded }}</span></span>
                <span style="margin-right:2px" class="guestAmount" v-if="draggableGuest.status.reserve > 0"><svg class="i_user"><use xlink:href="#i_user"></use></svg> <span style="font-weight:bold">{{ draggableGuest.status.reserve }}</span></span>

            </div>
        </span>
        <button class="guestEdit" @click.prevent="editGuest" v-if="!isLocked">
            <svg class="editGuestBtn"><use xlink:href="#i_edit"></use></svg>
            <svg class="deleteGuestBtn"><use xlink:href="#i_close"></use></svg>
            <span class="sr-only">{{ $t('editGuest') }}</span>
        </button>
    </li>
</template>

<script>
    import { isEventLocked } from '../../helpers/event'
    export default {
        name: "DraggableGuest",
        props:{
            draggableGuest:{
                type:Object,
                default:()=>{ return {} }
            },
            presetLocked:{
                type:Boolean,
                default:false
            }
		},
        computed: {
            isLocked(){
                return (this.presetLocked || this.isEventLocked(this.$store.state.EventStore.event))  && t(his.$store.state.user.type==3 || this.$store.state.user.type==7)
            }
        },
		data(){
            return {
                editMode:false,
                tableInitiated:false,
				tableNum:''
			}
		},
		methods:{
            isEventLocked,
            getIcon(){
                if(this.draggableGuest.hasOwnProperty("status") && typeof this.draggableGuest.status === "object"){
                    for(const type of Object.keys(this.draggableGuest.status)){
                        if(this.draggableGuest.status.hasOwnProperty(type) && this.draggableGuest.status[type]){
                            if(this.draggableGuest.status[type] > 0){
                                switch (type) {
                                    case 'coming':
                                        return 'i_arriving';
                                    case 'maybeComing':
                                        return 'i_maybeArriving';
                                    case 'notComing':
                                        return 'i_notArriving';
                                    case 'notResponded':
                                        return 'i_didentAnswer';
									case 'reserve':
                                        return 'i_didentAnswer';
                                }
							}
						}
					}
				} else {
                    return 'i_notArriving';
                }
			},
            table(){
                let tableId = this.draggableGuest.tableId;
                if(this.$parent.$data.event && typeof this.$parent.$data.event.presetData.filter !=='function') return {};
                return this.$parent.$data.event.presetData.filter((_)=>{
                    if(_.dbID == tableId){
                        if(!this.tableInitiated){
                            this.tableNum = _.tableNum;
                            this.tableInitiated = true;
                        }
                        return _;
                    }
                    return null;
                });
			},
            setTable(){
                let updated = false;
                if(this.$parent.$data.event && typeof this.$parent.$data.event.presetData.filter ==='function'){
                    this.$parent.$data.event.presetData.filter((_)=>{
                        if(_.tableNum == this.tableNum){
                            this.draggableGuest.tableId = _.dbID;
                            this.$forceUpdate();
                            updated = true;
                            return true;
                        }
                        return null;
                    });
				}

				if(!updated) {
                    this.draggableGuest.tableId = '';
				}

                this.$store.dispatch('updateGuest',{
                    id: this.draggableGuest.id,
                    event:this.draggableGuest.event, 
                    tableId: this.draggableGuest.tableId
                })
                    .then(response =>{
                        //success
                        this.$parent.fetchGuests();
                    })
                    .catch(err => {
                        //err
                        this.alertError(this.$t('saveFailed'))
                       
                    });

				this.editMode = false;
			},
            editGuest(){
              this.$emit('editClick', this.draggableGuest)
			},
            getTableLabel(table){
                if(table){
                    return table.name ?  `${table.name}(#${table.tableNum})` : `#${table.tableNum}`
                } else {
                    return this.$t('notSeatedGuest')
                }
            }
		}
    }
</script>

<style>
	.draggableGuestIcon.i_arriving{
		fill: #44b642;
	}
	.draggableGuestIcon.i_maybeArriving{
		fill: #e5a2a2;
	}
	.draggableGuestIcon.i_notArriving{
		fill: #ba1818;
	}
	.draggableGuestIcon.i_didentAnswer{
		fill: #797979;
	}
</style>
