import Role from '../auth/roles.enum'

/*
 case "hp":
          icon = "home";
          break;
        case "settings":
        case "sms":
          icon = "settings";
          break;
        case "users":
        case "guests":
          icon = "users";
          break;
        case "calendar":
          icon = "calender";
          break;
        case "seating":
          icon = "seating";
          break;
        case "waiters":
          icon = "waiter";
          break;
        case "drinks":
          icon = "drinks";
          break;
        case "messages":
          icon = "message";
          break;
        case "screen":
          icon = "template";
          break;
        case "reports":
          icon = "i_download";
          break;
        default:
          icon = "home";
          break;

*/

let MenuByPermission = [
   {
      name: 'dashboard',
      permission: '*',
      svg: 'i_home',
      route: '/'
   },
   {
      name: 'settings',
      permission: 'settings.view',
      svg: 'i_settings',
      route: '/settings'
   },
   {
      name: 'users',
      permission: 'users.index',
      svg: 'i_users',
      route: '/users'
   },
   {
      name: 'messages',
      permission: 'messages.view',
      svg: 'i_message',
      route: '/messages'
   },
   {
      name: 'calendar',
      permission: 'calendar.view',
      svg: 'i_calender',
      route: '/calendar'
   },
   
   // {
   //    name: 'guests',
   //    permission: 'guests.view',
   //    icon: '',
   //    route: '/guests/{eventId}'
   // },
   {
      name: 'seating',
      permission: 'seating.view',
      svg: 'i_seating',
      route: '/seating/{eventId}'
   },
   {
      name: 'screen',
      permission: 'screen.view',
      svg: 'i_template',
      route: '/screen'
   },
   // {
   //    name: 'drinks',
   //    permission: 'drinks.edit',
   //    svg: 'i_drinks',
   //    route: '/events/{eventId}/drinks'
   // },
   {
      name: 'waiters',
      permission: 'waiters',
      svg: 'i_waiter',
      route: '/waiters'
   },
   {
      name: 'billing',
      permission: 'billing',
      svg: 'i_billing',
      route: '/billing'
   },
   {
      name: 'analytics',
      permission: 'analytics',
      svg: 'i_analytics',
      route: '/analytics'
   },
];

export default function () { return MenuByPermission }