var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":function($event){return _vm.globalClick($event)}}},[_c('div',{staticClass:"innerMainContent"},[_c('PageHeader',{attrs:{"icon":"i_users","title":_vm.$t('usersFor_' + this.$store.state.user.type),"breadcrumbs":[
        { name: _vm.$t('menu_users'), route: '/users' } ]},scopedSlots:_vm._u([{key:"action-buttons",fn:function(){return [_c('router-link',{staticClass:"s_btn",attrs:{"tag":"button","to":"/users/add"}},[_c('svg',[_c('use',{attrs:{"xlink:href":"#i_plus"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("addUser")))])])]},proxy:true}])}),_c('div',{staticClass:"closeEvents innerBox"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"rtl":true,"totalRows":_vm.totalRecords,"search-options":{
            enabled: true,
            placeholder: _vm.$t('search'),
          },"pagination-options":{
            enabled: true,
            nextLabel: _vm.$t('next'),
            prevLabel: _vm.$t('prev'),
            rowsPerPageLabel: _vm.$t('Rows per page'),
            ofLabel: _vm.$t('of'),
            pageLabel: _vm.$t('page'), // for 'pages' mode
            allLabel: _vm.$t('all'),
          },"styleClass":"vgt-table condensed"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-search":_vm.onSearchChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticStyle:{"transform":"rotate(90deg)","font-size":"40px","display":"block"},on:{"click":function($event){$event.preventDefault();return _vm.showActions($event, props.formattedRow.id)}}},[_vm._v(" ... ")])]):(props.column.field == 'type')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("userType_" + props.formattedRow[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticClass:"eventActionDropDown",style:(_vm.actionsStyle)},[_c('ul',[_c('li',[_c('router-link',{staticClass:"permissionUser",attrs:{"to":'users/' + _vm.currentID + '/permissions'}},[_c('svg',{staticClass:"i_editEvent"},[_c('use',{attrs:{"xlink:href":"#i_contact"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("permissions")))])])],1),_c('li',[_c('router-link',{staticClass:"editUser",attrs:{"to":'users/' + _vm.currentID}},[_c('svg',{staticClass:"i_editEvent"},[_c('use',{attrs:{"xlink:href":"#i_contact"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])])],1),_c('li',[(_vm.$store.state.user.id === 2)?_c('a',{on:{"click":function($event){return _vm.LoginAs(_vm.currentID)}}},[_c('span',[_vm._v("התחבר כזוג")])]):_vm._e()]),_c('li',[_c('a',{staticClass:"deleteUser",on:{"click":function($event){return _vm.deleteUser()}}},[_c('svg',{staticClass:"i_deleteEvent"},[_c('use',{attrs:{"xlink:href":"#i_contact"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }