import Config from "../Config";
import axiosV2, { apiRequest } from "../core/http/axios_api_v2";

const EventItemStore = {
    namespaced: false,
    state: {
        itemCategories: [],
        foodItems: [],
        napkinItems: [],
        tableTopItems: [],
        items: [],
        currentItemCategory: null
    },
    actions: {
        EventItemCategories: ({ commit, state }) =>
            new Promise((resolve, reject) => {

                axiosV2.get('event-item-category')
                    .then(function (response) {
                        state.itemCategories = response.data
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
        getAllEventItems: ({ commit, state }, type) =>
            new Promise((resolve, reject) => {
                //var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
                axiosV2.get('event-item/' + type)
                    .then((response) => {
                        //commit('setEventItems',items)  
                        state.items = state.items
                            .filter(x => x.type != type)
                            .concat(response.data)

                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
        EventItemsByCategory: ({ commit, state }, categoryId) =>
            new Promise((resolve, reject) => {
                var $state = state
                axiosV2.get('event-item/by-category/' + categoryId)
                    .then(function (response) {
                        // state.items = response.data
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
        createEventItemCategory: ({ commit, state }, category) =>
            new Promise((resolve, reject) => {
                var $state = state
                axiosV2.post('event-item-category', category)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
        updateEventItemCategory: ({ commit, state }, category) =>
            new Promise((resolve, reject) => {
                axiosV2.patch('event-item-category/' + category.id, category)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject()
                    });

            }),
        createEventItem: ({ commit, state }, i) =>
            new Promise((resolve, reject) => {
                apiRequest('post', 'event-item', i)
                    .then(function (item) {
                        commit('addItem', item)
                        resolve(item)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });

            }),

        updateEventItem: ({ commit, state }, data) =>
            new Promise((resolve, reject) => {

               apiRequest('patch', 'event-item/' + data.id, data)
                    .then(function (response) {
                        const Item = response.data
                        resolve(Item)
                    })
                    .catch(function (error) {
                        console.warn(error);
                        reject(error)
                    });
            }),

    },
    mutations: {
        updateItem(state, payload) {
            const index = state.items.findIndex(item => item.id === payload.id);
            if (index !== -1) state.items.splice(index, 1, payload);
        },
        addItem(state, payload) {
            state.items.push(payload);
        },
        addItemCategory(state, payload) {
            state.itemCategories.unshift(payload);
        }
    },
    getters: {


        getNapkins(state) {
            //return state.napkinItems
            return state.items.filter(x => x.type == 'napkin')
        },
        getDishes(state) {
            //return state.foodItems
            return state.items.filter(x => x.type == 'food')
        },
        getTableTop(state) {
            //return state.tableTopItems
            return state.items.filter(x => x.type == 'tabletop')
        },
        getEventDesigns(state) {
            //return state.tableTopItems
            return state.items.filter(x => x.type == 'design')
        },

    }
}
export default EventItemStore;
