import { domCenterPoint, domPosition } from "../helpers/position";
import PresetBuilder from "../PresetBuilder";

export class PresetBuilderItemTransform {

    /**
     * 
     * @param {PresetBuilder} presetBuilder 
     */
    constructor(presetBuilder, config) {
        this.itemToTransform = null
        this.presetBuilder = presetBuilder
        this.rootContainer = this.presetBuilder.getContainerDOM()

        this.isMousedown = false
        this.startPos = [0, 0]
        this.currentPos = [0, 0]
        this.rotateSelector = config.rotateSelector
        this.scaleSelector = config?.scaleSelector

        this.listener = null
        this.operation = null

        this.startScale = 1
        this.startCenter = [0, 0]
        this.cornerPos = [0, 0]
        this.originalSize = [0, 0]
        this.lastDistanceMeasured = 0
        this.line = null
        this.lineText = null
        this.debug()
        this.apply()


    }
    calcDistance(r1, r2) {
        return Math.sqrt((r2[1] - r1[1]) ** 2 + (r2[0] - r1[0]) ** 2)
    }
    getSlopeAngle(s1, s2) {
        return (s2[1] - s1[1]) / (s2[0] - s1[0]);
    }
    applyScale(val) {
        return val / this.presetBuilder.scale
    }
    applyPointScale(point) {
        return [point[0] / this.presetBuilder.scale, point[1] / this.presetBuilder.scale]
    }
    debug() {

        let line = `<svg id="item-transform-svg"  style="overflow:visible;z-index:0;position:absolute;top:0;left:0;pointer-events: none;">`
        line += `<line id="item-transform-line" x1="0px" y1="0px" x2="0px" y2="0px" stroke-dasharray="4" style="stroke:green;stroke-width:2;" />`
        line += '<text fill="gold" style="direction:ltr;font-weight:bold;font-size:28px;stroke:#000000;stroke-width: 1px;" id="item-transform-text" x="0" y="0">0</text>'
        line += ' </svg> '
        this.rootContainer.insertAdjacentHTML("beforeend", line);
        this.svg = document.querySelector(`#item-transform-svg`)
        this.lineText = document.querySelector(`#item-transform-text`)
        this.line = document.querySelector(`#item-transform-line`)
        // setInterval(()=> {
        //     this.line.setAttribute('stroke-dasharray', ((+this.line.getAttribute('stroke-dasharray')+1)%2)+2) 
        // },100)
    }
    apply() {

        let rootContainer = this.rootContainer
        this.presetBuilder.getItemsDOM().forEach(item => {

            let rotateDom = item.querySelector(this.rotateSelector)
            let scaleDom = item.querySelector(this.scaleSelector)

            if (rotateDom)
                rotateDom.addEventListener('mousedown', (evt) => {
                    evt.stopPropagation()
                    this.startPos = [evt.clientX, evt.clientY]
                    this.itemToTransform = item
                    this.startCenter = domCenterPoint(item)
                    this.isMousedown = true
                    this.operation = 'rotate'
                })

            if (scaleDom)
                scaleDom.addEventListener('mousedown', (evt) => {
                    evt.stopPropagation()
                    this.startPos = this.applyPointScale([evt.clientX, evt.clientY])
                    this.itemToTransform = item

                    this.startScale = item.firstElementChild.dataset.scale
                    this.startCenter = domCenterPoint(item)
                    this.cornerPos = domPosition(item)
                    this.originalSize = [item.offsetWidth, item.offsetHeight]
                    this.isMousedown = true
                    this.operation = 'scale'
                })
        });



        rootContainer.addEventListener('mousemove', (evt) => {

            if (!this.isMousedown || !this.itemToTransform) return

            let tableDom = this.itemToTransform.firstElementChild
            let dataset = tableDom.dataset
            let rect = this.rootContainer.getBoundingClientRect()

            this.currentPos = this.applyPointScale([evt.clientX - rect.left, evt.clientY - rect.top])
            this.svg.style.display = 'block'


            if (this.operation == 'rotate') {
                let val 
                val = this.startPos[0] - this.currentPos[0] > 0 ? val * -1 : val
                var sAngle = Math.atan2((this.startCenter[1] - this.currentPos[1]), (this.startCenter[0] - this.currentPos[0]));
                       
                val = parseInt((sAngle) * 180 / Math.PI);

                this.itemToTransform.style.transform = `scale(${dataset.scale}) rotate(${val}deg)`
                this.line.style.stroke = 'orange'
                tableDom.setAttribute('data-rotation', val)
                this.line.setAttribute('x1', this.startCenter[0])
                this.line.setAttribute('y1', this.startCenter[1])
                this.line.setAttribute('x2', this.currentPos[0])
                this.line.setAttribute('y2', this.currentPos[1])

                this.lineText.innerHTML = `${val}&#176;`
            }
            if (this.operation == 'scale') {

                let d = this.calcDistance(this.cornerPos, this.currentPos)
                let cubeDimension = d / Math.sqrt(2)
                let scaleRatio = Math.max(0.4, cubeDimension / this.originalSize[1])
                
                this.itemToTransform.style.transform = `scale(${scaleRatio.toFixed(1)}) rotate(${dataset.rotation}deg)`
                this.line.style.stroke = 'green'
                tableDom.setAttribute('data-scale', scaleRatio.toFixed(1))
                this.line.setAttribute('x1', this.startCenter[0])
                this.line.setAttribute('y1', this.startCenter[1])
                this.line.setAttribute('x2', this.currentPos[0])
                this.line.setAttribute('y2', this.currentPos[1])

                this.lineText.textContent = `x${scaleRatio.toFixed(1)}`
                
            }
            this.lineText.setAttribute('x', this.startCenter[0] - 70)
            this.lineText.setAttribute('y', this.startCenter[1] - 70)
           
        })

        rootContainer.addEventListener('mouseup', (evt) => {
            this.line.setAttribute('x1', 0)
            this.line.setAttribute('y1', 0)
            this.line.setAttribute('x2', 0)
            this.line.setAttribute('y2', 0)
            this.svg.style.display = 'hidden'
            this.lineText.textContent = ''

            this.isMousedown = false
        })

    }

}