<template>
  <div class="s_box">
    <h3>הוספת {{ $t(itemType) }}</h3>
    <form action="" id="edititem" class="formWrap" @submit.prevent="() => submitForm()">
      <div class="row">



        <div class="col-sm-4" style="height: 100%;">

          <label style="
                margin: 20px 0 0 0 ;
                padding: 20px 0 ;
                border: dashed 2px var(--main-color);
                width: 100%;
                height: 250px;
                display:flex;
                flex-direction: column;
                justify-items:center;
                align-items: center;
                cursor: pointer;
              ">
            <div v-if="!previewimage">
              <div>
                <h2 class="flex-fill" style="width:100%; text-align:center">העלאת תמונה</h2>
              </div>

              <div class="imgWrap d-flex items-center justify-center ml-2" v-tippy="" title="העלו תמונה" @click="() => $refs.fileManager.openFileDialog()">
                <img :src="FileStoragePath(item.image)" alt="" v-if="item.image" class="galleryImage" />
                <label v-else>📷</label>
              </div>
            </div>
            <FileManager ref="fileManager" style="display:none" :autoUpload="false" @fileSelected="showPreview"></FileManager>
            <img :src="previewimage" v-if="previewimage" style="max-width:100%;max-height:100%">

          </label>

        </div>

        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-12">
              <div class="inputWrap">
                <label for="name">*{{ $t("name") }}</label>
                <input type="text" id="name" name="name" v-model="item.name" required />
              </div>
            </div>

            <div class="col-sm-12" v-if="itemType == 'food'">
              <div class="inputWrap">
                <label for="description">*{{ $t("price") }}</label>
                <input type="number" id="price" name="price" v-model="item.price" />
              </div>
            </div>

            <div class="col-sm-12" v-if="itemType == 'food'">
             
                  <FormSelect required :options="this.$store.state.EventItemStore
                  .itemCategories.map(x => ({ value: x.id, content: x.name }))" v-model="item.categoryId"
                  :label='$t("category")'></FormSelect>
                
              
            </div>
          </div>




          <div class="row">
            <div class="col-sm-12">
              <div class="inputWrap">
                <label for="description">*{{ $t("description") }}</label>
                <textarea id="description" name="description" v-model="item.description"></textarea>
              </div>
            </div>
          </div>


          <div class="inputWrap my-4">
          <label for="description">מיקום</label>
          <input type="number" id="position" name="position" class="w-100" v-model="item.position" :placeholder="0" />
          <small>מספר גבוה יופיע קודם</small>
        </div>

        </div>
      </div>



      <div class="row">
        <div class="col-sm-12">
          <FormInput type="checkbox" label="אזל מהמלאי" v-model="item.outofstock" :checked="item.outofstock"></FormInput>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="inputWrap submitWrap">
            <button class="submit mx-auto d-block">{{ $t("save") }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FileManager from '@/components/Common/FileManager'
export default {
  name: "CreateItem",
  props: {
    itemToEdit: {
      type: Object,
    },
    itemType: {
      type: String,
    },
  },
  watch: {
    itemToEdit(newval, oldval) {
      if (this.itemToEdit) {
        this.item = newval;
        this.itemType = this.itemToEdit.type

        this.$forceUpdate()
      } else {
        this.item = this.newItem()
      }
    }
  },
  data() {
    return {
      item: this.newItem(),
      previewimage: null
    };
  },
  components: {
    FileManager
  },

  mounted() {
    // this.$store.dispatch("EventItemCategories").then((response) => {});
  },
  methods: {
    showPreview(data) {
      console.log(data)
      this.previewimage = data
    },
    newItem() {
      return {
        name: "",
        hall: "",
        price: 0,
        category: "",
        image: "",
        description: "",
        outofstock: false
      }
    },
    setFile(_) {
      this.$data.item.background = _;
    },
    async submitForm() {

      if (this.$refs.fileManager.havePendingFiles()) {
        let files = await this.$refs.fileManager.uploadFile()
        this.item.image = files.file.filePath
      }
      const action = this.itemToEdit ? "updateEventItem" : "createEventItem"
      this.item.type = this.itemType
      this.$store
        .dispatch(action, this.item)
        .then((item) => {
          if (!this.itemToEdit)
            this.item = this.newItem()
          this.AlertSuccess();
        })
        .catch((response) => {
          console.error(response)
          this.AlertError(response);
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.imgWrap {
  width: 50%;
  float: right;
  // border: 4px dashed white;

  cursor: pointer;

  label {
    cursor: pointer;
    font-size: 4rem;
    transition: 0.5s font-size ease-out;
  }

  &:hover {
    label {
      font-size: 5rem;
    }

    // box-shadow: inset 1px 1px 10px 4px #bfffff;
  }
}

.imgWrap img {
  width: 100%;
}
</style>
