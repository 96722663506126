const TPL_RU = {
    langName:"Русский",
    langs:[
        {code:'he',name:'иврит'},
        {code:'en',name:'английский'},
        {code:'ru',name:'Русский'},
    ],
    loginTitle:"Welcome Back!",
}

export default TPL_RU