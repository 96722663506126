
//import { drawRectengularTable, drawSquareTable, drawCircleTable } from "./elements/seats"

export default class Seating {

    
    constructor(elementId, tablesSeatStatus) {
        this.tablesSeatStatus = tablesSeatStatus
        this.elementId = elementId
        this.useStatusColor = true
    }

    /**
     * Draw seats for preset
     */
    drawSeats() {
        if (!this.elementId) throw 'please set element id'
        const tableSeats = (table) => {
            let dbid = table.dataset.dbid;
            return this.tablesSeatStatus[dbid];
        };

        // RECTANGLE
        document.querySelectorAll(`${this.elementId} .rectangleTable`)
            .forEach((table) => {
                this.drawRectengularTable(table, tableSeats(table));
            });
        //SQUERE
        document.querySelectorAll(`${this.elementId} .squareTable`).forEach((table) => {
            this.drawSquareTable(table, tableSeats(table));
        });
        //CIRCLE
        document.querySelectorAll(`${this.elementId} .circleTable`).forEach((table) => {
            this.drawCircleTable(table, tableSeats(table));
        });

    }

    /**
     * 
     * @param {'arrived','confirmed','notComing','maybeComing','notResponded','reserve'} seatsStatusArray 
     * @param {*} capacity 
     * @returns 
     */
    getSeatsHtml(seatsStatusArray, capacity) {

        let seats = [];
        try {
            for (let status of Object.keys(seatsStatusArray)) {

                let status_places = seatsStatusArray[status]

                if (status_places > 0) {
                    for (let s = 1; s <= status_places; s++) {
                        let seat = "<span class='seat " + this.SeatClassByStatus(status) + "'></span>";
                        seats.push(seat);
                    }
                }
            }
        } catch (e) {
            // console.log('invalid seats status array', seatsStatusArray)
        }

        for (let s = seats.length; s <= capacity - 1; s++) {
            let seat = "<span class='seat " + this.SeatClassByStatus() + "'></span>";
            seats.push(seat);
        }

        return seats;
    }

    SeatClassByStatus(status = '') {

        // always set notResponded color(gray) to occupied seats
        if (!this.useStatusColor && ['arrived'].includes(status))
            return 'confirmed'
        if (!this.useStatusColor && ['notArrived'].includes(status))
            return 'notResponded'

        // alert();
        switch (status) {
            case 'arrived':
            case 'confirmed':
                return 'confirmed'

            case 'notComing':
                return 'notComing'

            case 'maybeComing':
                return 'maybe'

            case 'notResponded':
                return 'notResponded'

            // case 'reserve':
            //     return 'reserve'

            default:
                return '';

        }
    }


    static ShapeClass(shape) {
        switch (shape) {
            case 'squareTable':
            case 'wood':
                return 'square';
            case 'circleTable':
                return 'circle';
            case 'rectangleTable':
                return 'rectangle';
            case 'ellipseTable':
                return 'ellipse';
            case 'drop':
                return 'drop';
            default: return 'square'; break;
        }
    }


    /**
     * Rect Table
     * @param {*} table table DOM 
     * @param seatsData {
        "confirmed": 15,
        "notComing": 0,
        "maybeComing": 0,
        "notResponded": 0
        } seatsData 
     */
    drawRectengularTable = (table, seatsData) => {
     
        if (typeof table == "undefined" || !table || typeof table.dataset == 'undefined') {
            console.warn('could not build table (undefined)', table);
            return;
        }

        let capacity = table.dataset.capacity,
            tablehead = table.dataset.tablehead,
            capacityPerSideHeight = (capacity - (tablehead * 2)) / 2,
            capacityPerSideWidth = tablehead,
            width = capacityPerSideWidth * 14,
            height = capacityPerSideHeight * 14,
            text = "";

        let sides = {};
        if (typeof table.dataset.side1 !== 'undefined') {
            sides = {
                side0: (typeof table.dataset.side1 !== 'undefined' && table.dataset.side1 != '' ? table.dataset.side1 : ''),
                side1: (typeof table.dataset.side2 !== 'undefined' && table.dataset.side2 != '' ? table.dataset.side2 : ''),
                side2: (typeof table.dataset.side3 !== 'undefined' && table.dataset.side3 != '' ? table.dataset.side3 : ''),
                side3: (typeof table.dataset.side4 !== 'undefined' && table.dataset.side4 != '' ? table.dataset.side4 : ''),
            };
        } else {
            sides = {
                side0: (typeof table.parentElement.dataset.side1 !== 'undefined' && table.parentElement.dataset.side1 != '' ? table.parentElement.dataset.side1 : ''),
                side1: (typeof table.parentElement.dataset.side2 !== 'undefined' && table.parentElement.dataset.side2 != '' ? table.parentElement.dataset.side2 : ''),
                side2: (typeof table.parentElement.dataset.side3 !== 'undefined' && table.parentElement.dataset.side3 != '' ? table.parentElement.dataset.side3 : ''),
                side3: (typeof table.parentElement.dataset.side4 !== 'undefined' && table.parentElement.dataset.side4 != '' ? table.parentElement.dataset.side4 : ''),
            };
        }

        let seats = this.getSeatsHtml(seatsData, capacity);

        if (sides.hasOwnProperty('side1') && sides.side1 !== '') {
            width = sides.side1 * 16;
        }

        table.querySelector(".rectangle").style.cssText = (`width:${width}px;height:${height}px;line-height:${height - 2}px;`); //line-height:${height-2}px;
        for (let i = 0; i < 4; i++) {
            text += `<div class="tableSide side${i}">`;

            if (i == 1 || i == 3) {
                if (sides.hasOwnProperty(`side${i}`) && sides[`side${i}`] && sides[`side${i}`] != '') {
                    capacityPerSideWidth = sides[`side${i}`];
                }
                for (let j = 0; j < capacityPerSideWidth; j++) {
                    let seat = seats.shift();
                    if (typeof seat !== 'undefined') text += seat;
                }
            } else {
                if (sides.hasOwnProperty(`side${i}`) && sides[`side${i}`] && sides[`side${i}`] != '') {
                    capacityPerSideHeight = sides[`side${i}`];
                }
                for (let j = 0; j < capacityPerSideHeight; j++) {
                    let seat = seats.shift();
                    if (typeof seat !== 'undefined') text += seat;
                }
            }

            text += "</div>";
        }
        table.querySelector(".rectangle").insertAdjacentHTML('beforeend', text);

    }

    /**
     * Circle Table
     * @param {*} table table Class 
     * @param {
        "confirmed": 15,
        "notComing": 0,
        "maybeComing": 0,
        "notResponded": 0
        } seatsData 
     */
    drawCircleTable = (table, seatsData) => {
        let capacity = table.dataset.capacity,
            text = "",
            rot = 0,
            angle = 360 / capacity,
            angleV = 85,
            shapeDom = table.querySelector(".circle");


        let seats = this.getSeatsHtml(seatsData, capacity);

        let circamfrence = capacity * (11.5) // padding + chair width
        let r = circamfrence / (2 * Math.PI);
       

        if (shapeDom)
            shapeDom.style.cssText = (`width:${r * 2}px;height:${r * 2}px;`);

        //let r = shapeDom.getBoundingClientRect().height;
        for (let i = 0; i < capacity; i++) {
            let translateV = (r + 4),
                rotateV = rot;
            let seat = seats.shift();
            let margin = capacity > 12 ? 12 : 0; // fix chairs offset
            if (typeof seat !== 'undefined') {
                seat = $(seat).attr('style', `transform: rotate(${rotateV}deg) translate(${translateV}px) rotate(${angleV}deg);margin:${0}px;`)[0]
                .outerHTML;
                text += seat;
                rot = rot + angle;
                angleV = angleV + 1;
            }

        }
        shapeDom.insertAdjacentHTML('beforeend', text);
    };


    /**
     * Sqaure Table
     * @param {*} table table object Class 
     * @param {
        "confirmed": 15,
        "notComing": 0,
        "maybeComing": 0,
        "notResponded": 0
        } seatsData 
     */
    drawSquareTable = (table, seatsData) => {
        // console.log(seatsData)
        const shapeDom = table.querySelector(".square");
        let capacity = table.dataset.capacity,
            capacityPerSide = capacity / 4,
            size = capacityPerSide * 14,
            text = "";

        let sides = {};
        if (typeof table.dataset.side1 !== 'undefined' && table.dataset.side1 !== "") {
            sides = {
                side0: (typeof table.dataset.side1 !== 'undefined' && table.dataset.side1 != '' ? table.dataset.side1 : ''),
                side1: (typeof table.dataset.side2 !== 'undefined' && table.dataset.side2 != '' ? table.dataset.side2 : ''),
                side2: (typeof table.dataset.side3 !== 'undefined' && table.dataset.side3 != '' ? table.dataset.side3 : ''),
                side3: (typeof table.dataset.side4 !== 'undefined' && table.dataset.side4 != '' ? table.dataset.side4 : ''),
            };
        } else {
            sides = {
                side0: (typeof table.parentElement.dataset.side1 !== 'undefined' && table.parentElement.dataset.side1 != '' ? table.parentElement.dataset.side1 : ''),
                side1: (typeof table.parentElement.dataset.side2 !== 'undefined' && table.parentElement.dataset.side2 != '' ? table.parentElement.dataset.side2 : ''),
                side2: (typeof table.parentElement.dataset.side3 !== 'undefined' && table.parentElement.dataset.side3 != '' ? table.parentElement.dataset.side3 : ''),
                side3: (typeof table.parentElement.dataset.side4 !== 'undefined' && table.parentElement.dataset.side4 != '' ? table.parentElement.dataset.side4 : ''),
            };
        }

        let seats = this.getSeatsHtml(seatsData, capacity);

        if (shapeDom)
            shapeDom.style.cssText = (`width:${size}px;height:${size}px;line-height:${size - 2}px;`); //line-height:${size-2}px;

        for (let i = 0; i < 4; i++) {
            text += `<div class='tableSide side${i}'>`;
            if (sides[`side${i}`] && sides[`side${i}`] !== '') {
                capacityPerSide = sides[`side${i}`];
            }

            for (let j = 0; j < capacityPerSide; j++) {
                let seat = seats.shift();
                if (typeof seat !== 'undefined') text += seat;
            }

            //if left any un splitable seats
            if (i === 4 && seats.length > 0) {
                text += seats.join("");
            }
            text += "</div>";
        }
        shapeDom.insertAdjacentHTML('beforeend', text);
    }
}


// export { ShapeClass, SeatClassByStatus, getSeatsHtml }