<template>
    <div class="inputWrap">
        <label :for="label">{{ $t(label) }}</label>
        <div class="fileInputWrap">
            <input multiple v-bind:onchange="modal ? 'window.uploadFile(this)' : ''" type="file" class="fileUploadInput" :id="label" name="file" v-bind:accept="extensions.join(',')" data-alias=".files_names" @change="uploadFile" v-bind:required="required">
            <span class="files_names" v-bind:data-placeholder="$t(placeHolder)"></span>
            <svg class="i_files"><use xlink:href="#i_files"></use></svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FileUpload",
        props:{
            autoUpload:{
                type:Boolean,
                default: true
            },
            label:{
                type:String,
                default:'fileUpload'
            },
            placeHolder:{
                type:String,
                default:'fileUpload'
            },
            extensions:{
                type:Array,
                default:function(){
                    return ['.png','.gif','.bmp','.jpeg','.jpg'];
                }
            },
            required:{
                type:Boolean,
                default:false
            },
            modal:{
                type:Boolean,
                default:false
            }
        },
        mounted(){
            niceInputFile($("input[type='file']"));
            if(this.modal){
                let _this = this;
                window.uploadFile = function(_){
                    _this.uploadFile()
                }
            }
        },
        methods:{
            uploadFile(){
                debugger;
             

                let formData = new FormData();
                let file = document.getElementById(this.$props.label);
                formData.append("file", file.files[0]);
                if(typeof file.files[0] ==='undefined') return;

                this.$store.dispatch('uploadFile',formData)
                    .then(response => {
                        this.$emit('uploaded',response.id,this.$props.label);
                    })
                    .catch(err => {
                        this.$swal({
                            confirmButtonText:this.$t('okButton'),
                            type:'error',
                            title:this.$t('uploadFailed'),
                            timer:3000
                        }).catch(this.$swal.noop);
                        console.warn('failed to upload file ' + err);
                    })
            },
        }
    }
</script>
