<template>
    <div class="tableStatuses">
        <div class="innerMainContent" v-if="!loading">
            <div class="innerHeader">
                <div class="h1Wrap" style="width: 50%;">
                    <svg class="i_calender"><use xlink:href="#i_calender"></use></svg>
                    <div class="innerH1Wrap">
                        <h1>{{ `${$t('page_title_table_statsues')} - ${event.name}` }}</h1>
                        <ul class="breadcrumb">
                            <li><router-link to="/">{{ $t('page_title_dashboard') }}</router-link></li>
                            <li><router-link v-bind:to="'/event/wedding/'+event.id">{{ $t('event') }}</router-link></li>
                            <li class="active">{{ $t('page_title_table_statsues') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="editEventActions">
                    <button class="addEvent" @click="getEventData()">
                        <svg><use xlink:href="#i_download"></use></svg>
                        <span>{{ $t('refreshData') }}</span>
                    </button>
                </div>
            </div>
            <div class="innerBox filterBox">
                <form @submit.prevent="">
                    <h3>
                        <svg><use xlink:href="#i_filter"></use></svg>
                        <span>{{ $t('filterTables') }}</span>
                    </h3>
                    <div class="inputWrap">
                        <label for="tableNum">{{ $t('tableNum') }}</label>
                        <div class="selectWrap">
                            <select name="tableNum" id="tableNum" v-model="tableNum">
                                <option value="" selected="selected">{{ $t('select') }}</option>
                                <option v-for="table of tables.filter(table=>table.tableNum !== '').sort((tblA,tblB)=>tblA.tableNum-tblB.tableNum)" v-bind:value="table.dbID">{{ table.tableNum }}</option>
                            </select>
                            <svg class="i_select"><use xlink:href="#i_select"></use></svg>
                        </div>
                    </div>
                    <div class="inputWrap">
                        <label for="group">{{ $t('group') }}</label>
                        <div class="selectWrap">
                            <select name="group" id="group" v-model="group">
                                <option value="" selected="selected">{{ $t('select') }}</option>
                                <option v-for="group of event.groups">{{ group }}</option>
                            </select>
                            <svg class="i_select"><use xlink:href="#i_select"></use></svg>
                        </div>
                    </div>
                    <div class="orDivider">{{ $t('or') }}</div>
                    <div class="inputWrap searchWrapper">
                        <input type="search" v-bind:placeholder="$t('guestName') + '...'" v-model="guest">
                        <button class="searchEvent">
                            <svg><use xlink:href="#i_search"></use></svg>
                            <span class="sr-only">{{ $t('searchEvent') }}</span>
                        </button>
                    </div>

                    <div class="clearfix">
                        <h3>
                            <span>{{ $t('currentArrivalStatus') }}</span>
                        </h3>
                        <div>
                            <ul>
                                <li><strong>{{ arrived }}</strong> {{ $t('arrived') }}</li>
                                <li><strong>{{ seated }}</strong> {{ $t('seated') }}</li>
                                <li><strong>{{ percentage }}%</strong> {{ $t('attendance') }}</li>
                            </ul>
                        </div>
                    </div>

                </form>

            </div>
            <div class="col-xs-12 tables">
                <TableItem v-for="table of tablesFiltered()" :table="table" :searchInput="guest" :key="table.dbID" v-on:guestMark="guestMark" v-on:hideAll="hideAll"></TableItem>
            </div>
        </div>
        <div class="innerMainContent" v-if="loading" style="text-align: center">
            {{ $t('loading') }}
        </div>
    </div>
</template>

<script>
    import TableItem from './TableItem'
    export default {
        name: "TablesStatus",
        data(){
            return{
                guest:'',
                loading:true,
                tableNum:'',
                group:'',
                event:{presetData:[]},
                backgroundDataRefresh:null,
                intervalTiming:10000,
                tables:[]
            }
        },
        computed:{
            arrived(){

                let arrived = 0;
                if(this.tables.length > 0){
                    this.tables.forEach(table => {
                        arrived += table.arrived;
                    });
                }

                return arrived;
            },

            seated(){

                let seated = 0;
                if(this.tables.length > 0){
                    this.tables.forEach(table => {
                        seated += parseInt(table.seated.split("/")[0]);
                    });
                }

                return seated;
            },

            percentage(){
                let percentage = parseInt((this.arrived/this.seated)*100);
                return isNaN(percentage) ? 0 : percentage;
            }
        },
        components:{
            TableItem
        },
        beforeDestroy(){
            clearInterval(this.backgroundDataRefresh);
        },
        destroyed(){
            clearInterval(this.backgroundDataRefresh);
        },
        beforeMount(){
            const eventID = this.$router.currentRoute.params.eventID;
            if(typeof eventID !== 'undefined' && eventID !== ''){
                this.event.id = eventID;
                this.getEventData(true);
            } else {
                this.$router.push('/');
            }
        },
        methods:{
            setTables(){
                this.tables = this.event.presetData.filter(_item=>_item.type === 'table');
            },

            tablesFiltered(){
                return this.tables.filter(_item => {
                    if(this.group !== '' && !_item.groups.includes(this.group)) return false;
                    if(this.tableNum !== '' && _item.dbID.toString() !== this.tableNum.toString()) return false;
                    if(this.guest !== '' && _item.guests.filter(_guest=>_guest.fullName.toString().includes(this.guest)).length === 0) return false;
                    return true;
                }).sort((a, b) => (b.guests || []).length - (a.guests || []).length);
            },

            getEventData(initial = false){
                const _this = this;
                this.$store.dispatch('getEventWithGuests',this.event.id)
                    .then(response =>{
                        //success
                        setTimeout(()=>{
                            response.data.event.presetData = response.data.event.presetData.sort((a,b)=>a.guests.length-b.guests.length)
                            _this.event = response.data.event;
                            _this.setTables();
                            setTimeout(()=>{ $('.matchHeight').matchHeight() },1000);
                            if(initial){
                                setTimeout(function(){
                                    _this.backgroundDataRefresh = setInterval(_this.getEventData,_this.intervalTiming);
                                },3000);
                                _this.loading = false;
                            }
                        },1500)
                    })
                    .catch(err => {
                        console.error(err);
                        //err
                        this.$swal({
                            confirmButtonText:this.$t('okButton'),
                            type:'error',
                            title:this.$t('fatalError'),
                            timer:3000
                        }).catch(this.$swal.noop);
                        this.$router.push('/');
                    })
            },

            guestMark(guest){
                let table = {guests:[]};
                this.event.presetData.some(_table => {
                    if(_table.guests.some(g=>g.id === guest)){
                        table = _table;
                        return true;
                    }
                });
                if(table.hasOwnProperty("guests") && table.hasOwnProperty("dbID")){
                    table.guests.some(_guest => {
                        if(_guest.id === guest){
                            _guest.arrived = _guest.arrived ? false : true;

                            const modification = {id:_guest.id,arrived:_guest.arrived, event:this.event.id};
                            this.$store.dispatch('updateGuest',modification)
                                .then(response =>{
                                    //success
                                    this.getEventData();
                                });

                            return true;
                        }
                    });
                }
            },

            hideAll(e){
                try {
                    if (e.path.map(_ => _.className).toString().toLowerCase().includes('tableitem')) {
                        return;
                    }
                } catch(e){
                    //i dont care
                }
                if(typeof this.$children === 'object'){
                    this.$children.forEach(obj => {
                        if(obj.hasOwnProperty('show')) obj.show = false;
                    })
                }
            }
        }
    }
</script>

<style>

    .tableStatuses .tables .tableItem{
        padding-right:0px;
        padding-left:0px;
        padding-top:10px;
        cursor: pointer;
    }
    .tableStatuses .tables .tableFooter svg.i_longArr{
        fill:#2A3A5B;
    }
    .tableStatuses .tables .tableFooter svg.i_longArr{
        height:10px;
        width:20px
    }
    .tableStatuses .tables .tableFooter{
        background-color: #ECECEC;
        font-size:13px
    }
    .tableStatuses .tables .tableData{
        font-size: 15px;
        padding-bottom: 5px;
    }
    .tableStatuses .tables .tableData ul li span{
        font-weight: bold;
        color: #8FA7CB;
    }
    .tableStatuses .tables .tableHeader .border{
        border-top: #8FA7CB 2px solid;
        line-height: 5px;
    }
    .tableStatuses .tables .tableHeader{
        padding-left: 0px;
        padding-right: 0px;
    }
    .tableStatuses .tables .tableHeader .arrivalStatus{
        font-size: 15px;
        vertical-align: 3px;
    }
    .tableStatuses .tables .tableHeader .arrivalStatus span:nth-child(1){
        opacity: .7;
    }
    .tableStatuses .tables .tableHeader .arrivalStatus span+span{
        font-weight: bold;
        font-style: italic;
    }
    .tableStatuses .tables .tableHeader svg{
        width:17px;
        height:17px;
    }
    .tableStatuses .tables .tableHeader .i_table{
        stroke:black;
        width:20px;
        height:20px;
    }
    .tableStatuses .tables .tableHeader span.tableNum{
        vertical-align: 4px;
        font-weight: bold;
    }

    .tableStatuses .tables .tableItem{
        -webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.13);
        box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.13);
        background: #fff;
        border: 2px solid #f3f3f3;
        float: right;
    }
    .tableStatuses .innerMainContent{
        padding:55px 45px;
        float: right;
        width:100%;
    }

    .tableStatuses .editEventActions{
        float:left;
        margin-top: 6px;
    }
    .tableStatuses .editEventActions .backBtn span{
        color:#7c97c5;
        font-weight:700;
        font-size:1.235rem;
        display:inline-block;
        vertical-align:middle;
    }
    .tableStatuses .editEventActions .backBtn svg{
        display:inline-block;
        vertical-align:middle;
        width:26px;
        height:18px;
        fill:#293b5a;
        margin-right:5px;
    }

    .tableStatuses .tooltiptopicevent{

        width: 260px;
        position: absolute;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.09);
        background: #fff;
        transform: translate(-58%,26px);
    }
    .tableStatuses .tooltipHeader{
        background: #f2f2f2;
        border-bottom:1px solid #b1e2f9;
        text-align: left;
        padding: 10px;
    }
    .tableStatuses .tooltipHeader:before{
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #f2f2f2;
        position: absolute;
        bottom: 100%;
        right: calc(50% - 6px);
    }
    .tableStatuses .tooltipHeader .closeBtn button{
        display:inline-block;
        vertical-align: middle;
    }
    .tableStatuses .tooltipHeader .closeBtn svg{
        width:15px;
        height:15px;
        fill:#969696;
    }
    .tableStatuses .tooltipHeader .editEventBtn svg{
        width:14px;
        height:15px;
        fill:#293b5a;
    }
    .tableStatuses .tooltipHeader .deleteEventBtn svg{
        width:13px;
        height:17px;
        fill:#969696;
    }
    .tableStatuses .tooltipTitle{
        text-align: right;
    }
    .tableStatuses .tooltipTitle span{
        color:#7994c2;
        font-weight: bold;
        font-size:0.8824rem;
    }
    .tableStatuses .tooltipTitle span + span{
        color:#293b5a;
        font-weight: bold;
        font-size:0.8824rem;
        font-style: italic;
    }
    .tableStatuses .tooltipContent ul{
        padding: 7px 0;
    }
    .tableStatuses .tooltipContent ul li {
        padding: 2px 15px;
    }
    .tableStatuses .tooltipContent ul li svg{
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        width:16px;
        height:16px;
        fill:#848484;
    }

    .tableStatuses .tooltipContent ul li span{
        display: inline-block;
        vertical-align: middle;
        color:#848484;
        font-size: 0.7647rem;
    }
    .tableStatuses .addEvent{
        width: 181px;
        height: 42px;
        border: 1px solid #52dbcd;
        border-radius: 21px;
        background: -moz-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
        /* ff3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(41, 59, 90, 1)), color-stop(100%, rgba(123, 150, 196, 1)));
        /* safari4+,chrome */
        background: -webkit-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
        /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
        /* opera 11.10+ */
        background: -ms-linear-gradient(0deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
        /* ie10+ */
        background: linear-gradient(90deg, rgba(41, 59, 90, 1) 0%, rgba(123, 150, 196, 1) 100%);
        /* w3c */
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#293b5a', endColorstr='#7b96c4', GradientType=1 );
        /* ie6-9 */
    }
    .tableStatuses .addEvent svg{
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        fill: #fff;
        margin-left: 10px;
    }
    .tableStatuses .addEvent span{
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        font-size: 0.8235rem;
        font-weight: 700;
        color:#fff;
    }
    .tableStatuses .innerBox.filterBox{
        margin-bottom:20px;
        padding:23px;
    }

    .tableStatuses .innerBox.filterBox h3{
        display: inline-block;
        vertical-align: middle;
    }
    .tableStatuses .innerBox.filterBox h3 span{
        color:#293b5a;
        font-size: 1rem;
        display: block;
        font-weight: 700;
        margin-top:10px;
    }
    .tableStatuses .innerBox.filterBox h3 svg{
        width:24px;
        height:24px;
        display: block;
        margin:0 auto;
        fill:#293b5a;
    }
    .tableStatuses .innerBox.filterBox .inputWrap{
        display: inline-block;
        vertical-align: middle;
        margin-right:15px;
        width:250px;
    }
    .tableStatuses .innerBox.filterBox  .inputWrap + .inputWrap{
        margin-top:0;

    }
    .tableStatuses .innerBox.filterBox .inputWrap label{
        color:#293b5a;
        font-size: 0.8824rem;
        font-weight: bold;
    }
    .tableStatuses .innerBox.filterBox .inputWrap input,
    .tableStatuses .innerBox.filterBox .inputWrap select{
        background:#fff;
        color:#434343;
    }
    .tableStatuses .innerBox.filterBox .inputWrap select#eventStatus{
        background: #f6f6f6
    }
    .tableStatuses .innerBox.filterBox .inputWrap.searchWrapper{
        width:335px;
    }
    .tableStatuses .innerBox.filterBox .inputWrap.searchWrapper input{
        padding-left: 40px;
    }
    .tableStatuses .innerBox.filterBox .inputWrap.searchWrapper .searchEvent{
        position: absolute;
        top: calc(50% - 10px);
        left: 20px;
    }
    .tableStatuses .innerBox.filterBox .inputWrap.searchWrapper .searchEvent svg{
        fill: #293b5a;
        width: 18px;
        height: 20px;
        display: block;
    }
    .tableStatuses .orDivider{
        display: inline-block;
        height: 54px;
        width: 54px;
        line-height: 54px;
        text-align: center;
        border-radius: 50%;
        background: #e0e0e0;
        color: #414141;
        font-size: 1rem;
        font-style: italic;
        margin: 0px 15px;
        vertical-align: bottom;
    }
    .tableStatuses .searchWrapper input[type="search"]{
        width:100%;
        height:unset;
        padding-bottom:unset;
    }
    .tableStatuses .searchWrapper{
        margin-top: 25px;
    }
    .eventProgress span:not(.progressBar):not(.progressNumber){
        width:170px;
        height:5px;
        border-radius:2.5px;
        background: #e2e2e2;
        display: inline-block;
        vertical-align: middle;
        margin-right:10px;
        position: relative;
    }
    .eventProgress span span{
        position:absolute;
        top:0;
        left:0;
        height:5px;
        border-radius:2.5px;
        background: #000;
    }
    .tableItem .tableGuests{
        position: absolute;
        z-index: 999;
        background-color: #fff;
        top: 35%;
        border: #8FA7CB 2px solid;
        padding:10px 10px 10px 10px;
        /*height: 300px;
        overflow: scroll;*/
    }
    .tableItem .tableGuests table thead{
        border-bottom: #8FA7CB 2px solid;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .tableItem .tableGuests table tr+tr{
        border-top: #8FA7CB 1px solid;
    }
    .tableItem .tableGuests table svg{
        height:15px;
        width:15px;
    }
    .tableItem{
        margin-bottom: 20px;
    }
</style>
