<template>
  <div class="row no-gutters">

    <!-- Select Menu -->
    <div class="s-box col-sm-12 col-md-4 p-4" v-if="$store.state.EventItemStore.itemCategories">

      <h2 class="mb-3">{{ $t("ChooseMenu") }}</h2>

      <div v-for="category in $store.state.EventItemStore.itemCategories" v-bind:key="category.id" @click="selectedCategory = category.id">
        <div :class="'dishCat ' + (selectedCategory == category.id ? 'selected' : '')">
          <h3>{{ category.name }}</h3>
          <div class="d-flex my-3" v-if="limit">
            <span>{{ filterByCategory(selected_dishes, category.id).length }}</span>
            <span> / </span>
            <span v-if="eventPlanner.categoryDishLimit">{{ getCategoryLimit(category.id) }}</span>
            <span> &nbsp; {{ $t("selected") }}</span>
          </div>

          <div class="d-flex wrap">
            <span v-for="s in filterByCategory(selected_dishes, category.id)" v-bind:key="s.id" class="selected_dish" @click="removeDish(s, category.name)">
              X {{ s.name }}
            </span>
          </div>
        </div>
        <hr />
      </div>

      <div class="inputWrap textareaWrap">
        <textarea :placeholder="$t('notes')" v-model="eventPlanner.dishNotes"></textarea>
        <button @click="saveNotes" class="s_btn">שמור הערות</button>
      </div>
    </div>

    <!-- Select Dish -->
    <div class="col-sm-12 col-md-8">
      <div class="p-4">
        <div class="d-flex items-center">

          <h3>{{ $t("ChooseDishes") }}</h3>
        </div>
        <hr />
        <ItemTiles :items="dishes.filter((x) => x.categoryId == selectedCategory)" @itemAdded="addItem" :selected_items="selected_dishes" :showPrice="true" missingIconClass="fas fa-hamburger"></ItemTiles>
      </div>
    </div>
  </div>
</template>

<script>

import ViewItem from "../EventItem/ViewItem.vue";
import ItemTiles from "../EventItem/ItemTiles.vue";
import { apiRequest } from '../../core/http/axios_api_v2';
export default {
  name: "MenuSelect",

  components: { ViewItem, ItemTiles },

  computed: {
    dishes: function () {

      return (this.$store.getters.getDishes || []).filter(
        (x) => {
          if (this.category)
            return x.categoryId == this.category && x.outofstock == 0
          return x.outofstock == 0
        }

      );
    },
    selected_dishes: function () {
      return this.eventPlanner.eventItems || [];
    },
  },
  // watch: {
  //   'eventPlanner.categoryDishLimit': function(){
  //     console.log('yyy',this.eventPlanner.categoryDishLimit);
  //     this.$forceUpdate();
  //   }
  // },
  props: {
    eventPlanner: {
      type: Object,
      default: () => {
        return {
          categoryDishLimit: [],
        };
      },
    },
    limit: {
      type: Boolean,
      default: true

    },
  },
  data() {
    return {
      step: 1,
      selectedCategory: "",
      //selected_dishes: [],
      selectedDish: null,
    };
  },

  beforeMount() {
    this.$store.dispatch("getAllEventItems", "food");
    this.$store.dispatch("EventItemCategories").then((c) => {
      this.selectedCategory = c[0].id;
    });
  },

  methods: {

    saveNotes() {
      this.$store.dispatch('saveEventPlanner', {
        id: this.eventPlanner.id,
        dishNotes: this.eventPlanner.dishNotes
      }).then(r => {
        this.$notify(this.$t('save'))
      })
    },

    getCategoryLimit(catId) {

      if (!this.eventPlanner.categoryDishLimit) return 0;

      let limit = this.eventPlanner.categoryDishLimit[catId]
      if (!limit) return 0;
      return limit;
    },

    filterByCategory(items, categoryId) {
      if (!Array.isArray(items)) return [];
      return items.filter((x) => x.type == 'food' && x.categoryId == categoryId);
    },
    removeDish(dish) {
      let i = this.selected_dishes.findIndex((x) => {
        return x.id == dish.id;
      });

      this.selected_dishes.splice(i, 1);
      this.$forceUpdate();
    },
    addItem(dish) {

      if (this.eventPlanner == null || typeof this.eventPlanner.id === 'undefined') {
        this.AlertError("בניית אירוע לא מוגדר על אירוע זה");
        return
      }

      if (
        !this.selected_dishes.find((x) => {
          return x.id == dish.id;
        })
      ) {
        //check if limit reached
        if (
          this.limit &&
          this.getCategoryLimit(dish.categoryId) <=
          this.filterByCategory(this.selected_dishes, dish.categoryId).length
        ) {
          this.AlertError("עברת את מכסת המנות");
          return;
        }

        this.selected_dishes.push(dish);

        apiRequest('post',`event-planner/${this.eventPlanner.id}/add-item`,dish)
          .then(r=> this.$forceUpdate());
        
      } else {
        this.$store.dispatch("restPost", {
          route: `event-planner/${this.eventPlanner.id}/remove-item`,
          data: dish,
        });
        this.removeDish(dish);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dishCat {
  padding: 10px;
  border-radius: 30px;

  &.selected {
    background: #f8f8f8;
  }
}

#event_btn_nav {
  width: 100%;

  button {
    background: #f8f8f8;
    border: #ccc;

    padding: 3px;
    margin: 3px;
    display: inline-block;
  }
}

.dish {
  background: #f8f8f8;
  border: #ccc;
  padding: 3px;
  margin: 3px;
  cursor: pointer;

  &-name {
    text-align: center;
  }

  &.selected {
    background: lightblue;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .dish-image {
    max-height: 120px;
  }
}

.selected_dish {
  background: white;
  border-bottom: 2px solid #293b5a;
  padding: 3px;
  margin: 3px 5px;
  border-radius: 2px;
  cursor: pointer;
  white-space: nowrap;
}
</style>