<template>
  <form action="" id="editBanner" class="formWrap" @submit.prevent="submitForm">
    <h2>הגדרות עסק</h2>

    <div class="row">
      <div class="col-xs-12 col-md-4">

        <div class="d-flex items-center">
           <div class="ml-3">
            <img :src="FileStoragePath(userSettings.attrs.logo)" style="width:80px;height:80px;object-fit:cover">
          </div>
          <div class="flex-fill">
            <label>לוגו</label>
            <FileManager @onUploaded="setLogo" :autoUpload="true"></FileManager>
          </div>
         
        </div>
        <hr />
        <div>
          <div class="inputWrap">
            <label>שם עסק</label>
            <input type="text" placeholder="שם העסק" v-model="userSettings.businessName" required />
          </div>
        </div>

        <div>
          <div class="inputWrap">
            <label>מספר ח.פ</label>
            <input type="text" placeholder="מספר ח.פ" v-model="userSettings.vatNumber" required />
          </div>
        </div>

        <div>
          <div class="inputWrap">
            <label>טלפון עסק</label>
            <input type="text" placeholder=" טלפון עסק" v-model="userSettings.businessPhone" required />
          </div>
        </div>

        <div>
          <FormInput label="כתובת עסק" v-model="userSettings.attrs.businessAddress" placeholder="כתובת עסק"></FormInput>
          <!-- <label>כתובת עסק</label>
          <div class="inputWrap">
            <input
              type="text"
              placeholder="כתובת עסק"
              v-model="userSettings.attrs.businessAddress"
            />
          </div> -->
        </div>
        <div>
          <FormInput type="email" label="אימייל עסק" v-model="userSettings.attrs.businessEmail" placeholder="אימייל עסק"></FormInput>
        </div>
        <hr>
        <h3>{{ $t('billing') }}</h3>
        <FormInput label="Billing key" v-model="userSettings.attrs.billing_api_key"></FormInput>
        <FormInput label="Billing secret" v-model="userSettings.attrs.billing_api_secret"></FormInput>
        <FormInput label="Brand UID" v-model="userSettings.attrs.billing_api_brand_uuid"></FormInput>
        <hr>
        <h3>{{ $t('invoice') }}</h3>
        <FormInput type="number" label="מספר עסקה נוכחי" v-model="userSettings.orderNumbering" placeholder="0000001" required></FormInput>

      </div>

      <div class="col-xs-12 col-md-8">
        <div class="s-tabs">

          <button class="s-tab" type="button" @click="tab = 1" :class="tab == 1 ? 'selected' : ''">
            <h3>חוזה</h3>
          </button>
          <button class="s-tab" type="button" @click="tab = 2" :class="tab == 2 ? 'selected' : ''">
            <h3>הצעת מחיר</h3>
          </button>
          <button class="s-tab" type="button" @click="tab = 3" :class="tab == 3 ? 'selected' : ''">
            <h3>חוזה התחייבות</h3>
          </button>

        </div>
        <div>
          <!-- Contract -->
          <div v-show="tab == 1">
            <TextEditor ref="contractEditor" v-model="userSettings.contract"></TextEditor>
          </div>

          <!-- Quote -->
          <div v-show="tab == 2">
            <TextEditor ref="quoteTermsEditor" v-model="userSettings.quoteTerms"></TextEditor>
          </div>
          <!-- Quote -->
          <div v-show="tab == 3">
            <TextEditor ref="commitmentTerms" v-model="userSettings.commitmentTerms"></TextEditor>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="inputWrap submitWrap">
          <button class="submit">{{ $t("save") }}</button>
        </div>

      </div>
    </div>
  </form>
</template>

<script>
import axiosV2 from '../../../core/http/axios_api_v2';
import FileManager from "../../Common/FileManager";
import TextEditor from "../../Common/TextEditor";

export default {
  name: "AppSettingsTab",
  components: {
    FileManager, TextEditor

  },
  data() {
    return {
      userSettings: {
        contract: "",
        vatNumber: "",
        businessName: "",
        quoteTerms: "",
        attrs: {}
      },
      tab: 1,
    };
  },

  mounted() {
    this.$store.dispatch('getUserSettings').then(_settings => {
      this.userSettings = _settings
    })
   
  },
  methods: {
    setLogo(file) {
      const attrs = { ...this.userSettings.attrs, ...{ logo: file.file.filePath } }
      this.$store
        .dispatch("saveUserSettings", { id: this.userSettings.id, attrs })
        .then((response) => {
          this.AlertSuccess(this.$t("appSettingsUpdated"));
        })
        .catch((err) => {
          this.AlertError(this.$t("fatalError"));
        });

    },
    submitForm() {
      this.$store
        .dispatch("saveUserSettings", this.userSettings)
        .then((response) => {
          this.AlertSuccess(this.$t("appSettingsUpdated"));
        })
        .catch((err) => {
          this.AlertError(this.$t("fatalError"));
        });

    },


  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  height: 100vh;
}

.col-md-4>div {
  margin-top: 10px;
}
</style>
