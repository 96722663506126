<template>
  <div class="innerHeader d-flex">

    <div class="h1Wrap">

      <slot name="header-icon" v-if="icon">
        <svg :class="icon">
          <use :xlink:href="'#' + icon"></use>
        </svg>
      </slot>

      <div class="innerH1Wrap">
        <h1>{{ title }}</h1>
        <ul class="breadcrumb">
          <li>
            <router-link to="/">{{ $t("menu_dashboard") }}</router-link>
          </li>
          <li v-for="crumb of breadcrumbs">
            <router-link v-if="crumb.route" :to="crumb.route">{{
                crumb.name
            }}</router-link>
            <span v-else>{{ crumb.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="page-header-actions editEventActions">
      <slot name="action-buttons"></slot>

      <button v-if="showBack" class="backBtn flex-fill hidden-xs hidden-sm mr-4" @click="$router.go(-1)">
        <i class="fa fa-chevron-left" />

      </button>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {

    };
  },
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    breadcrumbs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {},
  mounted() {
    //this.$store.dispatch('getPandaLogs');
    //  this.$store.dispatch('inbox');
  },
  methods: {},

  components: {},
};
</script>


<style scoped lang="scss">
.innerHeader {
  flex-wrap: wrap;

  div:first-child {
    align-items: stretch;
    flex: 1;
  }
}

.h1Wrap {

  display: flex;
  gap: 10px;
  align-content: center;

  h1 {
    margin: 0;
  }
  i{
    margin-top:10px;
  }
}

.s_btn {
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .h1Wrap {
    width: 100%;
  }

  .page-header-actions {
    margin: 10px 0 0 0;
    display: flex;
    width: 100%;

    button {
      padding: 5px;
      width: auto;

    }

    .s_btn {
      height: auto;
      border-radius: 10px;

      svg {
        width: 100%;
      }
    }
  }
}
</style>
