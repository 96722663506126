<template>
    <!--MODALS START-->
    <div id="modal-custom" class="modal">
        <button data-iziModal-close class="icon-close">
            <svg><use xlink:href="#i_close"></use></svg>
            <span class="sr-only">{{ $t('close') }}</span>
        </button>
        <div>
            <h2>{{ $t('contactModalTitle') }}</h2>
            <h3>
                <svg><use xlink:href="#i_contact_grad"></use></svg>
                <span>{{ $t('callUs') }} {{ contactPhone }}</span>
            </h3>

            <form action="" id="contactForm" onsubmit="return window.contactSubmit()">
                <div class="inputWrap">
                    <label for="contactSubject">{{ $t('contactSubject') }}</label>
                    <input type="text" id="contactSubject" name="contactSubject" required>
                </div>
                <div class="inputWrap textareaWrap">
                    <label for="contactContent">{{ $t('howCanWeHelp') }}</label>
                    <textarea name="contactContent" id="contactContent" required></textarea>
                    <span class="charsLength"><span id="charNum">0</span>/200</span>
                </div>
                <div class="inputWrap submitWrap">
                    <button type='submit' class="submit">{{ $t('hereYouSubmit') }}</button>
                </div>
            </form>
        </div>
    </div>
    <!--MODALS END-->
</template>

<script>
    // kombina with jquery and window let because of nested child in child in child component and VueJS Bug
    import Config from '../../Config'
    import axios from 'axios'
    export default {
        name: 'ContactModal',
        computed:{
            contactPhone:function(){
                return (this.$store.state.hasOwnProperty("app") 
                && this.$store.state.app.hasOwnProperty("admin")  &&  this.$store.state.app.admin
                && this.$store.state.app.admin.hasOwnProperty("phone") 
                && this.$store.state.app.admin.phone !== "" 
                ? this.$store.state.app.admin.phone : this.$t('defaultPhone'))
            }
        },

        mounted(){
            let _this = this;
            window.contactSubmit = function(){
                axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.contact,{
                    subject:$("#contactSubject").val(),
                    content:$("#contactContent").val(),
                    userID:_this.$store.state.user.id
                })
                    .then(function (response) {
                        response = response.data;
                        if(response.status){

                            _this.$swal({
                                confirmButtonText:_this.$t('okButton'),
                                type:'success',
                                title:_this.$t('contactSuccess'),
                                timer:3000
                            })
                                .then(response =>{
                                    //close modal
                                    $("#modal-custom").iziModal('close');
                                })
                                .catch(_this.$swal.noop);

                        }else{
                            _this.$swal({
                                confirmButtonText:_this.$t('okButton'),
                                type:'error',
                                title:_this.$t('contactFailed'),
                                timer:3000
                            }).catch(_this.$swal.noop);
                        }
                    })
                    .catch(function(error){
                        _this.$swal({
                            confirmButtonText:_this.$t('okButton'),
                            type:'error',
                            title:_this.$t('contactFailed'),
                            timer:3000
                        }).catch(_this.$swal.noop);
                    });
                return false;
            }
        }

    }

</script>
