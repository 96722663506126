import ROLES_NUM from '../../core/auth/roles.enum';

export default [
    {
        path: '/root-admin',
        name: 'root-admin-dashboard',
        component: () =>  import('@/root-admin/Index'),
        meta: {
            requiresAuth: true,
            userInRole: [ROLES_NUM.Admin],
            matchHeight: true
        }
    }, 
    {
        path: '/root-admin/tenants',
        name: 'root-admin-tenants',
        component: () =>  import('@/root-admin/Tenants'),
        meta: {
            requiresAuth: true,
            userInRole: [ROLES_NUM.Admin],
            matchHeight: true
        }
    }, 
]