<template>
  <section class="s-box p-4">
    <h2 class="mb-3">החלפת אולם ופריסה</h2>
    <div
      class="inputWrap"
      v-if="$store.state.halls && $store.state.halls.length > 0"
    >
      <label for="presetType">{{ $t("hall") }}</label>
      <div class="selectWrap">
        <select
          name="presetType"
          id="presetType"
          v-model="change_to_hall"
          @change="getPresetByHall"
        >
          <option
            v-for="preset of $store.state.halls"
            v-bind:key="preset.id"
            v-bind:value="preset.id"
          >
            {{ preset.name }}
          </option>
        </select>
        <svg class="i_select"><use xlink:href="#i_select"></use></svg>
      </div>
    </div>
    <div v-else>אין אולמות</div>

    <div class="inputWrap" v-if="presets && presets.length > 0" >
      <label for="presetType">{{ $t("choosePreset") }}</label>
      <div class="selectWrap">
        <select name="presetType" id="presetType" v-model="change_to_preset">
          <option
            v-for="preset of presets"
            v-bind:key="preset.id"
            v-bind:value="preset.id"
          >
            {{ preset.name }}
          </option>
        </select>
        <svg class="i_select"><use xlink:href="#i_select"></use></svg>
      </div>
    </div>
    <div v-else>אין פריסות</div>

    <button class="s_btn mt-3" @click.prevent="saveChange">{{ $t("save") }}</button>
  </section>
</template>

<script>
export default {
  name: "ChangeEventPreset",
  data() {
    return {
      change_to_hall: null,
      change_to_preset: null,
      presets: [],
      isSaving:false
    };
  },
  mounted() {},
  destroy() {
    delete this.presets;
  },
  methods: {
    getPresetByHall(e) {
      const hallId = e.target.value;
      this.$store.dispatch("getPreset", "hall/" + hallId).then((r) => {
        this.presets = r;
        this.change_to_preset = null;
      });
    },
    saveChange() {
      if(this.isSaving) return
      
     
      const theEvent = this.$store.state.EventStore.event;
      const selectedHall = this.$store.state.halls.find((x) => x.id == this.change_to_hall);
      const selectedPreset = this.presets.find((x) => x.id == this.change_to_preset);

      if (!selectedPreset) {
        throw "invalid hall id";
      }
      if (!selectedPreset) {
        this.AlertError("יש לבחור תבנית")
        throw "invalid preset id";
      }
      
      if(!Array.isArray(selectedPreset.jData)){
        this.AlertError("תבנית ריקה ממידע, לא מחליף")
        return
      }
        
      const newEventPresetData = [...selectedPreset.jData];
     
      for (let prvPreset of theEvent.presetData) {
        for (let newPreset of newEventPresetData) {
          if (prvPreset.tableNum == newPreset.tableNum) {
            newPreset.dbID = prvPreset.dbID; // set previous dbId in order to keep guests seating places
          }
        }
      }
      
      this.isSaving=true

      this.$store.dispatch("updateEvent", {
        id: theEvent.id,
        hall: selectedHall,
        preset: selectedPreset,
        presetData: newEventPresetData,
      }).then(r=> {
          this.isSaving = false
        
          this.$emit('saved',  {
                    hall: selectedHall,
                    preset: selectedPreset,
                    presetData: newEventPresetData,
           })
           this.AlertSuccess()
        });
    },
  },
};
</script>

<style scoped>
.s-box {
    max-width: 320px;
}
</style>