import PresetBuilder from "../PresetBuilder";

export class PresetBuilderItemSelect {

    /**
     * 
     * @param {PresetBuilder} presetBuilder 
     */
    constructor(presetBuilder) {
        this.selected = {}
        this.presetBuilder = presetBuilder
        this.onSelectHandler = null
        this.areaId = 'item-area-select'
        this.selectAreaStart
        this.selectAreaEnd
        this.isMousedown = true
        this.ctrlPressed = false
        this.presetBuilder.getItemsDOM().forEach(item => {
            item.addEventListener('mousedown', (evt) => { evt.stopPropagation();this.itemClick(evt) })
        });

        this.areaSelect()
    }
    applyScale(value) {
        return value / this.presetBuilder.scale
    }
    getPosFromEvent(e) {
        var rect = e.currentTarget.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.
        
        return { x: this.applyScale(x), y: this.applyScale(y) }
    }
    moreThenOneSelected() {
        return Object.keys(this.selected).length > 1
    }
    areaSelect() {

        /*   * Area SVG *  */
        const container = this.presetBuilder.getContainerDOM()
        let line = `<svg style="overflow:visible;z-index:0;position:absolute;top:0;left:0;pointer-events: none;">`
        line += ` <polygon id="${this.areaId}" width="0" height="0" x="0" y="0" style="display:none;fill:cyan;opacity:0.5;stroke:green;stroke-width:2;" /> `
        line += ' </svg>'
        container.insertAdjacentHTML("beforeend", line);

        const area = document.querySelector(`#${this.areaId}`)


        /*  
        * Mouse Events
        * for area selection
        */
        container.addEventListener('mousedown', (evt) => {
            evt.stopPropagation();
            this.ctrlPressed = evt.ctrlKey
            if (evt.target.id != container.id) return
           
            if (!this.ctrlPressed)
                this.selected = {}
            this.selectAreaStart = this.getPosFromEvent(evt)
            this.selectAreaEnd = this.selectAreaStart
            this.isMousedown = true

        })

        container.addEventListener('mousemove', (evt) => {
            this.ctrlPressed = evt.ctrlKey
            if (!this.selectAreaStart || !this.selectAreaEnd) return
            let _p = this.getPosFromEvent(evt)

            let x = this.selectAreaStart.x,
                y = this.selectAreaStart.y,
                w = _p.x - this.selectAreaStart.x,
                h = _p.y - this.selectAreaStart.y

            let points = `${x},${y} ${x + w},${y} ${x + w},${y + h} ${x},${y + h}`
            area.setAttribute('points', points)

            if (this.isMousedown)
                area.style.display = 'block'
        })

        container.addEventListener('mouseup', (evt) => {
            area.style.display = 'none'
            this.ctrlPressed = evt.ctrlKey
            this.selectAreaEnd = this.getPosFromEvent(evt)
            if (this.isMousedown)
                this.selectedInsideArea()
            this.isMousedown = false
        })
    }
    selectedInsideArea() {

        if (!this.selectAreaStart || !this.selectAreaEnd) return

        let minX, minY, maxX, maxY
        if (this.selectAreaStart.x < this.selectAreaEnd.x) {
            minX = this.selectAreaStart.x;
            maxX = this.selectAreaEnd.x
        } else {
            minX = this.selectAreaEnd.x
            maxX = this.selectAreaStart.x;

        }

        if (this.selectAreaStart.y < this.selectAreaEnd.y) {
            minY = this.selectAreaStart.y;
            maxY = this.selectAreaEnd.y
        } else {
            minY = this.selectAreaEnd.y
            maxY = this.selectAreaStart.y;

        }

        // if ((maxX - minX) * (maxY - minY) < 150) {
        //     console.log('area too small , not selecting')
        //     return
        // }

        this.presetBuilder.getItemsDOM().forEach(item => {

            if (item.offsetLeft > minX && item.offsetLeft < maxX
                && item.offsetTop > minY && item.offsetTop < maxY) {
                const dbID = item.firstElementChild.dataset.dbid
                const tableFound = this.presetBuilder.getItem(dbID)

                if(tableFound)
                    this.selected[tableFound.dbID] = tableFound
            }

        });

        if(this.onSelectHandler) {
            this.onSelectHandler(this.getSelected())
        }

        this.markSelected()
    }
    itemClick(evt) {
        const dbID = evt.currentTarget.firstElementChild.dataset.dbid
        const tableFound = this.presetBuilder.getItem(dbID)
        if (!tableFound) {
            console.debug('table not found', dbID)
            console.log('table not found')
            return;
        }
        if (evt.ctrlKey || evt.shiftKey) {
            if (this.isSelected(tableFound.dbID) && !evt.shiftKey)
                this.removeFromSelected(tableFound.dbID)
            else
                this.addToSelected(tableFound.dbID, tableFound)
        } else {
            this.clearAllSelected()
            this.addToSelected(tableFound.dbID, tableFound)
        }

        if(this.onSelectHandler) {
            this.onSelectHandler(this.getSelected())
        }
        this.markSelected()
        //console.log('select', this.selected)
    }

    markSelected() {
       
        this.presetBuilder.getItemsDOM().forEach(item => {
            item.classList.remove("selected")
        })
        Object.keys(this.selected).forEach((id) => {
           
            document.querySelector(`[data-dbid="${id}"]`)
                .parentNode
                .classList.add("selected");
        });
    }
    addToSelected(dbid, tableObject) {
        this.selected[dbid] = tableObject
    }
    setSelected(selected) {
        this.selected = selected
       
        this.markSelected()
    }
    removeFromSelected(dbid) {
        delete this.selected[dbid]
    }
    clearAllSelected() {
        this.selected = {}
    }
    isSelected(dbid) {
        return dbid in this.selected
    }
    getSelected() {
        return this.selected
    }

    getSelectedAsArray() {
        return Object.values(this.selected)
    }

    countSelected() {
        return Object.keys(this.selected).length
    }
}