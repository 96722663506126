<template>
  <div @click="globalClick($event)">

    <div class="innerMainContent">

      <PageHeader
        icon="i_users"
        :title=" $t('usersFor_' + this.$store.state.user.type) "
        :breadcrumbs="[
          { name: $t('menu_users'), route: '/users' },
          
        ]"
      >
        <template v-slot:action-buttons>
          <router-link tag="button" class="s_btn" to="/users/add">
            <svg><use xlink:href="#i_plus"></use></svg>
            <span>{{ $t("addUser") }}</span>
          </router-link>
        </template>
      </PageHeader>
    


      <div class="closeEvents innerBox">
       
          <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="true"
            :totalRows="totalRecords"
            :search-options="{
              enabled: true,
              placeholder: $t('search'),
            }"
            :pagination-options="{
              enabled: true,
              nextLabel: $t('next'),
              prevLabel: $t('prev'),
              rowsPerPageLabel: $t('Rows per page'),
              ofLabel: $t('of'),
              pageLabel: $t('page'), // for 'pages' mode
              allLabel: $t('all'),
            }"
            styleClass="vgt-table condensed"
             @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-search="onSearchChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <button
                  @click.prevent="showActions($event, props.formattedRow.id)"
                  style="
                    transform: rotate(90deg);
                    font-size: 40px;
                    display: block;
                  "
                >
                  ...
                </button>
              </span>
              <span v-else-if="props.column.field == 'type'">
                {{ $t("userType_" + props.formattedRow[props.column.field]) }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

          <div class="eventActionDropDown" v-bind:style="actionsStyle">
            <ul>
              <li>
                <router-link
                  class="permissionUser"
                  v-bind:to="'users/' + currentID + '/permissions'"
                >
                  <svg class="i_editEvent">
                    <use xlink:href="#i_contact"></use>
                  </svg>
                  <span>{{ $t("permissions") }}</span>
                </router-link>
              </li>
              <li>
                <router-link class="editUser" v-bind:to="'users/' + currentID">
                  <svg class="i_editEvent">
                    <use xlink:href="#i_contact"></use>
                  </svg>
                  <span>{{ $t("edit") }}</span>
                </router-link>
              </li>
              <li> <a @click="LoginAs(currentID)" v-if="$store.state.user.id === 2">
                  <span>התחבר כזוג</span>
                </a></li>
              <li>
               
                <a class="deleteUser" @click="deleteUser()">
                  <svg class="i_deleteEvent">
                    <use xlink:href="#i_contact"></use>
                  </svg>
                  <span>{{ $t("delete") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import PageHeader from "../Common/PageHeader.vue";
import axiosV2 from "../../core/http/axios_api_v2";
export default {
  name: "Users",
  data() {
    return {
      actionsStyle: "",
      currentID: 0,
      search: "",
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: '',
        // columnFilters: {},
        // sort: [
        //   {
        //     field: "",
        //     type: "",
        //   },
        // ],
        page: 1,
        perPage: 10,
      },
      columns: [
        {
          label: "#",
          field: "id",
        },
        {
          label: this.$t("name"),
          field: "name",
        },
        {
          label: this.$t("email"),
          field: "email",
        },
        {
          label: this.$t("phone"),
          field: "phone",
        },
        {
          label: this.$t("userType"),
          field: "type",
        },
        {
          label: this.$t("actions"),
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  mounted() {},

  beforeMount() {
    this.getUsers();
  },
  components: { PageHeader },
  methods: {
    LoginAs(userID) {
      this.$store.dispatch("LoginAs", userID).then((res) => {
        this.AlertSuccess("Connected As " + res.user.email);
        this.$router.push("/");
      });
    },

    deleteUser() {
      this.$swal({
        title: this.$t("deletePrompt"),
        text: this.$t("unrevetableOperation"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
      })
        .then((result) => {
          if (result === true) {
            this.$store
              .dispatch("deleteUser", this.$data.currentID)
              .then((response) => {
                this.getUsers();
              })
              .catch((response) => {
               
                this.AlertError();
              });
          }
        })
        .catch(this.$swal.noop);
    },
    showActions(e, id) {
      if (this.$data.actionsStyle != "") {
        this.$data.actionsStyle = "";
      } else {
        let top = e.y - document.body.getBoundingClientRect().top + 30;
        let left = e.currentTarget.getBoundingClientRect().left - 64;
        this.$data.actionsStyle =
          "display:block;left:" + left + "px;top:" + top + "px";
      }
      this.$data.currentID = id;
    },
    globalClick(e) {
      if (
        this.$data.actionsStyle != "" &&
        e.target.nodeName.toLowerCase() != "button"
      ) {
        this.$data.actionsStyle = "";
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getUsers();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getUsers();
    },
    onSearchChange(params) {
      this.updateParams({ search: params.searchTerm });
      this.getUsers();
    },

    onSortChange(params) {
    
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.getUsers();
    },

    getUsers() {

      this.$store.dispatch("getUsers", this.serverParams).then((r) => {
        this.totalRecords = r.meta.totalItems;
        this.rows = r.data;
       
      });
      return;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  height: 100%;
}

.innerBox h2 {
  color: #293b5a;
  font-weight: 700;
  font-size: 1.824rem;
}


.allEventsBtn {
  border: 1px solid #e4e4e4;
  border-radius: 21px;
  width: 181px;
  height: 42px;
}
.allEventsBtn svg {
  width: 19px;
  height: 14px;
  fill: #7b96c4;
  margin-right: 10px;
}
.allEventsBtn span {
  color: #293b5a;
}
.searchWrapper {
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
}
.search_open .searchWrapper {
  border-color: #adadad;
}
.searchWrapper input[type="search"] {
  width: 0;
  height: 32px;
  transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.search_open input[type="search"] {
  width: 275px;
  transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
}
.searchWrapper input[type="search"]::-webkit-input-placeholder {
  font-style: italic;
  color: #adadad;
  font-size: 0.9412rem;
}
.search_open .searchEvent svg {
  fill: #293b5a;
}

.eventsActions {
  position: relative;
}
.eventActionDropDown {
  width: 165px;
  position: absolute;
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  display: none;
}
.eventActionDropDown ul li a {
  padding: 10px 20px;
  display: block;
}

.eventActionDropDown ul li a:hover,
.eventActionDropDown ul li a:focus {
  background: #f4f4f4;
  text-decoration: none;
}
.eventActionDropDown ul li a svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  fill: #878787;
}
.eventActionDropDown ul li a span {
  display: inline-block;
  vertical-align: middle;
  color: #969696;
  font-size: 0.7647rem;
}
.eventActionDropDown ul li a:hover span,
.eventActionDropDown ul li a:focus span {
  color: #2f4262;
}
.eventActionDropDown ul li a:hover svg,
.eventActionDropDown ul li a:focus svg {
  fill: #7590bd;
}
.eventActionDropDown ul li a svg.i_deleteEvent {
  width: 13px;
  height: 16px;
}
.eventActionDropDown ul li a svg.i_editEvent {
  width: 14px;
  height: 15px;
}
.eventActionDropDown ul li a svg.i_contact {
  width: 16px;
  height: 12px;
}

.eventActionDropDown:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #dddddd;
  position: absolute;
  bottom: 100%;
  right: calc(50% - 6px);
}
.eventActionDropDown:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  bottom: 100%;
  right: calc(50% - 4px);
}
.eventActionDropDown ul {
  width: 100%;
}
.eventsActions button {
  height: 38px;
  width: 68px;
  float: left;
}
.eventsActions button span {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #a3a3a3;
  display: block;
  margin: 2px auto;
}
.progressNumber {
  display: inline-block;
  vertical-align: middle;
  width: 45px;
}
.eventProgress span:not(.progressBar):not(.progressNumber) {
  width: 170px;
  height: 5px;
  border-radius: 2.5px;
  background: #e2e2e2;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
}
.eventProgress span span {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  border-radius: 2.5px;
  background: #000;
}
.eventsCharts {
  width: 70%;
  height: 415px;
  float: right;
  margin-top: 40px;
}


</style>
