
const axios = require('axios');
import Config from '../../Config.js';


// *****************************************
// Create Instance
// *****************************************
const axiosV2 = axios.create({
    baseURL: Config.SETTINGS.API_V2_URL,
    headers: {
        'Content-Type': 'application/json',
        //'Authorization': 'bearer ' + store.state.access_token //window.getCookie('token'),
    }
});


export const apiRequest = (method, url, data, config = {}) => {
    return new Promise((resolve, reject) => {

        const params = {
            ...{
                method: method,
                url: url,
                data: data
            }, ...config
        }

        axiosV2(params).then(response => {
            if (response?.status == 201) {
                const event = new CustomEvent('s_apiSuccess', { detail: 'resource_created' });
                document.dispatchEvent(event);
            }
            resolve(response.data)
        })
        .catch(httpError => {
           
            if (config && config.showDefaultError !== false) {
                const event = new CustomEvent('s_apiError', { detail: httpError });
                document.dispatchEvent(event);
            }
            console.error(httpError)
            reject(httpError)
        })
    })
}
if (localStorage.getItem('tenantId')) {
    axiosV2.defaults.headers.common['x-tenant-id'] = localStorage.getItem('tenantId')
}

// *****************************************
// Interceptor 
// catch 401|400 errors
// *****************************************
axiosV2.interceptors.response.use(response => {
    return response;
}, error => {
    try {
        if (error.response.status === 401 && error.response.statusText == 'Unauthorized') {
            console.log('axios.interceptors.response 401 triggered - logging off')
            // store.dispatch('logoff')
        }
        console.log(error.response.data.statusCode, error.response)
        if ([400, 500, 404].includes(error.response.data.statusCode)) {
            return Promise.reject({
                message: error.response.data.message,
                statusCode: error.response.data.statusCode
            })
        }
        return Promise.reject(error.response);
    } catch (e) {
        console.error('axios.interceptors.preflight probably ', e)
    }
});




export default axiosV2