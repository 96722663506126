<template>
    <div>
        <div class="innerMainContent">

            <PageHeader :title="$t('settings')" icon="i_calender" :breadcrumbs="[{
                route: '/settings', name: $t('menu_settings')
            }]">

            </PageHeader>

            <ul class="nav nav-tabs tabsWrap">
                <li v-can:event_planner="'create'" v-if="hallManagerTabs" v-bind:class="selectedSettingsComponent == 'ContractTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'ContractTab'">
                        <span>הגדרות עסק</span>
                    </a>
                </li>
                <li v-if="resellerTabs" v-bind:class="selectedSettingsComponent == 'AppSettingsTab' ? 'active' : ''" ref="application">
                    <a @click="selectedSettingsComponent = 'AppSettingsTab'" href="#app_settings_tab">
                        <span>{{ $t('app_settings') }}</span>
                    </a>
                </li>
                <li v-if="resellerTabs || hallManagerTabs" v-bind:class="selectedSettingsComponent == 'HallsTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'HallsTab'" ref="halls">
                        <span>{{ $t('halls') }}</span>
                    </a>
                </li>
                <li v-if="resellerTabs || hallManagerTabs" v-bind:class="selectedSettingsComponent == 'TableTypesTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'TableTypesTab'" ref="tables">
                        <span>{{ $t('tableTypes') }}</span>
                    </a>
                </li>
                <li v-if="resellerTabs || hallManagerTabs" v-bind:class="selectedSettingsComponent == 'EquipmentTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'EquipmentTab'" ref="equipment">
                        <span>{{ $t('equipment') }}</span>
                    </a>
                </li>
                <li v-if="adminTabs" ref="banners" v-bind:class="selectedSettingsComponent == 'BannersTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'BannersTab'">
                        <span>{{ $t('banners') }}</span>
                    </a>
                </li>
                <li v-if="resellerTabs || hallManagerTabs" v-bind:class="selectedSettingsComponent == 'PresetsTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'PresetsTab'" ref="presets">
                        <span>{{ $t('presets') }}</span>
                    </a>
                </li>
                <li v-can:event_planner="'create'" v-if="hallManagerTabs" v-bind:class="selectedSettingsComponent == 'EventItemTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'EventItemTab'" ref="dishes">
                        <span>פריטי אירוע</span>
                    </a>
                </li>
                <li v-can:event_planner="'create'" v-if="hallManagerTabs" v-bind:class="selectedSettingsComponent == 'EventSettingsTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'EventSettingsTab'">
                        <span>הגדרות אירוע</span>
                    </a>
                </li>

                <li v-can:event_planner="'create'" v-if="hallManagerTabs || resellerTabs" v-bind:class="selectedSettingsComponent == 'ExportTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'ExportTab'">
                        <span>{{ $t('Export') }}</span>
                    </a>
                </li>
                <li v-can:event_planner="'create'" v-if="hallManagerTabs" v-bind:class="selectedSettingsComponent == 'TemplatesTab' ? 'active' : ''">
                    <a @click="selectedSettingsComponent = 'TemplatesTab'">
                        <span>{{ $t('templates') }}</span>
                    </a>
                </li>
            </ul>

            <div class="innerBox">
                <div class="tab-content">
                    <keep-alive>
                        <component v-bind:is="selectedSettingsComponent"></component>
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BannersTab from './Tabs/BannersTab.vue'
import HallsTab from './Tabs/HallsTab.vue'
import TableTypesTab from './Tabs/TableTypesTab.vue'
import EquipmentTab from './Tabs/EquipmentTab.vue'
import AppSettingsTab from './Tabs/AppSettingsTab.vue'
import PresetsTab from './Tabs/PresetsTab.vue'
import ExportTab from './Tabs/ExportTab.vue'

import EventItemTab from './Tabs/EventItemTab.vue'
import EventSettingsTab from './Tabs/EventSettingsTab.vue'
import ContractTab from './Tabs/ContractTab.vue'
import TemplatesTab from './Tabs/TemplatesTab.vue'


export default {
    name: 'Settings',
    data() {
        return {
            resellerTabs: false,
            adminTabs: false,
            hallManagerTabs: false,
            selectedTab: '',
            selectedSettingsComponent: null //'AppSettingsTab' //'EventSettingsTab'
        }
    },
    components: {
        BannersTab,
        HallsTab,
        TableTypesTab,
        EquipmentTab,
        AppSettingsTab,
        PresetsTab,
        EventSettingsTab,
        EventItemTab,
        ContractTab,
        ExportTab,
        TemplatesTab

    },
    beforeMount() {

        this.$data.selectedTab = (typeof this.$router.currentRoute.params.tab !== 'undefined' ? this.$router.currentRoute.params.tab : '');

        switch (this.$store.state.user.type) {
            case 1:
                this.adminTabs = true;
                break;
            case 2:
                this.resellerTabs = true;
                break;
            case 5:
                this.hallManagerTabs = true;
                //this.resellerTabs = true;
                break;
        }

    },

    mounted() {

        this.$store
            .dispatch("getUserSettings")
            .catch((err) => {
                this.AlertError();
            });

        if (this.selectedTab != '') {
            let elm = this.$refs[this.$data.selectedTab];
            if (typeof elm !== 'undefined') elm.click();
        }
        document.querySelector('.nav.nav-tabs li a').click();
    }
}

</script>
<style scoped>
.editEventActions {
    float: left;
    margin-top: 6px;
}

.editEventActions .backBtn span {
    color: #7c97c5;
    font-weight: 700;
    font-size: 1.235rem;
    display: inline-block;
    vertical-align: middle;
}

.editEventActions .backBtn svg {
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 18px;
    fill: #293b5a;
    margin-right: 5px;
}

.editEventActions .deleteBtn {
    width: 218px;
    height: 54px;
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 27px;
    margin-left: 30px;

}

.editEventActions .deleteBtn span {
    color: #293b5a;
    font-weight: 700;
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
}

.editEventActions .deleteBtn svg {
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 23px;
    fill: #849dc8;
    margin-left: 10px;
}

.innerBox {
    padding: 30px;
}

.tabsWrap li a span {
    font-weight: bold;
    color: #a3a3a3;
    font-size: 1.294rem;
    display: inline-block;
    vertical-align: bottom;
}

.tabsWrap li a svg {
    display: inline-block;
    vertical-align: bottom;
    fill: #a3a3a3;
    margin-left: 10px;
}

.tabsWrap li a svg.i_event_tab {
    width: 36px;
    height: 36px;
}

.tabsWrap li a svg.i_offer_tab {
    width: 30px;
    height: 36px;
}

.tabsWrap li a svg.i_meeting_tab {
    width: 31px;
    height: 36px;
}

.tabsWrap li.active a span {
    color: #7d97c5;
}

.tabsWrap li.active a svg {
    fill: #293b5a;
}

.nav-tabs>li>a {
    border-radius: 0;
    padding: 15px 40px;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    border-color: transparent;
    border-bottom-color: #293b5a;
    background: transparent;
}

.nav>li>a:hover,
.nav>li>a:focus {
    background-color: transparent;
    border-color: transparent;
}

.formWrap .inputWrap {
    margin-top: 20px;
}

@media only screen and (min-width:580px) and (max-width:768px) {
    .editEventActions .deleteBtn {
        width: 185px;
        height: 45px;
        margin-left: 15px;
    }

    .h1Wrap {
        width: 210px;
    }
}
</style>
