<template>
    <div>
        <div class="innerMainContent">
            <div class="innerHeader">
                <div class="h1Wrap">
                    <svg class="i_profile"><use xlink:href="#i_profile"></use></svg>
                    <div class="innerH1Wrap">
                        <h1>{{ $t('equipmentEdit') }}</h1>
                        <ul class="breadcrumb">
                            <li><router-link to="/">{{ $t('menu_hp') }}</router-link></li>
                            <li><router-link to="/settings">{{ $t('menu_settings') }}</router-link></li>
                            <li><router-link to="/settings/equipment">{{ $t('equipments') }}</router-link></li>
                            <li class="active">{{ $t('equipmentEdit') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="editEventActions">
                    <router-link tag="button" class="backBtn" to="/settings/equipment">
                        <span>{{ $t('back') }}</span>
                        <svg class="i_back"><use xlink:href="#i_longArr"></use></svg>
                    </router-link>
                </div>
            </div>
            <div class="innerBox">
                <form action="" id="editBanner" class="formWrap" @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-xs-12 containerWidth">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="name">*{{ $t('equipment_name') }}</label>
                                        <input type="text" id="name" name="name" v-model="equipment.title" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="target">*{{ $t('shape') }}</label>
                                        <div class="selectWrap">
                                            <select name="target" id="target" v-model="equipment.shape" required>
                                                <option v-for="shape in $t('shapes')" v-bind:value="shape.id" v-bind:selected="shape.id == 'all'">{{ shape.title }}</option>
                                            </select>
                                            <svg class="i_select"><use xlink:href="#i_select"></use></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap submitWrap">
                                        <button class="submit">{{ $t('save') }}</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AddEquipment',
        data() {
            return {
                equipment:{
                    id:'',
                    title:'',
                    shape:'SQU',
                }
            }
        },
        beforeMount(){
            //check permissions
            if(this.$store.state.user.type != 2){
                this.$router.push('/');
            }

            //get equipment
            this.$data.equipment.id = this.$router.currentRoute.params.id;
            this.$store.dispatch('getEquipment',this.$data.equipment.id)
                                    .then(response =>{
                                        //success
                                        this.$data.equipment = response;
                                    })
                                    .catch(err => {
                                        //err
                                    })
        },
        methods:{
            submitForm(){
                this.$data.equipment.color = this.$data.equipment.color.hex;
                this.$store.dispatch('updateEquipment',this.$data.equipment)
                    .then(response => {
                        this.$router.push('/settings/equipment');
                    })
                    .catch(response => {
                        let err = this.$t('fatalError');

                        this.$swal({
                            confirmButtonText:this.$t('okButton'),
                            type:'error',
                            title:err,
                            timer:3000
                        }).catch(this.$swal.noop);
                    })
            }
        }
    }

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .innerMainContent{
        padding:55px 45px;
        float: right;
        width:100%;
    }

    .editEventActions{
        float:left;
        margin-top: 6px;
    }
    .editEventActions .backBtn span{
        color:#7c97c5;
        font-weight:700;
        font-size:1.235rem;
        display:inline-block;
        vertical-align:middle;
    }
    .editEventActions .backBtn svg{
        display:inline-block;
        vertical-align:middle;
        width:26px;
        height:18px;
        fill:#293b5a;
        margin-right:5px;
    }
    .editEventActions .deleteBtn{
        width:218px;
        height:54px;
        background: #fff;
        border:1px solid #d4d4d4;
        border-radius:27px;
        margin-left:30px;

    }
    .editEventActions .deleteBtn span{
        color:#293b5a;
        font-weight:700;
        font-size:1rem;
        display:inline-block;
        vertical-align:middle;
    }
    .editEventActions .deleteBtn svg{
        display:inline-block;
        vertical-align:middle;
        width:19px;
        height:23px;
        fill:#849dc8;
        margin-left:10px;
    }
    .innerBox{
        padding:30px;
    }
    .h1Wrap svg.i_profile{
        width:26px;
        height:30px;
    }
</style>
