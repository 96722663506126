import Config from "../Config";
const axios = require('axios').default;
const AuthStore = {
    namespaced: false, 
    state: {

    },
    actions: {
      
        LoginAs: ({ commit, rootState, dispatch }, userID) =>  new Promise((resolve, reject) => {
            dispatch("restPost", {
                route: "auth/connect-as-user/" + userID,
                data: {},
              })
              .then((res) => {
                commit('setAccessToken', res.token)
                rootState.user = res.user
                //commit('setUser', res.user)
                
                resolve(res)
              }).catch(function (error) {
                console.warn(error);
                reject()
            });;
          }),

    },
    mutations: {
     
    },
    getters: {

    }
}
export default AuthStore;
