import { apiRequest } from '../core/http/axios_api_v2';

const ModuleTemplateStore = {
    namespaced: false,
    state: {
        meetings: []
    },
    actions: {
        addMeeting: ({ dispatch, state }, o) => new Promise((a, r) => {
            apiRequest('post', 'meeting', o).then(_meeting => {
                state.meetings.push(_meeting)
                a(_meeting)
            }).catch(e => r(e))
        }),
        getMeetings: ({ commit, dispatch }, id) => apiRequest('get', 'meeting')
    },
    mutations: {

    },
    getters: {

    }
}
export default ModuleTemplateStore;
