import ROLES_NUM from '../../core/auth/roles.enum';

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/Login'),
        meta: {
            requiresAuth: false,
            matchHeight: true
        }
    },
    {
        path: '/login/recover',
        name: 'passwordRecovery',
        component: () => import('@/components/PasswordRecovery'),
        meta: {
            requiresAuth: false,
            matchHeight: true
        }
    },

    {
        path: '/login/recovery/:token',
        name: 'passwordRecoveryWithToken',
        component: () => import('@/components/PasswordRecovery'),
        meta: {
            requiresAuth: false,
            matchHeight: true
        }
    },

]