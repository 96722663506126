<template>
    <div class="actionsWrap">
        <router-link to="/guests/myPlace" v-bind:class="(active == 'myPlace' || active == 'guest') ? 'active' : ''">
            <div class="dottedCircle">
                <svg class="i_myplace"><use xlink:href="#i_myplace"></use></svg>
            </div>
            <span>{{ $t('myPlace') }}</span>
        </router-link>
        <router-link v-if="!hideTbls" to="/event/tables/preset" href="#" v-bind:class="active == 'tables' ? 'active' : ''">
            <div class="dottedCircle">
                <svg class="i_table"><use xlink:href="#i_table"></use></svg>
            </div>
            <span>{{ $t('guestsOrder') }}</span>
        </router-link>
        <router-link to="/" >
            <div class="dottedCircle">
                <svg class="i_home"><use xlink:href="#i_home"></use></svg>
            </div>
            <span>{{ $t('mainScreen') }}</span>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "KioskFooter",
        props:{
            active:{
                type:String
            },
            hideTables:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                hideTbls:false
            }
        },
        mounted(){
            this.hideTbls = this.hideTables;
            if(typeof this.$store.state.kioskEvent === 'object' && this.$store.state.kioskEvent.hasOwnProperty('isConference') && this.$store.state.kioskEvent.isConference){
                this.hideTbls = true;
            }
        }
    }
</script>

<style>
    div#nprogress{
        display: none !important;
    }
</style>