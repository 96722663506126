var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.domID,staticStyle:{"height":"400px","overflow-y":"scroll"},attrs:{"id":"dragablleItems"}},[_c('span',{staticClass:"item matchHeight"},[_vm._m(0),_c('div',{staticClass:"itemLabel"},[_vm._v(_vm._s(_vm.$t("square")))])]),_c('span',{staticClass:"item matchHeight"},[_vm._m(1),_c('div',{staticClass:"itemLabel"},[_vm._v(_vm._s(_vm.$t("circle")))])]),_c('span',{staticClass:"item matchHeight"},[_vm._m(2),_c('div',{staticClass:"itemLabel"},[_vm._v(_vm._s(_vm.$t("rectangle")))])]),_c('span',{staticClass:"item matchHeight"},[_vm._m(3),_c('div',{staticClass:"itemLabel"},[_vm._v(_vm._s(_vm.$t("ellipse")))])]),_c('span',{staticClass:"item"},[_vm._m(4),_c('div',{staticClass:"itemLabel"},[_vm._v(_vm._s(_vm.$t("wood")))])]),_vm._l((_vm.tableList),function(table){return _c('span',{staticClass:"item"},[_c('div',{staticClass:"tableWrap",class:table.shape != 'drop'
          ? table.shape == 'wood'
            ? 'squareTable ' + table.shape
            : table.shape == 'ellipseTable'
            ? 'rectangleTable ' + table.shape
            : table.shape
          : '',attrs:{"data-type":"table","data-side1":table.side1 ? table.side1 : '',"data-side2":table.side2 ? table.side2 : '',"data-side3":table.side3 ? table.side3 : '',"data-side4":table.side4 ? table.side4 : '',"data-wood":(table.attrs && table.attrs.isWood) ? 'true' : 'false',"data-capacity":table.capacity,"data-shape":_vm.ShapeClass(table.shape),"data-tablehead":table.shape == 'rectangleTable'
          ? Math.round(table.capacity / 4 / 2): (table.shape == 'ellipseTable' ? 1 : '')}},[(table.shape != 'drop')?_c('div',{class:table.shape != 'ellipseTable' ? _vm.ShapeClass(table.shape) : 'rectangle'}):_vm._e(),(table.shape == 'drop')?_c('img',{attrs:{"src":"/static/images/drop.png"}}):_vm._e()]),_c('div',{staticClass:"itemLabel"},[_vm._v(_vm._s(table.title))])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableWrap squareTable",attrs:{"data-capacity":"12","data-type":"table","data-shape":"square"}},[_c('div',{staticClass:"square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableWrap circleTable",attrs:{"data-capacity":"12","data-type":"table","data-shape":"circle"}},[_c('div',{staticClass:"circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableWrap rectangleTable",attrs:{"data-tablehead":"2","data-capacity":"12","data-type":"table","data-shape":"rectangle"}},[_c('div',{staticClass:"rectangle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableWrap rectangleTable ellipseTable",attrs:{"data-tablehead":"1","data-capacity":"12","data-type":"table","data-shape":"ellipse"}},[_c('div',{staticClass:"rectangle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableWrap squareTable wood",attrs:{"data-capacity":"12","data-type":"table","data-shape":"square"}},[_c('div',{staticClass:"square"})])}]

export { render, staticRenderFns }