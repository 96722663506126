const TPL_EN = {
    langName:"English",
    langs:[
        {code:'he',name:'Hebrew'},
        {code:'en',name:'English'},
        {code:'ru',name:'Русский'},
    ],
    loginTitle:"Welcome Back!",
}

export default TPL_EN