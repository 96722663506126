<template>
  <div class="text-left" style="display:inline-block">
    <div>  
      <!-- v-on-clickaway="hide" -->
      <slot name="button">
        <button @click="toggle" @keydown.esc="hide" type="button" :class="textClasses">
          <slot name="button-content">
            <span v-html="text" />
            <svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </slot>
        </button>
      </slot>
    </div>

    <div v-show="show" :class="menuClasses" style="z-index:999">
      <div class="rounded-md bg-white shadow-xs">
        <div class="py-1">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { directive as onClickaway } from 'vue-clickaway2'
  export default {
    directives: {
      // onClickaway
    },
    props: {
      text: {
        type: String,
        default: 'Options'
      },
      textClasses: {
        type: String,
        default: 's_btn d-flex items-center'
      },
      menuClasses: {
        type: String,
        default: 'p-abs'
      }
    },
    data() {
      return {
        show: false
      }
    },
    methods: {
      toggle() {
        this.show = !this.show
      },
      hide() {
        this.show = false
      }
    }
  }
</script>