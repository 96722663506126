
// prototypes
////////////////////////////////////////////////////////////
if (!Array.IsNotArrayOrEmpty) {
    Array.IsNotArrayOrEmpty = function (obj) {
        return (
            typeof obj === 'undefined'
            || !obj
            || !Array.isArray(obj)
            || obj.length == 0
        )
    };
}

window.downloadFile = function (fileUrl) {
    var a = document.createElement('A');
    a.href = fileUrl;
    a.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
import Vue from 'vue'
import App from './App'

import router from './router';
import store from './store';
import socket from './core/http/socket'
// plugins import
import VueSweetAlert from 'vue-sweetalert';
import VueTippy from 'vue-tippy';
import VueHtml2Canvas from 'vue-html2canvas';
import vSelect from 'vue-select';
import Dropdown from '@/components/UI/Dropdown/index';

import AlertMixing from './mixings/Alerts'
// import VueSocketIO from 'vue-socket.io'
import Notifications from 'vue-notification'

import Acl from './core/acl/index'
import './core/filters/date_filters'
import './core/filters/number'
import PageHeader from './components/Common/PageHeader.vue'
import Modal from './components/Common/Modal.vue'
import Preloader from './components/Common/Preloader.vue'
import FormInput from './components/UI/FormInput.vue'
import FormSelect from './components/UI/FormSelect.vue'

// css
import './scss/seatup_style.scss'
import 'vue-select/dist/vue-select.css';
import "@fortawesome/fontawesome-free/css/all.css";

// datatable VueGoodTablePlugin
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

// mixings import
import FileStoragePath from './mixings/FileStoragePath';
import Auth from './mixings/Auth';



// Components
////////////////////////////////////////////////////////////
Vue.component('v-select', vSelect)
Vue.component('PageHeader', PageHeader)
Vue.component('Modal', Modal)
Vue.component('FormInput', FormInput)
Vue.component('FormSelect', FormSelect)
Vue.component('Preloader', Preloader)

// Plugins
////////////////////////////////////////////////////////////
Vue.use(Acl, '')
Vue.use(VueGoodTablePlugin); // datatable VueGoodTablePlugin
Vue.use(VueHtml2Canvas);
Vue.use(VueSweetAlert, { useRejections: false });
Vue.use(VueTippy); //add tippy tooltips
Vue.use(Notifications)
Vue.use(Dropdown)


// Mixings
//////////////////////////////////////////////////////////////////
Vue.mixin(AlertMixing);
Vue.mixin(FileStoragePath);
Vue.mixin(Auth);



// Init
//////////////////////////////////////////////////////////////////
Vue.config.productionTip = false;
Vue.prototype.$socket = socket
new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app');




// Generic error handler
// Vue.config.errorHandler = function(err, vm, info) {
//     console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// }

