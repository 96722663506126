export const  domCenterPoint = (dom) => {
    let centerX = dom.offsetLeft + dom.offsetWidth / 2;
    let centerY = dom.offsetTop + dom.offsetHeight / 2;
    return [centerX, centerY]
}

export const  domPosition = (dom) => {
    let centerX = dom.offsetLeft ;
    let centerY = dom.offsetTop ;
    return [centerX, centerY]
}