<template>
    <div>
        <div class="innerMainContent">
            <div class="innerHeader">
                <div class="h1Wrap">
                    <svg class="i_profile"><use xlink:href="#i_profile"></use></svg>
                    <div class="innerH1Wrap">
                        <h1>{{ $t('bannerAdd') }}</h1>
                        <ul class="breadcrumb">
                            <li><router-link to="/">{{ $t('menu_hp') }}</router-link></li>
                            <li><router-link to="/settings">{{ $t('menu_settings') }}</router-link></li>
                            <li><router-link to="/settings/banners">{{ $t('banners') }}</router-link></li>
                            <li class="active">{{ $t('bannerAdd') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="editEventActions">
                    <router-link tag="button" class="backBtn" to="/settings/banners">
                        <span>{{ $t('back') }}</span>
                        <svg class="i_back"><use xlink:href="#i_longArr"></use></svg>
                    </router-link>
                </div>
            </div>
            <div class="innerBox" v-if="!showLoader">
                <form action="" id="editBanner" class="formWrap" @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-xs-12 containerWidth">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="name">*{{ $t('banner_name') }}</label>
                                        <input type="text" id="name" name="name" v-model="banner.name" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="url">{{ $t('banner_url') }}</label>
                                        <input type="text" id="url" name="url" v-model="banner.url">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <FileUpload v-on:uploaded="setFile" :label="'banner_file'" :placeHolder="'addFile'"></FileUpload>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="target">*{{ $t('banner_target') }}</label>
                                        <div class="selectWrap">
                                            <select name="target" id="target" v-model="banner.target" required>
                                                <option v-for="target in $t('banner_targets')" v-bind:value="target.id" v-bind:selected="target.id == 'all'">{{ target.title }}</option>
                                            </select>
                                            <svg class="i_select"><use xlink:href="#i_select"></use></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="Array.isArray($store.state.users) && $store.state.users.length > 0">
                                <div class="col-sm-6">
                                    <div class="inputWrap">
                                        <label for="resellers">*{{ $t('banner_resellers') }}</label>
                                        <div class="selectWrap">
                                            <select style="height:100%" name="resellers" id="resellers" v-model="banner.resellers" multiple required>
                                                <option value="all" selected="selected">{{ $t('all') }}</option>
                                                <option v-for="user of $store.state.users" :key="user.id" :value="user.id">{{ user.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="inputWrap submitWrap">
                                        <button class="submit">{{ $t('save') }}</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </form>
            </div>

            <div class="innerBoxLoader" v-if="showLoader">
                <rotate-loader style="opacity: .6" :color="'#132C43'"></rotate-loader>
            </div>
        </div>
    </div>
</template>

<script>
    import FileUpload from '../Common/FileUpload'
    import RotateLoader from 'vue-spinner/src/RotateLoader.vue'
    export default {
        name: 'AddBanner',
        data() {
            return {
                showLoader:false,
                banner:{
                    name:'',
                    url:'',
                    image:'',
                    target:'all',
                    resellers:['all'],
                },
            }
        },
        components:{
            RotateLoader,
            FileUpload
        },
        mounted(){
            niceInputFile($("input[type='file']"));
        },

        beforeMount(){
            //check permissions
            if(this.$store.state.user.type != 1){
                this.$router.push('/');
            } else {
                this.$store.dispatch('getUsers');
            }

        },
        methods:{
            setFile(_){
                console.log("SetFile");
                this.banner.image = _;
            },
            submitForm(){
                this.showLoader = true;

                this.$store.dispatch('addBanner',this.$data.banner)
                    .then(response => {
                        this.showLoader = false;
                        this.$swal({
                            confirmButtonText:this.$t('okButton'),
                            type:'success',
                            title:this.$t('bannerAdded'),
                            timer:2000
                        })
                            .then(banner =>{
                                this.$router.push('/settings/banners/' + response.id);
                            })
                            .catch(this.$swal.noop);
                    })
                    .catch(response => {
                        this.showLoader = false;
                        let err = this.$t('fatalError');

                        this.$swal({
                            confirmButtonText:this.$t('okButton'),
                            type:'error',
                            title:err,
                            timer:3000
                        }).catch(this.$swal.noop);
                    })
            }
        }
    }

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .innerMainContent{
        padding:55px 45px;
        float: right;
        width:100%;
    }

    .editEventActions{
        float:left;
        margin-top: 6px;
    }
    .editEventActions .backBtn span{
        color:#7c97c5;
        font-weight:700;
        font-size:1.235rem;
        display:inline-block;
        vertical-align:middle;
    }
    .editEventActions .backBtn svg{
        display:inline-block;
        vertical-align:middle;
        width:26px;
        height:18px;
        fill:#293b5a;
        margin-right:5px;
    }
    .editEventActions .deleteBtn{
        width:218px;
        height:54px;
        background: #fff;
        border:1px solid #d4d4d4;
        border-radius:27px;
        margin-left:30px;

    }
    .editEventActions .deleteBtn span{
        color:#293b5a;
        font-weight:700;
        font-size:1rem;
        display:inline-block;
        vertical-align:middle;
    }
    .editEventActions .deleteBtn svg{
        display:inline-block;
        vertical-align:middle;
        width:19px;
        height:23px;
        fill:#849dc8;
        margin-left:10px;
    }
    .innerBox{
        padding:30px;
    }
  
</style>
