import Vue from 'vue';
function twoDigits(d){
    return (d<10?'0':'') + d
}
Vue.filter('formatDate', function(value) {
    if (value) {
        //return value;
        let d = new Date(value)
        return `${twoDigits(d.getDate().toString())}/${twoDigits((d.getMonth()+1).toString())}/${d.getFullYear().toString().substr(-2)}
        ${twoDigits(d.getHours().toString())}:${twoDigits(d.getMinutes().toString())}`
        
    }
});

Vue.filter('dateOnly', function(value) {
    if (value) {
        let d = new Date(value)
        return `${twoDigits(d.getDate().toString())}/${twoDigits((d.getMonth()+1).toString())}/${d.getFullYear().toString().substr(-2)} `
        
    }
});

Vue.filter('hourOnly', function(value) {
    if (value) {
        let d = new Date(value)
        return `${twoDigits(d.getHours().toString())}:${twoDigits(d.getMinutes().toString())}`
        
    }
});