export const ROLES_NUM = {
    Admin: 1,
    Resseler: 2,
    Client: 3,
    HallManager: 5,
    HallManagerChild: 6
}

export default ROLES_NUM

