<template>
  <div class="container-fluid p-4">
    <h2 class="mb-5">{{$t("CanomyWelcome")}}</h2>
    <div class="col-sm-8">

      <div class="d-flex w-50 items-center">
        <h3 class="ml-5">קבלת פנים</h3>
        <div class="switch-toggle switch-candy w-50">

          <input id="canopy_in" name="view3" type="radio" :checked="fieldValues['canopi'] == 'inside'" />
          <label for="canopy_in" @click="fieldValues['canopi'] = 'inside'">
            פנימי
          </label>

          <input id="canopy_out" name="view3" type="radio" :checked="fieldValues['canopi'] == 'outside'" />
          <label for="canopy_out" @click="fieldValues['canopi'] = 'outside'">
            חיצוני
          </label>
          <a></a>
        </div>
      </div>
      <hr>
      <form v-for="field of fields" :key="field.title" class="canopy-tile">
        <h3>{{ field.title }}</h3>
        <!-- multiple  -->
        <div v-if="field.type == 'multiple'">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="inputWrap">
                <input type="text" placeholder="" v-model="field.newValue" />
              </div>
            </div>
            <div class="">
              <button class="s_btn px-3 mx-4" @click.prevent="addMulti(field)">
                {{ $t("add") }}
              </button>
            </div>
          </div>
          <div class="d-flex" style="flex-direction:column">
            <span class="s-badge" v-for="val of fieldValues[field.title]">
              <button @click.prevent="remove(field.title, val)"> ❌ </button>
              {{ val }}
            </span>
          </div>
        </div>

        <!-- single -->
        <div v-else class="d-flex">
          <div class="flex-fill">
            <div class="inputWrap">
              <input type="text" class="" placeholder="" v-model="fieldValues[field.title]" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-4">
      <div class="inputWrap textareaWrap">
        <label for="">{{ $t("notes") }}</label>
        <textarea v-model="eventPlanner.canopyNotes" maxlength="200" class="noteWrapper"></textarea>
        <span class="charsLength"><span class="charNum">200</span>/200</span>
      </div>
    </div>
    <button class="s_btn px-3 mx-4" @click.prevent="save()">
      {{ $t("save") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CanopyAndWelcome",

  data() {
    return {
      fieldValues: {},
      fields: [
        {
          title: "כמות שושבינים",
          type: "single",
        },

        {
          title: "עמדות חיצוניות",
          type: "multiple",
        },

        {
          title: "סדר כניסה לחופה",
          type: "multiple",
        },
      ],
    };
  },
  watch: {
    'eventPlanner': function () {
      this.fieldValues = this.eventPlanner.canopyDetails || {}
    }
  },
  props: {
    eventPlanner: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},

  methods: {
    remove(title, value) {
      
      const index = this.fieldValues[title].indexOf(value);
      if (index !== -1) this.fieldValues[title].splice(index, 1);
      this.$forceUpdate();
      this.save();
    },
    loadData: function (id) {

      this.itemName = this.items[id]["task"];
      this.updateid = id;
      this.update = true;
    },

    save() {
      this.$store.dispatch("restPatch", {
        route: `event-planner/${this.eventPlanner.id}`,
        data: {
          id: this.eventPlanner.id,
          canopyDetails: this.fieldValues,
          canopyNotes: this.eventPlanner.canopyNotes
        },
      });
    },
    addSingle(field) {
      let _f = this.fieldValues;

      _f[field.title] = field.newValue;
      this.$forceUpdate();
    },
    addMulti(field) {
      let _f = this.fieldValues;
      if (_f == null) _f = {};
      if (!_f[field.title] || !Array.isArray(_f[field.title]))
        _f[field.title] = [];

      if (field.newValue != ''){
        _f[field.title].push(field.newValue);
        field.newValue = ''
      }

      this.fieldValues = _f
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
@import url("../../scss/UI/toggle_switch.scss");

h3 {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 15px 0
}

.canopy-tile {
  border: 1px solid #ccc;
  padding: 20px;
  float: right;
  width: calc(50% - 20px);
  margin: 10px;
  border-radius: 20px;
  

}

.inputWrap input,
.inputWrap select {
  width: 100%;
  height: 42px;
  line-height: 32px;
}

.editable {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 30px;

  &:focus {
    background-color: white;
    border: 1px solid #ccc;
  }
}
</style>