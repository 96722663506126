export default {
    methods: {
        AlertSuccess(msg = '', options = {}) {
            const _o = {
                ...{
                    confirmButtonText: this.$t("okButton"),
                    type: "success",
                    title: msg ? msg : this.$t("done_successfully"),
                    timer: 3000,
                }, ...options
            }
            return this.$swal(_o).catch(this.$swal.noop);
        },
        AlertError(msg = '', options = {}) {
            const _o = {
                ...{
                    confirmButtonText: this.$t("okButton"),
                    type: "error",
                    title: msg ? msg : this.$t("fatalError"),
                    timer: 3000,
                }, ...options
            }
            this.$swal(_o).catch(this.$swal.noop);

        },
        AlertConfirm(txt = '', options = {}) {
            const _o = {
                ...{
                    title: txt ? txt : this.$t("deletePrompt"),
                    text: this.$t("unrevetableOperation"),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                }, ...options
            }
            return this.$swal(_o).catch(this.$swal.noop);
        }


    },
};
